<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <!--
          Background backdrop, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->

      <transition
        enter-active-class="transform duration-400 ease-out"
        enter-from-class="transform opacity-0"
        enter-to-class="transform opacity-100"
        leave-active-class="transform duration-400 delay-200  ease-in"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
      >
      <div v-if="show" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </transition>

      <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="transform opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div v-if="showDetails" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <!--
                  Modal panel, show/hide based on modal state.

                  Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                  Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                                v-if="!loading"
                                @click="$emit('close')"
                                type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            <span class="sr-only">Close</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>

                        </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10" :class="'bg-' + iconBgColor + '-100'">
                            <!-- Heroicon name: outline/exclamation -->
                            <svg v-if="!customIcon" class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>

                            <slot v-if="customIcon" name="icon">
                            </slot>
                        </div>
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                <slot name="title"></slot>
                            </h3>
                            <div class="mt-2 text-sm text-gray-500">
                                <slot name="message"></slot>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                                :disabled="loading"
                                :class="getColorClass"
                                @click="$emit('confirm')"
                                type="button" class="w-full flex items-center inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                         <i class="el-icon-loading mr-1" v-if="loading"></i>   {{ confirmText }}
                        </button>
                        <button
                                :disabled="loading"
                                @click="$emit('cancel')"
                                :class="{
                                'bg-opacity-50 text-gray-300 cursor-not-allowed': loading,
                                'text-gray-700 hover:text-gray-500': !loading
                                }"
                                type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm">
                            {{ cancelText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
      </transition>
    </div>
</template>

<script>
export default {
  name: 'AlertConfirm',
  props: {
    iconBgColor: {
      type: String,
      default() {
        return 'red';
      },
    },
    customIcon: {
      type: Boolean,
      default() {
        return false;
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    confirmColor: {
      type: String,
      default() {
        return 'red';
      },
    },
    confirmText: {
      type: String,
      default() {
        return 'Yes';
      },
    },
    cancelText: {
      type: String,
      default() {
        return 'Cancel';
      },
    },
  },
  watch: {
    show(){
      this.showDetails = this.show;
    }
  },
  data(){
    return {
      showDetails: false
    }
  },
  computed: {
    getColorClass() {
      if (this.loading) {
        return `bg-${this.confirmColor}-600 bg-opacity-50 cursor-not-allowed`;
      }
      return `bg-${this.confirmColor}-600 hover:bg-${this.confirmColor}-700 focus:ring-${this.confirmColor}-500`;
    },
  },
};
</script>

<style scoped>

</style>
