<template>
    <div class="rounded shadow bg-white border-b border-gray-200">
        <div class="title flex justify-between items-center flex-wrap sm:flex-no-wrap border-b border-gray-200">
                <div class="flex items-center w-full p-3">
                    <div class="flex  flex-grow">
                        <h3 class="text-base leading-6 font-medium text-gray-900">
                            {{ title }}
                        </h3>
                        <p class="mt-1 text-sm leading-5 text-gray-500">
                            {{ description }}
                        </p>
                    </div>
                    <div class="flex items-center">
                        <slot name="cta"></slot>
                    </div>
                </div>
        </div>
        <div :class="paddingClass">
            <slot></slot>
        </div>
    </div>

</template>

<script>
export default {
  name: 'DashboardWidget',
  props: {
    paddingClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>

</style>
