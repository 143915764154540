<template>
    <page-wrapper title="Play Logs" :show-feed="false">

        <template slot="nav-cta">

            <el-dropdown  @command="downloadFile" class="ml-3">
                <el-button
                        :loading="exporting"
                        :disabled="isLoading"
                        icon="el-icon-download"
                        type="success">Export
                    <i
                            class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="xlsx">Excel</el-dropdown-item>
                    <el-dropdown-item command="csv">CSV</el-dropdown-item>
                    <el-dropdown-item command="ods">ODS</el-dropdown-item>
                    <el-dropdown-item command="html">HTML</el-dropdown-item>
                    <el-dropdown-item command="txt">Text</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </template>
        <template slot="nav-search">
            <div class="flex-grow lg:block" :class="{
            '': date.show,
             'hidden': !date.show,
            }">
                <div class="relative max-w-sm md:max-w-md mx-auto">
                    <i class="el-icon-search top-0 left-0 absolute text-lg text-gray-500 ml-4 mt-3"></i>
                    <el-date-picker
                            class="!w-full"
                            :disabled="isLoading"
                            v-model="datepicker.value"
                            type="daterange"
                            format="dd MMM yyyy"
                            :picker-options="datepicker.pickerOptions"
                            range-separator="To"
                            start-placeholder="Start date"
                            end-placeholder="End date"
                            :clearable="false"
                            align="right">
                    </el-date-picker>
                </div>
            </div>
            <div
                    @click="date.show = !date.show"
                    :class="{
                    'flex-0': date.show,
                    'flex-grow': !date.show,
                    }"
                    class="lg:hidden text-right text-2xl text-gray-600 hover:text-gray-900 cursor-pointer">
                <i
                        v-if="!date.show"
                        class="el-icon-search"></i>
                <i
                        v-if="date.show"
                        class="el-icon-close"></i>
            </div>
        </template>

        <template slot="content">



          <div class="flex grid grid-cols-4 gap-3 w-full">
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
              <simple-stat-box :loading="chart.loading">
                <template slot="title">
                  Media Plays
                </template>
                <template slot="value">
                  {{ stats && stats.plays ? stats.plays.toLocaleString() : 0 }}
                </template>
              </simple-stat-box>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
              <simple-stat-box  :loading="chart.loading">
                <template slot="title">
                  Duration
                </template>
                <template slot="value">
                                          <span v-if="stats && stats.duration">
                                            {{ stats.duration | secs_to_human }}
                                          </span>
                  <span v-else>
                    0 secs
                  </span>
                </template>
              </simple-stat-box>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
              <simple-stat-box  :loading="chart.loading">
                <template slot="title">
                  Playlists
                </template>
                <template slot="value">
                  {{ stats && stats.playlists ? stats.playlists : 0 }}
                </template>
              </simple-stat-box>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
              <simple-stat-box  :loading="chart.loading">
                <template slot="title">
                  Media Items
                </template>
                <template slot="value">
                  {{ stats && stats.media_items ? stats.media_items : 0 }}
                </template>
              </simple-stat-box>
            </div>
            <div class="col-span-4">
              <div class="p-8 bg-white shadow border border-gray-50 rounded-lg h-full overflow-hidden">
                <div v-loading="chart.loading" class="p-4 pt-0">

                  <div class="grid gap-x-6 grid-cols-3">
                    <div>
                      <form-input label="Group by">
                        <el-select
                          class="w-full"
                          @change="() => fetchChart()"
                          v-model="chart.group_by"
                          placeholder="Group by..."
                        >
                          <el-option
                            v-for="(item, index) in group_by"
                            :key="index"
                            :label="item.name"
                            :value="item.value"
                          >
                            {{ item.name }}
                          </el-option>
                        </el-select>
                      </form-input>
                    </div>
                    <div>
                      <form-input label="Plot by">
                        <el-select
                          class="w-full"
                          @change="() => fetchChart()"
                          v-model="chart.plot_by"
                          placeholder="Plot by..."
                        >
                          <el-option
                            v-for="(item, index) in plot_by"
                            :key="index"
                            :label="item.name"
                            :value="item.value"
                          >
                            {{ item.name }}
                          </el-option>
                        </el-select>
                      </form-input>
                    </div>
                  </div>

                </div>
                <div class="my-3">
                  <div v-if="!chart.loading" class="w-full">
                    <apex-chart type="bar" height="470" :options="getChartOptions"
                                :series="chart.series" />
                  </div>
                  <div v-else>
                    <spin-loader
                      height="h-72"
                      bg="transparent" />
                  </div>
                </div>

              </div>
            </div>
            <div class="col-span-4">

              <div class="w-full">
                  <pagination-details
                    v-if="results.meta"
                    class="my-6"
                    :rpp="10"
                    type="video plays"
                    :total-pages="results.meta.pageCount"
                    :current-page="results.meta.current"
                    :total="results.meta.totalResult"
                    @change="(page) => { fetch(page) }">
                    <template slot="actions">
                        <sort-order-by
                          offset-class="mr-5"
                          v-model="sort_options"
                          :sort="sort"
                          :order="order"
                          @change="onSortChange"
                        />
                    </template>
                  </pagination-details>
              </div>

              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Media</th>
                    <th scope="col" class="hidden px-3 py-3.5 w-56 text-left text-sm font-semibold text-gray-900 md:table-cell">Location</th>
                    <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">Playlist</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Played</th>
                    <th scope="col" class="hidden px-3 py-3.5 w-40 text-left text-sm font-semibold text-gray-900 xl:table-cell">Start</th>
                    <th scope="col" class="hidden px-3 py-3.5 w-40 text-left text-sm font-semibold text-gray-900 xl:table-cell">End</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right">Duration</th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-if="loading">
                    <td colspan="7">
                      <spin-loader class="py-20 my-20" bg="transparent" />
                    </td>
                  </tr>
                  <tr v-if="!loading && !results.data.length">
                    <td colspan="7">
                     <div class="py-20">
                       <empty-state description="No Play logs found" />
                     </div>
                    </td>
                  </tr>
                  <tr :key="'line-' + x" v-for="(item, x) of results.data">
                    <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">

                      <div class="flex w-full">
                        <div>
                          <img
                            v-if="item.screenshot_src"
                            class="h-10 w-10 rounded border border-gray-400"
                            :src="item.screenshot_src"/>

                          <div v-else class="text-3xl text-center w-10 h-10 flex justify-center items-center">
                            <i
                              v-if="item.media_type.toString().toLowerCase() === 'video' && !x.screenshot_src"
                              class="el-icon-video-camera text-gray-500"></i>
                            <i
                              v-if="item.media_type.toString().toLowerCase() === 'image'  && !x.screenshot_src"
                              class="el-icon-picture text-gray-500"></i>
                          </div>
                        </div>
                        <div class="min-w-0 flex-1 px-4 grid grid-cols-1">
                          <p class="text-sm font-medium text-gray-900 truncate">
                            {{ item.media_name }}
                          </p>
                          <p class="text-sm text-gray-500 text-xs truncate">
                            {{ item.media_type.toString().toLowerCase() === 'image' ? 'Image' : 'Video' }}
                          </p>
                        </div>
                      </div>

                    </td>
                    <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                      <div class="min-w-0 flex-1 grid grid-cols-1 cursor-pointer" @click="$router.push('/locations/' + item.location_id)">
                        <p class="text-sm text-red-600 font-medium text-gray-900 truncate">
                          {{ item.location_name }}
                        </p>
                        <p class="text-sm text-gray-500 text-xs truncate">
                          <i class="el-icon-location"></i> {{ item.location_address }}
                        </p>
                      </div>
                    </td>
                    <td class="hidden px-3 py-4 text-sm text-gray-500 xl:table-cell text-red-600 font-medium cursor-pointer"
                        @click="$router.push('/playlists/' + item.playlist_id)"
                    >{{ item.playlist_name }}</td>
                    <td class="px-3 py-4 text-sm text-gray-500 table-cell">
                      {{ item.human_last_played }}
                    </td>
                    <td class="hidden px-3 py-4 text-sm text-gray-500 xl:table-cell">{{ item.start_time  }}</td>
                    <td class="hidden px-3 py-4 text-sm text-gray-500 xl:table-cell">{{ item.end_time }}</td>
                    <td class="px-3 py-4 text-sm text-gray-500 text-right">{{ item.duration }} <span class="text-xs">secs</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <pagination-details
                v-if="results.meta"
                class="mt-6"
                :rpp="10"
                type="video plays"
                :total-pages="results.meta.pageCount"
                :current-page="results.meta.current"
                :total="results.meta.totalResult"
                @change="(page) => { fetch(page) }"/>

            </div>
          </div>

        </template>
    </page-wrapper>
</template>

<script>

import moment from 'moment';

import { impressionChartOptions } from '@/utils/chart';
import { download } from '@/utils/download';
import EmptyState from '@/components/EmptyState';
import SimpleStatBox from '@/components/Report/SimpleStatBox';
import SpinLoader from '@/components/SpinLoader';
import SortOrderBy from "@/components/SortOrderBy";

import { playLogs } from "@/utils/sort-options"
import dateOptions from "@/utils/date-options";
import FormInput from "@/components/FormInput";

export default {
  name: 'impressions-page',
  components: {
    FormInput,
    SortOrderBy,
    SpinLoader,
    SimpleStatBox,
    EmptyState,
  },
  watch: {
    'datepicker.value': function () {
      const dates = this.datepicker.value;
      if (dates && Array.isArray(dates) && dates.length === 2) {
        this.fetchChart();
        this.fetch(1);
      }
    },
  },
  computed: {
    isLoading() {
      return this.chart.loading || this.loading || this.exporting;
    },
    getChartOptions() {
      return impressionChartOptions({
        plotBy: this.chart.plot_by,
        categories: this.chart.categories,
      });
    },
    getFromDate() {
      const date = this.datepicker.value;
      if (Array.isArray(date) && date.length === 2) {
        return date[0];
      }
      return null;
    },
    getToDate() {
      const date = this.datepicker.value;
      if (Array.isArray(date) && date.length === 2) {
        return date[1];
      }
      return null;
    },
  },
  data() {
    return {
      sort_options: playLogs(),
      sort: 'start_time',
      order: 'desc',
      stats: {},
      date: {
        running: false,
        show: true,
      },
      group_by: [
        { value: 'location', name: 'Location' },
        { value: 'playlist', name: 'Playlist' },
        { value: 'media', name: 'Media' },
      ],
      plot_by: [
        { value: 'plays', name: 'Video Plays' },
        { value: 'minutes', name: 'Minutes' },
      ],
      chart: {
        group_by: 'location',
        plot_by: 'plays',
        loading: true,
        series: [],
        categories: [],
      },
      datepicker: dateOptions,
      loading: true,
      results: {
        data: [],
        meta: null,
      },
      exporting: false,
    };
  },
  created() {
    const vm = this;
    vm.fetch(1);
    vm.fetchChart();
  },
  methods: {
    onSortChange({sort, order}){
      this.sort = sort;
      this.order = order;
      this.fetch(1);
    },
    downloadFile(command) {
      const vm = this;
      vm.exporting = true;

      return vm.$http.get('/api/v1/impressions', {
        params: {
          from: moment(this.getFromDate).format('YYYY-MM-DD'),
          to: moment(this.getToDate).format('YYYY-MM-DD'),
          export: true,
          format: command,
        },
      }).then((response) => {
        vm.exporting = false;

        download({
          buffer: response.data.data.buffer,
          name: response.data.data.name,
          contentType: response.data.data.content_type,
        });
      }).catch(() => {
        vm.exporting = false;
      });
    },
    fetch(page) {
      const vm = this;
      vm.loading = true;
      vm.results.data = [];
      return vm.$http.get('/api/v1/impressions', {
        params: {
          page: page || 1,
          rpp: 10,
          from: moment(this.getFromDate).format('YYYY-MM-DD'),
          to: moment(this.getToDate).format('YYYY-MM-DD'),
          sort: vm.sort,
          order: vm.order
        },
      }).then((response) => {
        vm.loading = false;
        vm.results.data = response.data.data.results;
        vm.results.meta = response.data.data.meta;
      }).catch(() => {
        vm.loading = false;
        vm.results.data = [];
        vm.results.meta = null;
      });
    },
    fetchChart() {
      const vm = this;
      vm.chart.loading = true;
      return vm.$http.get('/api/v1/impressions/chart', {
        params: {
          from: moment(this.getFromDate).format('YYYY-MM-DD'),
          to: moment(this.getToDate).format('YYYY-MM-DD'),
          group_by: vm.chart.group_by,
          plot_by: vm.chart.plot_by,
        },
      }).then((response) => {
        vm.chart.loading = false;
        vm.chart.series = response.data.data.series;
        vm.chart.categories = response.data.data.categories;
        vm.stats = response.data.data.stats;
      }).catch(() => {
        vm.chart.loading = false;
        vm.chart.series = [];
        vm.chart.categories = [];
        vm.stats = {};
      });
    },

  },
};
</script>
