export function getQRCodePositions() {
  return [
    {
      label: 'Top',
      value: 'TOP',
    },
    {
      label: 'Middle',
      value: 'MIDDLE',
    },
    {
      label: 'Bottom',
      value: 'BOTTOM',
    },
  ].map((x) => [
    {
      label: `${x.label} left`,
      value: `${x.value}-LEFT`,
    },
    {
      label: `${x.label} centre`,
      value: `${x.value}-CENTER`,
    },
    {
      label: `${x.label} right`,
      value: `${x.value}-RIGHT`,
    },
  ]).flat().map((x) => {
    x.location_class = `qr-location-${x.value.toString().toLowerCase()}`;
    return x;
  });
}
