<template>
  <playlist
      :is-private="false"
      :playlist-id="$route.params.playlistId" />
</template>

<script>

import Playlist from '@/components/Playlist';

export default {
  name: 'PlayList',
  components: { Playlist },
};
</script>
