<template>
    <el-popover
            class="w-full h-full"
            placement="left"
            width="250"
            trigger="hover"
            :disabled="tooltipTitle === null">
        <div class="select-none">
            <h4 v-if="tooltipTitle" class="font-bold mmb-1">
              {{ tooltipTitle }}
            </h4>
          <div class="my-3">
            {{ date.format('ddd MMM Do YYYY') }}
          </div>
           <div>
             <el-button
                 @click="showEvent"
                 size="small" icon="el-icon-info" type="info">View Event Details</el-button>
           </div>
        </div>
        <button
                slot="reference"
                @click="toggleSelection"
                type="button" class="py-1.5 focus:z-10 cursor-pointer h-16 w-full select-none bg-white" :class="{
     '!bg-gray-100 cursor-pointer': !currentMonth,
                                        'cursor-not-allowed !bg-gray-100 hover:bg-gray-100': isDisabled,
    }">
            <time :datetime="date.format('YYYY-MM-DD')" class="mx-auto items-center justify-center rounded-full">
                <p
                        :class="{
                   'bg-blue-800 text-white': selected,
                   'text-gray-400': isDisabled
                   }"
                        class="w-6 mt-1 h-6 font-semibold my-2 rounded-full flex items-center justify-center text-sm mx-auto"> {{ date.format("D") }}</p>
                <p class="block mt-1 date-size font-semibold"
                   :class="{
               'text-blue-500': !isSpecial,
                'text-green-600': isSpecial,
               }"
                   v-if="!isDisabled && price">{{ price | currency($store.getters['auth/language'], currency) }}</p>
                <p class="block mt-1 text-xs text-gray-400" v-if="isDisabled">
                    <!-- Unavailable -->
                </p>
            </time>
        </button>
    </el-popover>
</template>

<script>
export default {
  name: 'CalendarDay',
  props: {
    date: {
      type: Object,
    },
    eventLocation: {
      type: Object,
      default() {
        return null;
      },
    },
    currency: {
      type: String,
      default() {
        return 'GBP';
      },
    },
    currentMonth: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isSpecial: {
      type: Boolean,
      default() {
        return false;
      },
    },
    selected: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    tooltipDescription: {
      type: String,
      default() {
        return null;
      },
    },
    tooltipTitle: {
      type: String,
      default() {
        return null;
      },
    },
    price: {
      type: Number,
      default() {
        return null;
      },
    },
  },
  methods: {
    showEvent() {
      this.$emit('event-select', {
        title: this.tooltipTitle,
        description: this.tooltipDescription,
        date: this.date,
        price: this.price,
        location: {
          name: this.eventLocation.location_name,
          address: this.eventLocation.location_address,
          type: this.eventLocation.location_type,
          lat: this.eventLocation.location_lat,
          lng: this.eventLocation.location_lng,
        },
      });
    },
    toggleSelection() {
      if (this.isDisabled) {
        return;
      }
      if (this.selected) {
        this.$emit('remove', this.date);
      } else {
        this.$emit('select', this.date);
      }
    },
  },
};
</script>

<style scoped>
    .date-size {
        font-size: 9px;
    }
</style>
