<template>
    <div class="bg-white border-t border-b border-gray-200 overflow-hidden">
        <ul role="list" class="divide-y divide-gray-200">
           <slot></slot>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'TableCards',
};
</script>

<style scoped>

</style>
