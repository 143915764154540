<template>
    <div v-if="loading">
        <spin-loader bg="transparent" height="h-64"/>
    </div>
    <div v-else>

        <div v-if="!loading && items.length > 0">
            <ul class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4"
            >
                <li
                        @dragover.prevent
                        @dragenter.prevent
                        :draggable="!isSearchMode"
                        @dragstart="onDragStart($event, x)"
                        @dragend="() =>  { dragging = null; active_hover = null }"
                        @dragenter="onDragEnter($event, x)"
                        @drop="onDrop($event, x)"
                        class="col-span-1 cursor-pointer select-none rounded"
                        :data-item-id="x.id"
                        :data-type="x.type"
                        :key="i"
                        :class="{
                            'file-item': x.type === 'file',
                              'folder-item': x.type === 'folder',
                              'opacity-25': dragging && x.type === 'file' && dragging === x.id,
                              'bg-gray-100': (active_hover && active_hover === x.id) || (x.selected && (multiple)),
                            }"
                        v-for="(x, i) of items">
                    <div class="w-full h-full cursor-pointer hover:bg-gray-100 rounded group relative" :class="{
                                          'bg-red-400': x.hovering
            }">
                        <div class="p-6" @click="(e) => onItemClick(e, x)" >
                            <div class="w-full select-none">
                                <div v-if="!x.screenshot_src" class="text-6xl text-center h-24 flex justify-center items-center">
                                    <i
                                            v-if="x.type === 'folder'"
                                            class="el-icon-folder text-blue-800"></i>
                                    <i
                                            v-if="x.type === 'file' && x.media_type === 'video' && !x.screenshot_src"
                                            class="el-icon-video-camera text-gray-500"></i>
                                    <i
                                            v-if="x.type === 'file' && x.media_type === 'image'  && !x.screenshot_src"
                                            class="el-icon-picture text-gray-500"></i>
                                </div>

                                <div class="h-24" v-if="x.screenshot_src">
                                    <img
                                            draggable="false" :src="x.screenshot_src"
                                         class="object-cover shadow-sm h-24 rounded overflow-hidden w-full"/>

                                </div>
                            </div>
                            <div class="my-2 text-center">

                                <div
                                        data-ref="edit-name-placeholder"
                                        v-if="editing_name.index !== i && !editing_name.editing"
                                     @click="onEditTextClick(i, x.name, x.type)"
                                        class="flex h-8 items-center justify-center w-full relative my-2 mb-0">
                                   <h4  data-ref="edit-name-placeholder" class="truncate text-sm font-semibold">{{ x.name }}<span v-if="x.file_extension">.{{ x.file_extension }}</span></h4>
                                   <div data-ref="edit-name-placeholder"  class="hidden group-hover:block ml-1">
                                       <i data-ref="edit-name-placeholder" class="el-icon-edit text-sm text-gray-600"></i>
                                   </div>
                                </div>
                                <div v-else class="w-full h-8"  data-ref="edit-name-input">
                                    <input
                                            data-ref="edit-name-input"
                                            v-click-outside-element="onClickOutside"
                                            @keyup.enter="onClickOutside"
                                            ref="editFileName"
                                            class="p-1 border-b text-sm w-full bg-transparent border-gray-300 focus:outline-none" v-model="editing_name.name" />
                                </div>


                              <h5 v-if="x.type === 'file'" class="text-xs my-2">
                                <span v-if="x.duration_secs">{{ x.duration_secs }} secs</span>
                              </h5>

                                <div
                                        v-if="x.type === 'file' && x.status === 'PROCESSING'"
                                        class="p-3 text-center text-blue-400 flex font-semibold w-full truncate items-center justify-center text-xs">
                                    <i class="el-icon-loading mr-1"></i> Processing
                                </div>

                                <div
                                        v-if="x.type === 'file' && x.status === 'PENDING'"
                                        class="p-3 text-center text-orange-500 font-semibold flex  w-full truncate items-center justify-center text-xs">
                                    <i class="el-icon-info mr-1"></i> Waiting for Approval
                                </div>

                                <div
                                        v-if="x.type === 'file' && x.status === 'REJECTED'"
                                        class="p-3 text-center text-red-500 flex font-semibold  w-full truncate items-center justify-center text-xs">
                                    <i class="el-icon-warning mr-1"></i> Rejected
                                </div>


                              <h5 v-if="x.type === 'file'" class="text-xs my-2">
                                <span>
                                  Uploaded {{ x.uploaded_at | moment("from", "now") }}
                                </span>
                              </h5>



                                <h5 v-else class="text-xs my-2">
                                    <span v-if="x.count === 0">Empty folder</span>
                                    <span v-else-if="x.count === 1">1 item</span>
                                    <span v-else>{{ x.count }} items</span>
                                </h5>
                            </div>
                        </div>

                        <div
                                :class="{
                                'hidden group-hover:block': !multiple,
                                'block': multiple
                                }"
                                class="absolute group top-0 right-0 z-10 mt-4 mr-4" v-if="!dragging || multiple">
                            <div
                                    v-if="!multiple"
                                    @mouseenter="x.menu_open = true"
                                    @mouseleave="x.menu_open = false"
                                    class="relative inline-block text-left">
                                <div class="relative">
                                    <button
                                            type="button"
                                            class="inline-flex bg-blue-500 text-white justify-center w-full rounded-md shadow-sm px-4 py-2 text-sm font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                                    >
                                        <i class="el-icon-more"></i>
                                    </button>
                                </div>

                                <transition enter-active-class="transition ease-out duration-100"
                                            enter-from-class="transform opacity-0 scale-95"
                                            enter-to-class="transform opacity-100 scale-100"
                                            leave-active-class="transition ease-in duration-75"
                                            leave-from-class="transform opacity-100 scale-100"
                                            leave-to-class="transform opacity-0 scale-95">
                                    <div v-show="x.menu_open"
                                         class="origin-top-right z-40 absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                                         role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
                                         tabindex="-1">
                                        <div class="py-1" role="none">
                                            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                                          <div
                                            v-if="x.type === 'file' && (x.status === 'PENDING' || x.status === 'ACTIVE')"
                                            @click="$emit('details', x.id)"
                                            class="text-gray-700 hover:bg-gray-100 group flex items-center px-4 py-2 text-sm"
                                            role="menuitem" tabindex="-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                 class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500">
                                              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                            </svg>

                                            View Details
                                          </div>

                                            <div
                                                    v-if="x.type === 'file' && (x.status === 'PENDING' || x.status === 'ACTIVE')"
                                                    @click="$emit('preview', {
                                                       remote_src: x.remote_src,
                                                       bg_color: x.bg_color
                                                         })"
                                                    class="text-gray-700 hover:bg-gray-100 group flex items-center px-4 py-2 text-sm"
                                                    role="menuitem" tabindex="-1">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                                Preview Media
                                            </div>
                                          <div
                                              v-if="x.type === 'file' && (x.status === 'PENDING' || x.status === 'ACTIVE')"
                                              @click="openItem(x.remote_src)"
                                              class="text-gray-700 hover:bg-gray-100 group flex items-center px-4 py-2 text-sm"
                                              role="menuitem" tabindex="-1">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 class="mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                                                 fill="currentColor"
                                                 viewBox="0 0 24 24">
                                              <path d="M15 10h4l-7 8-7-8h4v-10h6v10zm3.213-8.246l-1.213 1.599c2.984 1.732 5 4.955 5 8.647 0 5.514-4.486 10-10 10s-10-4.486-10-10c0-3.692 2.016-6.915 5-8.647l-1.213-1.599c-3.465 2.103-5.787 5.897-5.787 10.246 0 6.627 5.373 12 12 12s12-5.373 12-12c0-4.349-2.322-8.143-5.787-10.246z"/></svg>
                                            Download
                                          </div>
                                            <div
                                                    @click="$emit('select-folder', multiple ? getSelectedItemObjects : [{
                                        id: x.id,
                                        type: x.type
                                        }])"
                                                    class="text-gray-700 hover:bg-gray-100 group flex items-center px-4 py-2 text-sm"
                                                    role="menuitem" tabindex="-1">

                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                                </svg>
                                                Move
                                            </div>
                                        </div>
                                        <div class="py-1" role="none">
                                            <div @click="$emit('delete', {
                                        id: x.id,
                                        type: x.type
                                        })"
                                                 class="text-gray-700 hover:bg-gray-100 group flex items-center px-4 py-2 text-sm"
                                                 role="menuitem" tabindex="-1" id="menu-item-6">
                                                <!-- Heroicon name: solid/trash -->
                                                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                     fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd"
                                                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                          clip-rule="evenodd"/>
                                                </svg>
                                                Delete
                                            </div>
                                        </div>
                                    </div>
                                </transition>

                            </div>

                            <div v-if="multiple">
                                <input
                                        v-model="x.selected"
                                        type="checkbox" class="focus:ring-blue-700 h-6 w-6 cursor-pointer text-blue-700 border-gray-300 rounded" />
                            </div>

                        </div>
                    </div>
                </li>
            </ul>

                <div
                        style="top: -3000px; left: -3000px"
                        class="w-24 absolute h-24 z-0 bg-gray-200 p-4" id="ghost">
                    <div class="p-3 flex items-center justify-center">
                    <span class="w-6 text-center text-white h-6 bg-red-700 block text-xs flex items-center justify-center rounded-full">
                        {{ multiple ? getSelectCount : 1 }}
                    </span>
                    </div>
                </div>

        </div>
        <div v-else>
            <empty-state :description="isSearchMode ? 'Sorry but no media items found for your search' : 'Looks like this folder is empty' "/>
            <div class="text-center">
                <el-button
                        @click="$emit('open-upload')"
                        type="success" icon="el-icon-upload2">Upload Media</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import EmptyState from '../EmptyState';
import SpinLoader from '../SpinLoader';

export default {
  name: 'FolderView',
  components: { SpinLoader, EmptyState },
  props: {
    value: {
      type: Array,
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
    mode: {
      type: String,
      default() {
        return 'folder';
      },
    },
    loading: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  watch: {
    getSelectionCount(value) {
      this.$emit('has-selected', value > 0);
    },
    isSearch() {
      this.items = JSON.parse(JSON.stringify(this.value.map((x) => {
        x.menu_open = false;
        x.selected = false;
        return x;
      })));
    },
    multiple() {
      this.items = JSON.parse(JSON.stringify(this.value.map((x) => {
        x.menu_open = false;
        x.selected = this.multiple;
        return x;
      })));
    },
    value() {
      this.items = JSON.parse(JSON.stringify(this.value.map((x) => {
        x.menu_open = false;
        x.selected = this.multiple;
        return x;
      })));
    },
  },
  data() {
    return {
      editing_name: {
        index: null,
        name: null,
        type: null
      },
      dragging: null,
      active_hover: null,
      items: [],
    };
  },
  computed: {
    isSearchMode() {
      return this.mode === 'search';
    },
    getSelectedItemObjects() {
      return this.getSelectedItems.map((x) => ({
        id: x.id,
        type: x.type,
      }));
    },
    getSelectCount() {
      return this.getSelectedItems.length;
    },
    getSelectedItems() {
      return this.items.filter((x) => x.selected);
    },
  },
  methods: {
    openItem(url) {
      window.open(url);
    },
    onClickOutside(e) {

      const isEditCommand = !!(e.target && e.target.dataset && e.target.dataset.ref && e.target.dataset.ref === 'edit-name-placeholder');

      if (this.editing_name.index !== null && !isEditCommand) {
        const name = this.editing_name.name && this.editing_name.name.trim().length > 0 ? this.editing_name.name : null;
        if (name) {
          this.items[this.editing_name.index].name = this.editing_name.name;

          const { id } = this.items[this.editing_name.index];
          if (id) {
            this.$emit('edit-name', {
              id,
              name: this.editing_name.name,
              type: this.editing_name.type,
            });
            this.editing_name.name = false;
          }
        }
        this.editing_name.index = null;
        this.editing_name.name = null;
        this.editing_name.type = null;
      }
    },
    onEditTextClick(index, name, type) {
      this.editing_name.index = index;
      this.editing_name.name = name;
      this.editing_name.type = type;
    },
    onItemClick(e, item) {
      if (this.editing_name.index !== null) {
        return;
      }

      const isEditCommand = !!(e.target && e.target.dataset && e.target.dataset.ref && e.target.dataset.ref === 'edit-name-placeholder');
      if (isEditCommand) {
        return;
      }


      if (item.type === 'folder') {
        this.$emit('folder-clicked', item.id);
      }else{
        if(item.status === 'PENDING' || item.status === 'ACTIVE'){
          this.$emit('details', item.id)
        }
      }
    },
    onDragEnter(evt) {
      this.active_hover = null;
      evt.path = evt.path ||  evt.composedPath();
      for (const el of evt.path) {
        if (this.isFolder(el)) {
          const index = this.items.findIndex((x) => x.id === el.dataset.itemId);
          if (index > -1) {
            this.active_hover = el.dataset.itemId;
          }
        }
      }
    },
    onDragStart(evt, item) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';

      const node = document.getElementById('ghost');
      evt.dataTransfer.setDragImage(node, 0, 0);

      this.dragging = item.id;
      evt.dataTransfer.setData('id', item.id);
      evt.dataTransfer.setData('type', item.type);
    },
    onDrop(evt) {
      this.active_hover = null;
      this.dragging = null;

      evt.path = evt.path ||  evt.composedPath();

      const id = evt.dataTransfer.getData('id');
      const type = evt.dataTransfer.getData('type');
      for (const el of evt.path) {
        const isFolder = this.isFolder(el);
        if (isFolder) {
          let items = this.multiple ? this.getSelectedItemObjects : [
            { id, type },
          ];

          items = items.filter((x) => x !== isFolder.id);

          const params = {
            items,
            destination: isFolder.id,
          };

          this.$emit('move-file', params);
        }
      }
    },
    isFolder(el) {
      if (el.className && el.className.includes('folder-item')) {
        if (el.dataset.itemId && el.dataset.type && el.dataset.type === 'folder') {
          return {
            id: el.dataset.itemId,
          };
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>

</style>
