import moment from "moment";

export default  {
  value: [
    moment().subtract(7, 'days'),
    moment(),
  ],
  pickerOptions: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    shortcuts: [
      {
      text: 'Last 7 Days',
      onClick(picker) {
        picker.$emit('pick', [
          moment().subtract(7, 'days').toDate(),
          moment().toDate()
        ]);
      },
    },
      {
        text: 'Last 14 Days',
        onClick(picker) {
          picker.$emit('pick', [
            moment().subtract(14, 'days').toDate(),
            moment().toDate()
          ]);
        },
      },
      {
        text: 'Last 30 Days',
        onClick(picker) {
          picker.$emit('pick', [
            moment().subtract(30, 'days').toDate(),
            moment().toDate()
          ]);
        },
      },
      {
        text: 'Last 90 Days',
        onClick(picker) {
          picker.$emit('pick', [
            moment().subtract(90, 'days').toDate(),
            moment().toDate()
          ]);
        },
      },
      {
        text: 'Last Quarter',
        onClick(picker) {
          picker.$emit('pick', [
            moment().subtract(1, 'quarter').toDate(),
            moment().toDate()
          ]);
        },
      },
      {
        text: 'Last 6 months',
        onClick(picker) {
          picker.$emit('pick', [
            moment().subtract(6, 'months').toDate(),
            moment().toDate()
          ]);
        },
      },
      {
        text: 'Last 180 Days',
        onClick(picker) {
          picker.$emit('pick', [
            moment().subtract(180, 'days').toDate(),
            moment().toDate()
          ]);
        },
      },
      {
        text: 'Today',
        onClick(picker) {
          picker.$emit('pick', [
            moment().startOf('day').toDate(),
            moment().toDate()
          ]);
        },
      },
      {
        text: 'This Week',
        onClick(picker) {
          picker.$emit('pick', [
            moment().startOf('week').toDate(),
            moment().toDate()
          ]);
        },
      },
      {
        text: 'This Month',
        onClick(picker) {
          picker.$emit('pick', [
            moment().startOf('month').toDate(),
            moment().toDate()
          ]);
        },
      },
      {
        text: 'This Year',
        onClick(picker) {
          picker.$emit('pick', [
            moment().startOf('year').toDate(),
            moment().toDate()
          ]);
        },
      }

      ],
  },
}
