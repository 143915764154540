<template>
  <div class="flex justify-center lg:items-center flex-col gap-y-2 px-3 mt-3 select-none relative">

    <el-tooltip content="Book Advertising Slots" placement="right" popper-class="hidden lg:block">
      <router-link to="/locations"
                   class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                   active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
        <svg class="mr-3 h-6 w-6 lg:mr-0 lg:mr-0" xmlns="http://www.w3.org/2000/svg"
             fill="currentColor" viewBox="0 0 24 24">
          <path d="M11 11l1.256 5 3.744-10-10 3.75 5 1.25zm1-11c-5.522 0-10 4.395-10 9.815 0 5.505 4.375 9.268 10 14.185 5.625-4.917 10-8.68 10-14.185 0-5.42-4.478-9.815-10-9.815zm0 18c-4.419 0-8-3.582-8-8s3.581-8 8-8 8 3.582 8 8-3.581 8-8 8z"/>
        </svg>
        <span class="lg:hidden">Book Advertising Slots</span>
      </router-link>
    </el-tooltip>

    <el-tooltip content="Private Locations" placement="right" popper-class="hidden lg:block">
      <router-link to="/private-locations"
                   class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                   active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">

        <svg class="mr-3 h-6 w-6 lg:mr-0 lg:mr-0"
             fill="currentColor"
             xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"><path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10-4c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v4h-8v-4zm3.408 14l-2.842-2.756 1.172-1.173 1.67 1.583 3.564-3.654 1.174 1.173-4.738 4.827z"/></svg>

        <span class="lg:hidden">Private Locations</span>
      </router-link>
    </el-tooltip>

    <el-tooltip content="Bookings" placement="right" popper-class="hidden lg:block">
      <router-link to="/bookings"
                   class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                   active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
        <svg class="mr-3 h-6 w-6 lg:mr-0" xmlns="http://www.w3.org/2000/svg"
             fill="currentColor" viewBox="0 0 24 24">
          <path d="M17 3v-2c0-.552.447-1 1-1s1 .448 1 1v2c0 .552-.447 1-1 1s-1-.448-1-1zm-12 1c.553 0 1-.448 1-1v-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5zm-14.237 3.5h-7.763v-13h19v1.763c.727.33 1.399.757 2 1.268v-9.031h-3v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-9v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-3v21h11.031c-.511-.601-.938-1.273-1.268-2z"/>
        </svg>
        <span class="lg:hidden">Bookings</span>
      </router-link>
    </el-tooltip>

    <el-tooltip content="Upcoming Events" placement="right" popper-class="hidden lg:block">
    <router-link
        to="/events"
                 class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                 active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
      <svg
          class="mr-3 h-6 w-6 lg:mr-0" xmlns="http://www.w3.org/2000/svg"
           fill="currentColor" viewBox="0 0 24 24">
        <path d="M17 1c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-12 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm13 5v10h-16v-10h16zm2-6h-2v1c0 1.103-.897 2-2 2s-2-.897-2-2v-1h-8v1c0 1.103-.897 2-2 2s-2-.897-2-2v-1h-2v18h20v-18zm4 3v19h-22v-2h20v-17h2zm-17 7h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"/>
      </svg>
      <span class="lg:hidden">Upcoming Events</span>
    </router-link>
    </el-tooltip>

    <el-tooltip content="Media Library" placement="right" popper-class="hidden lg:block">
    <router-link to="/media"
                 class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                 active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
      <svg class="mr-3 h-6 w-6 lg:mr-0" xmlns="http://www.w3.org/2000/svg"
           fill="currentColor" viewBox="0 0 24 24">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/>
      </svg>
      <span class="lg:hidden">Media Library</span>
    </router-link>
    </el-tooltip>

    <el-tooltip content="Playlists" placement="right" popper-class="hidden lg:block">
    <router-link to="/playlists"
                 class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                 active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
      <svg class="mr-3 h-6 w-6 lg:mr-0" fill="currentColor" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
        <path d="M15 21v-10l9 5-9 5zm-3 0h-12v-2h12v2zm0-4.024h-12v-2h12v2zm0-3.976h-12v-2h12v2zm12-4h-24v-2h24v2zm0-6v2h-24v-2h24z"/>
      </svg>
      <span class="lg:hidden">Playlists</span>
    </router-link>
    </el-tooltip>

    <el-tooltip content="Play Logs" placement="right" popper-class="hidden lg:block">
    <router-link to="/play-logs"
                 class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                 active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
      <svg class="mr-3 h-6 w-6 lg:mr-0" xmlns="http://www.w3.org/2000/svg"
           fill="currentColor" viewBox="0 0 24 24">
        <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/>
      </svg>
      <span class="lg:hidden">Play Logs</span>
    </router-link>
    </el-tooltip>

    <el-tooltip content="Invoices" placement="right" popper-class="hidden lg:block">
    <router-link to="/invoices"
                 class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                 active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
      <svg class="mr-3 h-6 w-6 lg:mr-0" xmlns="http://www.w3.org/2000/svg"
           fill="currentColor" viewBox="0 0 24 24">
        <path d="M21.5 6c.276 0 .5.224.5.5v11c0 .276-.224.5-.5.5h-19c-.276 0-.5-.224-.5-.5v-11c0-.276.224-.5.5-.5h19zm2.5 0c0-1.104-.896-2-2-2h-20c-1.104 0-2 .896-2 2v12c0 1.104.896 2 2 2h20c1.104 0 2-.896 2-2v-12zm-20 3.78c0-.431.349-.78.78-.78h.427v1.125h-1.207v-.345zm0 .764h1.208v.968h-1.208v-.968zm0 1.388h1.208v1.068h-.428c-.431 0-.78-.349-.78-.78v-.288zm4 .288c0 .431-.349.78-.78.78h-.429v-1.068h1.209v.288zm0-.708h-1.209v-.968h1.209v.968zm0-1.387h-1.629v2.875h-.744v-4h1.593c.431 0 .78.349.78.78v.345zm5.5 2.875c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5c.484 0 .937.138 1.32.377-.53.552-.856 1.3-.856 2.123 0 .824.326 1.571.856 2.123-.383.239-.836.377-1.32.377zm1.5-2.5c0-1.381 1.12-2.5 2.5-2.5 1.381 0 2.5 1.119 2.5 2.5s-1.119 2.5-2.5 2.5c-1.38 0-2.5-1.119-2.5-2.5zm-8 4.5h-3v1h3v-1zm4 0h-3v1h3v-1zm5 0h-3v1h3v-1zm4 0h-3v1h3v-1z"/>
      </svg>
      <span class="lg:hidden">Invoices</span>
    </router-link>
    </el-tooltip>

    <el-tooltip content="My Account" placement="right" popper-class="hidden lg:block">
    <router-link to="/account"
                 class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                 active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
      <svg class="mr-3 h-6 w-6 lg:mr-0" xmlns="http://www.w3.org/2000/svg"
           fill="currentColor" viewBox="0 0 24 24">
        <path d="M10.644 17.08c2.866-.662 4.539-1.241 3.246-3.682-3.932-7.427-1.042-11.398 3.111-11.398 4.235 0 7.054 4.124 3.11 11.398-1.332 2.455.437 3.034 3.242 3.682 2.483.574 2.647 1.787 2.647 3.889v1.031h-18c0-2.745-.22-4.258 2.644-4.92zm-12.644 4.92h7.809c-.035-8.177 3.436-5.313 3.436-11.127 0-2.511-1.639-3.873-3.748-3.873-3.115 0-5.282 2.979-2.333 8.549.969 1.83-1.031 2.265-3.181 2.761-1.862.43-1.983 1.34-1.983 2.917v.773z"/>
      </svg>
      <span class="lg:hidden">My Account</span>
    </router-link>
    </el-tooltip>

    <el-tooltip v-if="$store.getters['auth/isAdmin']" content="Service Status" placement="right" popper-class="hidden lg:block">
      <router-link to="/admin/status"
                   class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                   active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
        <svg class="mr-3 h-6 w-6 lg:mr-0" xmlns="http://www.w3.org/2000/svg"
             fill="currentColor" viewBox="0 0 24 24">
          <path d="M19.5 15c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm-.875 7l.623-2h-1.248l2.291-3-.79 2h1.415l-2.291 3zm1.375-16h-18l4-6h10l4 6zm-.5 7c.886 0 1.73.179 2.5.501v-5.501h-22v7h14.82c1.184-1.23 2.843-2 4.68-2zm-17.5 0l.863-3h1.275l-.863 3h-1.275zm2.066 0l.863-3h1.275l-.863 3h-1.275zm2.067 0l.863-3h1.275l-.864 3h-1.274zm2.066 0l.863-3h1.274l-.863 3h-1.274zm4.801 6.5c0-.886.179-1.73.501-2.5h-13.501v7h14.82c-1.124-1.169-1.82-2.753-1.82-4.5zm-11 2.5l.863-3h1.275l-.863 3h-1.275zm2.066 0l.863-3h1.275l-.863 3h-1.275zm2.067 0l.863-3h1.275l-.864 3h-1.274zm3.341 0h-1.275l.863-3h1.274l-.862 3z"/></svg>
        <span class="lg:hidden">Service Status</span>
      </router-link>
    </el-tooltip>

    <el-tooltip content="Logout" placement="right" popper-class="hidden lg:block">
    <router-link to="/login?logout=true"
                 class="text-white flex items-center lg:justify-center p-2 lg:h-12 lg:w-12 text-sm font-medium rounded-md hover:bg-red-800"
                 active-class="bg-yellow-300 !text-gray-700 hover:bg-yellow-300">
      <svg class="mr-3 h-6 w-6 lg:mr-0" xmlns="http://www.w3.org/2000/svg"
           fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 22h-20v-7h2v5h16v-16h-16v5h-2v-7h20v20zm-13-11v-4l6 5-6 5v-4h-11v-2h11z"/></svg>
      <span class="lg:hidden">Logout</span>
    </router-link>
    </el-tooltip>

  </div>
</template>

<script>
export default {
  name: 'SideNav',
};
</script>

<style scoped>
</style>
