<template>
    <page-wrapper :has-content="false" :show-feed="false" title="Book Advertising Slot">

        <template slot="overlays">

            <menu-sidebar-layer
                    :show-actions="true"
                    @close="()=> { details.open = false; $router.push('/locations'); updateCoords() }"
                    position="left" :show="details.open">

                <template slot="title">
                    <h2>{{ details.data.name }}</h2>
                    <p class="text-sm"><i class="el-icon-location mr-1"></i> {{ details.data.address }}</p>
                </template>
                <template slot="content">

                    <pod-details
                            v-if="details.open"
                            :details="details.data"
                            :loading="details.loading"
                            @open-booking="() => book.open = true"
                            @open-event="openEvent"
                    />

                </template>

                <template slot="actions" v-if="!details.loading">
                    <el-button @click="() => { details.open = false;  $router.push('/locations') }">
                        Close
                    </el-button>
                    <el-button
                            @click="$router.push('/locations/' + $route.params.podId + '/book')"
                            type="success" icon="el-icon-date">
                        Book Advertising Slot
                    </el-button>
                </template>

            </menu-sidebar-layer>

            <menu-sidebar-layer
                    :show-actions="false"
                    @close="()=> { book.open = false; $router.push('/locations/' + $route.params.podId)  }"
                    position="left" :show="book.open">

                <template slot="title">
                    <h2>View Availability</h2>
                    <p class="text-sm"><i class="el-icon-location"></i> {{ details.data.name }} - {{
                        details.data.address }}</p>
                </template>

                <template slot="content">
                    <book-pod
                            @close="onBookingClosed"
                            v-model="details.data"/>
                </template>

            </menu-sidebar-layer>

            <small-modal :title="event.object.title"
                         @close="() => event.show = false"
                         :show="event.show">
                <template slot="content">
                    <div class="p-4 h-full relative overflow-scroll pb-24">

                        <div class="flex w-full px-2">
                            <div class="w-1/2 p-2">

                                <p>{{ event.object.description }}</p>

                            </div>
                            <div class="w-1/2 p-2">

                                <div class="shadow border border-gray-100 h-full rounded">
                                    <preview-map-object
                                            :lat="event.object.lat"
                                            :lon="event.object.lng"
                                    />
                                </div>

                                <div class="flex flex-wrap w-full">
                                    <div class="w-full">
                                        <dl>
                                            <div class="py-3 grid grid-cols-3">
                                                <dt class="text-sm font-medium text-gray-400">Location</dt>
                                                <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                                                    {{ event.object.name }}
                                                </dd>
                                            </div>
                                        </dl>
                                        <dl>
                                            <div class="py-3 grid grid-cols-3">
                                                <dt class="text-sm font-medium text-gray-400">Address</dt>
                                                <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                                                    {{ event.object.address }}
                                                </dd>
                                            </div>
                                        </dl>
                                        <dl>
                                            <div class="py-3 grid grid-cols-3">
                                                <dt class="text-sm font-medium text-gray-400">Type</dt>
                                                <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                                                    {{ event.object.type }}
                                                </dd>
                                            </div>
                                        </dl>
                                        <dl>
                                            <div class="py-3 grid grid-cols-3">
                                                <dt class="text-sm font-medium text-gray-400">Price</dt>
                                                <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                                                    {{ event.object.booking_price | currency }} <small>excl. VAT</small>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="px-4 w-full border-t border-gray-200 bg-gray-50 flex items-center justify-end fixed bottom-0 h-20 left-0">
                            <el-button
                                    @click="() => event.show = false"
                            >Close
                            </el-button>
                            <el-button
                                    @click="() =>  { event.show = false; $router.push('/locations/' + event.object.location_id + '/book?date=' + event.object.date); }"
                                    icon="el-icon-date" type="success">Book Event
                            </el-button>
                        </div>
                    </div>
                </template>
            </small-modal>

        </template>

        <template slot="content-fs">

          <div class="absolute left-0 bottom-0 z-40 switch-action">
            <button
              @click="toggleView()"
              class="bg-black flex items-center font-semibold gap-x-2 text-sm transition-all hover:shadow-lg hover:scale-105 rounded-full px-4 flex gap-1 p-3 text-white">
              <span>Show {{ view === 'map' ? 'list' : 'map' }}</span>
              <span>
                    <svg
                      v-if="view === 'map'"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
</svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
</svg>

                  </span>
            </button>
          </div>

          <div class="h-full w-full scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200" v-loading="loading.pods">






            <div v-if="view === 'map'" class="w-full h-full">

              <map-view
                @open="(podId) => openMarker(podId)"
                class="w-full h-full"
              :center="map.center"
              :zoom="map.zoom"
                :bbox="map.bbox"
                :pods="pods"
              />


            </div>

            <div class="overflow-y-scroll p-6" v-show="view === 'list'">


              <div v-if="pods.length > 0">
                <div class="sm:flex sm:items-center mb-3">
                  <div class="sm:flex-auto text-gray-500 overflow-hidden truncate">
                    {{ pods.length }} advertising locations available
                  </div>
                  <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <div class="flex w-auto -mx-2">
                      <div class="p-1">
                        <sort-order-by
                          offset-class="mr-10"
                          v-model="sort_options"
                          :sort="sort"
                          :order="order"
                          @change="onSortChange"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


                <div class="flex grid gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  <div
                    :key="'list-location-' + k"
                    v-for="(pod, k) of pods">
                    <div class="h-64 w-full z-20 overflow-hidden relative rounded-xl border border-gray-300 shadow">

                      <div
                        class="h-64 w-full"
                        @click="onLocationClick(pod.id)">
                        <preview-map-object
                          @click="onLocationClick(pod.id)"
                          class="z-30 cursor-pointer"
                          :bounce="false"
                          :lat="pod.lat"
                          :lon="pod.lng"
                        />
                      </div>

                      <div
                        @click="addToFavourite(pod.id, pod.is_favourite, k)"
                        class="z-40 absolute top-0 right-0 mr-3 mt-3">
                          <svg
                            :class="{
                            'text-red-500': pod.is_favourite,
                            'text-black': !pod.is_favourite
                            }"
                            class="w-8 h-10 cursor-pointer transition-all hover:scale-105"
                            fill="currentColor"
                            style="stroke-width: 2; stroke: #FFFFFF; opacity: 0.8;"
                            clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 5.72c-2.624-4.517-10-3.198-10 2.461 0 3.725 4.345 7.727 9.303 12.54.194.189.446.283.697.283s.503-.094.697-.283c4.977-4.831 9.303-8.814 9.303-12.54 0-5.678-7.396-6.944-10-2.461z" fill-rule="nonzero"/></svg>
                      </div>
                    </div>
                    <div
                      @click="onLocationClick(pod.id)"
                      class="mt-4 tracking-tight cursor-pointer">
                      <h4 class="flex select-none items-center gap-2">
                        <span class="flex-1 font-semibold text-red-600 truncate">
                          {{ pod.name }}
                        </span>
                        <span class="ml-auto text-xs">
                          {{ pod.type }}
                        </span>
                      </h4>
                      <h5 class="text-sm select-none">{{ pod.address }}</h5>
                      <div class="mt-2 underline">
                        Advertising slots from <strong>{{ pod.default_price | currency }}</strong>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


        <!--
                <div class="overflow-hidden z-40 hidden md:block md:absolute right-0 top-0 mt-8 mr-8 select-none">
                  <div class="grid gap-2">
                    <div v-if="map.filters.income" class="flex justify-end">
                      <div class="rounded inline-block overflow-hidden shadow-lg select-none text-xs">
                        <h4 class="bg-gray-900 p-1 text-white px-2">
                          <i class="icon-el-layer"></i>
                          <span>Income</span>
                        </h4>
                        <div class="p-2 bg-white">
                          <div class="flex w-full gap-3">
                            <div>Lowest</div>
                            <div
                                :key="'deprivation-' + i"
                                v-for="(x, i) of map.legends.income_map_colours">
                              <div class="flex gap-2 items-center">
                                <div
                                    :style="{
                                                        'background-color': x
                                                        }"
                                    class="w-4 h-4 rounded shadow"></div>
                              </div>
                            </div>
                            <div>Highest</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="map.filters.deprivation" class="flex justify-end">
                      <div class="rounded inline-block overflow-hidden shadow-lg select-none text-xs">
                        <h4 class="bg-gray-900 p-1 text-white px-2">
                          <i class="icon-el-layer"></i>
                          <span>Deprivation Index</span>
                        </h4>
                        <div class="p-2 bg-white">
                          <div class="flex w-full gap-3">
                            <div>Least</div>
                            <div
                                :key="'deprivation-' + i"
                                v-for="(x, i) of map.legends.deprivation_map_colours">
                              <div class="flex gap-2 items-center">
                                <div
                                    :style="{
                                                        'background-color': x
                                                        }"
                                    class="w-4 h-4 rounded shadow"></div>
                              </div>
                            </div>
                            <div>Most</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="hasPOIMap" class="flex justify-end">
                      <div class="rounded inline-block overflow-hidden shadow-lg select-none text-xs">
                        <h4 class="bg-gray-900 p-1 text-white px-2">
                          <i class="icon-el-layer"></i>
                          <span>Points of Interest</span>
                        </h4>
                        <div class="p-2 bg-white">
                          <div class="flex w-full gap-3">
                            <div
                                :key="'deprivation-' + i"
                                v-for="(x, i) of map.legends.poi_legend">
                              <div class="flex gap-2 items-center">
                                <div
                                    :style="{
                                                        'background-color': x.color
                                                        }"
                                    class="w-4 h-4 rounded shadow"></div>
                                <span>{{ x.label }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="hasPOIMap" class="flex justify-end">
                      <div class="rounded inline-block overflow-hidden shadow-lg select-none text-xs">
                        <h4 class="bg-gray-900 p-1 text-white px-2">
                          <i class="icon-el-layer"></i>
                          <span>Transport Link</span>
                        </h4>
                        <div class="p-2 bg-white">
                          <div class="flex w-full gap-3">
                            <div
                                :key="'deprivation-' + i"
                                v-for="(x, i) of map.legends.transport_links">
                              <div class="flex gap-2 items-center">
                               <div>
                                 <img class="w-5 h-5" :src="x.image" />
                               </div>
                                <span>{{ x.label }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
             -->
                <!--
                <div class="rounded overflow-hidden w-32 z-40 hidden md:block md:absolute right-0 top-0 mt-8 mr-8 shadow-lg select-none">
                    <h4 class="bg-gray-900 p-1 text-white text-sm px-2">
                        <i class="icon-el-layer"></i>
                        <span>Deprivation Index</span>
                    </h4>
                    <div class="p-3 bg-white">
                    </div>
                </div>
                -->

              <!--
                <div class="rounded overflow-hidden w-64 z-40 border-2 border-gray-800 hidden md:block md:absolute left-0 top-0 mt-8 ml-8 shadow-lg select-none">
                    <h4 class="bg-gray-900 p-2 text-white">
                        <i class="icon-el-layer"></i>
                        <span>Map Overlays</span>
                    </h4>
                    <div class="p-3 bg-white">
                        <div class="w-full" v-if="map.filters.poi.length > 0 && map.zoom < 14">
                            <div class="mb-4">
                                <el-alert class="flex justify-center items-center w-full" effect="dark" type="warning"
                                          :closable="false">
                                    Zoom in further to view nearby points of interest
                                </el-alert>
                            </div>
                        </div>
                        <div class="flex flex-wrap py-1 w-full">
                            <label class="uppercase block text-xs text-gray-800 mb-1">Nearby Points of Interest</label>
                            <div class="w-full flex-none">
                                <el-select
                                        multiple
                                        clearable
                                        placeholder="Select Points of Interest..."
                                        collapse-tags
                                        v-model="map.filters.poi"
                                        class="w-full">

                                        <el-option-group
                                                v-for="group in poi_options"
                                                :key="group.label"
                                                :label="group.label">
                                            <el-option
                                                    v-for="x in group.options"
                                                    :key="x.id"
                                                    :label="x.label"
                                                    :value="x.id">
                                                <div class="flex items-center">
                                            <span v-if="x.color" class="w-4 h-4 rounded-full shadow bg mr-2" :style="{
                                            'background-color': x.color
                                            }"></span>
                                                    <span v-if="x.image">
                                                        <img class="w-5 h-5 mr-2 my-1" :src="x.image" />
                                                    </span>
                                                    <span>{{ x.label }}</span>
                                                </div>
                                            </el-option>
                                        </el-option-group>
                                </el-select>
                            </div>
                        </div>
                        <div class="flex flex-wrap py-1 w-full items-center">
                            <label class="uppercase block text-xs text-gray-800">Income</label>
                            <div class="flex-none ml-auto">
                                <el-switch v-model="map.filters.income"></el-switch>
                            </div>
                        </div>
                        <div class="flex flex-wrap py-1 w-full items-center">
                            <label class="uppercase block text-xs text-gray-800">Deprivation</label>
                            <div class="flex-none ml-auto">
                                <el-switch v-model="map.filters.deprivation"></el-switch>
                            </div>
                        </div>


                    </div>
                </div>
                   --->


            </div>

        </template>
    </page-wrapper>
</template>

<script>

import tippy from 'tippy.js';
import Vue from 'vue';
import L from 'leaflet';
import BookPod from '@/components/Pod/BookPod';
import LocationPopupData from '@/components/Pod/LocationPopupData';
import PoiTooltip from '@/components/Pod/PoiTooltip';
import PodDetails from '@/components/Pod/PodDetails';
import SmallModal from '@/components/SmallModal';
import PreviewMapObject from '@/components/PreviewMapObject';
import MapView from "@/components/Pods/MapView";
import SortOrderBy from "@/components/SortOrderBy";
import {pods} from "@/utils/sort-options";

const poi = [
  {
    id: 'MRD',
    label: 'Residential',
    color: '#d4812b',
  },
  {
    id: 'MRE',
    label: 'Retail/Leisure',
    color: '#4E9F3D',
  },
  {
    id: 'MO',
    label: 'Offices',
    color: '#60A5FA',
  },
  {
    id: 'CP',
    label: 'Car Parks',
    color: '#3812b3',
  },
];
const transport = [
  {
    id: 'coach',
    label: 'Coach/Bus Stop',
    image: '/imgs/icons/ico-bus.png',
  },
  {
    id: 'rail',
    label: 'Rail/Train',
    image: '/imgs/icons/ico-train.png',
  },
  {
    id: 'ferry',
    label: 'Ferry/Port',
    image: '/imgs/icons/ico-ferry.png',
  },
];

export default {
  name: 'available-pods',
  components: {
    SortOrderBy,
    MapView,
    PreviewMapObject, SmallModal, PodDetails, BookPod,
  },
  watch: {
    '$store.state.auth.google_maps.opts.type': function () {
      this.map.poi_visible = false;
      this.map.google_visible = false;
      this.$nextTick(() => {
        this.map.google_visible = true;
        this.map.poi_visible = true;
      });
    },
    'map.filters.poi': function () {
      this.map.poi_visible = false;
      this.$nextTick(() => {
        this.map.poi_visible = true;
      });
    },
    view(){
      if(this.view === 'map'){
        this.$nextTick(() => {
          this.setBBOX();
        })
      }
    },
    $route(to) {
      // Put your logic here...
      if (to && to.name === 'PodView') {
        this.openByPodId(this.$route.params.podId);
      }
      if (to && to.name === 'PodBook') {
        this.openByPodId(this.$route.params.podId);
      }
    },
  },
  data() {
    return {
      view: 'list',
      map_views: [
        { id: 'roadmap', name: 'Roadmap' },
        { id: 'satellite', name: 'Satellite' },
        { id: 'hybrid', name: 'Hybrid' },
      ],
      sort: 'is_favourite',
      order: 'desc',
      sort_options: pods(),
      show_search: false,
      loading: {
        pods: true,
      },
      event: {
        show: false,
        object: {
          id: null,
          name: null,
        },
      },
      naptanOptions: {
        interactive: true,
        getFeatureId: (f) => f.properties.code,
        filter: (object) => {
          const { type } = object.properties;
          return this.map.filters.poi.indexOf(type) > -1;
        },

        vectorTileLayerStyles: {
          poi: (item) => ({
            icon: this.styleNaptanIcon(item),
          }),
        },
      },
      poiOptions: {
        interactive: true,
        getFeatureId: (f) => f.properties.uprn,
        filter: (object) => {
          const code = object.properties.classification;
          return this.map.filters.poi.indexOf(code) > -1;
        },
        vectorTileLayerStyles: {
          poi: (item) => this.stylePOI(item),
        },
      },
      poi_legend: poi,
      poi_options: [{
        label: 'Properties',
        options: poi,
      },
      {
        label: 'Transport Links',
        options: transport,
      },
      ],
      map_overlays: {
        imd: {
          url: 'https://tiles.landmonitor.com/geoserver/lm/wms',
          layers: 'lm:imd',
          opacity: 0.75,
        },
        income: {
          url: 'https://tiles.landmonitor.com/geoserver/lm/wms',
          layers: 'lm:imd',
          styles: 'IndexMultipleDeprivationIncome',
          opacity: 0.75,
        },
        population: {
          url: 'https://tiles.landmonitor.com/geoserver/lm/wms',
          layers: 'lm:imd',
          styles: 'IndexMultipleDeprivationPopulation',
          opacity: 0.75,
        },
      },
      map: {
        bbox: [],
        google_visible: true,
        poi_visible: true,
        filters: {
          poi: [],
          income: false,
          deprivation: false,
          population: false,
        },
        legends: {
          poi_legend: poi,
          transport_links: transport,
          income_map_colours: [
            '#F1E6F3ff',
            '#CBB9CFff',
            '#A58DAAff',
            '#806086ff',
            '#5A3461ff',
            '#34073Dff',
          ],
          deprivation_map_colours: [
            '#234A26ff',
            '#638434',
            '#bcaf52',
            '#8B422Fff',
            '#A04031ff',
            '#CA3D34ff',
          ],
        },
        accessToken: 'pk.eyJ1IjoiYXlpbWFjcmVhdGl2ZSIsImEiOiIwMl8xaVFJIn0.cneghc77NYvdVgHDlVb9cA',
        zoom: 14,
        center: [51.8185, -0.3590],
      },
      pods: [],
      details: {
        open: false,
        loading: false,
        data: {},
      },
      book: {
        open: false,
        loading: false,
        data: {},
      },
    };
  },
  computed: {
    hasPOIMap() {
      const vm = this;
      return vm.map.filters.poi.length > 0;
    },
    getPOIItemsList() {
      let items = [];
      for (const x of this.poi_options) {
        if (x.options) {
          items = items.concat(x.options);
        }
      }
      return items;
    },
    isZoomVisible() {
      return this.map.filters.poi.length > 0 && this.map.zoom >= 14;
    },
    getActiveOverlays() {
      const layers = [];
      if (this.map.filters.income) {
        layers.push(this.map_overlays.income);
      }
      if (this.map.filters.deprivation) {
        layers.push(this.map_overlays.imd);
      }
      if (this.map.filters.population) {
        layers.push(this.map_overlays.population);
      }
      return layers;
    },
  },
  mounted() {
    if (this.$route.name === 'PodView') {
      this.openByPodId(this.$route.params.podId);
    }

    if (this.$route.name === 'PodBook') {
      this.openByPodId(this.$route.params.podId);
    }

    /*
            if (this.$route.query && this.$route.query.session && this.$route.query['payment-success'] && this.$route.query['payment-success'] === 'false') {
                this.$http.delete('/api/v1/booking-confirm/' + this.$route.query.session)
            }else if (this.$route.query && this.$route.query.action && this.$route.query.action === 'view-pod' && this.$route.query.podId) {
                this.openByPodId(this.$route.query.podId);
            } else{
                this.$refs.locationSearch.focus();
            }
             */
  },
  created() {
    const vm = this;
    vm.fetch();
  },
  methods: {
    onSortChange({sort, order}){
      const vm = this;
      vm.sort = sort;
      vm.order = order;
      vm.fetch();
    },
    onLocationClick(podId){
      const vm = this;
      vm.loading.pods = true;
      vm.$router.push(`/locations/${podId}`);
    },
    addToFavourite(podId, isFavourite, index){
      const vm = this,
        pods = JSON.parse(JSON.stringify(vm.pods));

      if(pods[index]){
        pods[index].is_favourite = !isFavourite;
        vm.pods = pods;
      }

      return vm.$http.post('/api/v1/pod/' + podId + '/favourite')
        .then(() => {})
        .catch(() => null)
    },
    toggleView(){
      this.view = this.view === "map" ? 'list' : 'map';
    },
    setBBOX() {
      /*
const vm = this;
   const { bbox } = this.map;

   if (bbox.length === 4) {
     console.log(vm.$refs.map.mapObject)
     vm.$refs.map.mapObject.fitBounds([
       [bbox[1], bbox[0]],
       [bbox[3], bbox[2]],
     ]);
   }

    */
    },
    updateCoords() {

      let q = this.$route.query;
      const query = Object.assign(q, {
        zoom: this.map.zoom,
        lat: this.map.center.lat,
        lng: this.map.center.lng,
      })
      this.$router.push({ query }).catch(() => {});
    },
    onMouseOverPOI(e) {
      if (!e.layer._path._tippy) {
        const poiTooltip = new Vue({
          ...PoiTooltip,
          parent: this,
          propsData: {
            address: e.layer.properties.address,
            classification: e.layer.properties.classification,
            type: 'Properties',
            classification_items: this.getPOIItemsList,
          },
        }).$mount();

        if (!e.layer._path._tippy) {
          tippy(e.layer._path, {
            content: poiTooltip.$el,
            allowHTML: true,
            followCursor: true,
          });
        }
      }
    },
    onMouseLeavePOI() {

    },
    openEvent(o) {
      const vm = this;
      vm.event.object = o;
      vm.event.show = true;
    },
    onBookingClosed() {
      this.book.open = false;
    },

    styleNaptanIcon(item) {
      const items = this.getPOIItemsList;
      const find = items.find((x) => x.id === item.type);
      return new L.Icon({
        iconUrl: find ? find.image : '/imgs/icons/ico-bus.png',
        iconSize: [36, 36],
      });
    },

    stylePOI(item) {
      const items = this.getPOIItemsList;
      const find = items.find((x) => x.id === item.classification);
      return {
        weight: 0.5,
        radius: 7,
        fillColor: find ? find.color : '#fff',
        fillOpacity: 0.9,
        color: '#fff',
        fill: true,
      };
    },
    onMarkerMouseEnter(e, pod) {
      if (e.sourceTarget) {
        if (e.sourceTarget._icon && !e.sourceTarget._icon._tippy) {
          const popupDate = new Vue({
            ...LocationPopupData,
            parent: this,
            propsData: {
              address: pod.address,
              name: pod.name,
              income: pod.average_income,
              //  builtUpArea: pod.built_up_area,
              price: pod.default_price,
              type: pod.type,
              timezone: pod.time_zone,
              //  ruralUrban: pod.rural_urban,
              weather: pod.weather_forecast,
              iso: pod.iso,
              currency: pod.currency,
            },
          }).$mount();

          tippy(e.sourceTarget._icon, {
            content: popupDate.$el,
            allowHTML: true,
          });
        }
      }
    },
    getAddressData(addressData) {
      const vm = this;
      if (addressData.latitude && addressData.longitude) {

        vm.$refs.map.mapObject.setView( [addressData.latitude, addressData.longitude], 14);
      }
    },
    fetch() {
      const vm = this;
      vm.loading.pods = true;
      return vm.$http.get('/api/v1/pods', {
       params: {
         sort: vm.sort,
         order: vm.order
       }
      })
        .then((response) => {
          vm.map.bbox = response.data.data.bbox && Array.isArray(response.data.data.bbox) ? response.data.data.bbox : [];
          vm.pods = response.data.data.pods && Array.isArray(response.data.data.pods) ? response.data.data.pods : [];

          if (vm.$route.query && vm.$route.query.zoom && vm.$route.query.lat && vm.$route.query.lng) {
            vm.$nextTick(() => {
              vm.map.zoom = parseFloat(vm.$route.query.zoom);
              vm.map.center = [parseFloat(vm.$route.query.lat), parseFloat(vm.$route.query.lng)];
            });
          }

          vm.loading.pods = false;
        }).catch(() => {
          vm.pods = [];
          vm.loading.pods = false;
        });
    },
    openMarker(podId) {
      const vm = this;
      vm.loading.pods = true;
      vm.$router.push(`/locations/${podId}`);
    },
    openByPodId(podId) {
      const vm = this;
      vm.loading.pods = true;
      return vm.$http.get(`/api/v1/pods/${podId}`)
        .then((response) => {
          vm.loading.pods = false;
          vm.details.open = true;
          vm.details.data = response.data.data;
        }).catch(() => {
          vm.loading.pods = false;
        });
    },
  },
};
</script>
<style>
    .leaflet-control-attribution {
        display: none !important;
    }

    .pac-logo:after {
        display: none !important;
    }

    .pac-item {
        padding: 0.5rem;
    }

    .gmnoprint, .gm-style-cc {
        display: none !important;
    }
    .leaflet-right .leaflet-control {
        z-index: 900 !important;
    }

    .switch-action {
      bottom: 3rem;
      left: calc(50% - 4rem)
    }
    .search-location-action {
      left: calc(50% - 9rem)
    }
</style>
