<template>
    <div>
        <div v-if="loading" class="h-screen flex items-center justify-center">
            <spin-loader />
        </div>
        <div v-else>
            <div v-loading="updating">
                <div v-if="can_book">
                    <div v-if="status === 'PAUSED'" class="mb-3">
                        <el-alert type="warning" :closable="false">
                            Head's Up! This media object is paused, meaning that it will not be visible regardless of which slots have been assigned.
                        </el-alert>
                    </div>
                    <el-form>
                        <el-form-item  label="Available Slots">
                            <el-cascader
                                    v-if="!hide"
                                    ref="cascader"
                                    class="w-full"
                                    placeholder="Select available slots..."
                                    v-model="selected_slots"
                                    :options="available_dates"
                                    :props="props"
                                    collapse-tags
                                    clearable />

                        </el-form-item>

                    </el-form>
                    <div class="py-4">

                        <empty-state
                                description="No slot's have been assigned to this video."
                                v-if="!buildSelectedSlots.length" />

                        <div v-else>
                            <div class="mb-3">
                                <el-alert type="success" :closable="false">
                                    This media is scheduled play within {{ getTotalSlots }} slots.
                                </el-alert>
                            </div>
                            <div class="bg-white shadow overflow-hidden sm:rounded-md">
                                <ul role="list" class="divide-y divide-gray-200">
                                    <li
                                            :key="k"
                                            v-for="(i, k) of buildSelectedSlots">
                                        <div class="block cursor-default select-none">
                                            <div class="flex items-center py-4">
                                                <div class="min-w-0 flex-1 flex items-center">
                                                    <div class="px-4 w-1/2">
                                                        <p class="text-sm font-medium text-blue-900 truncate">
                                                            {{ i.location_name }}
                                                        </p>
                                                        <p class="mt-2 flex items-center text-xs text-gray-500">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                                                                 fill="currentColor"
                                                                 viewBox="0 0 24 24"><path d="M12 2c3.196 0 6 2.618 6 5.602 0 3.093-2.493 7.132-6 12.661-3.507-5.529-6-9.568-6-12.661 0-2.984 2.804-5.602 6-5.602m0-2c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
                                                            <span class="truncate">{{ i.location_address }}</span>
                                                        </p>
                                                    </div>
                                                    <div class="hidden px-2 md:block">
                                                        <div>
                                                            <p class="text-sm text-gray-900">
                                                                {{ i.date }}
                                                            </p>
                                                            <p v-if="i.event_description" class="mt-2 flex items-center text-sm text-gray-500">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     class="flex-shrink-0 mr-1.5 h-4 w-4 text-green-600"
                                                                     fill="currentColor"
                                                                     viewBox="0 0 24 24"><path d="M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm1 11.729l.855-.791c1 .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64c-2.141 1.869-3.709 3.949-5.967 7.999-1.393-1.64-2.322-2.686-4.033-4.271z"/></svg>
                                                                {{ i.event_description }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="flex-shrink-0 px-2 pr-10 text-xs">
                                                        {{ i.slots.length }} Slots
                                                    </div>
                                                </div>
                                                <div class="pr-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700"
                                                         fill="currentColor"
                                                         @click="selected_item = null"
                                                         v-if="selected_item === i.key"
                                                         viewBox="0 0 24 24">
                                                        <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/>
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         class="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700"
                                                         fill="currentColor"
                                                         @click="selected_item = i.key"
                                                         v-if="selected_item !== i.key"
                                                         viewBox="0 0 24 24">
                                                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
                                                    </svg>

                                                </div>
                                            </div>
                                            <div class="w-full"  v-if="selected_item === i.key">
                                                <div
                                                        :key="x"
                                                        v-for="(p, x) of i.slots"
                                                        class="flex items-center w-full border-t border-gray-200">
                                                    <div class="flex-grow p-2 px-4 text-xs">
                                                        {{ p.slot }}
                                                    </div>
                                                    <div class="p-2 px-4">
                                                        <svg
                                                                @click="deleteSlot(p.id)"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                class="h-5 w-5 text-red-500 cursor-pointer hover:text-red-700" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"/></svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
                <div v-else>
                    <el-alert v-if="status === 'PENDING'" type="error" theme="dark" :closable="false">
                        Sorry but you can not book any slot for this media as it is currently pending for review.
                    </el-alert>
                    <el-alert v-else type="error" theme="dark" :closable="false">
                        Sorry but you can not book any slot's for this media as it has been rejected.
                    </el-alert>

                      <div class="pt-4" v-if="rejection_reason">
                    <form-input label="Rejection reason">
                         <textarea class="border resize-none p-3 text-sm text-gray-800 border-gray-200 w-full rounded"
                                   v-model="rejection_reason"
                                   readonly rows="5">
                </textarea>
                    </form-input>
                </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SpinLoader from '../SpinLoader';
import EmptyState from '../EmptyState';
import FormInput from '../FormInput';

export default {
  name: 'MediaSlots',
  components: { FormInput, EmptyState, SpinLoader },
  props: {
    value: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      hide: false,
      loading: true,
      updating: false,
      can_book: false,
      status: 'PENDING',
      rejection_reason: null,
      selected_item: null,
      available_dates: [],
      selected_slots: [],
      slots: [],
      props: {
        multiple: true,
        emitPath: false,
      },
      selected: [],
    };
  },
  mounted() {
    this.$emit('hide-actions', true);
    this.fetchAvailability();
  },
  computed: {
    getTotalSlots() {
      return this.slots.filter((x) => this.selected_slots.indexOf(x.id) > -1).length;
    },
    buildSelectedSlots() {
      const items = this.slots.filter((x) => this.selected_slots.indexOf(x.id) > -1);

      const objects = {};

      for (const i of items) {
        const key = `${i.location_id}-${i.date}`;
        if (!objects[key]) {
          objects[key] = {
            key,
            type: i.type,
            location_address: i.location_address,
            location_name: i.location_name,
            date: i.date,
            event_description: i.event_description,
            slots: [],
          };
        }
        objects[key].slots.push({
          id: i.id,
          slot: `Slot ${objects[key].slots.length + 1}`,
        });
      }

      return Object.values(objects);
    },
  },
  methods: {
    deleteSlot(slotId) {
      const index = this.selected_slots.findIndex((x) => x === slotId);
      if (index > -1) {
        this.selected_slots.splice(index, 1);
      }
      this.hide = true;
      this.$nextTick(() => {
        this.hide = false;
      });
    },
    update() {
      const vm = this;
      vm.updating = true;
      this.$emit('updating', true);
      return vm.$http.post(`/api/v1/media/${vm.value}/availability`, {
        slots: vm.selected_slots,
      })
        .then(() => {
          vm.updating = false;
          vm.$emit('updating', false);
          this.$notify({
            type: 'success',
            title: 'Media Booking\'s Updated',
            message: 'Your media booking\'s have been updated successfully.',
            position: 'bottom-left',
          });
          vm.$emit('updated', true);
        }).catch((e) => {
          vm.updating = false;
          vm.$emit('updating', false);
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
            offset: 0,
          });
        });
    },
    fetchAvailability() {
      const vm = this;
      if (vm.value) {
        vm.loading = true;
        return vm.$http.get(`/api/v1/media/${vm.value}/availability`)
          .then((response) => {
            vm.loading = false;
            vm.can_book = response.data.data.can_book;
            vm.status = response.data.data.status;
            vm.rejection_reason = response.data.data.rejection_reason;
            vm.available_dates = response.data.data.available_dates;
            vm.selected_slots = response.data.data.selected_slots;
            vm.slots = response.data.data.slots;
            this.$emit('hide-actions', vm.can_book === false);
          }).catch((e) => {
            console.warn(e);
            vm.loading = false;
            this.$emit('hide-actions', true);
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
