<template>
  <div
      v-if="enabled && hasValidQRCode"
      :class="getQRCodeClass"
      class="absolute z-40 bg-white rounded overflow-hidden border-4 border-black bottom-0 right-0">
    <div
        v-if="url && url.length > 0"
        class="bg-white flex items-center justify-center p-1">
      <qrcode-vue
          :size="50"
          :value="url" />
    </div>
    <div
        v-if="text && text.length > 0"
        class="bg-black w-16  p-1 text-white text-qr-code uppercase">
      {{ text }}
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'PlaylistItemQRCode',
  props: {
    enabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    text: {
      type: String,
      default() {
        return null;
      },
    },
    url: {
      type: String,
      default() {
        return null;
      },
    },
    position: {
      type: String,
      default() {
        return null;
      },
    },
    positions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    QrcodeVue,
  },
  watch: {
    position() {
      this.$emit('change', this.position);
    },
  },
  computed: {
    hasValidQRCode() {
      const vm = this;
      if (vm.enabled) {
        return vm.url && vm.url.length > 0;
      }
      return false;
    },
    getQRCodeClass() {
      const vm = this;
      if (vm.enabled) {
        if (vm.position) {
          const find = vm.positions.find((x) => x.value === vm.position);
          if (find) {
            return find.location_class;
          }
        }
      }
      return null;
    },
  },
};
</script>
