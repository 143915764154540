<template>
  <playlist
      :is-private="true"
      :playlist-id="$route.params.playlistId" />
</template>

<script>

import Playlist from '@/components/Playlist';

export default {
  name: 'PrivateLocation',
  components: { Playlist },
};
</script>
