import axios from 'axios';
import { getApiEndpoint } from '@/utils/endpoints';
import store from '../store/index';
import router from '../router/index';

axios.defaults.baseURL = getApiEndpoint();

// Append the Access Token on all API Requests
axios.interceptors.request.use((config) => {
  if (store.getters['auth/accessToken']) {
    config.headers.Authorization = `Bearer ${store.getters['auth/accessToken']}`;
  }

  return config;
}, (error) => Promise.reject(error));

// Handle Refresh token here.
axios.interceptors.response.use((response) => response, (error) => {
  const originalRequest = error.config;

  if (error.response) {
    if (error.response.status === 401 && originalRequest.url === '/api/v1/auth/refresh-token') {
      router.push('/login?mode=refresh-expired&redirect=/');
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      return axios.post(
        '/api/v1/auth/refresh-token',
        {
          refresh_token: store.getters['auth/refreshToken'],
        }
        ).then((response) => {
          store.commit('auth/setAccessToken', response.data.data.token);
          store.commit('auth/setRefreshToken', response.data.data.refresh_token);

          return axios(originalRequest);
        })
        .catch(() => {
          router.push('/login?mode=refresh-expired&redirect=/');
          return Promise.reject(error);
        });
    }
  }
  return Promise.reject(error);
});

export default axios;
