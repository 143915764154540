export function media() {
return [
  {name: 'Date (Desc)', sort: 'created_at', order: 'desc'},
  {name: 'Date (Asc)', sort: 'created_at', order: 'asc'},
  {name: 'Name (Desc)', sort: 'name', order: 'desc'},
  {name: 'Name (Asc)', sort: 'name', order: 'asc'},
]
}

export function playlist() {
  return [
    {name: 'Date (Desc)', sort: 'created_at', order: 'desc'},
    {name: 'Date (Asc)', sort: 'created_at', order: 'asc'},
    {name: 'Name (Desc)', sort: 'name', order: 'desc'},
    {name: 'Name (Asc)', sort: 'name', order: 'asc'},
    {name: 'Status (Desc)', sort: 'status', order: 'desc'},
    {name: 'Status (Asc)', sort: 'status', order: 'asc'},
    {name: 'Duration (Desc)', sort: 'duration_secs', order: 'desc'},
    {name: 'Duration (Asc)', sort: 'duration_secs', order: 'asc'},
  ]
}


export function playLogs() {
return  [
    {name: "Start Time", sort: 'start_time'},
   {name: "Emd Time", sort:'end_time'},
   {name: "Duration", sort:'duration_secs'},
    {name: "Media", sort:'media_id'},
    {name: "Playlist", sort:'playlist_id'},
    {name: "Location", sort:'location_id'},
].map((x) => {
  let asc = JSON.parse(JSON.stringify(x)),
    desc = JSON.parse(JSON.stringify(x));


   asc.name = asc.name + ' (Asc)';
   asc.order = 'asc';

   desc.name = desc.name + ' (Desc)';
   desc.order = 'desc';

  return [
    desc,
    asc
  ]
 }).flat();

}

export function pods() {
  return [
    {name: 'Favourite (Desc)', sort: 'is_favourite', order: 'desc'},
    {name: 'Favourite (Asc)', sort: 'is_favourite', order: 'asc'},
    {name: 'Name (Desc)', sort: 'name', order: 'desc'},
    {name: 'Name (Asc)', sort: 'name', order: 'asc'},
    {name: 'Type (Desc)', sort: 'type', order: 'desc'},
    {name: 'Type (Asc)', sort: 'type', order: 'asc'},
    {name: 'Price (Desc)', sort: 'default_price', order: 'desc'},
    {name: 'Price (Asc)', sort: 'default_price', order: 'asc'},
    {name: 'Address (Desc)', sort: 'address', order: 'desc'},
    {name: 'Address (Asc)', sort: 'address', order: 'asc'}
  ]
}
