<template>
  <div id="filepond-el"></div>
</template>
<script>
import Vue from 'vue';
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FileUploadCta from './FileUploadCta';

FilePond.registerPlugin(FilePondPluginFileValidateSize);
FilePond.registerPlugin(FilePondPluginFileValidateType);

export default {
  name: 'FilePondUpload',
  props: {
    folderId: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      filePond: null,
    };
  },
  mounted() {
    const vm = this;
    let url = `${vm.$store.state.auth.api}/api/v1/media-upload`;

    if (this.folderId) {
      url += `?folderId=${this.folderId}`;
    }

    const el = (
      new Vue({
        ...FileUploadCta,
        parent: this,
      }).$mount()
    ).$el.outerHTML;

    vm.filePond = FilePond.create(document.querySelector('#filepond-el'), {
      labelFileProcessingError: (error) => {
        if (error.code && error.code === 422) {
          return 'Invalid media type supplied';
        }
        if (error.code && error.code === 404) {
          return 'Upload folder not found';
        }
        if (error.code && error.code === 413) {
          return 'Your video is too long.';
        }
        return 'Sorry, an error occurred';
      },
      acceptedFileTypes: [
        'video/mp4', 'video/webm',
        'image/png', 'image/jpeg', 'image/jpg',
      ],
      maxFileSize: '25MB',
      labelIdle: el,
      allowMultiple: true,
      server: {
        url,
        headers: {
          Authorization: `Bearer ${this.$store.getters['auth/accessToken']}`,
        },
      },
    });

    document.addEventListener('FilePond:processfile', (e) => {
      if (e.detail && e.detail.error === null) {
        vm.$emit('uploaded');
      }
    });
  },
};
</script>
<style>
    .filepond--credits {
        display: none !important;
    }
</style>
