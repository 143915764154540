<template>
    <div v-loading="fetching">
        <calendar-month
                :is-disabled-previous="isDisabledPrevious"
                :is-disabled-next="isDisabledNext"
                @event-select="onEventSelect"
                @prev-month="prevMonth"
                @next-month="nextMonth"
                @select="onDaySelect"
                @remove="onDayRemove"
                :selected-days="selected_days"
                :default-price="default_price"
                :currency="currency"
                :specific-pricing="specific_pricing"
                :unavailable-dates="unavailable_dates"
                :month="getActiveMonth"
                :year="getActiveYear" />
    </div>
</template>

<script>
import moment from 'moment';

import CalendarMonth from './CalendarMonth';

export default {
  name: 'CalendarSelect',
  props: {
    podId: {
      type: String,
      default() {
        return null;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return true;
      },
    },
    startDate: {
      type: String,
      default() {
        return moment().format('YYYY-MM-DD');
      },
    },
    defaultDates: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    CalendarMonth,
  },

  data() {
    return {
      fetching: true,
      default_price: null,
      currency: null,
      specific_pricing: [
      ],
      current_date: moment(this.current_date),
      selected_days: [],
      unavailable_dates: [],
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.date && this.$moment(this.$route.query.date).isValid()) {
      this.selected_days.push(this.$moment(this.$route.query.date));
    }
  },
  created() {
    const vm = this;
    if (vm.podId) {
      vm.fetchAvailability();
    }
  },
  watch: {
    selected_days() {
      this.$emit('total-days', this.selected_days.length);
      this.$emit('selected-days', this.buildDateObjects);
    },
  },
  methods: {
    onEventSelect(object) {
      this.$emit('event-select', object);
    },
    removeDateByDateString(date) {
      this.onDayRemove(moment(date));
    },
    fetchAvailability() {
      const vm = this;
      vm.fetching = true;
      return vm.$http.get(`/api/v1/pod/${this.podId}/availability`, {
        params: {
          month: this.current_date.format('MM'),
          year: this.current_date.format('YYYY'),
        },
      })
        .then((response) => {
          vm.default_price = response.data.data.default_price;
          vm.currency = response.data.data.currency;
          vm.specific_pricing = response.data.data.specific_day_pricing;
          vm.unavailable_dates = response.data.data.unavailable_dates.map((x) => moment(x));

          vm.removeUnavailableDates();
          vm.fetching = false;
        }).catch(() => {
          vm.fetching = false;
        });
    },
    removeUnavailableDates() {
      const vm = this;
      if (vm.selected_days && vm.selected_days.length > 0) {
        vm.selected_days = vm.selected_days.filter((x) => x.isAfter(vm.$moment())).filter((x) => !vm.unavailable_dates.filter((y) => y.format('YYYY-MM-DD') === x.format('YYYY-MM-DD')).length);
      }
    },
    removeDate(index) {
      if (index > -1) {
        this.selected_days.splice(index, 1);
      }
    },
    onDaySelect(moment) {
      this.selected_days.push(moment);
    },
    nextMonth() {
      this.current_date = moment(this.current_date).add(1, 'month');
      this.fetchAvailability();
    },
    prevMonth() {
      this.current_date = moment(this.current_date).subtract(1, 'month');
      this.fetchAvailability();
    },
    onDayRemove(moment) {
      const index = this.selected_days.findIndex((x) => x.format('YYYY-MM-DD') === moment.format('YYYY-MM-DD'));
      this.removeDate(index);
    }
  },
  computed: {
    isDisabledPrevious() {
      return this.current_date.isSameOrBefore(moment().startOf('month'));
    },
    isDisabledNext() {
      return this.current_date.isAfter(moment().add(6, 'months'));
    },
    buildDateObjects() {
      return this.selected_days.map((x) => {
        let price = this.default_price;
        const find = this.specific_pricing.find((d) => d.date === x.format('YYYY-MM-DD'));

        if (find) {
          price = find.price;
        }

        return {
          date: x,
          slots: 1,
          price,
        };
      });
    },
    getTotalPrice() {
      return this.buildDateObjects.map((x) => x.price).reduce((a, b) => a + b, 0);
    },
    getActiveMonth() {
      return this.current_date.format('MMMM');
    },
    getActiveYear() {
      return this.current_date.format('YYYY');
    },
  },
};
</script>

<style scoped>

</style>
