<template>
  <div class="min-h-screen bg-red-700 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full flex justify-center items-center text-center sm:max-w-md text-white">
      <svg class="h-10 w-auto"  fill="currentColor" viewBox="0 0 1260 982" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g  fill="currentColor" fill-rule="nonzero">
            <polygon points="479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"></polygon>
            <polygon points="1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"></polygon>
          </g>
        </g>
      </svg>

      <div class="ml-3">
        <h3 class="pr-3 relative text-2xl font-montserrat uppercase font-bold">
          Chargabull <span class="absolute right-0 top-0 text-xs">®</span>
        </h3>
        <h5 class="text-sm text-center">Electrify your audience</h5>
      </div>

    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-6 md:px-0">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <div class="leading-loose">
          <p>We are currently waiting to launch January 2023.</p>

          <p>For more information or to register your interest please email us <a class="text-red-700 font-bold hover:text-red-800" href="mailto:hello@chargabull.com">hello@chargabull.com</a></p>

        </div>

        <!--
        <form
        >
          <div>
            <label
                    for="name"
                    class="block text-sm font-medium leading-5 text-gray-700"
            >
              Full name <sup class="text-red-600">*</sup>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input id="name"
                        :disabled="running"
                        placeholder="E.g, Elon Musk"
                        @keyup.enter.native="register"
                        v-model="name" />
            </div>
          </div>
          <div class="mt-6">
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Email address <sup class="text-red-600">*</sup>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input id="email"
                        :disabled="running"
                        autocomplete="off"
                        placeholder="E.g, bill@microsoft.com"
                        @keyup.enter.native="register"
                        v-model="email" />
            </div>
          </div>

          <div class="mt-6">
            <label
              for="password"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Password <sup class="text-red-600">*</sup>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input
                      :disabled="running"
                      id="password"
                v-model="password"
                      @keyup.enter.native="register"
                      placeholder="E.g, ******"
                type="password"
                autocomplete="off"
              />
            </div>
          </div>

          <div class="mt-6 flex items-center justify-between">
            <div class="text-sm leading-5">
              <a
                href="/login"
                class="font-medium text-red-600 hover:text-red-700 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Already registered?
              </a>
            </div>
          </div>

          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <el-button
                class="w-full"
                type="success"
                :disabled="running"
                :loading="running"
                @click="register"
              >
                Register
              </el-button>
            </span>
          </div>
        </form>
        -->
      </div>
    </div>
  </div>
</template>

<script>

import { register, reset } from '../utils/auth';

export default {
  name: 'account-register',
  data() {
    return {
      name: null,
      email: null,
      password: null,
      running: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('auth/setBreadcrumbs', [
        { title: 'Register' },
      ]);
      reset(vm);
    });
  },
  created() {
    const vm = this;
    if (this.$route.query) {
      if (this.$route.query.email) {
        vm.email = this.$route.query.email;
      }
      if (this.$route.query.name) {
        vm.name = this.$route.query.name;
      }
    }
  },
  methods: {
    register() {
      const vm = this;
      vm.running = true;
      register(vm.name, vm.email, vm.password)
        .then(() => {
          vm.$http.get('/api/v1/auth/user')
            .then((response) => {
              vm.$store.commit('auth/setUser', response.data.data);
              vm.$router.push(vm.$route.query.redirect ? vm.$route.query.redirect : '/');
            })
            .catch((e) => {
              vm.running = false;
              vm.$store.dispatch('auth/handleServerError', {
                vm,
                error: e,
              });
            });
        })
        .catch((e) => {
          vm.running = false;
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
            offset: 0,
          });
        });
    },
  },
};
</script>
