<template>
    <div v-if="loading">
        <spin-loader bg="transparent" height="h-64"/>
    </div>
    <div v-else>
        <el-tree
                default-expand-all
                :props="defaultProps"
                @node-click="handleNodeClick"
                :data="items">
              <span class="custom-tree-node" slot-scope="{ data  }">
                  <div class="w-full flex items-center px-3 rounded" :class="{
                  'bg-blue-700 text-white': data.id === value
                  }">
                      <i class="el-icon-folder mr-2"></i>
                        <span>{{ data.name }}</span>
                  </div>
              </span>
        </el-tree>
    </div>
</template>

<script>
import SpinLoader from '../SpinLoader';

export default {
  name: 'FileMove',
  components: { SpinLoader },
  props: {
    value: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      loading: true,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      items: [],
    };
  },
  mounted() {
    this.fetchFolder();
  },
  methods: {
    handleNodeClick(data) {
      this.$emit('input', data.id);
    },
    fetchFolder() {
      const vm = this;
      vm.loading = true;

      const path = '/api/v1/folder';

      return vm.$http.get(path).then((response) => {
        vm.items = [response.data.data.tree];
        vm.loading = false;
      }).catch(() => {
        vm.loading = false;
        vm.items = [];
      });
    },
  },
};
</script>

<style scoped>

</style>
