<template>
  <page-wrapper title="Bookings" :show-feed="false">

    <template slot="nav-search">
      <div class="w-full"></div>
    </template>
    <template slot="nav-cta">
        <el-button
            @click="openBooking"
            icon="el-icon-date" type="success">Book Location
        </el-button>
    </template>

    <template slot="overlays">

      <small-modal :title="event.object.name"
                   @close="() => event.show = false"
                   :show="event.show">
        <template slot="content">
          <div class="p-4 h-full relative overflow-scroll pb-24">

            <div class="flex w-full px-2">
              <div class="w-1/2 p-2" v-html="event.object.description">
              </div>
              <div class="w-1/2 p-2">

                <div class="shadow border border-gray-100 h-full rounded">
                  <preview-map-object
                      :lat="event.object.lat"
                      :lon="event.object.lng"
                  />
                </div>

                <div class="flex flex-wrap w-full">
                  <div class="w-full">
                    <dl>
                      <div class="py-3 grid grid-cols-3">
                        <dt class="text-sm font-medium text-gray-400">Address</dt>
                        <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                          {{ event.object.address }}
                        </dd>
                      </div>
                    </dl>
                    <dl>
                      <div class="py-3 grid grid-cols-3">
                        <dt class="text-sm font-medium text-gray-400">Type</dt>
                        <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                          {{ event.object.type }}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div
                class="px-4 w-full border-t border-gray-200 bg-gray-50 flex items-center justify-end fixed bottom-0 h-20 left-0">
              <el-button
                  @click="() => event.show = false"
              >Close
              </el-button>
            </div>
          </div>
        </template>
      </small-modal>

      <menu-sidebar-layer
          :show-actions="false"
          @close="()=> { book.open = false;  }"
          position="left" :show="book.open">

        <template slot="title">
          <h2>Book Location</h2>
        </template>

        <template slot="content">

          <div class="mb-20">
            <div class="mb-8">
              <form-input label="Select Location" clear-label :required="true">

                <pod-location-select
                    :collapse-tags="false"
                    :clearable="false"
                    :filterable="true"
                    :multiple="false"
                    :select-default="true"
                    v-model="book.selected.id"/>

              </form-input>
            </div>

            <book-pod
                v-if="book.selected && book.selected.id && !book.loading_pod"
                v-model="book.selected"
                @close="onBookingClosed"
            />
          </div>
        </template>

      </menu-sidebar-layer>

      <menu-sidebar-layer
          :show-actions="true"
          :show-close="!slot.updating"
          @close="()=> { slot.open = false; $router.push('/bookings'); }"
          position="left" :show="slot.open">

        <template slot="title">
          <h2>{{ slot.data.location && slot.data.location.name ? slot.data.location.name : null }}</h2>
          <p class="text-sm" v-if="slot.data.booking && slot.data.booking.date">
            <i class="el-icon-date"></i>
            {{ slot.data.booking.date | moment("dddd MMM Do YYYY") }}</p>
        </template>
        <template slot="content">
          <div class="h-48 -mx-6 -mt-6 relative z-20" v-if="slot && slot.data && slot.data.location">
            <street-view-layer
                v-if="slot.data.location.use_street_view"
                :location="{lat: slot.data.location.lat, lng: slot.data.location.lon}"/>
            <div class="h-48 border-b border-gray-100 relative z-20 w-full" v-else>
              <preview-map-object
                  :lat="slot.data.location.lat"
                  :lon="slot.data.location.lon"
              />
            </div>
          </div>

          <div class="grid mt-5 gap-y-6" v-if="slot.data.location && slot.data.location.name">

            <div class="w-full" v-loading="loading.media || slot.updating">
              <div v-if="!bookingHasExpired">
                <div v-if="!slot.data.media.id">
                  <el-alert type="warning" :closable="false">
                    This booking does not have a playlist attached.
                  </el-alert>
                </div>

                <div v-if="selectedPlaylistHasConflicts && slot.data.media.id">
                  <el-alert type="warning" :closable="false">
                    This booking conflicts with the attached playlist due to advertising restrictions. If not resolved
                    one or more media items may not play.
                  </el-alert>
                </div>

                <h4 class="block text-sm mb-2 mt-4 font-medium text-gray-700">Select a published playlist</h4>

                <div class="flex w-full gap-2">
                  <div class="flex-grow">
                    <el-select
                        filterable
                        placeholder="Select playlist..."
                        clearable
                        v-model="slot.data.media.id" class="w-full">
                      <el-option
                          class="el-option--item"
                          v-for="item in media"
                          :key="'media-' + item.id"
                          :label="item.name"
                          :value="item.id">
                        <div class="px-2">
                          <div class="flex items-center w-full font-semibold">
                            {{ item.name }}
                          </div>
                          <div
                              v-if="item.has_booking_conflict"
                              class="text-xs my-1 text-red-700">
                            <i class="el-icon-warning"></i> <span class="mr-1"> Conflicts with advertising restrictions</span>
                          </div>
                          <div class="text-xs mt-1">
                            <i class="el-icon-video-play"></i> <span class="mr-1"> {{
                              item.item_count
                            }} item(s)</span>
                            <i class="el-icon-time"></i> <span>{{ item.duration_secs | duration_mm }}</span>

                          </div>
                        </div>
                      </el-option>
                    </el-select>
                  </div>
                  <div>
                    <el-button
                        type="danger"
                        :disabled="!slot.data.media.id"
                        @click="() => slot.data.media.id = null">
                      <div class="flex items-center">
                                                    <span class="mr-1">
                                                        <svg class="text-white w-4 h-4"
                                                             xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                             viewBox="0 0 24 24"><path
                                                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.094l-4.157-4.104 4.1-4.141-1.849-1.849-4.105 4.159-4.156-4.102-1.833 1.834 4.161 4.12-4.104 4.157 1.834 1.832 4.118-4.159 4.143 4.102 1.848-1.849z"/></svg>
                                                    </span>
                        <span>Detach</span>
                      </div>
                    </el-button>
                  </div>
                  <div>
                    <el-button
                        type="info"
                        :disabled="!slot.data.media.id || !getSelectedMediaPlaylist.length || selectedPlaylistHasConflicts"
                        @click="preview.show = true">
                      <div class="flex items-center">
                                                    <span class="mr-1">
                                                        <svg class="text-white w-4 h-4"
                                                             xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                             viewBox="0 0 24 24"><path
                                                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.094l-4.157-4.104 4.1-4.141-1.849-1.849-4.105 4.159-4.156-4.102-1.833 1.834 4.161 4.12-4.104 4.157 1.834 1.832 4.118-4.159 4.143 4.102 1.848-1.849z"/></svg>
                                                    </span>
                        <span>Preview</span>
                      </div>
                    </el-button>
                  </div>
                </div>
              </div>
              <div v-else>
                <el-alert type="warning" :closable="false">
                  This booking has now expired and playlists cannot be updated.
                </el-alert>
              </div>
            </div>

            <div class="grid w-full grid-cols-3 gap-10">
              <div class="col-span-3 md:col-span-2">
                <dl>
                  <div class="py-3 grid grid-cols-3">
                    <dt class="text-sm font-medium text-gray-400">Address</dt>
                    <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                      {{ slot.data.location.address }}
                    </dd>
                  </div>
                  <div class="py-3 grid grid-cols-3">
                    <dt class="text-sm font-medium text-gray-400">Type</dt>
                    <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                      {{ slot.data.location.type }}
                    </dd>
                  </div>

                  <div class="py-3 grid grid-cols-3" v-if="slot.data.location.weather_forecast">
                    <dt class="text-sm font-medium text-gray-400">Current weather</dt>
                    <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                      {{ slot.data.location.weather_forecast.celcius }}°c<br />
                      <span class="font-semibold">{{ slot.data.location.weather_forecast.weather_type }}</span>

                    </dd>
                  </div>
                  <div class="py-3 grid grid-cols-3">
                    <dt class="text-sm font-medium text-gray-400">Local time</dt>
                    <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                      {{ slot.data.location.time_zone | localTime }}
                    </dd>
                  </div>
                  <div class="py-3 grid grid-cols-3">
                    <dt class="text-sm font-medium text-gray-400">Local date</dt>
                    <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                      {{ slot.data.location.time_zone | localDate }}
                    </dd>
                  </div>

                </dl>
              </div>
              <div class="col-span-3 md:col-span-1">
                <div class="h-48 rounded overflow-hidden shadow-lg border border-gray-200 md:h-full w-full z-10 relative">

                  <preview-map-object
                      :lat="slot.data.location.lat"
                      :lon="slot.data.location.lon"
                  />

                </div>
              </div>
            </div>

            <div class="w-full">

              <h4 class="block text-sm mb-4 font-medium text-gray-700">Advertising Restrictions</h4>

              <div v-if="slot.data.location.restrictions && slot.data.location.restrictions.length > 0">

                <div class="mb-6">
                  <el-alert type="warning" effect="dark" :closable="false">
                    Please note that you will not be able to display playlist media items
                    within this location that contain any of the following below restrictions
                  </el-alert>
                </div>

                <div class="relative flex items-start mb-3"
                     :key="'restrictions-' + k"
                     v-for="(x, k) of slot.data.location.restrictions">
                  <div class="ml-3 text-sm">
                    <label :for="'restriction-' + k" class="font-medium text-gray-700"><i
                        class="el-icon-close text-red-500"></i> {{ x.type }}</label>
                    <p class="text-gray-500">
                      {{ x.description }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-3" v-else>
                <el-alert type="success" effect="dark" :closable="false">
                  This location has no advertising restrictions
                </el-alert>
              </div>

<!--
              <h4 class="block text-sm mb-4 font-medium text-gray-700">Operating Hours</h4>

              <operating-hours :operating-hours="[]" />
-->

            </div>

          </div>

        </template>
        <template slot="actions">
          <el-button
              :disabled="slot.updating"
              @click="()=> { slot.open = false; $router.push('/bookings'); }">
            Close
          </el-button>
          <el-button
              icon="el-icon-refresh-right"
              v-if="slot.data.booking && slot.data.booking.id && !bookingHasExpired"
              @click="updateBookingSlot(slot.data.booking.id)"
              :loading="slot.updating"
              :disabled="slot.updating"
              type="success">
            Update Booking
          </el-button>
        </template>
      </menu-sidebar-layer>

      <playlist-preview
          title="Preview Playlist"
          v-if="preview.show"
          @close="()=> { preview.show = false }"
          :videos="getSelectedMediaPlaylist"
          :show="true"/>
    </template>

    <template slot="content">

      <div class="lg:flex h-full lg:flex-col">

        <div class="flex flex-wrap mb-4">
          <div class="w-full">
            <div class="flex grid gap-6 grid-cols-12">
              <div class="col-span-12">
                <div class="flex gap-3 items-center">
                  <div class="flex-grow">
                    <h1 class="text-2xl font-semibold text-gray-900">
                      {{ getCurrentMonthString }}
                    </h1>
                  </div>
                  <div class="flex items-center rounded-md shadow-sm items-stretch mt-1">
                    <button
                        @click="previousMonth"
                        type="button"
                        class="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                      <span class="sr-only">Previous month</span>
                      <!-- Heroicon name: solid/chevron-left -->
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 20 20"
                           fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"/>
                      </svg>
                    </button>
                    <button
                        @click="getCurrentDate"
                        type="button"
                        class="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block">
                      Today
                    </button>
                    <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden"></span>
                    <button type="button"
                            @click="nextMonth"
                            class="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                      <span class="sr-only">Next month</span>
                      <!-- Heroicon name: solid/chevron-right -->
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 20 20"
                           fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"/>
                      </svg>
                    </button>
                  </div>
                  <div class="pt-2">
                    <el-dropdown @command="handleExport">
                      <el-button type="info">
                        Download <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="current">Current Month</el-dropdown-item>
                        <el-dropdown-item command="all">All Bookings</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-span-6 xl:col-span-2 flex items-center">
                <el-radio-group v-model="view_as">
                  <el-radio-button label="calendar">Calendar</el-radio-button>
                  <el-radio-button label="list">List</el-radio-button>
                </el-radio-group>
              </div>
              <div class="col-span-6 xl:col-span-3 items-center flex">
                <form-input label="Show Expired" :required="false">
                  <el-switch v-model="filters.show_expired" />
                </form-input>
              </div>
              <div class="col-span-12 md:col-span-6 lg:col-span-4 flex items-center">
                <form-input label="Location" :required="false">
                  <pod-location-select
                      :collapse-tags="true"
                      :clearable="true"
                      :show-price="false"
                      :filterable="true"
                      :multiple="true"
                      v-model="filters.pods"/>
                </form-input>
              </div>
              <div class="col-span-6 lg:col-span-3 flex items-center">
                <form-input label="Slot Status" :required="false">
                  <el-select
                      :loading="loading.bookings || loading.slot"
                      :disabled="loading.bookings || loading.slot"
                      class="w-full"
                      v-model="filters.assigned"
                      placeholder="Filter Status..."
                  >
                    <el-option
                        v-for="(item, index) in getAssignmentTypes"
                        :key="index + '-assignment'"
                        :label="item.label"
                        :value="item.id"
                    >
                      {{ item.label }}
                    </el-option>
                  </el-select>
                </form-input>
              </div>
              <div v-show="view_as === 'calendar'"
                   class="col-span-12 shadow select-none lg:flex lg:flex-auto lg:flex-col"
                   v-loading="loading.bookings || loading.slot">

                <div v-show="view_as === 'calendar'"
                     class="col-span-12 text-xs flex flex-wrap border-b border-gray-100 py-3 text-gray-700 bg-white border border-gray-200">
                  <div class="w-full flex flex-wrap mt-1 items-center justify-center py-3">
                    <div class="flex select-none items-center px-3 py-1">
                      <div class="w-3 h-3 rounded-full mr-1 bg-yellow-300 border border-yellow-400"></div>
                      <span>Current Day</span>
                    </div>
                    <div class="flex select-none items-center px-3 py-1">
                      <div class="w-3 h-3 rounded-full mr-1 bg-blue-700 border border-blue-800"></div>
                      <span>Event Day</span>
                    </div>
                    <div class="flex select-none items-center px-3 py-1">
                      <div class="w-3 h-3 rounded-full mr-1 bg-red-600 border border-red-700"></div>
                      <span>Unassigned Playlist Slots</span>
                    </div>
                    <div class="flex select-none items-center px-3 py-1">
                      <div class="w-3 h-3 rounded-full mr-1 bg-green-500 border border-green-600"></div>
                      <span>Assigned Playlist Slots</span>
                    </div>
                    <div class="flex select-none items-center px-3 py-1">
                      <div class="w-3 h-3 rounded-full mr-1 bg-yellow-500 border border-yellow-600"></div>
                      <span>Booking/Playlist Conflicts</span>
                    </div>
                    <div class="flex select-none items-center px-3 py-1">
                      <div class="w-3 h-3 rounded-full mr-1 bg-gray-400 border border-gray-500"></div>
                      <span>Expired Bookings</span>
                    </div>
                  </div>
                </div>

                <div
                    class="grid grid-cols-7 gap-px border-b border-gray-200 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                  <div class="bg-white py-2">M<span class="sr-only sm:not-sr-only">on</span></div>
                  <div class="bg-white py-2">T<span class="sr-only sm:not-sr-only">ue</span></div>
                  <div class="bg-white py-2">W<span class="sr-only sm:not-sr-only">ed</span></div>
                  <div class="bg-white py-2">T<span class="sr-only sm:not-sr-only">hu</span></div>
                  <div class="bg-white py-2">F<span class="sr-only sm:not-sr-only">ri</span></div>
                  <div class="bg-white py-2">S<span class="sr-only sm:not-sr-only">at</span></div>
                  <div class="bg-white py-2">S<span class="sr-only sm:not-sr-only">un</span></div>
                </div>
                <div class="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                  <div class="hidden w-full lg:grid lg:grid-cols-7 lg:gap-px">
                    <div
                        :class="{
                                           '!bg-gray-100': !d.is_current_month,
                                        }"
                        class="relative date-block py-2 px-3 hover:bg-gray-50 bg-white"
                        :key="k + '-date-range'"
                        v-for="(d, k) of buildDateRange">
                      <time
                          :class="{
                                            'flex h-6 w-6 items-center justify-center rounded-full bg-yellow-300 font-semibold': d.is_today
                                            }"
                          :datetime="d.date.format('YYYY-MM-DD')">
                        {{ d.date.format('DD') }}
                      </time>
                      <div>
                        <ol class="mt-3" v-if="d.events && d.events.length > 0">

                          <li class="mb-1 bg-blue-500 hover:bg-blue-700 flex-auto truncate font-medium text-white px-2 rounded"
                              :key="i"
                              @click="eventDetails(x)"
                              v-for="(x, i) of d.events"
                          >

                            <el-popover
                                class="w-full h-full"
                                placement="left"
                                width="250"
                                trigger="hover">
                              <div class="select-none">
                                <h4 class="font-bold mmb-1">Event</h4>
                                <div class="text-xs mt-1">
                                  <div class="!break-words">
                                   <span class="font-semibold">{{ x.name }}</span> - Click for details
                                  </div>
                                </div>
                              </div>
                              <span class="cursor-pointer" slot="reference">
                                                        <i class="el-icon-star-on"></i> {{ x.name }}
                                                    </span>
                            </el-popover>

                          </li>
                        </ol>
                        <ol class="mt-4" v-if="d.slots && d.slots.length > 0">
                          <li class="mb-1"
                              :key="i"
                              v-for="(x, i) of d.slots"
                              @click="$router.push('/bookings/' + x.id)">

                            <el-tooltip placement="left">
                              <div slot="content">
                                <span v-if="d.is_in_past">This booking has expired</span>
                                <span v-else-if="!x.assigned">You have not attached a playlist<br/> to this booking slot.<br/>Click the slot to attach a playlist.</span>
                                <span v-else-if="x.has_booking_conflict">
                                                        This booking/playlist is conflicted. <br/>Please  update.
                                                    </span>
                                <span v-else>Playlist attached.<br/>Click to update.</span>
                              </div>
                              <p
                                  :class="{
                                                        'bg-green-500 hover:bg-green-600': x.assigned && !x.has_booking_conflict && !d.is_in_past,
                                                        'bg-red-600 hover:bg-red-700': !x.assigned && !x.has_booking_conflict && !d.is_in_past,
                                                        'bg-yellow-500 hover:bg-yellow-600': x.has_booking_conflict && !d.is_in_past,
                                                        'bg-gray-400': d.is_in_past
                                                        }"
                                  class="flex-auto truncate font-medium text-white px-2 rounded  cursor-pointer">
                                <i v-if="x.has_booking_conflict && !d.is_in_past" class="el-icon-warning"></i>
                                {{ x.location }}</p>
                            </el-tooltip>
                            <!--
                            <el-popover
                                    class="w-full h-full"
                                    placement="left"
                                    width="250"
                                    trigger="hover">
                                <div class="select-none">
                                    <div class="text-xs">
                                        <el-alert v-if="!x.assigned" effect="dark"
                                                  type="warning" :closable="false">
                                                        <span class="break-normal !text-left leading-tight text-left tracking-normal">
                                                            You have not added a playlist to this slot. Click the slot to attach a playlist
                                                        </span>
                                        </el-alert>

                                        <el-alert v-if="x.assigned" effect="dark"
                                                  type="success" :closable="false">
                                                        <span class="break-normal text-left leading-tight text-left tracking-normal">
                                                            Playlist attached. Click to update.
                                                        </span>
                                        </el-alert>

                                    </div>
                                </div>

                            </el-popover>
                            --->

                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="grid w-full grid-cols-7 gap-px lg:hidden">

                    <button type="button"
                            :key="'date-' + k"
                            v-for="(d, k) of buildDateRange"
                            class="flex flex-col bg-gray-50 py-2 cursor-default px-3 text-gray-500 hover:bg-gray-100 focus:z-10 bg-white"
                            :class="{
                                 '!bg-gray-100': !d.is_current_month,
                                }"
                    >

                                            <span class="flex mb-4 items-end w-full">
                                        <time :datetime="d.date.format('YYYY-MM-DD')" class="ml-auto"
                                              :class="{
                                    'flex h-6 w-6 items-center justify-center rounded-full bg-yellow-300 text-xs': d.is_today
                                    }"
                                        >
                                            {{ d.date.format('DD') }}
                                        </time>
                                    </span>

                      <span class="sr-only">{{ d.slots ? d.slots.length : 0 }} slots</span>
                      <span class="w-full mb-10 block">
                                            <span class="-mx-0.5 mt-auto flex flex-wrap">

                                                     <span
                                                         class="mx-0.5 mb-1 h-3 w-3 rounded-full bg-blue-500 hover:bg-blue-700"
                                                         :key="'event-' + i + '-' + k"
                                                         v-for="(x, i) of d.events"
                                                         @click="eventDetails(x)"
                                                     >
                                                          <el-popover
                                                              class="w-full h-full"
                                                              placement="left"
                                                              width="250"
                                                              trigger="hover">
                                                               <div class="select-none">
                                                                   <h4 class="font-bold mmb-1">{{ x.name }}</h4>
                                                                <div class="text-xs mt-1">
                                                                    {{ x.description }}
                                                                </div>
        </div>
                                                              <span slot="reference" class="block w-3 h-3"> </span>
                                                          </el-popover>
                                                     </span>

            <span class="mx-0.5 mb-1 h-3 w-3 rounded-full cursor-pointer"
                  :key="i"
                  v-for="(x, i) of d.slots"
                  :class="{

                                                         'bg-green-500 hover:bg-green-600': x.assigned && !x.has_booking_conflict  && !d.is_in_past,
                                                        'bg-red-600 hover:bg-red-700': !x.assigned && !x.has_booking_conflict  && !d.is_in_past,
                                                        'bg-yellow-500 hover:bg-yellow-600': x.has_booking_conflict && !d.is_in_past,
                                                         'bg-gray-400': d.is_in_past
                                                        }"
                  @click="$router.push('/bookings/' + x.id)"
            >
              <el-tooltip placement="bottom">
                   <div slot="content">
                                                                           <span v-if="d.is_in_past">This booking has expired</span>
                                                    <span v-else-if="!x.assigned">You have not attached a playlist<br/> to this booking slot.<br/>Click the slot to attach a playlist.</span>
                                                    <span v-else-if="x.has_booking_conflict">
                                                        This booking/playlist is conflicted. <br/>Please  update.
                                                    </span>
                                                    <span v-else>Playlist attached.<br/>Click to update.</span>
                                                </div>
                    <span class="block w-3 h-3">
                    </span>
                </el-tooltip>
            </span>

          </span>
                               </span>
                    </button>

                  </div>
                </div>
              </div>
              <div
                  v-loading="loading.bookings || loading.slot"
                  v-show="view_as !== 'calendar'"  class="col-span-12 bg-white border border-gray-100">

                <div v-if="filteredBookings.length > 0">
                  <div class="sm:flex sm:items-center p-4">
                    <div class="sm:flex-auto text-gray-500 overflow-hidden text-sm truncate">
                      {{ filteredBookings.length }} bookings
                    </div>
                  </div>
                  <div class="overflow-hidden bg-white">
                    <ul role="list" class="divide-y divide-gray-100 border-t border-gray-100">
                      <li :key="'booking-' + i" v-for="(x, i) of filteredBookings">
                        <router-link :to="'/bookings/' + x.id" class="block hover:bg-gray-50">
                          <div class="flex items-center px-4 py-4 sm:px-6">
                            <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div class="truncate">
                                <div class="flex text-sm">

                                  <div class="grid gap-4 grid-flow-col auto-cols-max">
                                    <div class="w-16 h-16 hidden lg:block">
                                      <img
                                          class="rounded"
                                          :src="'https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=120x120&markers=color:red%7Clabel:C%7C' + x.event.lat + ',' + x.event.lng + '&key=AIzaSyAENZy2SL14YFJdl4T9OiaPIgWgKejcCPg'" />
                                    </div>
                                    <div>
                                      <div>
                                        <span class="font-semibold text-red-700">{{ x.location }} </span>  <span class="ml-2 text-xs"> {{ x.event.type }}</span>
                                      </div>
                                      <div class="flex text-xs items-center my-1">
                                       <span class="truncate">
                                       <i class="el-icon-location"></i> {{ x.event.address }}
                                       </span>
                                      </div>
                                      <div class="flex text-sm items-center my-1">
                                       <span class="truncate text-red-700 font-semibold">
                                       {{ x.date | date }}
                                       </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">

                                <span
                                    v-if="x.assigned && !x.has_booking_conflict && !x.is_in_past"
                          class="bg-green-100 flex items-center rounded justify-center" aria-hidden="true">
                        <span class="m-0.5 text-xs uppercase text-green-100 bg-green-600 rounded px-2 py-1">
                          Assigned
                        </span>
                      </span>

                                <span
                                    v-if="!x.assigned && !x.has_booking_conflict && !x.is_in_past"
                                      class="bg-red-100 flex items-center rounded justify-center" aria-hidden="true">
                        <span class="m-0.5 text-xs uppercase text-red-100 bg-red-600 rounded px-2 py-1">
                          Unassigned
                        </span>
                      </span>

                                <span
                                    v-if="x.has_booking_conflict && !x.is_in_past"
                                    class="bg-yellow-100 flex items-center rounded justify-center" aria-hidden="true">
                        <span class="m-0.5 text-xs uppercase text-red-100 bg-yellow-600 rounded px-2 py-1">
                          Conflict
                        </span>
                      </span>

                                <span
                                    v-if="x.is_in_past"
                                    class="bg-gray-100 flex items-center rounded justify-center" aria-hidden="true">
                        <span class="m-0.5 text-xs uppercase text-gray-100 bg-gray-600 rounded px-2 py-1">
                          Expired
                        </span>
                      </span>

                                <span
                                    :class="{
                                                        'bg-green-500 hover:bg-green-600': x.assigned && !x.has_booking_conflict && !x.is_in_past,
                                                        'bg-red-600 hover:bg-red-700': !x.assigned && !x.has_booking_conflict && !x.is_in_past,
                                                        'bg-yellow-500 hover:bg-yellow-600': x.has_booking_conflict && !x.is_in_past,
                                                        'bg-gray-100': x.is_in_past
                                                        }"
                                >
                                </span>

                              </div>
                            </div>
                            <div class="ml-5 flex-shrink-0">
                              <!-- Heroicon name: mini/chevron-right -->
                              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                              </svg>
                            </div>
                          </div>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="py-8" v-else>
                    <empty-state description="Look's like you have no bookings" />
                    <div class="text-center">
                      <el-button
                          @click="openBooking"
                          icon="el-icon-date" type="success">Book
                      </el-button>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </template>
  </page-wrapper>
</template>

<script>

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { parse } from 'fecha';
import PreviewMapObject from '@/components/PreviewMapObject';
import BookPod from '@/components/Pod/BookPod';
import StreetViewLayer from '@/components/Pod/StreetViewLayer';
import FormInput from '@/components/FormInput';
import PodLocationSelect from '@/components/PodLocationSelect';
import PlaylistPreview from '@/components/PlaylistPreview';
import SmallModal from '@/components/SmallModal';
import EmptyState from '@/components/EmptyState';
import { download } from '@/utils/download';

const moment = extendMoment(Moment);

export default {
  name: 'bookings-page',
  components: {
    EmptyState,
    SmallModal,
    PlaylistPreview,
    PodLocationSelect,
    FormInput,
    StreetViewLayer,
    BookPod,
    PreviewMapObject,
  },
  data() {
    return {
      view_as: 'calendar',
      event: {
        show: false,
        object: {
          id: null,
          name: null,
        },
      },
      preview: {
        show: false,
      },
      book: {
        show: false,
        loading_pod: true,
        selected: {
          id: null,
        },
        open: false,
      },
      video_preview_visible: false,
      slot: {
        updating: false,
        open: false,
        data: {
          location: null,
          book: null,
          media: {
            id: null,
            status: null,
          },
        },
      },
      loading: {
        bookings: true,
        pods: true,
        slot: false,
        media: false,
      },
      current: moment().startOf('month'),
      pods: [],
      stats: {},
      bookings: [],
      media: [],
      filters: {
        show_expired: false,
        assigned: 'all',
        pods: [],
      },
      exporting: false,
    };
  },
  mounted() {
    const vm = this;
    if (this.$route && this.$route.query && this.$route.query.confirmed && this.$route.query.confirmed === 'true') {
      vm.$notify({
        offset: 65,
        type: 'success',
        title: 'Booking Slot has been Updated',
        message: 'Your booking slot has been updated successfully.',
        position: 'top-right',
      });
    }

    if (this.$route.name === 'Booking') {
      this.fetchBooking(this.$route.params.bookingId);
    }
  },
  created() {
    this.fetchMonth();
    this.fetchPods();
  },
  methods: {
    handleExport(command) {
      const vm = this;
      vm.loading.bookings = true;
      vm.$http.get('/api/v1/booking-calendar', {
        params: {
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().endOf('month').format('YYYY-MM-DD'),
          export: true,
          export_type: command,
        },
      }).then((response) => {
        vm.loading.bookings = false;

        download({
          buffer: response.data.data.buffer,
          name: response.data.data.name,
          contentType: response.data.data.content_type,
        });
      }).catch(() => {
        vm.loading.bookings = false;
      });
    },
    openByPodId() {
      const vm = this;
      vm.book.loading_pod = true;
      return vm.$http.get(`/api/v1/pods/${vm.book.selected.id}`)
        .then((response) => {
          vm.book.selected = response.data.data;
          vm.book.loading_pod = false;
        }).catch(() => {
          vm.book.loading_pod = false;
        });
    },
    onBookingClosed() {
      const vm = this;
      vm.book.open = false;
      this.fetchMonth();
      this.fetchPods();
    },
    onBookingLocationChange() {
      const vm = this;
      vm.book.show = false;
      vm.$nextTick(() => {
        vm.book.show = true;
      });
    },
    openBooking() {
      const vm = this;
      vm.book.show = true;
      vm.book.open = true;
    },
    eventDetails(x) {
      this.event.object = x;
      this.event.show = true;
    },
    updateBookingSlot(id) {
      const vm = this;
      vm.slot.updating = true;
      const object = {};

      const o = vm.slot.data.media;
      if (o.id && o.id.length > 0) {
        object.playlist_id = o.id;
      }
      if (o.status) {
        object.status = o.status;
      }

      return vm.$http.put(`/api/v1/bookings/${id}/media`, object)
        .then(() => {
          vm.$notify({
            type: 'success',
            title: 'Booking Slot has been Updated',
            message: 'Your booking slot has been updated successfully.',
            position: 'bottom-left',
          });
          vm.fetchMonth();
          vm.slot.updating = false;
        }).catch((e) => {
          vm.slot.updating = false;
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
            offset: 0,
          });
        });
    },
    fetchBooking(id) {
      const vm = this;
      vm.loading.slot = true;
      return vm.$http.get(`/api/v1/bookings/${id}`)
        .then((response) => {
          vm.fetchMedia(id);
          vm.loading.slot = false;
          vm.slot.data = response.data.data;
          vm.slot.open = true;
        }).catch(() => {
          vm.loading.slot = false;
          vm.slot.open = false;
        });
    },
    fetchPods() {
      const vm = this;
      vm.loading.pods = true;
      vm.$http.get('/api/v1/pods')
        .then((response) => {
          vm.pods = response.data.data.pods;
          vm.loading.pods = false;
        }).catch(() => {
          vm.loading.pods = false;
        });
    },
    fetchMedia(id) {
      const vm = this;
      vm.loading.media = true;
      vm.$http.get(`/api/v1/bookings/${id}/available-playlists`, {
        params: {
          all: true,
        },
      })
        .then((response) => {
          vm.media = response.data.data && Array.isArray(response.data.data) ? response.data.data : [];
          vm.loading.media = false;
        }).catch(() => {
          vm.loading.media = false;
        });
    },
    fetchMonth() {
      const vm = this;
      vm.loading.bookings = true;
      vm.$http.get('/api/v1/booking-calendar', {
        params: {
          from: moment(vm.buildDateRange[0].date).format('YYYY-MM-DD'),
          to: moment(vm.buildDateRange[vm.buildDateRange.length - 1].date).format('YYYY-MM-DD'),
        },
      })
        .then((response) => {
          vm.bookings = response.data.data.bookings;
          vm.stats = response.data.data.stats;
          vm.loading.bookings = false;
        }).catch(() => {
          vm.loading.bookings = false;
        });
    },
    nextMonth() {
      this.current = moment(this.current).add(1, 'month');
    },
    previousMonth() {
      this.current = moment(this.current).subtract(1, 'month');
    },
    getCurrentDate() {
      this.current = moment().startOf('month');
    },
  },
  watch: {
    $route(to) {
      // Put your logic here...
      if (to && to.name === 'Booking') {
        this.fetchBooking(this.$route.params.bookingId);
      }
    },
    'book.selected.id': function () {
      const vm = this;
      if (vm.book.selected.id) {
        this.openByPodId();
      }
    },
    'slot.data.media.id': function () {
      const vm = this;
      if (vm.slot.data.media.id && vm.slot.data.media.id.length > 0) {
        vm.video_preview_visible = false;

        vm.$nextTick(() => {
          vm.video_preview_visible = true;
        });
      }
    },
    current() {
      const vm = this;
      vm.pods = [];
      vm.bookings = [];
      vm.fetchMonth();
    },
  },
  computed: {
    filteredBookings() {
      const vm = this;
      return vm.bookings.map((x) => {
        x.is_in_past = moment(x.date).isBefore(moment().format('YYYY-MM-DD'));
        return x;
      }).filter((x) => {
        if (vm.filters.assigned === 'assigned') {
          return x.assigned;
        }
        if (vm.filters.assigned === 'unassigned') {
          return !x.assigned;
        }
        return true;
      }).filter((x) => {
        if (Array.isArray(vm.filters.pods) && vm.filters.pods.length > 0) {
          return vm.filters.pods.indexOf(x.location_id) > -1;
        }
        return true;
      }).filter((x) => {
        if (!vm.filters.show_expired) {
          return vm.$moment(x.date).isSameOrAfter(moment().format('YYYY-MM-DD'));
        }
        return true;
      });
    },
    bookingHasExpired() {
      if (this.slot.data && this.slot.data.booking) {
        return this.slot.data.booking.has_expired;
      }
      return true;
    },
    selectedPlaylistHasConflicts() {
      const vm = this;
      if (this.slot.data.media.id && this.media.length > 0) {
        const find = this.media.find((y) => y.id === vm.slot.data.media.id);
        return find ? find.has_booking_conflict : false;
      }
      return false;
    },
    getSelectedMediaPlaylist() {
      const vm = this;
      if (this.slot.data.media.id && this.media.length > 0) {
        const find = this.media.find((y) => y.id === vm.slot.data.media.id);
        return find ? find.items : [];
      }
      return [];
    },
    getExpiredTypes() {
      return [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ];
    },
    getAssignmentTypes() {
      return [
        { id: 'all', label: 'All' },
        { id: 'assigned', label: 'Assigned' },
        { id: 'unassigned', label: 'Unassigned' },
      ];
    },
    getCurrentMonthString() {
      return this.current.format('MMMM YYYY');
    },
    buildDateRange() {
      const vm = this;
      const start = parse(`${this.current.format('MMMM')} 1st, ${this.current.format('YYYY')}`, 'MMMM Do, YYYY');
      const startMoment = moment(start);
      const endMoment = moment(start).endOf('month');

      const range = moment().range(startMoment, endMoment);
      const days = Array.from(range.by('day', { step: 1 })).map((x) => x);

      if (days.length > 0) {
        while (days[0].format('e') > 1) {
          const first = days[0];
          days.unshift(first.clone().subtract(1, 'day'));
        }

        while (days.length < 35) {
          const last = days[days.length - 1];
          days.push(last.clone().add(1, 'day'));
        }
        while (days.length % 7 !== 0) {
          const last = days[days.length - 1];
          days.push(last.clone().add(1, 'day'));
        }
      }

      return days.map((x) => {
        const slots = vm.filteredBookings.filter((y) => x.format('YYYY-MM-DD') === y.date);

        const eventDay = vm.filteredBookings.filter((y) => x.format('YYYY-MM-DD') === y.date).filter((y) => y.is_event && y.event).map((y) => ({
          name: y.event.name,
          type: y.event.type,
          description: y.event.description,
          address: y.event.address,
          lat: y.event.lat,
          lng: y.event.lng,
        }));

        const isEventDay = eventDay.length > 0;

        const obj = {};

        for (const x of eventDay) {
          obj[x.id] = x;
        }

        return {
          is_in_past: x.isBefore(moment().format('YYYY-MM-DD')),
          is_today: x.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
          is_current_month: x.format('YYYY-MM') === this.current.format('YYYY-MM'),
          is_event_day: isEventDay,
          events: Object.values(obj),
          date: x,
          slots,
        };
      });
    },
  },
};
</script>
<style>
.date-block {
  min-height: 6.5rem;
}

.video-height {
  min-height: 32rem;
}
</style>
