<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class=" z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <!--
          Background backdrop, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <!--
                  Modal panel, show/hide based on modal state.

                  Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                  Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                    <main v-if="!isLoading" class="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
                        <h1 class="sr-only">Checkout</h1>

                        <!-- Mobile order summary -->
                        <section aria-labelledby="order-heading" class="bg-gray-50 lg:hidden">
                            <div class="p-4 bg-red-700 text-white">
                                <h4 class="text-lg font-semibold">{{ location.name }}</h4>
                                <h5 class="text-sm">{{ location.address }}</h5>
                            </div>
                            <div class="mx-auto px-4 py-6 sm:px-6 max-w-lg">
                                <div id="disclosure-1">
                                    <ul role="list" class="divide-y divide-gray-200 border-b border-gray-200">
                                        <li class="flex space-x-6 py-6 pt-0">
                                            <div class="flex flex-col justify-between space-y-4">
                                                <div class="space-y-1 text-sm"
                                                     :key="'list-item-' + i"
                                                     v-for="(x, i) of date_objects">
                                                    <h3 class="text-gray-900 font-bold mb-1">
                                                        {{ x.date | moment("dddd MMM Do YYYY") }}
                                                    </h3>
                                                    <p class="text-gray-900">
                                                        {{ x.slots }} x slot(s) at {{ x.price | currency($store.getters['auth/language'], currency) }}
                                                    </p>
                                                    <p class="text-gray-500 text-xs">
                                                    {{ x.estimated_plays ? x.estimated_plays.toLocaleString() :
                                                        x.estimated_plays }} estimated plays
                                                    </p>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- More products... -->
                                    </ul>

                                    <dl class="mt-10 space-y-3 text-sm font-medium text-gray-500">
                                        <div v-if="totals.slot_total" class="flex justify-between">
                                            <dt>Total Estimated Plays</dt>
                                            <dd class="text-gray-900">
                                                {{ getTotalEstimatedPlays ? getTotalEstimatedPlays.toLocaleString() : 0
                                                }}
                                            </dd>
                                        </div>
                                        <div v-if="totals.slot_total" class="flex justify-between">
                                            <dt>Subtotal</dt>
                                            <dd class="text-gray-900">{{ totals.slot_total | currency($store.getters['auth/language'], currency) }}</dd>
                                        </div>
                                        <div v-if="totals.discount_percentage && totals.discounts"
                                             class="flex justify-between">
                                            <dt>Discount ({{ totals.discount_percentage }}%)</dt>
                                            <dd class="text-gray-900">-{{ totals.discounts | currency($store.getters['auth/language'], currency) }}</dd>
                                        </div>
                                        <div class="flex justify-between" v-if="totals.vat">
                                            <dt>VAT (20%)</dt>
                                            <dd class="text-gray-900">{{ totals.vat | currency($store.getters['auth/language'], currency) }}</dd>
                                        </div>
                                        <div class="flex justify-between border-t border-gray-200 text-gray-900 pt-6">
                                            <dt class="text-base">Total <small class="text-gray-600 ml-2">incl.
                                                VAT</small></dt>
                                            <dd class="text-base">{{ totals.total | currency($store.getters['auth/language'], currency) }}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </section>

                        <!-- Order summary -->
                        <section aria-labelledby="summary-heading"
                                 class="hidden w-full max-w-sm flex-col bg-gray-50 lg:flex">
                            <h2 id="summary-heading" class="sr-only">Order summary</h2>

                            <header class="p-4 bg-red-700 text-white">
                                <h4 class="text-lg font-semibold">{{ location.name }}</h4>
                                <h5 class="text-sm">{{ location.address }}</h5>
                            </header>

                            <ul role="list" class="flex-auto divide-y divide-gray-200 overflow-y-auto px-6">
                                <li class="flex space-x-6 py-6">
                                    <div class="flex flex-col justify-between space-y-4">
                                        <div class="space-y-1 text-sm"
                                             :key="'list-item-' + i"
                                             v-for="(x, i) of date_objects">
                                            <h3 class="text-gray-900 font-bold mb-1">
                                                {{ x.date | moment("dddd MMM Do YYYY") }}
                                            </h3>
                                            <p class="text-gray-900">
                                                {{ x.slots }} x slot(s) at {{ x.price | currency($store.getters['auth/language'], currency) }}
                                            </p>
                                            <p class="text-gray-500 text-xs">
                                                {{ x.estimated_plays ? x.estimated_plays.toLocaleString() :
                                                x.estimated_plays }} estimated plays
                                            </p>
                                        </div>
                                    </div>
                                </li>

                                <!-- More products... -->
                            </ul>

                            <div class="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6">

                                <dl class="space-y-3 text-sm font-medium text-gray-500">
                                    <div v-if="totals.slot_total" class="flex justify-between">
                                        <dt>Total Estimated Plays</dt>
                                        <dd class="text-gray-900">
                                            {{ getTotalEstimatedPlays ? getTotalEstimatedPlays.toLocaleString() : 0 }}
                                        </dd>
                                    </div>
                                    <div v-if="totals.slot_total" class="flex justify-between">
                                        <dt>Subtotal</dt>
                                        <dd class="text-gray-900">{{ totals.slot_total | currency($store.getters['auth/language'], currency) }}</dd>
                                    </div>
                                    <div v-if="totals.discount_percentage && totals.discounts"
                                         class="flex justify-between">
                                        <dt>Discount ({{ totals.discount_percentage }}%)</dt>
                                        <dd class="text-gray-900">-{{ totals.discounts | currency($store.getters['auth/language'], currency) }}</dd>
                                    </div>
                                    <div class="flex justify-between" v-if="totals.vat">
                                        <dt>VAT (20%)</dt>
                                        <dd class="text-gray-900">{{ totals.vat | currency($store.getters['auth/language'], currency) }}</dd>
                                    </div>
                                    <div class="flex justify-between border-t border-gray-200 text-gray-900 pt-6">
                                        <dt class="text-base">Total <small class="text-gray-600 ml-2">incl. VAT</small>
                                        </dt>
                                        <dd class="text-base">{{ totals.total | currency($store.getters['auth/language'], currency) }}</dd>
                                    </div>
                                </dl>
                            </div>
                        </section>

                        <!-- Checkout form -->
                        <section aria-labelledby="payment-heading"
                                 class="flex-auto overflow-y-auto px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-12">

                            <div v-loading="purchasing" class="mx-auto max-w-lg py-6 px-4">

                                <form-input v-if="existing_cards && existing_cards.length > 0 || has_account"
                                            clear-label required
                                            label="Payment method">
                                    <div
                                            class="relative mt-1">
                                        <button
                                                @click="show_card_dropdown = !show_card_dropdown"
                                                type="button"
                                                class="relative w-full cursor-default rounded-md border border-gray-300 bg-white pr-10 text-left shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm"
                                                aria-haspopup="listbox" aria-expanded="true"
                                                aria-labelledby="listbox-label">

                                            <div v-if="getSelectedCardDetails === null && !has_account">
                                                <billing-new-payment-placeholder>
                                                    <template slot="title">
                                                        New Payment Method
                                                    </template>
                                                    <template slot="description">
                                                        Add a new payment method to your account
                                                    </template>
                                                </billing-new-payment-placeholder>
                                            </div>
                                            <div v-else-if="card_type === 'account'">
                                                <billing-new-payment-placeholder icon="el-icon-coin">
                                                    <template slot="title">
                                                        Account
                                                    </template>
                                                    <template slot="description">
                                                        Add this order to your account
                                                    </template>
                                                </billing-new-payment-placeholder>
                                            </div>
                                            <div v-else>
                                                <payment-option
                                                        :hover="false"
                                                        :details="getSelectedCardDetails"/>
                                            </div>
                                            <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
        <!-- Heroicon name: mini/chevron-up-down -->
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
             aria-hidden="true">
          <path fill-rule="evenodd"
                d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                clip-rule="evenodd"/>
        </svg>
      </span>
                                        </button>

                                        <ul
                                                @mouseleave="show_card_dropdown = false"
                                                v-if="show_card_dropdown"
                                                class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                tabindex="-1" role="listbox" aria-labelledby="listbox-label">
                                            <li
                                                    @click="selectMethod('account')"
                                                    v-if="has_account">

                                                <billing-new-payment-placeholder icon="el-icon-coin">
                                                    <template slot="title">
                                                        Account
                                                    </template>
                                                    <template slot="description">
                                                        Add this order to your account
                                                    </template>
                                                </billing-new-payment-placeholder>

                                            </li>
                                            <li
                                                    v-for="(item, index) in existing_cards"
                                                    @click="selectMethod(item)"
                                                    :key="index">
                                                <payment-option
                                                        :selected="item.id === card_type"
                                                        :details="item"
                                                />
                                            </li>
                                            <li
                                                    v-if="!has_account"
                                                    @click="selectMethod(null)"
                                            >
                                                <billing-new-payment-placeholder>
                                                    <template slot="title">
                                                        New Payment Method
                                                    </template>
                                                    <template slot="description">
                                                        Add a new payment method to your account
                                                    </template>
                                                </billing-new-payment-placeholder>
                                            </li>
                                        </ul>
                                    </div>
                                </form-input>

                                <div v-if="card_type === null" class="mt-6 bg-gray-50 p-5 border border-gray-100">
                                    <StripeElements
                                            :stripe-key="stripeKey"
                                            :instance-options="instanceOptions"
                                            :elements-options="elementsOptions"
                                            #default="{ elements }" ref="elms">

                                        <div class="grid grid-cols-3 gap-3">
                                            <div class="col-span-3">
                                                <form-input clear-label label="Card holder name" required>
                                                    <input type="text" name="first-name" id="first-name"
                                                           autocomplete="given-name"
                                                           v-model="cardOptions.name"
                                                           :class="{
                                                           'border-red-700 text-red-700': invalidCardholderName
                                                           }"
                                                           class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-gray-500 sm:text-sm">
                                                    <div
                                                            v-if="invalidCardholderName"
                                                            class="text-xs ml-1 leading-tight mt-1 text-red-700">
                                                        Please enter the card holder name
                                                    </div>
                                                </form-input>

                                            </div>
                                            <div class="col-span-3">
                                                <form-input clear-label label="Card number" required>

                                                    <div class="relative flex w-full flex-col">
                                                        <StripeElement
                                                                @change="onStripeInputChange"
                                                                type="cardNumber"
                                                                ref="card"
                                                                :elements="elements"
                                                                :options="cardOptions"
                                                        />
                                                        <div
                                                                v-if="input.cardNumber.brand && input.cardNumber.brand !== 'unknown'"
                                                                class="absolute top-0 right-0 h-full flex items-center px-3">
                                                            <card-icon v-model="input.cardNumber.brand"/>
                                                        </div>
                                                    </div>
                                                    <div
                                                            v-if="input.cardNumber.error && input.cardNumber.error.message"
                                                            class="text-xs ml-1 text-red-700">
                                                        {{ input.cardNumber.error.message }}
                                                    </div>
                                                </form-input>
                                            </div>
                                            <div>
                                                <form-input clear-label label="Expiry date" required>
                                                    <StripeElement
                                                            @change="onStripeInputChange"
                                                            type="cardExpiry"
                                                            :elements="elements"
                                                            :options="cardOptions"
                                                    />
                                                    <div
                                                            v-if="input.cardExpiry.error && input.cardExpiry.error.message"
                                                            class="text-xs ml-1 leading-tight mt-1 text-red-700">
                                                        {{ input.cardExpiry.error.message }}
                                                    </div>
                                                </form-input>
                                            </div>
                                            <div>
                                                <form-input clear-label label="CVC" required>
                                                    <StripeElement
                                                            @change="onStripeInputChange"
                                                            type="cardCvc"
                                                            :elements="elements"
                                                            :options="cardOptions"
                                                    />
                                                    <div
                                                            v-if="input.cardCvc.error && input.cardCvc.error.message"
                                                            class="text-xs ml-1 leading-tight mt-1 text-red-700">
                                                        {{ input.cardCvc.error.message }}
                                                    </div>
                                                </form-input>
                                            </div>
                                            <div>
                                                <form-input clear-label label="Postcode" required>
                                                    <StripeElement
                                                            @change="onStripeInputChange"
                                                            type="postalCode"
                                                            :elements="elements"
                                                            :options="cardOptions"
                                                    />
                                                    <div
                                                            v-if="input.postalCode.error && input.postalCode.error.message"
                                                            class="text-xs ml-1 leading-tight mt-1 text-red-700">
                                                        {{ input.postalCode.error.message }}
                                                    </div>
                                                </form-input>
                                            </div>
                                        </div>

                                    </StripeElements>
                                </div>
                                <div>
                                    <button
                                            @click="pay"
                                            type="button"
                                            class="mt-6 w-full rounded-md border border-transparent bg-green-600 py-4 px-6 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                                        Pay {{ totals.total | currency($store.getters['auth/language'], currency) }}
                                    </button>
                                </div>
                                <div class="text-center py-3">
                                    <span
                                            @click="$emit('close')"
                                            class="cursor-pointer underline text-red-700 text-sm">Cancel</span>
                                </div>
                            </div>
                        </section>
                    </main>
                    <div class="p-20" v-else>
                        <spin-loader/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus';
import FormInput from './FormInput';
import CardIcon from './CardIcon';
import PaymentOption from './PaymentOption';
import SpinLoader from './SpinLoader';
import BillingNewPaymentPlaceholder from './BillingNewPaymentPlaceholder';

export default {
  name: 'BookingCheckout',
  props: {
    podId: {
      type: String,
      default() {
        return null;
      },
    },
    currency: {
      type: String,
      default() {
        return 'GBP';
      },
    },
    slots: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    BillingNewPaymentPlaceholder,
    SpinLoader,
    PaymentOption,
    CardIcon,
    FormInput,
    StripeElements,
    StripeElement,
  },
  data() {
    return {
      has_account: false,
      calculating: true,
      loading: true,
      purchasing: false,
      location: {
        name: null,
        address: null,
        currency: null,
      },
      existing_cards: [],
      date_objects: [],
      totals: {},
      card_type: null,
      show_card_dropdown: false,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      input: {
        cardNumber: {},
        cardExpiry: {},
        cardCvc: {},
        postalCode: {},
      },

      stripeKey: process.env.NODE_ENV === 'production' ? 'pk_live_51L0mAMEpHQRNDctILYX3Z5xUJ1TUK1wGEuy5vAHoz9C7PNish1uevLAAc9Vl12VtS1CPx1UpTDZfv2P9VJuq8TqM00lHgOEox6' : 'pk_test_51L0mAMEpHQRNDctIi8jlYy5gIujbzN90zrAT43uTYYLPcSKDgGom3iT0zhg0BnVNpXfjvNMRnUichYnNBGxttAed00Ur7vC3Jh', // test key, don't hardcode,
      cardOptions: {
        name: '',
        classes: {
          base: 'block w-full p-3 rounded-md border border-gray-300 shadow-sm sm:text-sm bg-white',
          focus: 'border-gray-500',
          invalid: 'border-red-700',
          complete: 'border-green-700',
        },
      },
    };
  },
  mounted() {
    this.calculatePricing();
    this.fetchPaymentMethods();
  },
  computed: {
    isLoading() {
      return this.calculating || this.loading;
    },
    invalidCardholderName() {
      if (this.card_type === null) {
        return !this.cardOptions.name || !this.cardOptions.name.toString().length || !this.cardOptions.name.replace(/\s/g, '').length > 0;
      }
      return false;
    },
    getTotalEstimatedPlays() {
      return this.date_objects.map((x) => (x.estimated_plays ? x.estimated_plays : 0)).reduce((partialSum, a) => partialSum + a, 0);
    },
    getSelectedCardDetails() {
      const vm = this;
      const find = this.existing_cards.find((x) => x.id === vm.card_type);
      return find || null;
    },
  },
  methods: {

    calculatePricing() {
      const vm = this;
      vm.calculating = true;
      vm.$http.post(`/api/v1/pod/${vm.podId}/checkout-calculator`, {
        selected: vm.slots,
      })
        .then((response) => {
          vm.location = response.data.data.location;
          vm.date_objects = response.data.data.rows;
          vm.totals = response.data.data.totals;
          vm.calculating = false;
        }).catch(() => {
          vm.date_objects = [];
          vm.totals = {};
          vm.$emit('close');
        });
    },
    selectMethod(item) {
      const vm = this;

      if (item === 'account') {
        vm.card_type = 'account';
      } else {
        vm.card_type = item ? item.id : null;
      }

      vm.show_card_dropdown = false;
    },
    fetchPaymentMethods() {
      const vm = this;
      vm.loading = true;
      vm.$http.get('/api/v1/payment-methods')
        .then((response) => {
          vm.existing_cards = response.data.data.cards;
          //  vm.existing_cards = [];
          vm.has_account = response.data.data.account ? response.data.data.account : false;

          if (vm.has_account) {
            vm.card_type = 'account';
          } else {
            vm.card_type = vm.existing_cards.length > 0 ? vm.existing_cards[0].id : null;
          }

          vm.loading = false;
        }).catch(() => {
          vm.loading = false;
          vm.card_type = null;
        });
    },
    onStripeInputChange(e) {
      this.input[e.elementType] = e;
    },
    makePayment(type) {
      const vm = this;
      vm.purchasing = true;

      vm.$http.post(`/api/v1/pod/${vm.podId}/book`, {
        selected: vm.date_objects,
        type,
      })
        .then(() => {
          vm.$router.push('/bookings');
          vm.$notify({
            type: 'success',
            title: 'Booking Slot Confirmed',
            message: 'Your booking slot(s) has been confirmed - you can now start attaching media.',
            offset: 65,
            position: 'top-right',
          });
          vm.$emit('close');
        }).catch((e) => {
          vm.purchasing = false;
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
          });
        });
    },
    pay() {
      const vm = this;
      vm.purchasing = true;

      if (this.invalidCardholderName) {
        return false;
      }

      if (this.card_type === 'account') {
        return this.makePayment('account');
      }

      if (this.card_type && this.card_type.toString().startsWith('card_')) {
        return this.makePayment(this.card_type);
      }

      if (this.card_type === null) {
        // ref in template
        const groupComponent = this.$refs.elms;
        const cardComponent = this.$refs.card;
        // Get stripe element
        const cardElement = cardComponent.stripeElement;
        // Access instance methods, e.g. createToken()
        groupComponent.instance.createToken(cardElement, { name: this.cardOptions.name }).then((result) => {
          // Handle result.error or result.token

          if (result.error) {
            vm.purchasing = false;
          } else {
            if (result.token && result.token && result.token.id) {
              return this.makePayment(result.token.id);
            }
            vm.purchasing = false;
          }
        });
      }
    },
  },
};
</script>

<style>
    .el-select--card-type {
        height: auto !important;
    }
</style>
