<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <!--
          Background backdrop, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->

    <transition
      enter-active-class="transform duration-400 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="transform opacity-100"
      leave-active-class="transform duration-400 delay-200  ease-in"
      leave-from-class="transform opacity-100"
      leave-to-class="transform opacity-0"
    >
      <div v-if="show" class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"></div>
    </transition>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="transform opacity-100"
      leave-to-class="transform opacity-0"
    >
        <div v-if="show" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <!--
                  Modal panel, show/hide based on modal state.

                  Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                  Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                                v-if="!loading"
                                @click="$emit('close')"
                                type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            <span class="sr-only">Close</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>

                        </button>
                    </div>
                    <div>
                        <div class="w-full text-center sm:mt-0  sm:text-left">
                            <h3 class="text-lg leading-6 mb-4 font-medium text-gray-900" id="modal-title">
                                <slot name="title"></slot>
                            </h3>
                            <div class="w-full">
                                <slot name="content"></slot>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <slot name="actions"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    </div>
</template>

<script>
export default {
  name: 'TinyModal',
  props: {
    iconBgColor: {
      type: String,
      default() {
        return 'red';
      },
    },
    customIcon: {
      type: Boolean,
      default() {
        return false;
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    confirmColor: {
      type: String,
      default() {
        return 'red';
      },
    },
    confirmText: {
      type: String,
      default() {
        return 'Yes';
      },
    },
    cancelText: {
      type: String,
      default() {
        return 'Cancel';
      },
    },
  },
  computed: {
    getColorClass() {
      if (this.loading) {
        return `bg-${this.confirmColor}-600 bg-opacity-50 cursor-not-allowed`;
      }
      return `bg-${this.confirmColor}-600 hover:bg-${this.confirmColor}-700 focus:ring-${this.confirmColor}-500`;
    },
  },
};
</script>

<style scoped>

</style>
