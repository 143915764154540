<template>
    <div class="flex" :class="{
    'h-full': !hasContent
    }">

      <div
          :class="{
                         'bg-opacity-75 z-40': $store.state.auth.showMenu,
                      'bg-opacity-0 -z-20': !$store.state.auth.showMenu
            }"
          class="fixed lg:hidden transition-all duration-0 inset-0 bg-gray-600" aria-hidden="true"></div>

      <div
          :class="{
                         'left-0': $store.state.auth.showMenu,
                      '-left-96': !$store.state.auth.showMenu
            }" class="fixed lg:hidden transition-all w-80  delay-400 duration-1000 inset-0 z-40 flex">

        <div
            :class="{
                         'opacity-100': $store.state.auth.showMenu,
                      'opacity-0': !$store.state.auth.showMenu
            }"
            class="top-0 left-64 ml-6 z-40 transform delay-500 top-2 transition-all fixed">
          <button type="button"
                  @click="$store.state.auth.showMenu = false"
                  class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white ">
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"/>
            </svg>
            <span class="sr-only">Close sidebar</span>
          </button>
        </div>

        <div
            class="relative w-80 bg-red-700 pt-5 pb-4 flex flex-col">
          <!--
            Close button, show/hide based on off-canvas menu state.

            Entering: "ease-in-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in-out duration-300"
              From: "opacity-100"
              To: "opacity-0"
-->
          <div class="flex-shrink-0 px-4 flex items-center">
            <div class="flex items-center justify-center flex-shrink-0 text-white select-none">

              <svg class="h-10 w-auto"  fill="currentColor" viewBox="0 0 1260 982" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g  fill="currentColor" fill-rule="nonzero">
                    <polygon points="479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"></polygon>
                    <polygon points="1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"></polygon>
                  </g>
                </g>
              </svg>
              <div class="ml-3">
                <h3 class="pr-3 relative text-xl font-montserrat uppercase font-bold">
                  Chargabull <span class="absolute right-0 top-0 text-xs">®</span>
                </h3>
                <h5 class="text-sm -ml-1 -mt-1 text-center">Electrify your audience</h5>
              </div>
            </div>
          </div>
          <div class="mt-10 flex-1 h-0 px-2 overflow-y-auto">
            <nav class="h-full flex flex-col">
              <side-nav />
            </nav>
          </div>
        </div>

        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>

        <!-- Narrow sidebar -->
        <div class="flex flex-col w-20 lg:flex hidden fixed h-full z-40">
            <div class="flex-1 flex flex-col min-h-0 border-r border-red-700 bg-red-700">
              <div class="flex items-center justify-center flex-shrink-0 px-4 bg-red-800 h-16 text-white select-none">

                <svg class="h-8 text-white"  fill="currentColor" viewBox="0 0 1260 982" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g  fill="currentColor" fill-rule="nonzero">
                      <polygon id="Path" points="479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"></polygon>
                      <polygon id="Path" points="1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"></polygon>
                    </g>
                  </g>
                </svg>

                <!--
                  <div class="ml-3">
                      <h3 class="pr-3 relative text-xl font-montserrat uppercase font-bold">
                          Chargabull <span class="absolute right-0 top-0 text-xs">®</span>
                      </h3>
                      <h5 class="text-xs text-center">Electrify your audience</h5>
                  </div>
                  -->

              </div>
                <div class="flex-1 flex flex-col overflow-y-auto">
                    <nav class="flex-1" aria-label="Sidebar">
                        <side-nav />
                    </nav>
                </div>
            </div>
        </div>

        <div class="fixed w-full lg:pl-20 z-30 flex-shrink-0 h-16 lg:bg-white border-b border-gray-200 shadow-sm flex">
            <button type="button"
                    @click="$store.state.auth.showMenu = !$store.state.auth.showMenu"
                    class="border-r border-white px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500 lg:hidden bg-red-700">
                <span class="sr-only">Open sidebar</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7"/>
                </svg>
            </button>
            <div class="flex-1 flex justify-between px-4 sm:px-6 bg-white">
                <div class="flex-1 flex items-center flex items-center justify-center">
                    <div class="w-full space-x-4 flex  items-center mx-2">
                        <h2 v-if="title && title.length > 0" class="md:mr-16 font-semibold">{{ title }}</h2>
                        <slot name="nav-search">
                        </slot>
                        <div class="hidden lg:block">
                            <slot name="nav-cta">
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!hasContent" class="flex-1 flex flex-col overflow-hidden z-20 lg:ml-20 mt-16 relative">
            <slot name="content-fs"></slot>
        </div>

        <!-- Content area -->
        <div v-if="hasContent"
             :class="{
             'xl:mr-64': showFeed
             }"
             class="flex-1 flex flex-col overflow-hidden z-20 lg:ml-20 bg-gray-50 lg:h-screen">

            <!-- Main content -->
            <div class="flex-1 flex items-stretch overflow-hidden">
                <main class="flex-1 overflow-y-auto mt-16 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                    <!-- Primary column -->

                    <!-- Your content -->
                    <slot name="heading"></slot>
                    <div class="mx-auto p-6 max-w-7xl">
                        <slot name="content"></slot>
                    </div>
                </main>

            </div>
        </div>

      <div v-if="showFeed"
           class="hidden scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 xl:block w-64 p-4 mt-16 fixed right-0 full-screen-feed  bg-white  overflow-y-scroll pr-4 sm:pr-6 lg:flex-shrink-0 lg:border-l lg:border-gray-200 lg:pr-8">
          <div>
              <h2 class="text-sm font-semibold">
              Account Notifications
              </h2>
          </div>

          <div v-if="notifications.fetching" class="flex p-4 justify-center items-center py-10">
              <el-spinner />
          </div>

          <div v-if="!notifications.fetching && !notifications.results.length" class="flex flex-wrap justify-center items-center text-gray-400 my-12">

              <div  class="w-full text-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                       class="w-12 h-12 mx-auto">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                  </svg>
              </div>

              <h4 class="text-sm text-center leading-tight">Nothing to display. Your all up-to-date</h4>
          </div>
          <div v-if="!notifications.fetching && notifications.results.length > 0">
              <ul role="list" class="divide-y divide-gray-200">
                  <li :key="i" class="py-4" v-for="(r, i) of notifications.results">
                      <div class="flex space-x-3">

                          <conflict
                                  v-if="r.type === 'conflict'"
                          :data="r"
                          />

                          <conflict-empty-booking
                                  v-if="r.type === 'empty_booking'"
                                  :data="r"
                          />

                      </div>
                  </li>

                  <!-- More items... -->
              </ul>
          </div>
      </div>

        <slot name="overlays"></slot>
    </div>
</template>

<script>
import SideNav from './SideNav';
import Conflict from './Notifications/Conflict';
import ConflictEmptyBooking from './Notifications/EmptyBooking';

export default {
  name: 'PageNew',
  components: { ConflictEmptyBooking, Conflict, SideNav },
  props: {
    hasContent: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showFeed: {
      type: Boolean,
      default() {
        return true;
      },
    },
    title: {
      type: String,
      default() {
        return null;
      },
    },
  },
  watch: {
    $route() {
      if (this.$store.state.auth.showMenu) {
        this.$store.state.auth.showMenu = false;
      }
    },
  },
  mounted() {
    if (this.showFeed) {
      this.fetchNotifications();
    }

    document.title = 'Chargabull';
    if (this.title && this.title.length > 0) {
      document.title = `Chargabull / ${this.title}`;
    }
  },
  methods: {
    fetchNotifications() {
      const vm = this;
      vm.notifications.fetching = true;
      vm.$http.get('/api/v1/notifications')
        .then((response) => {
          vm.notifications.results = response.data.data;
          vm.notifications.fetching = false;
        }).catch(() => {
          vm.notifications.fetching = false;
        });
    },
  },
  data() {
    return {
      notifications: {
        fetching: true,
        results: [],
      },
    };
  },
};
</script>

<style>
    .full-screen-feed {
        height: calc(100vh - 4rem);
    }
    .transition-delay-2ms {
      transition-delay: 500ms;
    }

    .grecaptcha-badge {
      display: none !important;
    }

</style>
