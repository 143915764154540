<template>
    <page-wrapper title="My Account" :show-feed="false">
        <template slot="overlays">
            <email-verify-popup
                    @close="() => verify_email.open = false"
                    v-if="verify_email.open" />
        </template>
        <template slot="content">

            <div class="grid grid-cols-2 gap-6">
              <div
                  v-if="!$store.getters['auth/emailVerified']"
                  class="col-span-2">
                <el-alert
                    type="warning"
                    effect="dark"
                    :closable="false">
                  <div class="flex items-center gap-2">
                    <div class="text-lg">
                      <i class="el-icon-warning-outline"></i>
                    </div>
                    <div class="text-sm">
                      Your email address has not been verified.
                      Please verify your email address below.
                    </div>
                  </div>
                </el-alert>
              </div>
              <div>
                <dashboard-widget title="Account Details">

                  <div v-loading="true" class="h-48 w-20 mx-auto" v-if="loading">
                  </div>
                  <div v-else class="grid gap-3 p-6">
                    <form-input label="First name" :required="true">
                      <el-input v-model="user.first_name" />
                    </form-input>

                    <form-input label="Last name" :required="true">
                      <el-input v-model="user.last_name" />
                    </form-input>

                    <form-input label="Email" :required="true">
                      <div class="flex w-full">
                        <el-input v-model="user.email" />
                        <div v-if="!$store.getters['auth/emailVerified']" class="ml-3">
                          <el-button type="info" @click="verify_email.open = true">Verify</el-button>
                        </div>
                      </div>
                      <span class="block text-orange-600 text-xs mt-1" v-if="!$store.getters['auth/emailVerified']">Email address not verified</span>
                    </form-input>

                    <form-input label="Timezone" :required="true">
                      <el-select :filterable="true" class="w-full" v-model="user.timezone">
                        <el-option
                            :key="'timezone-' + k"
                            :value="e.id"
                            :label="e.name"
                            v-for="(e, k) of timezones">{{ e.name }}
                        </el-option>
                      </el-select>

                      <div class="text-xs text-blue-800 mt-2">
                       It is currently
                        <span class="font-semibold">{{ user.timezone | localTime }}</span>
                        on
                        <span class="font-semibold">{{ user.timezone | localDate }}</span>
                        at <strong>{{ user.timezone }}</strong>
                      </div>

                    </form-input>

                    <form-input label="Language" :required="true">
                      <el-select :filterable="true" class="w-full" v-model="user.language">
                        <el-option
                            :key="'lang-' + k"
                            :value="e.value"
                            :label="e.name"
                            v-for="(e, k) of languages">
                          {{ e.name }}
                        </el-option>
                      </el-select>
                    </form-input>
                  </div>
                  <div v-if="!loading" class="bg-gray-100 p-3 flex justify-end border-t border-gray-200">
                    <el-button
                        @click="updateAccount('account')"
                        :disabled="updating.account"
                        :loading="updating.account"
                        type="success" icon="el-icon-refresh">
                      Update
                    </el-button>
                  </div>
                </dashboard-widget>
              </div>
              <div>
              <dashboard-widget title="Billing Address">
                            <div v-loading="true" class="h-48 w-20 mx-auto" v-if="loading">
                            </div>

                            <div v-else class="grid gap-3 p-6">
                                    <form-input label="Company name" :required="false">
                                        <el-input v-model="address.company" />
                                    </form-input>
                                    <form-input label="Contact number" :required="false">
                                        <el-input v-model="address.phone_number" />
                                    </form-input>
                                    <form-input label="Address line one" :required="true">
                                        <el-input v-model="address.address_line_one" />
                                    </form-input>
                                    <form-input label="Address line one" :required="false">
                                        <el-input v-model="address.address_line_two" />
                                    </form-input>
                                    <form-input label="City/town" :required="false">
                                        <el-input v-model="address.address_city" />
                                    </form-input>
                                    <form-input label="State/County" :required="true">
                                        <el-input v-model="address.address_state" />
                                    </form-input>
                                    <form-input label="Country" :required="true">
                                        <el-select class="w-full" :filterable="true" v-model="address.address_country">
                                            <el-option
                                                    :key="'country-' + k"
                                                    :value="e"
                                                    v-for="(e, k) of countries">{{ e }}
                                            </el-option>
                                        </el-select>
                                    </form-input>
                            </div>

                            <div v-if="!loading" class="bg-gray-100 p-3 flex justify-end border-t border-gray-200">
                                <el-button
                                        :disabled="updating.account"
                                        :loading="updating.account"
                                        @click="updateAccount('billing')"
                                        type="success" icon="el-icon-refresh">
                                    Update
                                </el-button>
                            </div>
                        </dashboard-widget>
              </div>
              <div>
                <dashboard-widget title="Change Password">
                        <div v-loading="true" class="h-48 w-20 mx-auto" v-if="loading">
                        </div>
                        <div v-else class="grid gap-3 p-6">
                            <form-input label="Current password"  :required="true">
                                <el-input type="password" v-model="passwordForm.current_password" />
                            </form-input>
                            <form-input label="New password" :required="true">
                                <password autocomplete="false"
                                          default-class="p-1 px-2 border border-gray-300 text-xl rounded w-full"
                                          v-model="passwordForm.password"/>
                            </form-input>
                            <form-input label="Repeat new password" :required="true">
                                <password autocomplete="false"
                                          default-class="p-1 px-2 border border-gray-300 text-xl rounded w-full"
                                          v-model="passwordForm.repeat_password"/>
                            </form-input>
                        </div>
                        <div v-if="!loading" class="bg-gray-100 p-3 flex justify-end border-t border-gray-200">
                            <el-button
                                    @click="updateAccount('password')"
                                    type="success" icon="el-icon-refresh">
                                Change
                            </el-button>
                        </div>
                    </dashboard-widget>
              </div>
              <div>
                  <dashboard-widget title="Delete Account">
                            <div v-loading="true" class="h-48 w-20 mx-auto" v-if="loading">
                            </div>
                            <div v-else class="p-6">
                                <el-alert
                                        :closable="false"
                                        type="error"
                                        effect="dark">Warning! Deleting your account cannot be undone. You will forfeit all current bookings and all data associated with your account will be removed.</el-alert>

                                <div class="mt-3 text-sm leading-loose">
                                    <p>If you wish to delete your account, please enter your password below and click confirm.</p>
                                </div>

                                <div class="mt-4">
                                    <el-input v-model="deletePassword.password"
                                              type="password"
                                              :disabled="updating.deleting"
                                              :show-password="true"/>
                                </div>
                            </div>
                            <div v-if="!loading" class="bg-gray-100 p-3 flex justify-end border-t border-gray-200">
                                <el-button
                                        @click="deleteAccount"
                                        :disabled="updating.deleting"
                                        :loading="updating.deleting"
                                        type="danger" icon="el-icon-delete">
                                    Delete
                                </el-button>
                            </div>
                        </dashboard-widget>
              </div>
            </div>
        </template>
    </page-wrapper>
</template>

<script>

import Password from 'vue-password-strength-meter';

import DashboardWidget from '@/components/DashboardWidget';
import FormInput from '@/components/FormInput';
import EmailVerifyPopup from '@/components/EmailVerifyPopup';

export default {
  name: 'user-account-page',
  components: {
    EmailVerifyPopup,
    FormInput,
    DashboardWidget,
    Password,
  },
  mounted() {
    const vm = this;
    if (vm.$route.query
            && vm.$route.query.action
            && vm.$route.query.action === 'verify-email'
            && !vm.$store.getters['auth/emailVerified']) {
      vm.verify_email.open = true;
    }
  },
  data() {
    return {
      languages: [
        { value: 'en-GB', name: 'English (UK)' },
        { value: 'en-US', name: 'English (US)' },
      ],
      verify_email: {
        open: false,
      },
      updating: {
        account: false,
        deleting: false,
      },
      timezones: [],
      countries: [],
      passwordForm: {
        current_password: '',
        password: '',
        repeat_password: '',
      },
      deletePassword: {
        password: '',
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        email_confirmed: false,
        timezone: '',
      },
      address: {
        company: '',
        phone_number: '',
        address_line_one: '',
        address_line_two: '',
        address_city: '',
        address_state: '',
        address_post_code: '',
        address_country: '',
      },
      loading: true,
      // user: {}
    };
  },
  methods: {
    deleteAccount() {
      const vm = this;
      vm.loading = true;
      vm.$http.delete('/api/v1/auth/user', {
        data: {
          password: this.deletePassword.password,
        },
      })
        .then(() => {
          vm.$router.push('/login?mode=account-deleted');
        }).catch((e) => {
          vm.loading = false;
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
            offset: 65,
          });
        });
    },
    updateAccount(mode) {
      const vm = this;
      if (mode === 'billing') {
        const object = vm.address;
        object.mode = 'billing';
        vm.update(object);
      }
      if (mode === 'account') {
        const object = vm.user;
        object.mode = 'account';
        vm.update(object);
      }
      if (mode === 'password') {
        const object = vm.passwordForm;
        object.mode = 'password';
        vm.update(object, 'Password Updated', 'Your password has been updated successfully');
      }
    },
    update(object, title, message) {
      const vm = this;
      vm.loading = true;

      vm.updating.account = true;
      vm.$http.post('/api/v1/auth/user', object)
        .then(() => {
          vm.$http.get('/api/v1/auth/user')
            .then((response) => {
              vm.$store.commit('auth/setUser', response.data.data);
              vm.updating.account = false;
              vm.loading = false;

              if (object.mode === 'password') {
                vm.passwordForm.current_password = '';
                vm.passwordForm.password = '';
                vm.passwordForm.repeat_password = '';
              }

              vm.$notify({
                type: 'success',
                offset: 65,
                title: title || 'Account Updated',
                message: message || 'Your account has been updated successfully.',
                position: 'top-right',
              });
            })
            .catch(() => {
            });
        }).catch((e) => {
          vm.updating.account = false;
          vm.loading = false;
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
            offset: 65,
          });
        });
    },
  },
  created() {
    const vm = this;
    vm.$http.get('/api/v1/auth/user')
      .then((response) => {
        vm.loading = false;
        vm.user = response.data.data.account;
        vm.address = response.data.data.billing;
        vm.timezones = response.data.data.timezones;
        vm.countries = response.data.data.countries;
      }).catch(() => {
        vm.$router.push('/');
      });
  },
  computed: {
    getRegisteredAddress() {
      return Object.values(this.registered_address);
    },
    getBillingAddress() {
      return Object.values(this.billing_address).filter((x) => x && x.length > 0);
    },
  },
};
</script>
<style>
    .Password {
        margin: 0 !important;
        width: auto !important;
    }
</style>
