<template>
  <div
          :class="showOverlay && show ? 'top-0  z-40 w-full top-0 h-full fixed' : ''">

    <transition
            enter-active-class="transition ease-in-out duration-300 transform"
            enter-class="opacity-0"
            enter-to-class="opacity-50"
            leave-active-class="transition ease-in-out duration-300 transform"
            leave-class="opacity-0"
            leave-to-class="opacity-0"
    >
      <div v-if="show && showOverlay" class="absolute w-full h-full bg-black bg-opacity-50" :style="{
    'z-index': 5001
    }">
      </div>
    </transition>

    <transition
            enter-active-class="transition ease-in-out duration-300 transform"
            :enter-class="position !== 'left' ? 'translate-x-full' : '-translate-x-full'"
            enter-to-class="translate-x-0"
            leave-active-class="transition ease-in-out duration-300 transform"
            leave-class="translate-x-0"
            :leave-to-class="position !== 'left' ? 'translate-x-full' : '-translate-x-full'"
    >
      <div
              v-if="show"
              :style="{
    'z-index': 6001
    }"
              class="fixed h-full top-0 shadow-lg bg-white shadow el-block--sidebar-option w-full md:w-3/4 lg:w-1/2"
              :class="{
        'left-0': position === 'left',
        'right-0': position !== 'left',
        customClass
        }"
      >
        <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll bg-gray-50  pb-0">

          <div class="py-6 px-4 bg-red-700 sm:px-6">
            <div class="flex items-center justify-between">
              <h2 class="text-lg font-semibold text-white">
                <slot name="title"></slot>
              </h2>
              <div v-if="showClose" class="ml-3 h-7 flex items-center">
                <button type="button" @click="$emit('close')" class="rounded-md text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="sr-only">Close panel</span>
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-0">
              <p class="text-xs text-white">
                <slot name="subtitle"></slot>
              </p>
            </div>
          </div>

          <div
                  :class="{
                  'relative': showActions
                  }"
                  class="px-4 sm:px-6 bg-white h-full overflow-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
            <!-- Replace with your content -->
            <div
                    :class="{
                    'absolute inset-0 px-4 sm:px-6': showActions
                    }"
            >
              <div class="py-6" :class="{
              'pb-24': showActions
              }">
                <slot name="content"></slot>
              </div>
            </div>
            <!-- /End replace -->
          </div>
          <div v-if="showActions" class="px-4 py-4 w-full flex justify-end bg-gray-200 shadow border-t border-gray-200 absolute bottom-0 right-0 z-30">
           <slot name="actions"></slot>
          </div>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MenuSidebarLayer',
  props: {
    customClass: {
      type: String,
      default() {
        return '';
      },
    },
    showOverlay: {
      type: Boolean,
      default() {
        return true;
      },
    },
    position: {
      type: String,
      default() {
        return 'left';
      },
    },
    showClose: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showActions: {
      type: Boolean,
      default() {
        return false;
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    width: {
      type: Number,
      default() {
        return 500;
      },
    },
  },
};
</script>

<style scoped>
  .el-btn--close {
    top: 10px;
  }
</style>
