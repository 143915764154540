<template>
    <notification
            icon="video"
            :link="'/playlists/' + data.id"
            title="Playlist/booking conflict">
        <template slot="content">
            Your playlist <span class="font-semibold">{{ data.name }}</span> has one or more booking conflicts.
        </template>
    </notification>
</template>

<script>
import Notification from './Notification';

export default {
  name: 'ConflictNotification',
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: { Notification },
};
</script>

<style scoped>

</style>
