<template>
    <notification
            :link="'/bookings/' + data.id"
            title="Booking slot empty">
        <template slot="content">
            Your booking on <strong>{{ data.date | moment("ddd MMM Do YY")  }}</strong> located at  <span class="font-semibold">{{ data.name }}</span> currently has no playlist attached so will not display any content.
        </template>
    </notification>
</template>

<script>
import Notification from './Notification';

export default {
  name: 'ConflictEmptyBooking',
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: { Notification },
};
</script>

<style scoped>

</style>
