<template>
  <div class="w-full h-full">
    <div class="absolute left-0 top-0 z-40 pl-3 pt-3">
      <el-button
        @click="setBBOX()"
        class="transition-all hover:shadow-lg hover:scale-105"
        type="info" icon="el-icon-refresh">Reset Map</el-button>
    </div>

    <div class="absolute search-location-action top-3 z-40 w-72">
      <div class="relative flex-grow">
        <div class="pl-4 top-0 left-0 flex items-center justify-center absolute text-lg text-gray-500 h-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
        </div>
        <google-autocomplete ref="locationSearch" id="locationSearch" placeholder="Search UK Town/City..."
                             class="w-full p-2 px-4 pl-12 text-gray-500 rounded-full
                                      focus:shadow-lg
                                      focus:border-gray-800
                                      outline-none
                                      border-2 border border-gray-800 h-12 shadow-lg text-sm"
                             country="gb"
                             types="(regions)"
                             v-on:placechanged="getAddressData"/>
      </div>
    </div>

    <l-map
      class="z-30"
      ref="map"
      :max-zoom="18"
      @update:center="(e) => { map.center = e; }"
      @update:zoom="(zoom)=>{ map.zoom = zoom;  }"
      :options="{zoomControl: false}"
      :zoom="map.zoom"
      :center="map.center">

      <l-control-zoom position="topright"></l-control-zoom>

      <l-google-tile-layer
        :apikey="$store.state.auth.google_maps.key"
        :options="$store.state.auth.google_maps.opts"/>


      <l-marker-cluster class="el-location-cluster">
        <l-marker
          :key="'pod' + pod.id"
          @click="() => $emit('open', pod.id)"
          @mouseenter="(e) => onMarkerMouseEnter(e, pod)"
          v-for="(pod) of pods"
          :lat-lng="[pod.lat, pod.lng]">
          <l-icon>

            <div class="animate-bounce flex flex-col items-center">
              <div class="bg-white shadow text-sm rounded-full px-3 font-semibold p-1 mb-1">
                {{ pod.default_price | currency }}
              </div>
              <div class="w-8 h-8 bg-red-700 border-white border-2 shadow-lg text-white text-lg rounded-full flex items-center justify-center">
                <svg
                  fill="currentColor" viewBox="0 0 1260 982"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-auto text-white"><g data-v-09512f3f="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g data-v-09512f3f="" fill="currentColor" fill-rule="nonzero"><polygon data-v-09512f3f="" id="Path" points="479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"></polygon><polygon data-v-09512f3f="" id="Path" points="1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"></polygon></g></g></svg>
              </div>
            </div>
          </l-icon>
        </l-marker>
      </l-marker-cluster>


    </l-map>


  </div>
</template>

<script>
import Vue from "vue";
import LocationPopupData from "@/components/Pod/LocationPopupData";
import tippy from "tippy.js";
//import GoogleAutocomplete from 'vue-google-autocomplete';
import GoogleAutocomplete from '@/components/CustomGoogleAutoComplete';

export default {
  name: "MapView",
  components: {
    GoogleAutocomplete
  },
  props: {
    center: {
      type: [Object, Array],
      default(){
        return [0,0]
      }
    },
    pods: {
      type: Array,
      default(){
        return []
      }
    },
    bbox: {
      type: Array,
      default(){
        return [0,0,0,0]
      }
    },
    zoom: {
      type: Number,
      default(){
        return 14;
      }
    }
  },
  mounted(){
    console.log("mounted", this.bbox)
    this.setBBOX();
  },
  methods:{
    getAddressData(addressData) {
      const vm = this;
      if (addressData.latitude && addressData.longitude) {

        vm.$refs.map.mapObject.setView( [addressData.latitude, addressData.longitude], 14);
      }
    },
    onMarkerMouseEnter(e, pod) {
      if (e.sourceTarget) {
        if (e.sourceTarget._icon && !e.sourceTarget._icon._tippy) {
          const popupDate = new Vue({
            ...LocationPopupData,
            parent: this,
            propsData: {
              address: pod.address,
              name: pod.name,
              income: pod.average_income,
              price: pod.default_price,
              type: pod.type,
              timezone: pod.time_zone,
              weather: pod.weather_forecast,
              iso: pod.iso,
              currency: pod.currency,
            },
          }).$mount();

          tippy(e.sourceTarget._icon, {
            content: popupDate.$el,
            allowHTML: true,
          });
        }
      }
    },
    setBBOX(){
      const vm = this;
      const { bbox } = this.map;

      if (bbox.length === 4) {
        vm.$refs.map.mapObject.fitBounds([
          [bbox[1], bbox[0]],
          [bbox[3], bbox[2]],
        ]);
      }
    }
  },
  data(){
    return {
      map: {
        center: this.center,
        zoom: this.zoom,
        bbox: this.bbox
      }
    }
  }
}
</script>

<style scoped>

</style>
