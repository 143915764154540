<template>
    <div class="block hover:bg-gray-50">
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div class="truncate">
            <div class="flex text-sm">
              <p class="truncate font-medium text-red-700">
                {{ details.name }}
              </p>
              <p class="ml-1 flex-shrink-0 font-normal text-gray-500">{{ details.type }}</p>
            </div>
            <div class="mt-2 flex">
              <div class="flex items-center text-sm text-gray-500">
                <!-- Heroicon name: mini/calendar -->
                <p>
                {{ details.address }}
                </p>
              </div>
            </div>
            <div class="mt-2 text-gray-800 text-xs">
              <p v-if="details.last_connected">Last Connected: {{ details.last_connected }}</p>
            </div>
          </div>
        </div>
        <div v-if="details.online">
          <el-dropdown @command="handleCommand">
            <el-button size="medium" type="primary" icon="el-icon-download">
               Commands <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="hardKill">Hard Kill</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'PodRow',
  props: {
    details: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    handleCommand(command){
      const vm = this;
      vm.$http.post(`/api/v1/admin/pod/${vm.details.id}/command`, {
        command: command,
      })
        .then(() => {
        }).catch(() => {
      });
    }
  }
};
</script>

<style scoped>

</style>
