<template>
    <div class="w-full" :class="height"
         :element-loading-background="bg"
         v-loading="true">
    </div>
</template>

<script>
export default {
  name: 'SpinLoader',
  props: {
    height: {
      type: String,
      default() {
        return 'h-32';
      },
    },
    bg: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
</script>

<style scoped>

</style>
