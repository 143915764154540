import { render, staticRenderFns } from "./SimpleStatBox.vue?vue&type=template&id=0644e479&scoped=true&"
import script from "./SimpleStatBox.vue?vue&type=script&lang=js&"
export * from "./SimpleStatBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0644e479",
  null
  
)

export default component.exports