<template>
    <invoice-view v-if="!loading" v-model="invoice" />
</template>

<script>

import InvoiceView from '../../components/Invoice/InvoiceViewPrint';

export default {
  name: 'invoice-print-page',
  components: { InvoiceView },
  data() {
    return {
      loading: true,
      invoice: {
        invoice: {},
        registered_address: {},
        billing_address: {},
        rows: [],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    return next((vm) => vm.$http.get(`/api/v1/invoices/${to.params.invoiceId}`)
      .then((response) => {
        vm.invoice = response.data.data;
        vm.loading = false;
        window.print();
      }).catch(() => {
        vm.$router.push('/invoices');
      }));
  },
};
</script>
