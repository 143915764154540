<template>
  <div class="min-h-screen bg-red-700 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full flex justify-center items-center text-center sm:max-w-md text-white">
      <svg class="h-10 w-auto"  fill="currentColor" viewBox="0 0 1260 982" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g  fill="currentColor" fill-rule="nonzero">
            <polygon points="479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"></polygon>
            <polygon points="1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"></polygon>
          </g>
        </g>
      </svg>
      <div class="ml-3">
        <h3 class="pr-3 relative text-3xl font-montserrat uppercase font-bold">
          Chargabull <span class="absolute right-0 top-0 text-xs">®</span>
        </h3>
        <h5 class="text-xs text-center">Electrify your audience</h5>
      </div>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-6 md:px-0">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <div
            v-if="isDemo()"
            class="bg-blue-300 p-2 rounded text-blue-700 text-sm mb-5">
          Login below with the following credentials;
          <div class="mt-3 ml-3 mb-5">
            Username: <strong>demo@chargabull.com</strong><br />
            Password: <strong>chargabull</strong>
          </div>
          All data is reset after 1 hour.
        </div>

        <form
        >
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Email address <sup class="text-red-600">*</sup>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input id="email"
                        :disabled="running"
                        placeholder="E.g, elon@x.com"
                        @keyup.enter.native="login"
                        v-model="email" />
            </div>
          </div>

          <div class="mt-6">
            <label
              for="password"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Password <sup class="text-red-600">*</sup>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input
                      :disabled="running"
                      id="password"
                v-model="password"
                      placeholder="E.g, ******"
                      @keyup.enter.native="login"
                      type="password"
                autocomplete="off"
              />
            </div>

            <div class="mt-1 rounded-md shadow-sm">
              <recaptcha-component
                  v-if="showRecaptcha"
                  @token="(val) => recaptcha = val" />
              </div>
            </div>

          <div class="text-sm flex col-span-2 leading-5 mt-5">
            <a
                    href="/register"
                    class="font-medium block w-1/2 text-red-600 hover:text-red-700 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Need an Account?
            </a>
            <a href="/password-reset"
                    class="font-medium text-right w-1/2 block text-red-600 hover:text-red-700 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Reset Password?
            </a>
          </div>

          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <el-button
                class="w-full"
                type="success"
                :disabled="running"
                :loading="running"
                @click="login"
              >
                Sign in
              </el-button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import RecaptchaComponent from '@/components/RecaptchaComponent';
import { authenticate, reset } from '../utils/auth';

export default {
  name: 'account-login',
  components: { RecaptchaComponent },
  data() {
    return {
      email: '',
      password: '',
      recaptcha: null,
      running: false,
      showRecaptcha: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('auth/setBreadcrumbs', [
        { title: 'Login' },
      ]);

      if (vm.$route.query) {
        if (vm.$route.query.email) {
          vm.email = vm.$route.query.email;
        }
      }

      if (vm.email && vm.$route.query['password-reset']) {
        vm.$notify({
          type: 'success',
          title: 'Password Reset',
          message: 'Your password has been reset successfully',
          position: 'top-right',
        });
      }

      reset(vm);
    });
  },
  methods: {
    isDemo() {
      return window.location.hostname === 'demo.chargabull.com';
    },
    hideRecaptcha() {
      const el = document.querySelector('.grecaptcha-badge');
      if (el) {
        el.style = 'display: none !important';
      }
    },
    login() {
      const vm = this;
      vm.running = true;
      authenticate(vm.email, vm.password, vm.recaptcha)
        .then(() => {
          vm.$http.get('/api/v1/auth/user')
            .then((response) => {
              vm.hideRecaptcha();
              vm.$store.commit('auth/setUser', response.data.data);
              vm.$router.push(vm.$route.query.redirect ? vm.$route.query.redirect : '/');
            })
            .catch((e) => {
              vm.running = false;
              vm.showRecaptcha = false;
              setTimeout(() => {
                vm.showRecaptcha = true;
              }, 10);
              vm.$store.dispatch('auth/handleServerError', {
                vm,
                error: e,
              });
            });
        })
        .catch((e) => {
          vm.running = false;
          vm.showRecaptcha = false;
          setTimeout(() => {
            vm.showRecaptcha = true;
          }, 10);
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
            offset: 0,
          });
        });
    },
  },
};
</script>
<style>
.grecaptcha-badge {
  display: block !important;
}
</style>
