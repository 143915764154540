<template>
  <small-modal :title="title"
               @close="() => $emit('close')"
               :show="true">
    <template slot="content">

      <div class="px-4 w-full border-t border-gray-200 bg-gray-50 flex items-center justify-end fixed bottom-0 h-20 left-0">
        <el-button
            @click="() => $emit('close')"
        >Close</el-button>
        <el-button
            v-if="locationId !== null"
            @click="() =>  $router.push('/locations/' + locationId + '/book?date=' + date.format('YYYY-MM-DD'))"
            icon="el-icon-date" type="success">Book Event</el-button>
      </div>

      <div class="p-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 h-full relative">
        <div class="flex w-full px-2">
          <div class="w-1/2">
            <h4 class="font-semibold" v-if="date">{{ date.format('dddd MMM Do YYYY') }}</h4>
            <div class="text-sm mt-3 " v-html="description">
            </div>
          </div>
          <div class="w-1/2 p-2">

            <div class="shadow border border-gray-100 h-full rounded">
              <preview-map-object
                  :lat="lat"
                  :lon="lng"
              />
            </div>

            <div class="flex flex-wrap w-full">
              <div class="w-full">
                <dl>
                  <div class="py-3 grid grid-cols-3">
                    <dt class="text-sm font-medium text-gray-400">Location</dt>
                    <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                      {{ locationName }}
                    </dd>
                  </div>
                </dl>
                <dl>
                  <div class="py-3 grid grid-cols-3">
                    <dt class="text-sm font-medium text-gray-400">Address</dt>
                    <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                      {{ address }}
                    </dd>
                  </div>
                </dl>
                <dl>
                  <div class="py-3 grid grid-cols-3">
                    <dt class="text-sm font-medium text-gray-400">Type</dt>
                    <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                      {{ type }}
                    </dd>
                  </div>
                </dl>
                <dl>
                  <div class="py-3 grid grid-cols-3">
                    <dt class="text-sm font-medium text-gray-400">Price</dt>
                    <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                      {{ price | currency }} <small>excl. VAT</small>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </small-modal>
</template>

<script>
import SmallModal from '@/components/SmallModal';
import PreviewMapObject from '@/components/PreviewMapObject';

export default {
  name: 'EventPopup',
  components: { PreviewMapObject, SmallModal },
  props: {
    price: {
      type: Number,
    },
    locationName: {
      type: String,
    },
    type: {
      type: String,
    },
    address: {
      type: String,
    },
    lat: {
      type: Number,
    },
    lng: {
      type: Number,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    locationId: {
      type: String,
      default() {
        return null;
      },
    },
    date: {
      type: Object,
    },
  },
};
</script>
