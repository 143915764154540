<template>
<page-wrapper title="Invoices" :show-feed="false">

    <template slot="nav-search">
        <div class="flex-grow lg:block" :class="{
            '': search.show,
             'hidden': !search.show,
            }">
            <div class="relative">
                <i class="el-icon-search top-0 left-0 absolute text-lg text-gray-500 ml-4 mt-3"></i>
                <input
                        placeholder="Search Invoices"
                        class="w-full p-2 px-4 pl-10 text-gray-500 rounded border border-gray-300 h-10 text-sm"
                        @input="($event) => debounceInput(this, $event)"
                        :value="search.term"/>
            </div>
        </div>
        <div
                @click="search.show = !search.show"
                :class="{
                    'flex-0': search.show,
                    'flex-grow': !search.show,
                    }"
                class="lg:hidden text-right text-2xl text-gray-600 hover:text-gray-900 cursor-pointer">
            <i
                    v-if="!search.show"
                    class="el-icon-search"></i>
            <i
                    v-if="search.show"
                    class="el-icon-close"></i>
        </div>
    </template>

    <template slot="content">

        <div v-if="fetching">
            <spin-loader bg="transparent" height="h-64"/>
        </div>
        <div v-else>
            <div v-if="items.length > 0">
                <div class="sm:flex sm:items-center mb-3">
                    <div class="sm:flex-auto text-gray-500 overflow-hidden truncate">
                        {{ items.length }} invoices
                    </div>
                </div>

                <ul class="el--playlist">
                    <li
                            class="group relative block select-none hover:bg-gray-50 bg-white"
                            :key="x" v-for="(o, x) of items">
                        <div class="flex items-center">
                            <div
                                    :class="{
                                                        'border-t rounded-t': x === 0,
                                                        'rounded-b': x === items.length - 1
                                                        }"
                                    class="min-w-0 flex-1 flex items-center border-b border-r border-l  border-gray-200  hover:cursor-pointer px-4 py-6">
                                <div class="min-w-0 flex-1 px-4 grid grid-cols-2 md:grid-cols-3 gap-2">
                                    <div @click="$router.push('/invoices/' + o.id)">
                                        <p class="font-medium text-red-700 text truncate">
                                            #{{ o.invoice_id }}
                                        </p>
                                        <p class="text-xs mt-1">
                                            {{ o.date | moment("MMMM Do YYYY") }}
                                        </p>
                                    </div>
                                    <div @click="$router.push('/invoices/' + o.id)"
                                         class="mx-3 text-center flex justify-center items-center text-gray-700">
                                        <p class="text-sm truncate">
                                          {{ o.total | currency($store.getters['auth/language'], o.currency) }} incl. VAT
                                        </p>
                                    </div>
                                    <div @click="$router.push('/invoices/' + o.id)"
                                         class="text-left flex justify-center items-center hidden md:block">
                                            <el-tag v-if="o.status === 'OVERDUE'" type="warning">Overdue</el-tag>
                                        <el-tag v-if="o.status === 'PAID'" type="success">Paid</el-tag>
                                    </div>
                                </div>
                                <div class="mx-2 w-24">
                                    <div class="hidden group-hover:block">
                                        <el-button
                                                @click="$router.push('/invoices/' + o.id)"
                                                type="info" icon="el-icon-view">
                                            View
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
            <div class v-else>
                <div class="py-20">
                    <empty-state
                            :description="search.term && search.term.length > 0 ?  'No search results found' : 'You currently have no invoices'"/>
                </div>
            </div>
        </div>

    </template>
</page-wrapper>
</template>

<script>

import { debounce } from 'debounce';
import EmptyState from '../../components/EmptyState';

import SpinLoader from '../../components/SpinLoader';

export default {
  name: 'invoices-page',
  components: { SpinLoader, EmptyState },
  data() {
    return {
      fetching: true,
      search: {
        term: '',
        running: false,
        show: true,
      },
    };
  },
  created() {
    const vm = this;
    vm.fetch();
  },
  methods: {
    debounceInput: debounce((vm, e) => {
      vm.search.term = e.target.value;
      vm.fetch();
    }, 500),
    fetch() {
      const vm = this;
      vm.fetching = true;
      let path = '/api/v1/invoices';
      if (vm.search.term.length && vm.search.term.length > 0) {
        path += `?q=${vm.search.term}`;
      }
      vm.$http.get(path)
        .then((response) => {
          vm.fetching = false;
          vm.items = response.data.data.map((x) => {
            x.menu_open = false;
            return x;
          });
        }).catch(() => {
          vm.items = [];
          vm.fetching = false;
        });
    },
  },
};
</script>
