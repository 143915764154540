<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <!--
          Background backdrop, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
      <transition
        enter-active-class="transform duration-400 ease-out"
        enter-from-class="transform opacity-0"
        enter-to-class="transform opacity-100"
        leave-active-class="transform duration-400 delay-200  ease-in"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div v-if="show" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      </transition>

      <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="transform opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div v-if="showDetails" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end sm:items-center justify-center min-h-full h-full">
                <!--
                  Modal panel, show/hide based on modal state.

                  Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                  Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                    <div class="relative bg-white md:rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:max-w-6xl h-full modal--content">
                        <div class="bg-white border-b border-gray-200 absolute flex p-4  w-full h-14">
                            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-grow">
                                {{ title }}
                            </h3>
                            <button type="button"
                                    @click="$emit('close')"
                                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                <span class="sr-only">Close</span>
                                <!-- Heroicon name: outline/x -->
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="2" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <div class="content h-full pt-14">
                            <slot name="content"></slot>
                        </div>
                    </div>
            </div>
        </div>
      </transition>
    </div>
</template>

<script>
export default {
  name: 'TitledOverlay',
  props: {
    title: {
      type: String,
      default() {
        return null;
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    show(){
      this.showDetails = this.show;
    }
  },
  data(){
    return {
      showDetails: false
    }
  },
};
</script>
