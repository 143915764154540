<template>
    <div class="flex items-center gap-x-4 p-2 cursor-pointer">
        <div>
            <div class="rounded shadow overflow-hidden w-12 bg-gray-50 flex items-center justify-center h-12">
                <i
                        :class="icon"
                        class="text-3xl"></i>
            </div>
        </div>
        <div class="text-md flex justify-center flex-col leading-loose">
            <div class="font-bold">
                <slot name="title">
                </slot>
            </div>
            <div>
                <slot name="description">
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BillingNewPaymentPlaceholder',
  props: {
    icon: {
      type: String,
      default() {
        return 'el-icon-bank-card';
      },
    },
  },
};
</script>

<style scoped>

</style>
