<template>
  <div :id="id"></div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'RecaptchaComponent',
  data() {
    return {
      grecaptcha: null,
      recaptcha_value: null,
      id: `el-item-recaptcha-${moment().unix()}`,
    };
  },
  methods: {
    reset() {
      if (this.grecaptcha) {
        this.grecaptcha.reset();
      }
    },
  },
  beforeCreate() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LdGFsojAAAAAM-a_-31fFruXIpuDBHZTqOUIV7F';

    document.head.appendChild(script);

    setTimeout(() => {
      const grecaptcha = window.grecaptcha.enterprise;
      grecaptcha.ready(() => {
        grecaptcha.execute('6LdGFsojAAAAAM-a_-31fFruXIpuDBHZTqOUIV7F', { action: 'login' })
          .then((token) => {
            this.$emit('token', token);
          });
      });
    }, 1000);
  },
};
</script>
