import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

import PageNotFound from '@/Pages/PageNotFound';
import Login from '@/Pages/Login';
import Register from '@/Pages/Register';
import PasswordReset from '@/Pages/PasswordReset';
import Dashboard from '@/Pages/Dashboard';
import Impressions from '@/Pages/Impressions';
import Pods from '@/Pages/Pods';
import Media from '@/Pages/Media';
import Playlist from '@/Pages/Playlists/Playlist';
import Playlists from '@/Pages/Playlists/Playlists';
import Bookings from '@/Pages/Bookings';
import Invoices from '@/Pages/Invoices/Invoices';
import Invoice from '@/Pages/Invoices/Invoice';
import InvoicePrint from '@/Pages/Invoices/Print';
import Events from '@/Pages/Events';
import Account from '@/Pages/Account';
import AdminStatusPage from '@/Pages/Admin/AdminStatusPage';
import PodQrLandingPage from '@/Pages/PodQrLandingPage';
import PrivateLocations from '@/Pages/PrivateLocations/PrivateLocations';
import PrivateLocation from '@/Pages/PrivateLocations/PrivateLocation';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/charge-point/:locationId',
    name: 'PodQrLandingPage',
    component: PodQrLandingPage,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/bookings',
    name: 'Bookings',
    component: Bookings,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/bookings/:bookingId',
    name: 'Booking',
    component: Bookings,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/invoices/:invoiceId',
    name: 'Invoice',
    component: Invoice,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/invoices/:invoiceId/print',
    name: 'Invoice',
    component: InvoicePrint,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/play-logs',
    name: 'Impressions',
    component: Impressions,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },

  },
  {
    path: '/locations',
    name: 'Pods',
    component: Pods,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/locations/:podId',
    name: 'PodView',
    component: Pods,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/locations/:podId/book',
    name: 'PodBook',
    component: Pods,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/playlists/:playlistId',
    name: 'Playlist',
    component: Playlist,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/playlists',
    name: 'Playlists',
    component: Playlists,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/private-locations/:playlistId',
    name: 'PrivateLocation',
    component: PrivateLocation,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/private-locations',
    name: 'PrivateLocations',
    component: PrivateLocations,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/media*',
    name: 'Media',
    component: Media,
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    requiresEmailVerification: true,
  },
  {
    name: 'AdminStatusPage',
    component: AdminStatusPage,
    path: '/admin/status',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store._vm.$notify.closeAll();

  store.commit('auth/setBreadcrumbs', []);

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresEmailVerification = to.matched.some((record) => record.meta.requiresEmailVerification);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

  if (requiresAuth && !store.getters['auth/isAuthenticated']) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  } else if (!store.getters['auth/isAdmin'] && requiresAdmin) {
    next({
      path: '/',
    });
  } else if (requiresEmailVerification && !store.getters['auth/emailVerified']) {
    next({
      path: '/account?action=verify-email',
    });
  } else {
    store.state.auth.showMenu = false;
    next();
  }
});

export default router;
