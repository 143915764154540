export function impressionChartOptions({ plotBy, categories }) {
  return {
    tooltip: {
      y: {
        formatter: (value) => {
          if (plotBy === 'minutes') {
            return value ? `${parseInt(value, 10).toLocaleString()} minutes` : 0;
          }
          return value ? `${parseInt(value, 10).toLocaleString()} plays` : 0;
        },
      },
    },
    legend: {
      showForSingleSeries: true,
      itemMargin: {
        vertical: 15,
      }
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      animations: {
        enabled: false,
      },
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: true,
    },
    yaxis: {
      title: {
        text: plotBy === 'minutes' ? 'Minutes' : 'Play Count'
      },
      labels: {
        formatter: (val) => {
          if (plotBy === 'minutes') {
            return `${val.toLocaleString()} minutes`;
          }
          return parseInt(val, 10).toLocaleString();
        },
      },
    },

    xaxis: {
      categories,
      title: {
      },
    },
  };
}
