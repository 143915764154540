<template>
    <div class="bg-white w-64 rounded text-sm overflow-hidden rounded shadow-xl border-2 border-gray-800">
        <h2 class="p-1 bg-gray-900 w-full text-white truncate">
            <span class="block font-medium">{{ name }}</span>
            <span class="text-xs block h-4 flex items-center"><i class="el-icon-location mr-1"></i>{{ address }}</span>
        </h2>
        <div class="px-2 pb-1 bg-white">
          <div class="flex items-center w-full" v-if="weather && weather.met_office_code !== null">
            <div class="-ml-1">
              <img
                  class="w-12"
                  :src="'/imgs/icons/met-office-codes/' + weather.met_office_code + '.svg'"
              />
            </div>
            <div class="leading-tight ml-1 flex-grow">
              <h4 class="font-bold">{{ weather.weather_type }}</h4>
            </div>
            <div class="ml-1">
              <h5 class="font-bold text-2xl">{{ weather.celcius }}°<span class="text-base">c</span></h5>
            </div>
          </div>
            <div class="flex flex-wrap py-1 w-full items-center text-xs" v-if="type">
                <label class="font-semibold block text-gray-800">Location type</label>
                <div class="flex-none ml-auto">
                    {{ type }}
                </div>
            </div>
          <div class="flex flex-wrap py-1 w-full items-center text-xs" v-if="timezone">
            <label class="font-semibold block text-gray-800">Local time</label>
            <div class="flex-none ml-auto">
              {{ timezone | localTime  }}
            </div>
          </div>
          <div class="flex flex-wrap py-1 w-full items-center text-xs" v-if="timezone">
            <label class="font-semibold block text-gray-800">Local date</label>
            <div class="flex-none ml-auto">
              {{ timezone | localDate("MMM Do YY")  }}
            </div>
          </div>
            <div class="flex flex-wrap py-1 w-full items-center text-xs" v-if="price">
                <label class="font-semibold block text-gray-800">Price per slot</label>
                <div class="flex-none ml-auto">
                     {{ price | currency($store.getters['auth/language'], currency) }}
                </div>
            </div>
            <div class="flex flex-wrap py-1 w-full items-center text-xs" v-if="builtUpArea">
                <label class="font-semibold block text-gray-800">Built up area</label>
                <div class="flex-none ml-auto">
                    {{ builtUpArea }}
                </div>
            </div>
            <div class="flex flex-wrap py-1 w-full items-center text-xs" v-if="ruralUrban">
                <label class="font-semibold block text-gray-800">Rural/Urban</label>
                <div class="flex-none ml-auto">
                    {{ ruralUrban }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LocationPopupData',
  props: {
    weather: {
      type: Object,
      default() {
        return null;
      },
    },
    iso: {
      type: String,
      default() {
        return null;
      },
    },
    currency: {
      type: String,
      default() {
        return null;
      },
    },
    address: {
      type: String,
      default() {
        return '';
      },
    },
    name: {
      type: String,
      default() {
        return null;
      },
    },
    timezone: {
      type: String,
      default() {
        return null;
      },
    },
    type: {
      type: String,
      default() {
        return null;
      },
    },
    builtUpArea: {
      type: String,
      default() {
        return null;
      },
    },
    ruralUrban: {
      type: String,
      default() {
        return null;
      },
    },
    price: {
      type: Number,
      default() {
        return null;
      },
    },
    income: {
      type: Number,
      default() {
        return null;
      },
    },
  },
};
</script>

<style scoped>

</style>
