<template>
    <div
            :class="{
            'bg-gray-100': selected,
            'hover:bg-gray-50' : hover
            }"
            class="flex items-center gap-x-4 p-3 cursor-pointer">
        <div>
            <div class="rounded shadow overflow-hidden">
                <card-icon :height="48" v-model="item.type" />
            </div>
        </div>
        <div class="text-md flex justify-center flex-col leading-loose">
            <div class="font-bold">
                {{ item.brand }} <span class="text-xs ml-2">•••• {{ item.last4 }}</span>
            </div>
            <div>
                Expires {{ item.exp | moment("MMMM YYYY") }}
            </div>
        </div>
    </div>
</template>

<script>
import CardIcon from './CardIcon';

export default {
  name: 'PaymentOption',
  components: { CardIcon },
  props: {
    selected: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true;
      },
    },
    details: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    details() {
      this.item = this.details;
    },
  },
  data() {
    return {
      item: this.details,
    };
  },
};
</script>

<style scoped>

</style>
