export default [
  {
    id: 'MRD',
    label: 'Residential',
    color: '#d4812b',
  },
  {
    id: 'MRE',
    label: 'Retail/Leisure',
    color: '#4E9F3D',
  },
  {
    id: 'MO',
    label: 'Offices',
    color: '#60A5FA',
  },
  {
    id: 'CP',
    label: 'Car Parks',
    color: '#3812b3',
  },
]
