<template>
    <page-wrapper title="My Playlists">

        <template slot="nav-cta">
            <el-button
                    @click="onCreatePlaylist"
                    icon="el-icon-plus"
                    type="success">Create Playlist
            </el-button>
        </template>
        <template slot="nav-search">
            <div class="flex-grow lg:block" :class="{
            '': search.show,
             'hidden': !search.show,
            }">
                <div class="relative">
                    <i class="el-icon-search top-0 left-0 absolute text-lg text-gray-500 ml-4 mt-3"></i>
                    <input
                            placeholder="Search Playlists"
                            class="w-full p-2 px-4 pl-10 text-gray-500 rounded border border-gray-300 h-10 text-sm"
                            @input="($event) => debounceInput(this, $event)"
                            :value="search.term"/>
                </div>
            </div>
            <div
                    @click="search.show = !search.show"
                    :class="{
                    'flex-0': search.show,
                    'flex-grow': !search.show,
                    }"
                    class="lg:hidden text-right text-2xl text-gray-600 hover:text-gray-900 cursor-pointer">
                <i
                        v-if="!search.show"
                        class="el-icon-search"></i>
                <i
                        v-if="search.show"
                        class="el-icon-close"></i>
            </div>
        </template>

        <template slot="overlays">
            <alert-confirm
                    :show="confirm.delete.open"
                    @cancel="confirm.delete.open = false"
                    @confirm="() => deletePlaylist()"
                    :loading="confirm.delete.loading"
                    :confirm-text="'Yes, Delete Playlist'"
                    @close="()=> { confirm.delete.open = false }"
            >
                <template slot="title">
                    Delete Playlist?
                </template>
                <template slot="message">
                    Are you sure you want to delete this playlist? This cannot be undone.
                </template>
            </alert-confirm>
        </template>

        <template slot="content">

            <div v-if="fetching">
                <spin-loader bg="transparent" height="h-64"/>
            </div>
            <div v-else>
                <div v-if="items.length > 0">
                    <div class="sm:flex sm:items-center mb-3">
                        <div class="sm:flex-auto text-gray-500 overflow-hidden truncate">
                            {{ items.length }} playlists
                        </div>
                        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <div class="flex w-auto -mx-2">
                              <div class="p-1">
                                <sort-order-by
                                  v-model="sort_options"
                                  :sort="sort"
                                  :order="order"
                                  @change="changeSort"
                                />
                            </div>
                                <div class="p-1" v-if="active.checkbox">
                                    <el-tooltip content="Delete Selected Items">
                                        <button
                                                @click="confirmMultipleDelete"
                                                :disabled="!hasSelectedItems"
                                                :class="{
                                        'text-gray-300 cursor-not-allowed': !hasSelectedItems,
                                        'text-red-700 hover:bg-gray-200': hasSelectedItems
                                        }"
                                                class="p-2 text-sm flex justify-center items-center rounded">
                                            <svg
                                                    class="h-6 w-6"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                    stroke="currentColor" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                            </svg>
                                        </button>
                                    </el-tooltip>
                                </div>
                                <div class="p-1" v-if="active.checkbox">
                                    <span class="block mx-2 h-full border-r border-gray-300"></span>
                                </div>
                                <div class="p-1">
                                    <el-tooltip :content="active.checkbox ? 'Deselect all' : 'Select all'">
                                        <button @click="active.checkbox = !active.checkbox"
                                                class="p-2 hover:bg-gray-200 hover:text-gray-900 rounded"
                                                :class="{
                             'text-gray-900': active.checkbox,
                              'text-gray-300': !active.checkbox
                            }">
                                            <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path v-if="!active.checkbox" fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6ZM6 5C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44772 18.5523 5 18 5H6Z"
                                                />

                                                <path v-if="active.checkbox"
                                                      d="M16.7068 9.04063C17.0973 9.43116 17.0973 10.0643 16.7068 10.4548L12.7881 14.3736C11.6165 15.5452 9.717 15.5452 8.54543 14.3736L7.29317 13.1213C6.90264 12.7308 6.90264 12.0976 7.29317 11.7071C7.68369 11.3166 8.31686 11.3166 8.70738 11.7071L9.95964 12.9594C10.3502 13.3499 10.9833 13.3499 11.3739 12.9594L15.2926 9.04064C15.6831 8.65011 16.3163 8.65011 16.7068 9.04063Z"/>
                                                <path v-if="active.checkbox" fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM5 6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6Z"/>

                                            </svg>

                                        </button>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul class="el--playlist">
                        <li
                                class="group relative block select-none hover:bg-gray-100 bg-white"
                                :key="x" v-for="(o, x) of items">
                            <div class="flex items-center">
                                <div
                                        :class="{
                                                        'border-t rounded-t': x === 0,
                                                        'rounded-b': x === items.length - 1
                                                        }"
                                        class="min-w-0 flex-1 flex items-center border-b border-r border-l  border-gray-200  hover:cursor-pointer px-4 py-6">
                                    <div class="min-w-0 flex-1 px-4 grid grid-cols-2 items-center md:grid-cols-4 gap-2">
                                        <div @click="$router.push('/playlists/' + o.id)">
                                            <p class="font-medium text-red-700 text truncate">
                                                {{ o.name }}
                                            </p>
                                            <p class="truncate text-xs mt-2">
                                            <span class="mr-2">
                                                <span class="text-orange-500" v-if="o.status === 'DRAFT'"><i
                                                        class="el-icon-warning"></i> Draft</span>
                                            <span class="text-green-500" v-else><i class="el-icon-check"></i> Published</span>
                                            </span>
                                                Created {{ o.human_created_at }}
                                            </p>
                                        </div>
                                        <div  @click="$router.push('/playlists/' + o.id)"
                                             class="mx-3 flex justify-center align-top">
                                            <div class="text-xs leading-tight flex items-center text-red-500" v-if="o.has_booking_conflict">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6"
                                                     fill="currentColor"
                                                     viewBox="0 0 24 24">
                                                    <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/>
                                                </svg>
                                                <span class="ml-1 leading-tight">
                                                    Booking/Playlist Conflict
                                                </span>
                                            </div>

                                        </div>
                                        <div @click="$router.push('/playlists/' + o.id)"
                                             class="mx-3 text-center flex justify-center items-center text-gray-700">
                                            <p class="text-sm truncate">
                                                {{ o.duration_secs | duration }}
                                            </p>
                                        </div>
                                        <div @click="$router.push('/playlists/' + o.id)"
                                             class="text-left flex justify-center items-center hidden md:block">
                                            <div v-if="o.items && o.items.length > 0"
                                                 class="flex overflow-hidden -space-x-2">
                                                <div :key="i" v-for="(x, i) of o.items">

                                                    <img
                                                            v-if="x.screenshot_src"
                                                            class="h-8 w-8 rounded border shadow-lg"
                                                            :style="{
                                                        'border-color': x.bg_color ? x.bg_color : '#000000',
                                                        }"
                                                            :src="x.screenshot_src"/>

                                                    <div v-else
                                                         :style="{
                                                        'border-color': x.bg_color ? x.bg_color : '#000000',
                                                        }"
                                                         class="text-xl text-center w-8 h-8 flex justify-center items-center rounded border bg-white shadow-lg">
                                                        <i
                                                                v-if="x.type === 'video' && !x.screenshot_src"
                                                                class="el-icon-video-camera text-gray-500"></i>
                                                        <i
                                                                v-if="x.type === 'image'  && !x.screenshot_src"
                                                                class="el-icon-picture text-gray-500"></i>
                                                    </div>
                                                </div>

                                            </div>
                                            <div v-else>
                                                <el-tag type="danger">No Media</el-tag>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-12 mx-2">
                                        <div v-if="active.checkbox">
                                            <input
                                                    v-model="o.selected"
                                                    type="checkbox"
                                                    class="focus:ring-blue-700 h-6 w-6 cursor-pointer text-blue-700 border-gray-300 rounded"/>
                                        </div>
                                        <!--
                                          @mouseenter="o.menu_open = true"
                                                @mouseleave="o.menu_open = false"
                                        -->
                                        <div
                                                v-if="!active.checkbox"
                                                class="relative inline-block text-left hidden group-hover:block">
                                            <div class="relative">

                                                <popper-js
                                                        :options="{
   placement: 'bottom-end',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8],
      },
    },
  ],
    }">

                                                    <div
                                                            class="z-40 py-2 w-48 mr-36"
                                                            role="menu" aria-orientation="vertical"
                                                            aria-labelledby="menu-button"
                                                            tabindex="-1">
                                                        <div class="rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                                            <div class="py-1" role="none">
                                                                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                                                                <div
                                                                        @click="$router.push('/playlists/' + o.id)"
                                                                        class="text-gray-700 hover:bg-gray-100 group flex items-center px-4 py-2 text-sm"
                                                                        role="menuitem" tabindex="-1" id="menu-item-0">
                                                                    <!-- Heroicon name: solid/pencil-alt -->
                                                                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                                         fill="currentColor" aria-hidden="true">
                                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"/>
                                                                        <path fill-rule="evenodd"
                                                                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                                                              clip-rule="evenodd"/>
                                                                    </svg>
                                                                    Edit
                                                                </div>
                                                                <div
                                                                        @click="duplicatePlaylist(o.id)"
                                                                        class="text-gray-700 hover:bg-gray-100 group flex items-center px-4 py-2 text-sm"
                                                                        role="menuitem" tabindex="-1" id="menu-item-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                         class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                         fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                                         stroke-width="2">
                                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                                                    </svg>
                                                                    Duplicate
                                                                </div>
                                                            </div>
                                                            <div class="py-1" role="none">
                                                                <div
                                                                        @click="confirmDelete(o.id)"
                                                                        class="text-gray-700 hover:bg-gray-100 group flex items-center px-4 py-2 text-sm"
                                                                        role="menuitem" tabindex="-1" id="menu-item-6">
                                                                    <!-- Heroicon name: solid/trash -->
                                                                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                                         fill="currentColor" aria-hidden="true">
                                                                        <path fill-rule="evenodd"
                                                                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                                              clip-rule="evenodd"/>
                                                                    </svg>
                                                                    Delete
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <button
                                                            slot="reference"
                                                            type="button"
                                                            class="inline-flex  text-gray-500 group-hover:text-gray-600 justify-center w-full rounded-md px-4 py-2 text-sm font-medium group-hover:bg-gray-200 focus:outline-none"
                                                    >
                                                        <i class="el-icon-more"></i>
                                                    </button>
                                                </popper-js>

                                                </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class v-else>
                    <div class="py-20">
                        <empty-state
                                :description="search.term && search.term.length > 0 ?  'No search results found' : 'You currently have no playlists'"/>

                        <div class="text-center mt-4">
                            <el-button
                                    @click="onCreatePlaylist"
                                    type="success" icon="el-icon-plus">Create new playlist
                            </el-button>
                        </div>

                    </div>
                </div>

            </div>

        </template>
    </page-wrapper>
</template>

<script>

import { debounce } from 'debounce';
import SpinLoader from '../../components/SpinLoader';
import AlertConfirm from '../../components/AlertConfirm';
import EmptyState from '../../components/EmptyState';
import SortOrderBy from "@/components/SortOrderBy";
import {playlist} from "@/utils/sort-options";

export default {
  name: 'play-lists',
  components: {
    SortOrderBy,
    EmptyState,
    AlertConfirm,
    SpinLoader,

  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      sort: 'created_at',
      order: 'desc',
      sort_options: playlist(),
      confirm: {
        delete: {
          open: false,
          ids: [],
          loading: false,
        },
      },
      active: {
        checkbox: false,
      },
      fetching: true,
      creating: false,
      search: {
        term: '',
        running: false,
        show: true,
      },
      items: [],
    };
  },
  watch: {},
  computed: {
    getSelectedItems() {
      return this.items.filter((x) => x.selected);
    },
    hasSelectedItems() {
      return this.getSelectedItems.length > 0;
    },
  },
  methods: {
    debounceInput: debounce((vm, e) => {
      vm.search.term = e.target.value;
      vm.fetch();
    }, 500),
    changeSort({sort, order}){
      const vm = this;
      vm.sort = sort;
      vm.order = order;
      vm.fetch();
    },
    deletePlaylist() {
      const vm = this;
      vm.confirm.delete.loading = true;
      const requests = vm.confirm.delete.ids.map((id) => this.$http.delete(`/api/v1/playlist/${id}`));

      vm.$http.all(requests)
        .then(vm.$http.spread(() => {
          vm.fetch();
          vm.$notify({
            type: 'success',
            title: 'Playlists Deleted',
            message: 'Your playlists have been deleted successfully',
            position: 'bottom-left',
          });
          vm.confirm.delete.open = false;
          vm.confirm.delete.loading = false;
        })).catch(() => {
          vm.confirm.delete.open = false;
          vm.confirm.delete.loading = false;
          vm.fetch();
        });
    },
    confirmMultipleDelete() {
      const vm = this;
      vm.confirm.delete.ids = this.getSelectedItems.map((x) => x.id);
      vm.confirm.delete.open = true;
    },
    confirmDelete(id) {
      const vm = this;
      vm.confirm.delete.ids = [id];
      vm.confirm.delete.open = true;
    },
    fetch() {
      const vm = this;
      vm.fetching = true;
      let path = '/api/v1/playlist', object = {
        sort: vm.sort,
        order: vm.order
      };

      if (vm.search.term.length && vm.search.term.length > 0) {
        object.q = vm.search.term;
      }

      vm.$http.get(path, {
        params: object
      })
        .then((response) => {
          vm.fetching = false;
          vm.items = response.data.data.map((x) => {
            x.selected = false;
            x.menu_open = false;
            return x;
          });
        }).catch(() => {
          vm.items = [];
          vm.fetching = false;
        });
    },
    onCreatePlaylist() {
      const vm = this;
      return vm.$http.post('/api/v1/playlist')
        .then((response) => {
          vm.creating = false;
          vm.$router.push(`/playlists/${response.data.data.id}`);
        }).catch(() => {
          vm.creating = false;
        });
    },
    duplicatePlaylist(playlistId) {
      const vm = this;
      return vm.$http.post(`/api/v1/playlist/${playlistId}/duplicate`)
        .then((response) => {
          vm.creating = false;
          vm.$router.push(`/playlists/${response.data.data.id}`);
        }).catch(() => {
          vm.creating = false;
        });
    },
  },
};
</script>
<style>
    .el-dropdown {
        position: static;
    }
</style>
