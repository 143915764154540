<template>

  <page-wrapper v-if="!loading" title="" :show-feed="false" :has-content="false">

    <template slot="nav-search">
      <div class="mr-auto" v-if="!isPrivate">
        <div
            v-if="!edit.details.name"
            class="min-w-0 flex-1 grid grid-cols-1">
          <h4
              data-ref="edit-name"
              v-loading="loading"
              @click="onEditNameClick"
              class="text-lg hover:underline cursor-pointer pb-1 font-semibold truncate">
            <i
                v-if="!loading"
                data-ref="edit-name"
                class="el-icon-edit mr-2"></i>
            <span
                data-ref="edit-name"
            >     {{ object.name }}    </span>
          </h4>
        </div>
        <div
            v-click-outside-element="onClickOutside"
            v-else
            class="relative flex-grow">
          <i class="el-icon-edit top-0 left-0 absolute text-xl text-gray-500 ml-4 mt-3"></i>
          <input
              ref="editName"
              class="w-full p-2 px-4 pl-10 text-gray-500 rounded border border-gray-300 h-10 text-sm"
              v-model="object.name"/>
        </div>
      </div>
      <div v-else class="text-lg font-semibold flex-grow">
        {{ object.name }}
      </div>

    </template>

    <template slot="nav-cta">
      <div class="flex gap-x-3"  v-if="!isPrivate">
        <div
          :class="{
        'bg-yellow-500 text-yellow-800': object.status === 'DRAFT',
        'bg-green-400 text-green-800': object.status !== 'DRAFT'
          }"
          class="text-sm font-semibold uppercase p-2 rounded select-none px-3">
          {{ object.status === 'DRAFT' ? 'DRAFT' : 'PUBLISHED' }}
        </div>
        <el-button
          :loading="loading"
          :disabled="loading"
          :icon="object.status === 'DRAFT' ? 'el-icon-upload2' : 'el-icon-download'"
          @click="updateStatus(object.status === 'PUBLISHED' ? 'DRAFT' : 'PUBLISHED')"
          :type="object.status === 'PUBLISHED' ? 'warning' : 'success'">
          {{ object.status === 'PUBLISHED' ? 'Move to Drafts' : 'Publish' }}
        </el-button>
      </div>
      <div v-else>
        <div class="bg-green-400 text-green-800 text-sm font-semibold uppercase p-2 rounded select-none px-3">
          LIVE
        </div>
      </div>
    </template>

    <template slot="content-fs">

      <div v-loading="loading" class="flex flex-wrap h-full md:overflow-hidden">
        <div class="w-full flex flex-col h-full w-full md:w-3/5 lg:w-2/3">
          <div class="flex bg-white shadow flex-wrap w-full p-8 py-6 relative z-40">
            <div class="w-full lg:w-1/4">
              <div class="text-gray-600">
                <div class="uppercase font-semibold text-xs">
                  Total Duration
                </div>
                <h4 class="text-xl font-semibold">{{ getTotalDurationSeconds() | duration }}</h4>
              </div>
            </div>
            <div class="w-full lg:w-3/4 text-right gap-2">
              <el-tooltip content="Remove all">
                <el-button
                    @click="confirm.removeAll.open = true"
                    :disabled="!items.length"
                    type="danger" icon="el-icon-delete">
                </el-button>
              </el-tooltip>
              <el-tooltip content="Preview Playlist">
                <el-button
                    :loading="loading"
                    @click="preview.show = true"
                    :disabled="loading || !getPreviewList().length"
                    icon="el-icon-view"
                    type="info">
                </el-button>
              </el-tooltip>
              <el-dropdown
                  v-if="!isPrivate"
                  @command="handleMoreCommand" class="ml-3">
                <el-button
                    :loading="loading"
                    :disabled="loading"
                >
                  <i class="el-icon-more"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="duplicate" icon="el-icon-document-copy">Duplicate</el-dropdown-item>
                  <el-dropdown-item command="delete" divided icon="el-icon-delete">Delete</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                  type="success"
                  @click="launchCredentials"
                  v-if="isPrivate && !credentials.loading">
                <div class="flex items-center gap-1">

                  <svg xmlns="http://www.w3.org/2000/svg"
                       class="w-3 h-3 text-white"
                       fill="currentColor"
                       viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4 17h-8v-6h8v6zm-6-6v-2c0-1.104.897-2 2-2s2 .896 2 2v2h1v-2c0-1.656-1.343-3-3-3s-3 1.344-3 3v2h1z"/></svg>

                  <span>View Credentials</span>
                </div>
              </el-button>
            </div>
          </div>
          <div
            v-if="buildBookingConflicts().length > 0"
            class="p-4 bg-red-100 w-full shadow">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: mini/exclamation-triangle -->
                <svg class="h-8 w-8 text-yellow-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-bold text-orange-800">
                  Your playlist has conflicts with your bookings
                </h3>
                <div class="mt-2 text-sm text-orange-800">
                  <p>
                    It looks like your playlist items are conflicting with an attached booking. Please resolve these or your media item will not be shown.
                  </p>
                </div>
              </div>
              <div class="flex items-center px-3 justify-center">
                <el-button
                  @click="openIssueResolver"
                  type="danger">Resolve </el-button>
              </div>
            </div>
          </div>
          <div class="flex-1 scrollbar-thin scrollbar-thumb-gray-400 z-10 scrollbar-track-gray-200 p-8">
            <div v-if="items.length > 0">
              <draggable tag="ul"
                         class="el--playlist border rounded-lg overflow-hidden"
                         :options="clonedItemOptions"
                         v-model="items">

                <li
                    :class="{
                                            'bg-orange-50 hover:bg-orange-100': o.disable_media,
                                            'bg-white hover:bg-gray-100': !o.disable_media
                                            }"
                    class="group relative block select-none"
                    :key="x" v-for="(o, x) of items">


                  <playlist-list-item
                      @edit="onListItemClick(o, x)"
                      @remove="confirmRemoveItem(x)"
                      :custom-availability="hasCustomAvailability(o)"
                      :custom-weather-availability="hasCustomWeatherAvailability(o)"
                      :disabled-media="o.disable_media"
                      :custom-duration-enabled="o.custom_duration_enabled"
                      :duration="o.duration_secs"
                      :custom-duration="o.custom_duration"
                      :bg-color="o.bg_color"
                      :media-type="o.media_type"
                      :screenshot="o.screenshot_src"
                      :file-extension="o.file_extension"
                      :name="o.name"
                      :qr-code-enabled="o.qr_code_enabled"
                      v-if="active_index !== x" />

                  <div v-else class="overlay h-20 w-full top-0 left-0 z-10 bg-gray-100"></div>

                </li>

              </draggable>
            </div>
            <div class="bg-transparent" v-else>
              <draggable
                  v-model="items"
                  class="el-block--drop-empty h-full relative z-20"
                  :options="clonedItemOptions">
                <div class="text-center text-gray-600 p-6 px-20 relative">
                  <!-- Arrow -->
                  <svg class="w-32 absolute top-0 right-0 mt-40 mr-10" viewBox="0 0 153 48"
                       version="1.1" xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <path
                          d="M30.5219469,13.945991 C44.1709471,16.5429312 59.1250161,17.3275202 75.384154,16.2997579 C91.1951334,15.3003244 116.855777,9.87327456 152.366084,0.0186085178 L152.366087,0.0186201836 C152.632167,-0.0552210864 152.907727,0.10061892 152.981568,0.366698579 C153.039867,0.576771024 152.955329,0.800400195 152.772642,0.919373619 C119.712457,22.4495769 78.9622251,33.9716267 30.5219469,35.485523 C30.5012841,35.4879677 30.5012841,28.3081237 30.5219469,13.945991 Z M0.987416583,22.2930222 L25.8389415,1.4515039 C27.0245589,0.457196101 28.8095197,0.591537056 29.8257631,1.75156282 C30.2650089,2.25295495 30.5064498,2.89154392 30.5064498,3.55191635 L30.5064498,45.234953 C30.5064498,46.7627972 29.2405633,48.0013596 27.679013,48.0013596 C27.004072,48.0013596 26.351395,47.7651301 25.8389415,47.3353655 L0.987416583,26.4938471 C-0.198200736,25.4995393 -0.335505413,23.7531069 0.680738003,22.5930812 C0.774961465,22.4855266 0.877489211,22.3852119 0.987416583,22.2930222 Z"
                          id="arrow" fill="#EEEEEE"></path>
                    </g>
                  </svg>

                  <div class="mx-auto w-2/3 my-4 flex items-center justify-center mb-10">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-72"
                         viewBox="0 0 823.12732 756.22564"
                         xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                          d="M720.73536,244.83443h-3.99878V135.2891a63.40183,63.40183,0,0,0-63.40179-63.40192H421.24806a63.40185,63.40185,0,0,0-63.402,63.40172V736.26331a63.40188,63.40188,0,0,0,63.4018,63.40192H653.33449a63.40187,63.40187,0,0,0,63.402-63.40168V322.81067h3.99884Z"
                          transform="translate(-188.43634 -71.88718)" fill="#e6e6e6"/>
                      <path
                          d="M655.89283,88.38216h-30.295a22.49484,22.49484,0,0,1-20.82715,30.99052H471.81123a22.49482,22.49482,0,0,1-20.82715-30.99056H422.68849a47.34781,47.34781,0,0,0-47.34784,47.34774V735.82245a47.3478,47.3478,0,0,0,47.34778,47.34784h233.2044a47.3478,47.3478,0,0,0,47.34784-47.34778h0V135.72991A47.34777,47.34777,0,0,0,655.89283,88.38216Z"
                          transform="translate(-188.43634 -71.88718)" fill="#fff"/>
                      <polygon
                          points="306.127 751.864 303.127 751.864 309.127 350.864 312.127 350.864 306.127 751.864"
                          fill="#3f3d56"/>
                      <polygon
                          points="388.127 751.864 385.127 751.864 379.127 350.864 382.127 350.864 388.127 751.864"
                          fill="#3f3d56"/>
                      <rect x="310.12732" y="358.86444" width="70" height="3" fill="#3f3d56"/>
                      <rect x="310.12732" y="410.86444" width="70" height="3" fill="#3f3d56"/>
                      <rect x="309.12732" y="462.86444" width="73" height="3" fill="#3f3d56"/>
                      <rect x="309.12732" y="514.86444" width="74" height="3" fill="#3f3d56"/>
                      <rect x="308.12732" y="566.86444" width="76" height="3" fill="#3f3d56"/>
                      <rect x="307.12732" y="618.86444" width="77" height="3" fill="#3f3d56"/>
                      <rect x="306.12732" y="670.86444" width="79" height="3" fill="#3f3d56"/>
                      <rect x="304.12732" y="722.86444" width="82" height="3" fill="#3f3d56"/>
                      <circle cx="341.7944" cy="504.2174" r="42.01233" fill="#2f2e41"/>
                      <rect x="544.31827" y="606.09253" width="12.76324" height="22.86749"
                            transform="matrix(0.98401, -0.17813, 0.17813, 0.98401, -289.63076, 36.08746)"
                            fill="#2f2e41"/>
                      <rect x="519.20011" y="610.63958" width="12.76324" height="22.86749"
                            transform="matrix(0.98401, -0.17813, 0.17813, 0.98401, -290.84248, 31.6858)"
                            fill="#2f2e41"/>
                      <ellipse cx="525.42828" cy="636.15279" rx="10.63602" ry="3.98853"
                               transform="translate(-475.09373 418.38172) rotate(-40.26106)"
                               fill="#2f2e41"/>
                      <ellipse cx="550.02321" cy="631.70037" rx="10.63605" ry="3.98853"
                               transform="translate(-466.38991 433.22197) rotate(-40.26106)"
                               fill="#2f2e41"/>
                      <circle cx="338.85318" cy="493.94094" r="14.35864" fill="#fff"/>
                      <circle cx="332.95181" cy="488.79416" r="4.78622" fill="#3f3d56"/>
                      <path
                          d="M563.37259,530.39966c.63177-15.55359-12.77313-28.7276-29.9408-29.42493s-31.59692,11.346-32.22873,26.8996,11.30191,19.08746,28.46955,19.78485S562.74081,545.95325,563.37259,530.39966Z"
                          transform="translate(-188.43634 -71.88718)" fill="#fde047"/>
                      <ellipse cx="491.43815" cy="568.45065" rx="6.59448" ry="21.00616"
                               transform="translate(-360.87753 848.57458) rotate(-77.0899)"
                               fill="#2f2e41"/>
                      <ellipse cx="573.90679" cy="582.16512" rx="6.59448" ry="21.00616"
                               transform="translate(-310.20199 939.60891) rotate(-77.0899)"
                               fill="#2f2e41"/>
                      <path
                          d="M541.76944,592.66113a9.57243,9.57243,0,1,1-18.8353,3.42884l-.00335-.0185c-.94175-5.20215,3.08038-7.043,8.28256-7.98474S540.82773,587.459,541.76944,592.66113Z"
                          transform="translate(-188.43634 -71.88718)" fill="#fff"/>
                      <path
                          d="M675.0911,327.12766,406.16849,351.22949a5.00588,5.00588,0,0,1-5.42636-4.53371l-5.97864-66.7082a5.00588,5.00588,0,0,1,4.53371-5.42637l268.9226-24.10183a5.00589,5.00589,0,0,1,5.42637,4.53371l5.97864,66.70821A5.00588,5.00588,0,0,1,675.0911,327.12766ZM399.47573,276.55322a3.00328,3.00328,0,0,0-2.72022,3.25582l5.97863,66.70821a3.00329,3.00329,0,0,0,3.25582,2.72023l268.9226-24.10183a3.00328,3.00328,0,0,0,2.72023-3.25582l-5.97863-66.70821a3.0033,3.0033,0,0,0-3.25583-2.72023Z"
                          transform="translate(-188.43634 -71.88718)" fill="#e6e6e6"/>
                      <circle cx="249.15676" cy="237.88361" r="21" fill="#fde047"/>
                      <path
                          d="M484.64976,291.49728a3.5,3.5,0,0,0,.62486,6.97206l164.34129-14.72887a3.5,3.5,0,1,0-.62486-6.97206Z"
                          transform="translate(-188.43634 -71.88718)" fill="#e6e6e6"/>
                      <path
                          d="M486.52434,312.41345a3.5,3.5,0,1,0,.62486,6.972l164.3413-14.72887a3.5,3.5,0,0,0-.62487-6.97205Z"
                          transform="translate(-188.43634 -71.88718)" fill="#e6e6e6"/>
                      <ellipse cx="570.62227" cy="340.38066" rx="21.53369" ry="6.76007"
                               transform="translate(-139.48994 679.9823) rotate(-69.08217)"
                               fill="#2f2e41"/>
                      <circle cx="530.81509" cy="372.90266" r="43.06735"
                              transform="translate(-110.73595 765.24401) rotate(-80.78252)"
                              fill="#2f2e41"/>
                      <rect x="322.75315" y="334.81517" width="13.08374" height="23.44171"
                            fill="#2f2e41"/>
                      <rect x="348.92063" y="334.81517" width="13.08374" height="23.44171"
                            fill="#2f2e41"/>
                      <ellipse cx="333.65629" cy="358.52946" rx="10.90314" ry="4.08868"
                               fill="#2f2e41"/>
                      <ellipse cx="359.82377" cy="357.98432" rx="10.90314" ry="4.08868"
                               fill="#2f2e41"/>
                      <path
                          d="M489.03837,332.85127c-3.47748-15.57379,7.63867-31.31045,24.82861-35.14883s33.94422,5.67508,37.42169,21.24887-7.91491,21.31765-25.10485,25.156S492.51585,348.42509,489.03837,332.85127Z"
                          transform="translate(-188.43634 -71.88718)" fill="#fde047"/>
                      <ellipse cx="482.30694" cy="352.91926" rx="6.76007" ry="21.53368"
                               transform="translate(-231.68444 565.57363) rotate(-64.62574)"
                               fill="#2f2e41"/>
                      <circle cx="82.9008" cy="694.62297" r="43.06733" fill="#2f2e41"/>
                      <rect x="63.27519" y="728.42266" width="13.08374" height="23.44171"
                            fill="#2f2e41"/>
                      <rect x="89.44267" y="728.42266" width="13.08374" height="23.44171"
                            fill="#2f2e41"/>
                      <ellipse cx="74.17827" cy="752.13696" rx="10.90314" ry="4.08868"
                               fill="#2f2e41"/>
                      <ellipse cx="100.34581" cy="751.59179" rx="10.90314" ry="4.08868"
                               fill="#2f2e41"/>
                      <circle cx="83.99113" cy="683.71987" r="14.71922" fill="#fff"/>
                      <circle cx="83.99113" cy="683.71987" r="4.90642" fill="#3f3d56"/>
                      <path
                          d="M229.56035,726.4588C226.08287,710.885,237.199,695.14837,254.389,691.31s33.94421,5.67511,37.42169,21.24891-7.91492,21.31768-25.10486,25.156S233.03788,742.03265,229.56035,726.4588Z"
                          transform="translate(-188.43634 -71.88718)" fill="#fde047"/>
                      <ellipse cx="296.42506" cy="610.23773" rx="21.53369" ry="6.76007"
                               transform="translate(-567.86257 597.36685) rotate(-69.08217)"
                               fill="#2f2e41"/>
                      <circle cx="256.61789" cy="642.75973" r="43.06735"
                              transform="translate(-607.38415 721.21814) rotate(-80.78252)"
                              fill="#2f2e41"/>
                      <rect x="48.55595" y="604.67224" width="13.08374" height="23.44171"
                            fill="#2f2e41"/>
                      <rect x="74.72343" y="604.67224" width="13.08374" height="23.44171"
                            fill="#2f2e41"/>
                      <ellipse cx="59.45909" cy="628.38653" rx="10.90314" ry="4.08868"
                               fill="#2f2e41"/>
                      <ellipse cx="85.62657" cy="627.84139" rx="10.90314" ry="4.08868"
                               fill="#2f2e41"/>
                      <circle cx="69.27189" cy="559.96945" r="14.71922" fill="#fff"/>
                      <circle cx="69.27189" cy="559.96945" r="4.90642" fill="#3f3d56"/>
                      <path
                          d="M214.84117,602.70834c-3.47748-15.57379,7.63867-31.31045,24.82861-35.14883s33.94422,5.67508,37.42169,21.24887-7.91491,21.31765-25.10486,25.156S218.31865,618.28216,214.84117,602.70834Z"
                          transform="translate(-188.43634 -71.88718)" fill="#fde047"/>
                      <ellipse cx="208.10974" cy="622.77633" rx="6.76007" ry="21.53368"
                               transform="translate(-632.20351 472.04423) rotate(-64.62574)"
                               fill="#2f2e41"/>
                      <path
                          d="M235.46336,658.29669c0,4.21515,10.85327,12.53857,22.89655,12.53857s23.33514-11.867,23.33514-16.08209-11.29193.81775-23.33514.81775S235.46336,654.08154,235.46336,658.29669Z"
                          transform="translate(-188.43634 -71.88718)" fill="#fff"/>
                      <ellipse cx="435.47266" cy="641.35704" rx="21.53369" ry="6.76007"
                               transform="translate(-507.5273 747.25911) rotate(-69.08217)"
                               fill="#2f2e41"/>
                      <circle cx="404.66549" cy="682.87904" r="43.06735"
                              transform="translate(-522.65242 901.04697) rotate(-80.78252)"
                              fill="#2f2e41"/>
                      <polygon
                          points="196.604 644.792 209.687 644.792 207.127 672.864 196.604 668.233 196.604 644.792"
                          fill="#2f2e41"/>
                      <ellipse cx="390.94302" cy="743.39302" rx="4.08868" ry="10.90314"
                               transform="translate(-493.58893 201.48401) rotate(-28.23232)"
                               fill="#2f2e41"/>
                      <polygon
                          points="225.127 644.864 238.211 644.864 235.651 672.937 225.127 668.306 225.127 644.864"
                          fill="#2f2e41"/>
                      <ellipse cx="419.4668" cy="743.46591" rx="4.08868" ry="10.90314"
                               transform="translate(-490.23014 214.9858) rotate(-28.23232)"
                               fill="#2f2e41"/>
                      <circle cx="217.31949" cy="600.08875" r="14.71922" fill="#fff"/>
                      <circle cx="217.31949" cy="600.08875" r="4.90642" fill="#3f3d56"/>
                      <path
                          d="M362.88876,642.82765c-3.47748-15.57379,7.63867-31.31046,24.82862-35.14884s33.94421,5.67508,37.42169,21.24888-7.91492,21.31765-25.10486,25.156S366.36624,658.40147,362.88876,642.82765Z"
                          transform="translate(-188.43634 -71.88718)" fill="#fde047"/>
                      <ellipse cx="359.15733" cy="652.89564" rx="6.76007" ry="21.53368"
                               transform="translate(-506.07599 269.2192) rotate(-36.4501)"
                               fill="#2f2e41"/>
                      <path
                          d="M425.67834,705.08563c0-3.474-8.945-10.334-18.8708-10.334s-19.23228,9.78051-19.23228,13.25448,9.30655-.674,19.23228-.674S425.67834,708.55966,425.67834,705.08563Z"
                          transform="translate(-188.43634 -71.88718)" fill="#fff"/>
                      <path
                          d="M380.56346,646.82224a94.95979,94.95979,0,0,1-95-94.99951c0-.19873.00049-.4082.01172-.60693.291-52.02539,42.90332-94.39307,94.98828-94.39307a95.01491,95.01491,0,0,1,42.18506,180.145A94.10649,94.10649,0,0,1,380.56346,646.82224Zm0-187.99951a93.19834,93.19834,0,0,0-92.98974,92.45557c-.01075.2124-.01026.38281-.01026.54443a93.0003,93.0003,0,0,0,185.15918,12.50684,92.98052,92.98052,0,0,0-92.15918-105.50684Z"
                          transform="translate(-188.43634 -71.88718)" fill="#3f3d56"/>
                      <path
                          d="M423.5861,556.42173,358.56366,518.881a2,2,0,0,0-3,1.73205V595.6945a2,2,0,0,0,3,1.73206l65.02244-37.54073a2,2,0,0,0,0-3.4641L358.56366,518.881a2,2,0,0,0-3,1.73205V595.6945a2,2,0,0,0,3,1.73206l65.02244-37.54073A2,2,0,0,0,423.5861,556.42173Z"
                          transform="translate(-188.43634 -71.88718)" fill="#67c23a"/>
                      <ellipse cx="227.12732" cy="747.86444" rx="53" ry="2" fill="#e6e6e6"/>
                      <ellipse cx="770.12732" cy="728.86444" rx="53" ry="2" fill="#e6e6e6"/>
                      <path
                          d="M958.30982,799.06723,720.64462,670.94417a5.00588,5.00588,0,0,1-2.02855-6.77384l31.78185-58.95459a5.0059,5.0059,0,0,1,6.77385-2.02855L994.837,731.31024a5.00589,5.00589,0,0,1,2.02856,6.77385l-31.78186,58.95458A5.00589,5.00589,0,0,1,958.30982,799.06723ZM756.22271,604.94767a3.00328,3.00328,0,0,0-4.06431,1.21713l-31.78185,58.95458a3.00327,3.00327,0,0,0,1.21713,4.06431l237.6652,128.12306a3.00327,3.00327,0,0,0,4.0643-1.21714L995.105,737.135a3.00329,3.00329,0,0,0-1.21713-4.06431Z"
                          transform="translate(-188.43634 -71.88718)" fill="#e6e6e6"/>
                      <circle cx="581.28038" cy="581.78679" r="21" fill="#aa2e26"/>
                      <path
                          d="M819.05175,664.36526a3.5,3.5,0,1,0-3.3217,6.16168l145.23958,78.29728a3.5,3.5,0,1,0,3.3217-6.16168Z"
                          transform="translate(-188.43634 -71.88718)" fill="#e6e6e6"/>
                      <path
                          d="M809.08665,682.8503a3.5,3.5,0,1,0-3.32171,6.16168l145.23958,78.29728a3.5,3.5,0,0,0,3.32171-6.16168Z"
                          transform="translate(-188.43634 -71.88718)" fill="#e6e6e6"/>
                    </svg>
                  </div>
                  <h4 class="text-xl font-semibold text-gray-600 my-6">
                    Your playlist is currently empty
                  </h4>

                  <p>
                    Drag and drop your approved media from the right sidebar. You can redorder
                    your
                    playlist at anytime.
                  </p>
                </div>
              </draggable>

            </div>
          </div>
        </div>
        <div class="w-full md:w-2/5 lg:w-1/3 bg-white border-l border-gray-200">
          <div class="h-18 w-full">
            <custom-tabs
                split="w-1/3"
                v-model="active_playlist_tab"
                :tabs="getPlaylistTabs"/>
          </div>
          <div class="el--scroll-settings-inner">
            <div v-if="active_playlist_tab === 'media'">
              <div class="m-4 my-6 relative">
                <div class="absolute px-3 flex items-center justify-center h-full">
                  <i class="el-icon-search text-lg text-gray-600"></i>
                </div>
                <input placeholder="Search content"
                       @input="($event) => debounceInput(this, $event)"
                       :value="search.term"
                       class="p-3 pl-10 rounded w-full border text-gray-600 border-gray-400"/>
              </div>
              <div class="p-3 w-full h-14">
                <div class="min-w-0 flex-1 flex items-center h-full">
                  <div class="flex-shrink-0 ">
                    <div v-if="search.term.length > 0">
                      <i class="el-icon-search text-2xl mr-1"></i>
                    </div>
                    <div class="mr-2" v-if="!search.term.length && getDropDownMenuOptions.length > 0">

                      <el-dropdown
                          @command="onFolderSelect"
                          v-if="getDropDownMenuOptions.length > 0">
                        <el-button class="el--button-custom-dropdown" size="mini" type="link">
                          <i class="el-icon-folder text-2xl"></i> <i
                            class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                              :command="f.id"
                              :key="'folder-' + x"
                              v-for="(f, x) of getDropDownMenuOptions"
                          >
                            {{ f.name }}
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>

                    </div>
                  </div>
                  <div class="min-w-0 flex-1 grid grid-cols-1 flex items-center">
                    <p class="text-sm font-medium text-red-700 truncate">
                      {{ search.term.length > 0 ? 'Search Results' : getFolderName }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                  class="hidden md:block overflow-scroll el--scroll-files bg-gray-100 border-t border-gray-200 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">

                <div class="p-4" v-if="search.loading">
                  <spin-loader bg="transparent" height="h-64"/>
                </div>

                <draggable
                    :options="availableItemOptions"
                    v-model="library.results"
                    v-if="!search.loading && library.results.length > 0"
                    tag="ul">
                  <li
                      @click="onItemClick(x)"
                      :key="k"
                      :class="{
                                        'is-file-object': x.type === 'file'
                                        }"
                      class="p-2 py-4 flex border-b  select-none bg-white cursor-pointer hover:bg-gray-50 border-gray-200"
                      v-for="(x, k) of library.results">
                    <img
                        v-if="x.screenshot_src"
                        class="h-10 w-10 rounded border-2 border-gray-400"
                        :src="x.screenshot_src"/>

                    <div v-else class="text-3xl text-center w-10 h-10 flex justify-center items-center">
                      <i
                          v-if="x.type === 'folder'"
                          class="el-icon-folder text-blue-800"></i>
                      <i
                          v-if="x.type === 'file' && x.media_type === 'video' && !x.screenshot_src"
                          class="el-icon-video-camera text-gray-500"></i>
                      <i
                          v-if="x.type === 'file' && x.media_type === 'image'  && !x.screenshot_src"
                          class="el-icon-picture text-gray-500"></i>
                    </div>

                    <div class="ml-1">
                      <div class="min-w-0 flex-1 px-4 grid grid-cols-1">

                        <p class="text-sm font-medium text-gray-900 truncate">
                          {{ x.name }}<span v-if="x.file_extension">.{{ x.file_extension }}</span>
                        </p>
                        <p v-if="x.type === 'file'" class="text-sm text-gray-500 text-xs truncate">
                          {{ x.media_type === 'image' ? 'Image' : 'Video' }}
                        </p>
                        <p v-if="x.type === 'folder'"
                           class="text-sm text-gray-500 text-xs truncate">
                          {{ x.count > 0 ? x.count + ' items' : 'Empty folder' }}
                        </p>
                      </div>
                    </div>
                  </li>
                </draggable>

                <div class="p-4 py-8" v-if="!search.loading && !library.results.length">
                  <empty-state
                      :description="isSearchMode ? 'Sorry but no media items found for your search' : 'Looks like this folder is empty' "/>
                </div>

              </div>
            </div>
            <div class="p-4" v-if="active_playlist_tab === 'settings'">
              <label class="block text-sm font-medium text-gray-700 py-2">Default Background Colour</label>
              <el-color-picker v-model="object.bg_color"/>
            </div>
            <div class="p-4" v-if="active_playlist_tab === 'categories'">

              <div v-if="getCategories().length > 0">

                <div class="mb-3 text-left">
                  <div class="rounded-md bg-blue-50 p-3 text-xs">
                    <div class="flex-1 md:flex md:justify-between">
                      <p class="text-blue-700">
                        Your playlist falls within {{ getCategories().length }} categories. This may restrict which bookings you can apply this to.
                      </p>
                    </div>
                  </div>
                </div>

                <el-collapse accordion>
                  <el-collapse-item :key="'cat' + x" v-for="(item, x) of getCategories()"
                                    :title="item.type + ' (' + item.count + ')'">
                    <div class="leading-tight my-2">
                      {{ item.description }}
                    </div>

                    <ul class="rounded border border-gray-200 mt-4">

                      <li
                          :key="k" class="p-2 py-4 flex border-b  select-none bg-white cursor-pointer border-gray-200"
                          v-for="(x, k) of item.ids">
                        <img
                            v-if="x.screenshot_src"
                            class="h-10 w-10 rounded border-2 border-gray-400"
                            :src="x.screenshot_src"/>

                        <div v-else class="text-3xl text-center w-10 h-10 flex justify-center items-center">
                          <i
                              v-if="x.media_type === 'video' && !x.screenshot_src"
                              class="el-icon-video-camera text-gray-500"></i>
                          <i
                              v-if="x.media_type === 'image'  && !x.screenshot_src"
                              class="el-icon-picture text-gray-500"></i>
                        </div>

                        <div class="ml-1">
                          <div class="min-w-0 flex-1 px-4 grid grid-cols-1">

                            <p class="text-sm font-medium text-gray-900 truncate">
                              {{ x.name }}<span v-if="x.file_extension">.{{ x.file_extension }}</span>
                            </p>
                            <p class="text-sm text-gray-500 text-xs truncate">
                              {{ x.media_type === 'image' ? 'Image' : 'Video' }}
                            </p>
                          </div>
                        </div>
                      </li>

                    </ul>

                  </el-collapse-item>
                </el-collapse>
              </div>
              <div v-else>

                <div class="mx-auto w-2/3 my-4 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-72" viewBox="0 0 827.8999 666.5"
                       xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path
                        d="M335.21814,555.90723a7.03378,7.03378,0,0,1-2.1438-.33692,6.95171,6.95171,0,0,1-4.09155-3.457l-6.76355-13.11816a7.00731,7.00731,0,0,1,3.014-9.4292l109.33728-56.37158A7.00756,7.00756,0,0,1,444,476.2085l6.76343,13.11816a7.00721,7.00721,0,0,1-3.01416,9.4292L338.41235,555.12744A6.95569,6.95569,0,0,1,335.21814,555.90723Z"
                        transform="translate(-186.05005 -116.75)" fill="#fde047"></path>
                    <path
                        d="M374.87971,504.79687a7.03706,7.03706,0,0,1,.74314,1.2945l11.55364,25.23071a6.99947,6.99947,0,0,1-3.5732,9.237l-38.859,13.582a17.48362,17.48362,0,0,1-21.47645-8.84216l.44928-.21893-.44888.21865a17.46934,17.46934,0,0,1,7.25337-22.95166l35.831-19.812a7.01189,7.01189,0,0,1,8.52709,2.262Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <path
                        d="M832.44995,438.75H645.26a17.65489,17.65489,0,0,1-11.57837-4.373L556.5791,366.375A14.47132,14.47132,0,0,0,546.99,362.75H480.45V133.25a16.51868,16.51868,0,0,1,16.5-16.5h319a16.51867,16.51867,0,0,1,16.5,16.5Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <circle cx="370.8999" cy="71.5" r="35" fill="#fff"></circle>
                    <path d="M692.94995,169.5h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
                          transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M787.30982,198H627.09009c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H787.30982c2.01025,0,3.64013-2.02,3.64013-4.5S789.32007,198,787.30982,198Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M774.72,258.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,258.25,774.72,258.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M774.72,286.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,286.25,774.72,286.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M774.72,314.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,314.25,774.72,314.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M774.72,342.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,342.25,774.72,342.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M987.45,438.75H645.26a17.37609,17.37609,0,0,1-9.585-2.86279l-.2251-.14795V202.25a16.51868,16.51868,0,0,1,16.5-16.5h319a16.51867,16.51867,0,0,1,16.5,16.5Z"
                        transform="translate(-186.05005 -116.75)" fill="#aa2e26"></path>
                    <circle cx="525.8999" cy="140.5" r="35" fill="#fff"></circle>
                    <path d="M847.95,238.5h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
                          transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M942.30982,267H782.09009c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H942.30982c2.01025,0,3.64013-2.02,3.64013-4.5S944.32007,267,942.30982,267Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M929.72,327.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,327.25,929.72,327.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M929.72,355.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,355.25,929.72,355.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M929.72,383.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,383.25,929.72,383.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M929.72,411.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,411.25,929.72,411.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M996.95,436.25H645.26a14.94463,14.94463,0,0,1-9.31006-3.24c-.21-.16-.41015-.33-.60986-.51l-29.77-26.25L558.23023,364.5A16.97377,16.97377,0,0,0,546.99,360.25H460.95a17.02411,17.02411,0,0,0-17,17v389a17.02411,17.02411,0,0,0,17,17h536a17.0241,17.0241,0,0,0,17-17v-313A17.0241,17.0241,0,0,0,996.95,436.25Zm15,330a15.01828,15.01828,0,0,1-15,15h-536a15.01828,15.01828,0,0,1-15-15v-389a15.01828,15.01828,0,0,1,15-15h86.04a14.965,14.965,0,0,1,9.91992,3.75l45.64014,40.25L634.01,434a17.14841,17.14841,0,0,0,11.25,4.25H996.95a15.01828,15.01828,0,0,1,15,15Z"
                        transform="translate(-186.05005 -116.75)" fill="#3f3d56"></path>
                    <circle cx="103.25377" cy="326.07215" r="53.51916" fill="#fde047"></circle>
                    <path
                        d="M288.871,782.668H274.1117a7.00786,7.00786,0,0,1-7-7V652.6543a7.00785,7.00785,0,0,1,7-7H288.871a7.00785,7.00785,0,0,1,7,7V775.668A7.00786,7.00786,0,0,1,288.871,782.668Z"
                        transform="translate(-186.05005 -116.75)" fill="#fde047"></path>
                    <path
                        d="M320.871,782.668H306.1117a7.00786,7.00786,0,0,1-7-7V652.6543a7.00785,7.00785,0,0,1,7-7H320.871a7.00785,7.00785,0,0,1,7,7V775.668A7.00786,7.00786,0,0,1,320.871,782.668Z"
                        transform="translate(-186.05005 -116.75)" fill="#fde047"></path>
                    <path
                        d="M374.46724,625.41873c0,43.6305-41.04132,57.70416-84.67181,57.70416s-73.32819-14.07366-73.32819-57.70416,29.36951-118,73-118S374.46724,581.78824,374.46724,625.41873Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <path
                        d="M288.17937,462.65814l-.05566-2c3.7207-.10352,7.001-.33692,9.46582-2.1377a6.14794,6.14794,0,0,0,2.38134-4.52832,3.51432,3.51432,0,0,0-1.15283-2.89453c-1.63623-1.38183-4.269-.93457-6.188-.05469l-1.65478.75879,3.17334-23.19043,1.98144.27149-2.69921,19.72656c2.60742-.7666,5.02343-.43652,6.67822.96094a5.471,5.471,0,0,1,1.86035,4.49219,8.13264,8.13264,0,0,1-3.2002,6.07324C295.60222,462.45013,291.4865,462.56537,288.17937,462.65814Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <rect x="119.87932" y="313.83392" width="10.77148" height="2" fill="#2f2e41"></rect>
                    <rect x="85.87932" y="313.83392" width="10.77148" height="2" fill="#2f2e41"></rect>
                    <path
                        d="M883.95,714.25h-294a15.017,15.017,0,0,1-15-15v-162a15.017,15.017,0,0,1,15-15h294a15.017,15.017,0,0,1,15,15v162A15.017,15.017,0,0,1,883.95,714.25Zm-294-190a13.0149,13.0149,0,0,0-13,13v162a13.0149,13.0149,0,0,0,13,13h294a13.01489,13.01489,0,0,0,13-13v-162a13.01489,13.01489,0,0,0-13-13Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <circle cx="451.3999" cy="465.5" r="35" fill="#e6e6e6"></circle>
                    <path d="M773.44995,563.5h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
                          transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <path
                        d="M867.80982,592H707.59009c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H867.80982c2.01025,0,3.64013-2.02,3.64013-4.5S869.82007,592,867.80982,592Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <path
                        d="M855.22,652.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H855.22c1.91992,0,3.48-2.02,3.48-4.5S857.13989,652.25,855.22,652.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <path
                        d="M855.22,680.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H855.22c1.91992,0,3.48-2.02,3.48-4.5S857.13989,680.25,855.22,680.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <path
                        d="M246.591,428.11737c4.16234-6.26559,11.4863-8.92153,18.70849-9.56761,8.12915-.72722,15.984.84034,23.92086,2.36333,8.73488,1.67612,17.97938,3.24433,26.78082,1.1252a26.40082,26.40082,0,0,0,17.94625-14.49785,33.771,33.771,0,0,0,1.96625-22.51809c-2.06247-7.98628-7.18189-15.01346-13.388-20.32108-12.66675-10.83291-29.808-15.70142-46.295-15.24284a78.26922,78.26922,0,0,0-45.37458,15.95451,73.69726,73.69726,0,0,0-26.14492,40.97166,76.82857,76.82857,0,0,0,3.72036,48.324c3.60782,8.36937,8.8257,15.91461,12.852,24.07322,3.74876,7.59622,6.63582,16.428,4.30331,24.90078-2.04673,7.43473-8.05578,14.03982-15.59957,16.05835-7.18512,1.92255-14.27963-1.22877-18.21661-7.43678-3.77111-5.94647-3.86134-13.73512,1.80078-18.53636a13.8984,13.8984,0,0,1,20.05,2.00069,14.30627,14.30627,0,0,1,2.86169,10.48915c-.27893,1.88857,2.61132,2.70358,2.89284.79752a16.923,16.923,0,0,0-31.55308-10.92725c-3.41455,6.41994-1.57221,14.16342,2.76225,19.65111a19.1535,19.1535,0,0,0,19.52851,7.02234c7.88991-1.82216,14.34427-8.093,17.37957-15.462,3.37918-8.2038,1.83093-17.35864-1.46324-25.31936-3.56091-8.60531-8.84882-16.323-13.03059-24.61558a70.10576,70.10576,0,0,1-7.11365-23.95286,75.791,75.791,0,0,1,10.80937-48.0074,70.44136,70.44136,0,0,1,38.79846-29.487c15.80536-4.96142,33.2372-4.80947,48.71265,1.288,15.24373,6.0062,29.96334,19.09555,29.78659,36.67535-.0755,7.50948-2.86319,15.16772-8.61526,20.17651-6.87415,5.98589-16.44555,6.49759-25.08688,5.5634-8.69614-.94013-17.14086-3.47979-25.87454-4.12765-7.59644-.5635-15.79185.02234-22.57455,3.79644a22.571,22.571,0,0,0-7.841,7.27391c-1.07134,1.61269,1.52584,3.11667,2.59041,1.51415Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <path
                        d="M231.17322,678.957a7.00641,7.00641,0,0,1-1.05725-.08057l-14.59119-2.2207a7.00788,7.00788,0,0,1-5.86694-7.97363l18.5105-121.61279a7.008,7.008,0,0,1,7.97351-5.86719l14.59131,2.22119a7.00713,7.00713,0,0,1,5.86694,7.97314L238.08948,673.00977A7.01178,7.01178,0,0,1,231.17322,678.957Z"
                        transform="translate(-186.05005 -116.75)" fill="#fde047"></path>
                    <path
                        d="M248.61011,596.07568a7.03708,7.03708,0,0,1-1.48413-.15918l-27.205-5.47412a6.99946,6.99946,0,0,1-5.37536-8.31836l11.8396-39.4248a17.4836,17.4836,0,0,1,19.77294-12.18408l-.08679.49218.08679-.49169a17.46934,17.46934,0,0,1,14.31128,19.354l-5.01916,40.63476a7.01188,7.01188,0,0,1-6.84021,5.57129Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <path d="M377.90665,783.25h-157a1,1,0,0,1,0-2h157a1,1,0,0,1,0,2Z"
                          transform="translate(-186.05005 -116.75)" fill="#3f3d56"></path>
                  </svg>
                </div>
                <h4 class="text-md text-center font-semibold text-gray-600"> Your playlist does not contain any
                  categories </h4>

              </div>

            </div>

            <div class="overflow-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 h-full p-4" v-if="active_playlist_tab === 'bookings'">

              <div v-if="getBookings.length > 0">

                <div class="mb-3 text-left">
                  <div class="rounded-md bg-blue-50 p-3 text-xs">
                    <div class="flex-1 md:flex md:justify-between">
                      <p class="text-blue-700">
                        Your playlist is attached to {{ getBookings.length }} bookings
                      </p>
                    </div>
                  </div>
                </div>

                <div
                    :key="'bookings-' + i" v-for="(item, i) of getBookings"
                    class="grid grid-cols-5 gap-4 py-2 border-b border-gray-100">
                  <div>
                    <img
                        :alt="item.name"
                        class="rounded"
                        :src="'https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=120x120&markers=color:red%7Clabel:C%7C' + item.lat + ',' + item.lng + '&key=AIzaSyAENZy2SL14YFJdl4T9OiaPIgWgKejcCPg'" />
                  </div>
                  <div class="col-span-3">
                    <h4 class="flex-1 truncate">
                        <span class="font-semibold text-sm text-red-700">
                          {{ item.name }}
                        </span>
                    </h4>
                    <h4 class="text-xs font-semibold py-1">{{ item.date | date }}</h4>
                    <div class="text-xs flex-1 truncate">
                      <i class="el-icon-location"></i> {{ item.address }}
                    </div>
                  </div>
                  <div class="flex items-center justify-center">
                    <el-tooltip content="View Booking">
                      <el-button
                          @click="viewBooking(item.id)"
                          type="info"
                          size="small" icon="el-icon-view"></el-button>
                    </el-tooltip>
                  </div>
                </div>

              </div>
              <div v-else>

                <div class="mx-auto w-2/3 my-4 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-72" viewBox="0 0 827.8999 666.5"
                       xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path
                        d="M335.21814,555.90723a7.03378,7.03378,0,0,1-2.1438-.33692,6.95171,6.95171,0,0,1-4.09155-3.457l-6.76355-13.11816a7.00731,7.00731,0,0,1,3.014-9.4292l109.33728-56.37158A7.00756,7.00756,0,0,1,444,476.2085l6.76343,13.11816a7.00721,7.00721,0,0,1-3.01416,9.4292L338.41235,555.12744A6.95569,6.95569,0,0,1,335.21814,555.90723Z"
                        transform="translate(-186.05005 -116.75)" fill="#fde047"></path>
                    <path
                        d="M374.87971,504.79687a7.03706,7.03706,0,0,1,.74314,1.2945l11.55364,25.23071a6.99947,6.99947,0,0,1-3.5732,9.237l-38.859,13.582a17.48362,17.48362,0,0,1-21.47645-8.84216l.44928-.21893-.44888.21865a17.46934,17.46934,0,0,1,7.25337-22.95166l35.831-19.812a7.01189,7.01189,0,0,1,8.52709,2.262Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <path
                        d="M832.44995,438.75H645.26a17.65489,17.65489,0,0,1-11.57837-4.373L556.5791,366.375A14.47132,14.47132,0,0,0,546.99,362.75H480.45V133.25a16.51868,16.51868,0,0,1,16.5-16.5h319a16.51867,16.51867,0,0,1,16.5,16.5Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <circle cx="370.8999" cy="71.5" r="35" fill="#fff"></circle>
                    <path d="M692.94995,169.5h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
                          transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M787.30982,198H627.09009c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H787.30982c2.01025,0,3.64013-2.02,3.64013-4.5S789.32007,198,787.30982,198Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M774.72,258.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,258.25,774.72,258.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M774.72,286.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,286.25,774.72,286.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M774.72,314.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,314.25,774.72,314.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M774.72,342.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,342.25,774.72,342.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M987.45,438.75H645.26a17.37609,17.37609,0,0,1-9.585-2.86279l-.2251-.14795V202.25a16.51868,16.51868,0,0,1,16.5-16.5h319a16.51867,16.51867,0,0,1,16.5,16.5Z"
                        transform="translate(-186.05005 -116.75)" fill="#aa2e26"></path>
                    <circle cx="525.8999" cy="140.5" r="35" fill="#fff"></circle>
                    <path d="M847.95,238.5h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
                          transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M942.30982,267H782.09009c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H942.30982c2.01025,0,3.64013-2.02,3.64013-4.5S944.32007,267,942.30982,267Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M929.72,327.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,327.25,929.72,327.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M929.72,355.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,355.25,929.72,355.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M929.72,383.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,383.25,929.72,383.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M929.72,411.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,411.25,929.72,411.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#fff"></path>
                    <path
                        d="M996.95,436.25H645.26a14.94463,14.94463,0,0,1-9.31006-3.24c-.21-.16-.41015-.33-.60986-.51l-29.77-26.25L558.23023,364.5A16.97377,16.97377,0,0,0,546.99,360.25H460.95a17.02411,17.02411,0,0,0-17,17v389a17.02411,17.02411,0,0,0,17,17h536a17.0241,17.0241,0,0,0,17-17v-313A17.0241,17.0241,0,0,0,996.95,436.25Zm15,330a15.01828,15.01828,0,0,1-15,15h-536a15.01828,15.01828,0,0,1-15-15v-389a15.01828,15.01828,0,0,1,15-15h86.04a14.965,14.965,0,0,1,9.91992,3.75l45.64014,40.25L634.01,434a17.14841,17.14841,0,0,0,11.25,4.25H996.95a15.01828,15.01828,0,0,1,15,15Z"
                        transform="translate(-186.05005 -116.75)" fill="#3f3d56"></path>
                    <circle cx="103.25377" cy="326.07215" r="53.51916" fill="#fde047"></circle>
                    <path
                        d="M288.871,782.668H274.1117a7.00786,7.00786,0,0,1-7-7V652.6543a7.00785,7.00785,0,0,1,7-7H288.871a7.00785,7.00785,0,0,1,7,7V775.668A7.00786,7.00786,0,0,1,288.871,782.668Z"
                        transform="translate(-186.05005 -116.75)" fill="#fde047"></path>
                    <path
                        d="M320.871,782.668H306.1117a7.00786,7.00786,0,0,1-7-7V652.6543a7.00785,7.00785,0,0,1,7-7H320.871a7.00785,7.00785,0,0,1,7,7V775.668A7.00786,7.00786,0,0,1,320.871,782.668Z"
                        transform="translate(-186.05005 -116.75)" fill="#fde047"></path>
                    <path
                        d="M374.46724,625.41873c0,43.6305-41.04132,57.70416-84.67181,57.70416s-73.32819-14.07366-73.32819-57.70416,29.36951-118,73-118S374.46724,581.78824,374.46724,625.41873Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <path
                        d="M288.17937,462.65814l-.05566-2c3.7207-.10352,7.001-.33692,9.46582-2.1377a6.14794,6.14794,0,0,0,2.38134-4.52832,3.51432,3.51432,0,0,0-1.15283-2.89453c-1.63623-1.38183-4.269-.93457-6.188-.05469l-1.65478.75879,3.17334-23.19043,1.98144.27149-2.69921,19.72656c2.60742-.7666,5.02343-.43652,6.67822.96094a5.471,5.471,0,0,1,1.86035,4.49219,8.13264,8.13264,0,0,1-3.2002,6.07324C295.60222,462.45013,291.4865,462.56537,288.17937,462.65814Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <rect x="119.87932" y="313.83392" width="10.77148" height="2" fill="#2f2e41"></rect>
                    <rect x="85.87932" y="313.83392" width="10.77148" height="2" fill="#2f2e41"></rect>
                    <path
                        d="M883.95,714.25h-294a15.017,15.017,0,0,1-15-15v-162a15.017,15.017,0,0,1,15-15h294a15.017,15.017,0,0,1,15,15v162A15.017,15.017,0,0,1,883.95,714.25Zm-294-190a13.0149,13.0149,0,0,0-13,13v162a13.0149,13.0149,0,0,0,13,13h294a13.01489,13.01489,0,0,0,13-13v-162a13.01489,13.01489,0,0,0-13-13Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <circle cx="451.3999" cy="465.5" r="35" fill="#e6e6e6"></circle>
                    <path d="M773.44995,563.5h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
                          transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <path
                        d="M867.80982,592H707.59009c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H867.80982c2.01025,0,3.64013-2.02,3.64013-4.5S869.82007,592,867.80982,592Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <path
                        d="M855.22,652.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H855.22c1.91992,0,3.48-2.02,3.48-4.5S857.13989,652.25,855.22,652.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <path
                        d="M855.22,680.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H855.22c1.91992,0,3.48-2.02,3.48-4.5S857.13989,680.25,855.22,680.25Z"
                        transform="translate(-186.05005 -116.75)" fill="#e6e6e6"></path>
                    <path
                        d="M246.591,428.11737c4.16234-6.26559,11.4863-8.92153,18.70849-9.56761,8.12915-.72722,15.984.84034,23.92086,2.36333,8.73488,1.67612,17.97938,3.24433,26.78082,1.1252a26.40082,26.40082,0,0,0,17.94625-14.49785,33.771,33.771,0,0,0,1.96625-22.51809c-2.06247-7.98628-7.18189-15.01346-13.388-20.32108-12.66675-10.83291-29.808-15.70142-46.295-15.24284a78.26922,78.26922,0,0,0-45.37458,15.95451,73.69726,73.69726,0,0,0-26.14492,40.97166,76.82857,76.82857,0,0,0,3.72036,48.324c3.60782,8.36937,8.8257,15.91461,12.852,24.07322,3.74876,7.59622,6.63582,16.428,4.30331,24.90078-2.04673,7.43473-8.05578,14.03982-15.59957,16.05835-7.18512,1.92255-14.27963-1.22877-18.21661-7.43678-3.77111-5.94647-3.86134-13.73512,1.80078-18.53636a13.8984,13.8984,0,0,1,20.05,2.00069,14.30627,14.30627,0,0,1,2.86169,10.48915c-.27893,1.88857,2.61132,2.70358,2.89284.79752a16.923,16.923,0,0,0-31.55308-10.92725c-3.41455,6.41994-1.57221,14.16342,2.76225,19.65111a19.1535,19.1535,0,0,0,19.52851,7.02234c7.88991-1.82216,14.34427-8.093,17.37957-15.462,3.37918-8.2038,1.83093-17.35864-1.46324-25.31936-3.56091-8.60531-8.84882-16.323-13.03059-24.61558a70.10576,70.10576,0,0,1-7.11365-23.95286,75.791,75.791,0,0,1,10.80937-48.0074,70.44136,70.44136,0,0,1,38.79846-29.487c15.80536-4.96142,33.2372-4.80947,48.71265,1.288,15.24373,6.0062,29.96334,19.09555,29.78659,36.67535-.0755,7.50948-2.86319,15.16772-8.61526,20.17651-6.87415,5.98589-16.44555,6.49759-25.08688,5.5634-8.69614-.94013-17.14086-3.47979-25.87454-4.12765-7.59644-.5635-15.79185.02234-22.57455,3.79644a22.571,22.571,0,0,0-7.841,7.27391c-1.07134,1.61269,1.52584,3.11667,2.59041,1.51415Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <path
                        d="M231.17322,678.957a7.00641,7.00641,0,0,1-1.05725-.08057l-14.59119-2.2207a7.00788,7.00788,0,0,1-5.86694-7.97363l18.5105-121.61279a7.008,7.008,0,0,1,7.97351-5.86719l14.59131,2.22119a7.00713,7.00713,0,0,1,5.86694,7.97314L238.08948,673.00977A7.01178,7.01178,0,0,1,231.17322,678.957Z"
                        transform="translate(-186.05005 -116.75)" fill="#fde047"></path>
                    <path
                        d="M248.61011,596.07568a7.03708,7.03708,0,0,1-1.48413-.15918l-27.205-5.47412a6.99946,6.99946,0,0,1-5.37536-8.31836l11.8396-39.4248a17.4836,17.4836,0,0,1,19.77294-12.18408l-.08679.49218.08679-.49169a17.46934,17.46934,0,0,1,14.31128,19.354l-5.01916,40.63476a7.01188,7.01188,0,0,1-6.84021,5.57129Z"
                        transform="translate(-186.05005 -116.75)" fill="#2f2e41"></path>
                    <path d="M377.90665,783.25h-157a1,1,0,0,1,0-2h157a1,1,0,0,1,0,2Z"
                          transform="translate(-186.05005 -116.75)" fill="#3f3d56"></path>
                  </svg>
                </div>
                <h4 class="text-md text-center font-semibold text-gray-600">
                  Your playlist has no bookings attached
                </h4>

              </div>

            </div>

          </div>
        </div>
      </div>

    </template>

    <template slot="overlays">

      <small-modal
          title="Private Location Credentials"
          @close="() => credentials.show = false"
          :show-close="true"
          :show="credentials.show">
        <template slot="content">

          <div class="grid p-4 gap-3 col-span-1">
            <div>
              <el-alert :closable="false" type="error">
                <div class="flex gap-2">
                  <div>
                    <i class="el-icon-warning"></i>
                  </div>
                  <div>
                    It is very important that you do not share the credentials below; they are
                    specific to your location. We will never ask you for these credentials.
                  </div>
                </div>
              </el-alert>
            </div>
            <div>
              <p class="text-sm font-bold text-center">
                Download and install the Chargabull Android App - when
                prompted enter your credentials below.
              </p>
            </div>
            <div>
              <form-input label="Location ID">
                <el-input v-model="credentials.details.location_id" disabled />
              </form-input>
              <form-input label="Password">
                <el-input v-model="credentials.details.password" disabled />
              </form-input>
            </div>
          </div>

        </template>
      </small-modal>


      <playlist-preview
          title="Preview Playlist"
          @close="()=> { preview.show = false }"
          :videos="getPreviewList()"
          :show="preview.show" />

      <conflict-resolver
          :show="conflicts.show"
          @preview="(o) => { video_preview.object = o; video_preview.open = true }"
          @close="()=> { conflicts.show = false;  }"
          :conflicts="buildBookingConflicts()"
          @remove="(id) => removePlaylistItemById(id)"
      />

      <alert-confirm
        :show="confirm.status.open"
        @cancel="confirm.status.open = false"
        @confirm="() => callUpdateStatus(confirm.status.status)"
        :confirm-text="'Yes, move to draft'"
        @close="()=> { confirm.status.open = false }"
      >
        <template slot="title">
          Move Playlist to Drafts?
        </template>
        <template slot="message">
          Are you sure you want to move this playlist to drafts?
          All attached bookings will be removed.
        </template>
      </alert-confirm>


      <alert-confirm
          :show="confirm.delete.open"
          @cancel="confirm.delete.open = false"
          @confirm="() => deletePlaylist()"
          :loading="confirm.delete.loading"
          :confirm-text="'Yes, delete playlist'"
          @close="()=> { confirm.delete.open = false }"
      >
        <template slot="title">
          Delete Playlist?
        </template>
        <template slot="message">
          Are you sure you want to delete this playlist? This cannot be undone.
        </template>
      </alert-confirm>

      <alert-confirm
          :show="confirm.removeAll.open"
          @cancel="confirm.removeAll.open = false"
          @confirm="() => removeAll()"
          :confirm-text="'Yes, remove all'"
          @close="()=> { confirm.removeAll.open = false }"
      >
        <template slot="title">
          Delete Playlist Items?
        </template>
        <template slot="message">
          Are you sure you want to delete all the playlist items? This cannot be undone and if it is live will go live
          immediately.
        </template>
      </alert-confirm>

      <alert-confirm
          :show="confirm.delete.open"
          @cancel="confirm.delete.open = false"
          @confirm="() => removeItem()"
          :loading="confirm.delete.loading"
          :confirm-text="'Yes, delete item'"
          @close="()=> { confirm.delete.open = false }"
      >
        <template slot="title">
          Delete Playlist Item?
        </template>
        <template slot="message">
          Are you sure you want to delete this playlist item? This cannot be undone and it will be removed.
        </template>
      </alert-confirm>

      <alert-confirm
          :show="conflicts.alert_open"
          @cancel="() => conflicts.alert_open = false"
          @confirm="() => openIssueResolver()"
          confirm-text="Resolve issues"
          @close="()=> { conflicts.alert_open = false }"
      >
        <template slot="title">
          Your playlist has conflicts with your bookings
        </template>
        <template slot="message">
          It looks like your playlist items are conflicting with an attached booking. Please resolve these or
          your media item will not be shown.
        </template>
      </alert-confirm>

      <titled-overlay
          :title="edit.model.name + (edit.model.file_extension ? '.' + edit.model.file_extension : '')"
          @close="onEditClose"
          :show="edit.visible">
        <template slot="content">

          <div class="md:flex h-full items-stretch overflow-scroll md:overflow-hidden">

            <div
                :style="{
                                'background-color': edit.model.bg_color
                                }"
                class="hidden md:block w-full flex items-center border-r relative border-gray-200 h-full w-96">

              <playlist-item-q-r-code
                  @change="(val) => edit.model.qr_code_location = val"
                  :enabled="edit.model.qr_code_enabled"
                  :text="edit.model.qr_code_text"
                  :position="edit.model.qr_code_location"
                  :url="edit.model.qr_code_url"
              />

              <div class="flex justify-center items-center h-full w-full">
                <video
                    autoplay
                    loop
                    muted
                    class="z-10 h-auto"
                >
                  <source
                      :src="edit.model.remote_src"
                  />
                </video>
              </div>

            </div>

            <div class="w-full relative flex-grow h-full">

              <div class="z-40 left-0 bottom-0 w-full fixed md:absolute">
                <div class="bg-gray-100 p-3 border-t border-gray-300 flex justify-end">
                  <el-button @click="edit.visible = false">
                    Close
                  </el-button>
                  <el-button
                      @click="downloadFile(edit.model.remote_src)"
                      type="info" icon="el-icon-download">
                    Download
                  </el-button>
                  <el-button
                      @click="() => {  confirmRemoveItem(edit.model.index) }"
                      type="danger" icon="el-icon-delete">
                    Remove
                  </el-button>
                </div>
              </div>

              <custom-tabs
                  split="w-1/2"
                  v-model="active_settings_tab"
                  :tabs="settings_tabs"
              />

              <div class="p-4 details--scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 pb-20 md:pb-0">
                <div v-if="active_settings_tab === 'details'">
                  <div class="grid gap-3">
                    <div v-if="edit.model.disable_media">
                      <div class="bg-orange-500 p-3 text-orange-100 rounded text-xs">
                        This media is currently disabled. It will <strong>NOT</strong> be played in your playlist
                      </div>
                    </div>
                    <form-input tooltip="Set the background colour when your media is playing" label="Background Colour"
                                :required="false">
                      <el-color-picker
                          :predefine="edit.model.predefined_colors"
                          :disabled="edit.updating"
                          v-model="edit.model.bg_color"></el-color-picker>
                    </form-input>
                    <form-input tooltip="Set a custom duration<br /> in seconds when playing"
                                label="Enable Custom Duration" :required="false">
                      <el-switch
                          v-model="edit.model.custom_duration_enabled"
                      />
                    </form-input>
                    <form-input v-if="edit.model.custom_duration_enabled" label="Custom Duration"
                                :required="false">
                      <el-input-number :min="0" :max="edit.model.duration_secs > 15 ? 15 : edit.model.duration_secs"
                                       v-model="edit.model.custom_duration"/>
                    </form-input>

                    <form-input label="Media Categories" label-position="start">
                      <div v-if="edit.model.categories && edit.model.categories.length > 0">
                        <div class="relative flex items-start my-3"
                             :key="'restrictions-' + k"
                             v-for="(x, k) of edit.model.categories">
                          <div class="ml-3 text-sm">
                            <label :for="'restriction-' + k" class="font-medium text-gray-700"><i
                                class="el-icon-close text-red-500"></i> {{ x.type }}</label>
                            <p class="text-gray-500">
                              {{ x.description }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <el-alert type="success" effect="dark" :closable="false">
                          This video has not been placed in any categories
                        </el-alert>
                      </div>
                    </form-input>

                  </div>

                </div>
                <div v-if="active_settings_tab === 'availability'">

                  <div v-if="edit.model.disable_media">
                    <div class="bg-orange-500 p-3 text-orange-100 rounded text-xs">
                      This media is currently disabled. It will <strong>NOT</strong> be played in your playlist
                    </div>
                  </div>

                  <div class="ml-2">
                    <form-input
                        tooltip="Disabled media will not be visible in the playlist"
                        label="Disable Media" :required="false">
                      <el-switch
                          v-model="edit.model.disable_media"
                      />
                    </form-input>
                  </div>

                  <div v-if="!edit.model.disable_media">
                    <custom-tabs
                        split="w-1/2"
                        v-model="active_availability_tab"
                        :tabs="availability_tabs"/>

                    <div class="m-5">
                      <div v-if="active_availability_tab === 'days'">

                        <div class="mb-5">
                          <el-alert
                              :closable="false"
                              effect="dark" type="warning">
                            When setting custom operating times, please be mindful of the local time of the booking you are looking to apply this media item to.
                          </el-alert>
                        </div>

                        <div class="mt-0" v-if="!edit.model.disable_media">
                          <div
                              v-for="(i, k) of edit.model.availability"
                              :key="'availability-' + k"
                              class="relative flex items-start border border-gray-100">
                            <div class="p-3">
                              <input :id="'availability-' + k"
                                     :name="'availability-' + k"
                                     v-model="i.day_enabled"
                                     aria-describedby="comments-description"
                                     type="checkbox"
                                     class="focus:ring-green-700 h-4 w-4 text-green-700 border-gray-300 rounded">
                            </div>
                            <div class="ml-3 p-3 pl-0 w-32 select-none">
                              <label :for="'availability-' + k" class="font-medium text-gray-700">
                                {{ i.label }}
                              </label>
                            </div>
                            <div class="ml-3 p-3 flex-grow">
                              <div
                                  class="flex items-center select-none gap-3 cursor-pointer">
                                <span @click="i.custom_hours = !i.custom_hours" class="text-sm">Enable custom hours</span>
                                <el-switch
                                    active-color="#32a852"
                                    inactive-color="#DC2626"
                                    :disabled="!i.day_enabled" v-model="i.custom_hours"/>
                              </div>

                              <div class="py-3" v-if="i.custom_hours && i.day_enabled">

                                <div
                                    :key="'time-' + ti"
                                    v-for="(time, ti) of i.custom">
                                  <custom-time-select
                                      @delete="() => removeCustomHour(ti, k)"
                                      v-model="time.times"/>
                                </div>

                                <div class="text-right">
                                  <el-button
                                      @click="addCustomHours(k)"
                                      type="success" size="small" icon="el-icon-plus">Add Hours
                                  </el-button>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="active_availability_tab === 'weather'">

                        <div class="mb-5">
                          <el-alert
                              :closable="false"
                              effect="dark" type="info">
                            Below you can choose which weather conditions to display your media items, allow you to display different adverts for
                            different conditions - Simply deselect the ones you do not want the media to display for.
                          </el-alert>
                        </div>

                        <div class="flex justify-end mb-5">
                          <el-button-group>
                            <el-button
                                @click="toggleWeatherSelection(true)"
                                type="success" size="small">Select All</el-button>
                            <el-button
                                @click="toggleWeatherSelection(false)"
                                type="danger" size="small">Select None</el-button>
                          </el-button-group>
                        </div>

                        <div class="grid grid-cols-2 border border-gray-100">
                          <div class="col-span-2 md:col-span-1 border-b border-gray-100"  v-for="(i, k) of edit.model.weather_availability"
                               :key="'weather-availability-list-' + k">
                            <div class="flex items-center w-full px-4">
                              <div>
                                <input :id="'weather-availability-' + k"
                                       :name="'weather-availability-' + k"
                                       v-model="i.enabled"
                                       type="checkbox"
                                       class="focus:ring-green-700 h-4 w-4 text-green-700 border-gray-300 rounded">
                              </div>
                              <div class="ml-2 p-2 pl-0 flex items-center gap-2 flex-grow">
                                <label :for="'weather-availability-' + k" class="font-medium text-gray-700 cursor-pointer select-none ">
                                  <img
                                      class="w-12"
                                      :src="'/imgs/icons/met-office-codes/' + i.code + '.svg'"
                                  />
                                </label>
                                <label :for="'weather-availability-' + k" class="font-medium text-gray-700 cursor-pointer select-none ">
                                  {{ i.label }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div>
                      </div>
                    </div>
                  </div>

                </div>
                <div v-if="active_settings_tab === 'qr_codes'">

                  <div v-if="edit.model.disable_media">
                    <div class="bg-orange-500 p-3 text-orange-100 rounded text-xs">
                      This media is currently disabled. It will <strong>NOT</strong> be played in your playlist
                    </div>
                  </div>

                  <form-input tooltip="Add a QR code that users can scan" label="Enable QR Code" :required="true">
                    <el-switch
                        v-model="edit.model.qr_code_enabled"
                    />
                  </form-input>

                  <div v-if="edit.model.qr_code_enabled">

                    <!--
                    <form-input label="QR Code Location" :required="false">
                      <el-select
                          filterable
                          placeholder="Select QR code location..."
                          v-model="edit.model.qr_code_location" class="w-full">
                        <el-option
                            v-for="(item, index) in positions"
                            :key="index + '-position'"
                            :label="item.label"
                            :value="item.value"
                        >
                          {{ item.label }}
                        </el-option>
                      </el-select>

                    </form-input>
                    -->
                    <form-input label="QR code text" :required="false">
                      <el-input
                        maxlength="20"
                        show-word-limit
                        v-model="edit.model.qr_code_text"/>
                    </form-input>
                    <form-input label="Landing Page" :required="true">
                      <el-input v-model="edit.model.qr_code_url"/>
                    </form-input>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </template>
      </titled-overlay>

      <media-preview
          @close="() => video_preview.open = false"
          :bg-color="video_preview.object.bg_color"
          :video-source="video_preview.object.remote_src"
          :show="video_preview.open"
      />

    </template>

  </page-wrapper>
</template>

<script>

import { getQRCodePositions } from '@/utils/qr-codes';
import { debounce } from 'debounce';

import Draggable from 'vuedraggable';
import SpinLoader from '@/components/SpinLoader';
import EmptyState from '@/components/EmptyState';
import TitledOverlay from '@/components/TitledOverlay';
import CustomTabs from '@/components/CustomTabs';
import FormInput from '@/components/FormInput';
import AlertConfirm from '@/components/AlertConfirm';
import PlaylistPreview from '@/components/PlaylistPreview';
import ConflictResolver from '@/components/ConflictResolver';
import MediaPreview from '@/components/MediaPreview';
import CustomTimeSelect from '@/components/CustomTimeSelect';
import PlaylistItemQRCode from '@/components/PlaylistItemQRCode';
import PlaylistListItem from '@/components/PlaylistListItem';
import SmallModal from '@/components/SmallModal';

export default {
  name: 'PlaylistComponent',
  props: {
    playlistId: {
      type: String,
      default() {
        return null;
      },
    },
    isPrivate: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    SmallModal,
    PlaylistListItem,
    PlaylistItemQRCode,
    CustomTimeSelect,
    MediaPreview,
    ConflictResolver,
    PlaylistPreview,
    AlertConfirm,
    FormInput,
    CustomTabs,
    TitledOverlay,
    EmptyState,
    SpinLoader,
    Draggable,
  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      positions: getQRCodePositions(),
      video_preview: {
        open: false,
        object: {
          bg_color: null,
          remote_src: null,
        },
      },
      preview: {
        show: false,
      },
      conflicts: {
        show: false,
        alert_open: false,
        alert_shown: false,
      },
      active_settings_tab: 'details',
      active_playlist_tab: 'media',
      settings_tabs: [
        { value: 'details', name: 'Details & Settings' },
        { value: 'availability', name: 'Availability' },
        { value: 'qr_codes', name: 'QR Code' },
      ],
      active_availability_tab: 'days',
      availability_tabs: [
        { value: 'days', name: 'Days & Times' },
        { value: 'weather', name: 'Weather' },
      ],
      confirm: {
        delete: {
          open: false,
          loading: false,
          index: null,
        },
        status: {
          open: false,
          loading: false,
          status: null,
        },
         removeAll: {
          open: false,
        },
      },
      edit: {
        details: {
          name: false,
        },
        visible: false,
        model: {
          bg_color: null,
          predefined_colors: [],
          qr_code_enabled: true,
        },
      },
      object: {
        name: '',
        status: '',
        bg_color: '#000000',
        booking_restrictions: [],
        bookings: [],
      },
      updating: false,
      loading: true,
      clonedItemOptions: {
        group: 'playlist',
      },
      credentials: {
        show: false,
        loading: false,
        details: {},
      },
      availableItemOptions: {
        handle: '.is-file-object',
        group: {
          name: 'playlist',
          pull: 'clone',
          put: false,
        },
        sort: false,
      },
      library: {
        results: [],
        path: [],
      },
      active_folder_id: null,
      search: {
        term: '',
        loading: true,
      },
      active_index: -1,
      items: [],
    };
  },
  watch: {
    items() {
      this.update();
    },
    'edit.model': {
      deep: true,
      handler(val) {
        if (val) {
          if (val) {
            if (this.edit.model.index > -1) {
              this.items[this.edit.model.index] = JSON.parse(JSON.stringify(val));
              this.update();
            }
          }
        }
      },
    },
    'edit.details.name': function (bool) {
      const vm = this;
      if (!bool) {
        if (vm.object.name.length === 0) {
          vm.object.name = 'My Playlist';
        }
        vm.$nextTick(() => {
          vm.update();
        });
      }
    },
  },
  computed: {
    getBookings() {
      return this.object.bookings && Array.isArray(this.object.bookings) ? this.object.bookings : [];
    },
    getCategoryCount() {
      return this.getCategories().length;
    },
    getPlaylistTabs() {
      let items = [
        { value: 'media', name: 'Media Library' },
      ];

      if (!this.isPrivate) {
        items = items.concat([
          { value: 'categories', name: `Categories (${this.getCategoryCount})` },
          { value: 'bookings', name: `Bookings (${this.getBookings.length})` },
        ]);
      }
      return items;
    },
    isSearchMode() {
      return this.search.term.length > 0;
    },
    getFolderName() {
      const path = JSON.parse(JSON.stringify(this.library.path));
      if (path && path.length > 0) {
        const item = path[path.length - 1];
        if (item && item.name) {
          return item.name;
        }
      }
      return null;
    },
    getDropDownMenuOptions() {
      const path = JSON.parse(JSON.stringify(this.library.path));
      if (path.length > 0) {
        path.splice(path.length - 1, 1);
        return path.reverse();
      }
      return [];
    },
  },
  methods: {
    launchCredentials() {
      const vm = this;
      if (!vm.credentials.loading && vm.isPrivate) {
        vm.credentials.show = true;
      }
    },
    toggleWeatherSelection(bool) {
      const copy = JSON.parse(JSON.stringify(this.edit.model.weather_availability));
      this.edit.model.weather_availability = copy.map((x) => {
        x.enabled = bool;
        return x;
      });
    },
    viewBooking(id) {
      return this.$router.push(`/bookings/${id}`);
    },
    hasCustomWeatherAvailability(o) {
      if (o.disable_media) {
        return false;
      }
      if (o.weather_availability && Array.isArray(o.weather_availability)) {
        const avail = o.weather_availability.filter((x) => !x.enabled).length;
        if (avail > 0) {
          return true;
        }
      }
      return false;
    },
    hasCustomAvailability(o) {
      if (o.disable_media) {
        return false;
      }
      if (o.availability && Array.isArray(o.availability)) {
        const avail = o.availability.filter((x) => {
          return x.day_enabled === false || (
            x.custom_hours === true && (x.custom && Array.isArray(x.custom) && x.custom.length > 0)
          );
        }).length;
        if (avail > 0) {
          return true;
        }
      }
      return false;
    },
    removeCustomHour(listIndex, index) {
      if (listIndex !== null && listIndex > -1) {
        this.edit.model.availability[index].custom.splice(listIndex, 1);
      }
    },
    addCustomHours(index) {
      this.edit.model.availability[index].custom.push({
        times: {
          from: '07:00',
          to: '21:00',
        },
      });
    },
    duplicate() {
      const vm = this;
      if (vm.isPrivate) {
        return;
      }
      vm.loading = true;
      return vm.$http.post(`/api/v1/playlist/${this.playlistId}/duplicate`)
        .then((response) => {
          window.location = `/playlists/${response.data.data.id}`;
        }).catch(() => {
          vm.loading = false;
        });
    },
    removePlaylistItemById(id) {
      this.items = JSON.parse(JSON.stringify(this.items.filter((x) => x.id !== id).map((x) => x)));
    },
    openIssueResolver() {
      this.conflicts.alert_open = false;
      this.conflicts.show = true;
    },
    buildBookingConflicts() {
      const vm = this; const
        categories = vm.getCategories();
      const items = vm.object.booking_restrictions.map((x) => {
        x.filtered_restrictions = categories.filter((y) => {
          const restrictionIds = x.restrictions.map((z) => z.id);
          return restrictionIds.indexOf(y.id) > -1;
        });
        return x;
      }).filter((x) => x.filtered_restrictions.length > 0);

      if (items.length > 0 && !vm.conflicts.alert_shown) {
        vm.conflicts.alert_shown = true;
        vm.conflicts.alert_open = true;
      }

      return items;
    },
    getCategories() {
      const items = this.items.filter((x) => x.categories && Array.isArray(x.categories) && x.categories.length > 0).map((x) => ({
        id: x.id,
        categories: x.categories,
      })); const
        categories = {};

      for (const x of items) {
        for (const c of x.categories) {
          if (!categories[c.id]) {
            categories[c.id] = {
              id: c.id,
              type: c.type,
              description: c.description,
              ids: [],
            };
          }
          categories[c.id].ids.push(x.id);
        }
      }

      return Object.values(categories).map((x) => {
        x.count = x.ids.length;
        x.ids = x.ids.filter((v, i, a) => a.indexOf(v) === i).map((x) => {
          const find = this.items.find((t) => t.id === x);
          return {
            id: find.id,
            name: find.name,
            file_extension: find.file_extension,
            screenshot_src: find.screenshot_src,
            remote_src: find.remote_src,
            bg_color: find.bg_color ? find.bg_color : this.object.bg_color,
            media_type: find.media_type,
          };
        });
        return x;
      });
    },
    getPreviewList() {
      return this.items.map((x) => ({
        name: x.name + (x.file_extension ? `.${x.file_extension}` : ''),
        custom_duration: x.custom_duration_enabled && x.custom_duration ? x.custom_duration : null,
        duration: x.duration_secs,
        screenshot_src: x.screenshot_src,
        bg_color: x.bg_color,
        media_type: x.media_type,
        src: x.remote_src,
        disable_media: x.disable_media,
        qr_code_enabled: x.qr_code_enabled,
        qr_code_text: x.qr_code_text ? x.qr_code_text : null,
        qr_code_url: x.qr_code_url ? x.qr_code_url : null,
        qr_code_location: x.qr_code_enabled ? x.qr_code_location : 'BOTTOM-RIGHT',
      })).filter((x) => !x.disable_media);
    },
    deletePlaylist() {
      const vm = this;
      if (vm.isPrivate) {
        return;
      }
      vm.confirm.delete.loading = true;
      vm.loading = true;
      vm.$http.delete(`/api/v1/playlist/${this.playlistId}`).then(() => {
        vm.$router.push('/playlists');
        this.$notify({
          type: 'success',
          offset: 65,
          title: 'Playlist Deleted Successfully',
          message: 'Your playlist has been deleted successfully.',
          position: 'top-right',
        });
      }).catch(() => {
        vm.$router.push('/playlists');
      });
    },
    fetchPrivateCredentials() {
      const vm = this;
      if (!vm.isPrivate) {
        return;
      }
      vm.credentials.loading = true;
      vm.$http.get(`/api/v1/private-locations/${this.playlistId}/credentials`).then((response) => {
        vm.credentials.details = response.data.data;
        vm.credentials.loading = false;
      }).catch(() => {

      });
    },
    fetch() {
      const vm = this;
      vm.loading = true;
      vm.fetchPrivateCredentials();
      vm.$http.get(`/api/v1/playlist/${this.playlistId}`).then((response) => {
        if (response.data.data.is_private_location_playlist && !vm.isPrivate) {
          vm.$router.push('/playlists');
          return;
        }

        vm.object.name = response.data.data.name;
        vm.object.status = response.data.data.status;
        vm.object.bookings = response.data.data.bookings;
        vm.object.booking_restrictions = response.data.data.booking_restrictions && Array.isArray(response.data.data.booking_restrictions) ? response.data.data.booking_restrictions : [];
        vm.items = response.data.data.items;
        vm.fetchFolder();
        vm.loading = false;
      }).catch(() => {
        vm.$router.push('/playlists');
      });
    },
    handleMoreCommand(command) {
      switch (command) {
        case 'delete':
          this.confirm.delete.open = true;
          break;
        case 'duplicate':
          this.duplicate();
          break;
      }
    },
    getTotalDurationSeconds() {
      return this.items.filter((x) => x.duration_secs).map((o) => (o.custom_duration_enabled && o.custom_duration ? o.custom_duration : o.duration_secs)).reduce((partialSum, a) => partialSum + a, 0);
    },
    onEditClose() {
      this.edit.visible = false;
    },
    removeAll() {
      this.confirm.removeAll.open = false;
      this.items = [];
    },
    confirmRemoveItem(index) {
      this.confirm.delete.index = index;
      this.confirm.delete.open = true;
    },
    removeItem() {
      this.edit.visible = false;
      this.items.splice(this.confirm.delete.index, 1);
      this.confirm.delete.index = null;
      this.confirm.delete.open = false;
    },
    downloadFile(url) {
      window.open(url);
    },
    onListItemClick(item, index) {
      const vm = this;
      vm.edit.model = JSON.parse(JSON.stringify(item));
      vm.edit.model.index = index;
      vm.active_availability_tab = 'days';
      vm.active_settings_tab = 'details';
      vm.edit.visible = true;
    },
    onClickOutside(e) {
      this.edit.details.name = !!(e.target && e.target.dataset && e.target.dataset.ref && e.target.dataset.ref === 'edit-name');
    },
    onEditNameClick() {
      const vm = this;
      vm.edit.details.name = true;
      this.$nextTick(() => {
        vm.$refs.editName.focus();
      });
    },
    onFolderSelect(x) {
      this.active_folder_id = x;
      this.fetchFolder();
    },
    onItemClick(i) {
      if (i.type === 'folder') {
        this.active_folder_id = i.id;
        this.fetchFolder();
        this.search.term = '';
      }
    },
    fetchFolder() {
      const vm = this;
      vm.search.loading = true;

      const object = new URLSearchParams();
      let path = '/api/v1/folder';

      if (!vm.isPrivate) {
        object.append('status', 'ACTIVE');
      }

      if (this.active_folder_id) {
        object.append('folderId', this.active_folder_id);
      }

      path += `?${object.toString()}`;

      return vm.$http.get(path).then((response) => {
        //  vm.path = response.data.data.path;
        vm.library.results = response.data.data.contents.map((x) => {
          if (x.type === 'file') {
            x.custom_duration_enabled = false;
            x.bg_color = vm.object.bg_color;
            x.disable_media = false;
            x.availability = [
              {
                id: 'MON', label: 'Monday', day_enabled: true, custom_hours: false, custom: [],
              },
              {
                id: 'TUES', label: 'Tuesday', day_enabled: true, custom_hours: false, custom: [],
              },
              {
                id: 'WEDS', label: 'Wednesday', day_enabled: true, custom_hours: false, custom: [],
              },
              {
                id: 'THURS', label: 'Thursday', day_enabled: true, custom_hours: false, custom: [],
              },
              {
                id: 'FRI', label: 'Friday', day_enabled: true, custom_hours: false, custom: [],
              },
              {
                id: 'SAT', label: 'Saturday', day_enabled: true, custom_hours: false, custom: [],
              },
              {
                id: 'SUN', label: 'Sunday', day_enabled: true, custom_hours: false, custom: [],
              },
            ];
            x.weather_availability = [
              { code: 0, label: 'Clear night', enabled: true },
              { code: 1, label: 'Sunny day', enabled: true },
              { code: 2, label: 'Partly cloudy (night)', enabled: true },
              { code: 3, label: 'Partly cloudy (day)', enabled: true },
              { code: 4, label: 'Dust', enabled: true },
              { code: 5, label: 'Mist', enabled: true },
              { code: 6, label: 'Fog', enabled: true },
              { code: 7, label: 'Cloudy', enabled: true },
              { code: 8, label: 'Overcast', enabled: true },
              { code: 9, label: 'Light rain shower (night)', enabled: true },
              { code: 10, label: 'Light rain shower (day)', enabled: true },
              { code: 11, label: 'Drizzle', enabled: true },
              { code: 12, label: 'Light rain', enabled: true },
              { code: 13, label: 'Heavy rain shower (night)', enabled: true },
              { code: 14, label: 'Heavy rain shower (day)', enabled: true },
              { code: 15, label: 'Heavy rain', enabled: true },
              { code: 16, label: 'Sleet shower (night)', enabled: true },
              { code: 17, label: 'Sleet shower (day)', enabled: true },
              { code: 18, label: 'Sleet', enabled: true },
              { code: 19, label: 'Hail shower (night)', enabled: true },
              { code: 20, label: 'Hail shower (day)', enabled: true },
              { code: 21, label: 'Hail', enabled: true },
              { code: 22, label: 'Light snow shower (night)', enabled: true },
              { code: 23, label: 'Light snow shower (day)', enabled: true },
              { code: 24, label: 'Light snow', enabled: true },
              { code: 25, label: 'Heavy snow shower (night)', enabled: true },
              { code: 26, label: 'Heavy snow shower (day)', enabled: true },
              { code: 27, label: 'Heavy snow', enabled: true },
              { code: 28, label: 'Thunder shower (night)', enabled: true },
              { code: 29, label: 'Thunder shower (day)', enabled: true },
              { code: 30, label: 'Thunder', enabled: true },
            ];
          }

          return x;
        });
        vm.library.path = response.data.data.path;
        vm.search.loading = false;
      }).catch(() => {
        vm.library.results = [];
        vm.library.path = [];
        vm.search.loading = false;
      });
    },
    updateStatus(status) {
      const vm = this;
      if (vm.isPrivate) {
        return;
      }

      if(status === "DRAFT"){
        vm.confirm.status.status = status;
        vm.confirm.status.open = true;
        return;
      }
      this.callUpdateStatus(status);
    },
    callUpdateStatus(status){
      const vm = this;
      vm.loading = true;
      vm.confirm.status.open = false;
      return vm.$http.put(`/api/v1/playlist/${this.playlistId}/status`, {
        status,
      }).then(() => {
        vm.loading = false;
        vm.fetch();
        setTimeout(() => {
          vm.$nextTick(() => {
            vm.$notify({
              type: 'success',
              offset: 65,
              title: 'Playlist Status Updated Successfully',
              message: 'Your playlist status has been updated successfully.',
              position: 'top-right',
            });
          })
        }, 500)
      }).catch(() => {
        vm.loading = false;
        vm.fetch();
      });
    },
    update() {
      const vm = this;
      const items = JSON.parse(JSON.stringify(vm.items));

      vm.updating = true;

      return vm.$http.put(`/api/v1/playlist/${this.playlistId}`, {
        name: vm.object.name,
        items: items.map((x) => ({
          id: x.id,
          bg_color: x.bg_color,
          custom_duration: x.custom_duration_enabled ? x.custom_duration : null,
          disable_media: x.disable_media,
          qr_code_enabled: x.qr_code_enabled,
          qr_code_text: x.qr_code_text ? x.qr_code_text : null,
          qr_code_url: x.qr_code_url ? x.qr_code_url : null,
          qr_code_location: x.qr_code_enabled ? x.qr_code_location : 'BOTTOM-RIGHT',
          weather_availability: (Array.isArray(x.weather_availability) ? x.weather_availability : []).filter((x) => !x.enabled).map((x) => x.code),
          availability: (Array.isArray(x.availability) ? x.availability : []).map((x) => {
            x.custom = x.custom
              .filter((x) => x !== null)
              .map((y) => y.times);
            return x;
          }),
        })),
      }).then(() => {
        vm.updating = false;
      }).catch(() => {
        vm.updating = false;
      });
    },
    performSearch() {
      const vm = this;
      vm.search.loading = true;
      const path = `/api/v1/media?q=${this.search.term}&status=ACTIVE`;
      return vm.$http.get(path).then((response) => {
        vm.library.results = response.data.data.map((x) => {
          if (x.type === 'file') {
            x.custom_duration_enabled = false;
          }
          return x;
        });
        vm.search.loading = false;
      }).catch(() => {
        vm.library.results = [];
        vm.search.loading = false;
      });
    },
    debounceInput: debounce((vm, e) => {
      vm.search.term = e.target.value;
      if (vm.search.term.length > 0) {
        vm.performSearch();
      } else {
        vm.active_folder_id = null;
        vm.fetchFolder();
      }
    }, 500),

  },
};
</script>
<style>

.el--scroll-settings-inner {
  height: calc(100vh - 6rem);
}

.el--scroll-files {
  height: calc(100vh - 16rem)
}

.el-block--drop-empty li {
  display: none !important;
}

.el--button-custom-dropdown {
  border-color: transparent !important;
  padding: 5px !important;
}

.video-height {
  min-height: 32rem;
}

.el-select--time-picker-custom {
  width: 100% !important;
}
</style>
