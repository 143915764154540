<template>
    <div>
        <div class="mb-6" aria-hidden="true">
            <div class="bg-gray-200 rounded-full overflow-hidden">
                <div class="h-2 bg-blue-600 rounded-full"
                     :style="{
                     'width': (33.3 * activeStep) + '%'
                     }"></div>
            </div>
            <div class="grid grid-cols-3 text-sm font-medium text-gray-400 mt-2 mb-5 select-none">
                <div
                        @click="activeStep = 1"
                        class="text-left cursor-pointer" :class="{
                'text-blue-600': activeStep >= 1
                }">
                    1. Select Dates
                </div>
                <div class="text-center" :class="{
                'text-blue-600': activeStep >= 2
                }">2. Confirm
                </div>
                <div class="text-right" :class="{
                'text-blue-600': activeStep >= 3
                }">3. Book
                </div>
            </div>
        </div>

        <div class="mb-24" v-show="activeStep === 1">
            <calendar-select
                    ref="calendarSelect"
                    :pod-id="value.id"
                    @event-select="onEventSelect"
                    @total-days="(total) => days = total"
                    @selected-days="onDaysSelected"
            />

            <div class="absolute bottom-0 w-full left-0 p-4 bg-gray-200 shadow border-t border-gray-200" >
                <div class="flex w-full  items-center">
                    <div class="w-auto flex-grow">
                        <h4 class="font-bold">{{ days.toLocaleString() }} days selected</h4>
                    </div>
                    <div>
                        <el-button
                                @click="changeStep(2)"
                                :disabled="!days"
                                type="success" icon="el-icon-date">Confirm Dates
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-24" v-show="activeStep === 2">

            <div class="h-64 w-full" v-loading="true" v-if="calculating">
            </div>

            <div v-else>

                <div class="mt-8 flex flex-col" v-if="totals.total_slots && totals.total_slots > 0">

                    <custom-tabs
                            split="w-1/2"
                            v-model="active_booking_tab"
                            :tabs="buildTabs"
                    />

                    <div class="mt-6">

                        <div v-if="active_booking_tab === 'dates'">

                            <div class="mb-4">
                                <el-alert :closable="false" v-if="totals.total_slots && totals.total_slots > 0">
                                    <div class="text-sm gap-2 flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
                                      </svg>
                                      <span>
                                      {{ totals.total_slots }} slots selected. <span v-if="totals.discount_percentage">{{ totals.discount_percentage }}% discount applied.</span>
                                        <span v-if="totals.next_break">Select {{ totals.slots_to_next_break }} more slot{{ totals.slots_to_next_break > 1 ? 's' : '' }} to qualify for <span class="font-bold text-md">{{ totals.next_break }}%</span> discount.</span>

                                      </span>
                                  </div>
                                </el-alert>
                            </div>

                            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-300 border-b border-gray-300">
                                    <thead class="bg-gray-200 shadow border-t border-gray-200">
                                    <tr>
                                        <th scope="col"
                                            class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Date
                                        </th>
                                        <th scope="col"
                                            class="hidden sm:table-cell whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900  text-right">
                                            Price <small>per slot</small></th>
                                        <th scope="col"
                                            class="whitespace-nowrap px-2 py-3.5 text-center text-sm w-10 font-semibold text-gray-900">
                                            Slots
                                        </th>
                                        <th scope="col"
                                            class="whitespace-nowrap px-2  py-3.5 text-left text-sm font-semibold text-gray-900  text-right">
                                            Est. Plays
                                        </th>
                                        <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 w-10">
                                            <span class="sr-only">Remove</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr
                                            :key="i"
                                            v-for="(s, i) of date_objects">
                                        <td class="whitespace-nowrap py-3 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                            <span class="font-semibold" v-if="s.date">
                                            {{ s.date | moment("ddd MMM Do YYYY")  }}
                                            </span>
                                            <div v-if="s.event_description" class="text-xs flex items-center mt-1">
                                                <i class="el-icon-date mr-1"></i>
                                                {{ s.event_description }}
                                            </div>

                                            <div class="text-xs flex items-center mt-1 md:hidden">
                                                {{ s.slots }} slot{{ s.slots > 1 ? 's' : '' }} @ {{ s.price | currency($store.getters['auth/language'], currency) }} =  {{ s.total | currency($store.getters['auth/language'], currency) }}
                                            </div>

                                        </td>
                                        <td class="whitespace-nowrap hidden sm:table-cell px-2 py-3 text-sm text-gray-900 text-right">
                                            <span v-if="s.price">
                                                {{ s.price | currency($store.getters['auth/language'], currency) }}
                                            </span>
                                        </td>
                                        <td class="whitespace-nowrap px-2 py-3 text-sm text-gray-900">
                                            <el-select v-if="s.slots" size="small"
                                                       @change="() => calculatePricing()"
                                                       v-model="s.slots"
                                                       class="w-16">
                                                <el-option
                                                        :key="i"
                                                        :value="i"
                                                        v-for="i in s.available_slots">
                                                    {{ i }}
                                                </el-option>
                                            </el-select>
                                        </td>
                                        <td class="whitespace-nowrap px-2 py-3 text-sm text-gray-900 text-right">
                                            {{ s.estimated_plays ? s.estimated_plays.toLocaleString() : 0 }}
                                        </td>
                                        <td class="relative whitespace-nowrap py-3 text-right pl-3 pr-4 text-right text-sm font-medium">
                                            <el-tooltip content="Delete Date" placement="left-start">
                                                <el-button
                                                        @click="removeDate(s.date)"
                                                        size="small" type="danger">
                                                    <i class="el-icon-delete"></i>
                                                </el-button>
                                            </el-tooltip>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="p-3">
                                    <dl class="text-sm font-medium text-gray-500 space-y-6">
                                        <div v-if="totals.slot_total" class="flex justify-between">
                                            <dt>Total Estimated Plays</dt>
                                            <dd class="text-gray-900">
                                                {{ getTotalEstimatedPlays ? getTotalEstimatedPlays.toLocaleString() : 0 }}
                                            </dd>
                                        </div>
                                        <div v-if="totals.slot_total" class="flex justify-between">
                                            <dt>Subtotal</dt>
                                            <dd class="text-gray-900">{{ totals.slot_total | currency($store.getters['auth/language'], currency) }}</dd>
                                        </div>
                                        <div v-if="totals.discount_percentage && totals.discounts" class="flex justify-between">
                                            <dt>Discount ({{ totals.discount_percentage }}%)</dt>
                                            <dd class="text-gray-900">-{{ totals.discounts | currency($store.getters['auth/language'], currency) }}</dd>
                                        </div>
                                        <div class="flex justify-between" v-if="totals.vat">
                                            <dt>VAT (20%)</dt>
                                            <dd class="text-gray-900">{{ totals.vat | currency($store.getters['auth/language'], currency) }}</dd>
                                        </div>
                                        <div class="flex justify-between border-t border-gray-200 text-gray-900 pt-6">
                                            <dt class="text-base">Total <small class="text-gray-600 ml-2">incl. VAT</small></dt>
                                            <dd class="text-base">{{ totals.total | currency($store.getters['auth/language'], currency) }}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div v-if="active_booking_tab === 'hours'">
                            <operating-hours :operating-hours="value.operating_hours" />
                        </div>

                        <div v-if="active_booking_tab === 'details'">
                            <div>
                              <div class="grid grid-cols-3 gap-10 mt-4">
                                  <div class="col-span-3 md:col-span-2">
                                        <dl>

                                          <div class="py-3 grid grid-cols-3">
                                            <dt class="text-sm font-medium text-gray-400">Address</dt>
                                            <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                              {{ value.address }}
                                            </dd>
                                          </div>
                                          <div class="py-3 grid grid-cols-3">
                                            <dt class="text-sm font-medium text-gray-400">Type</dt>
                                            <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                              {{ value.type }}
                                            </dd>
                                          </div>
                                          <div class="py-3 grid grid-cols-3">
                                            <dt class="text-sm font-medium text-gray-400">Starting from</dt>
                                            <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                              From {{ value.default_price | currency($store.getters['auth/language'], value.currency) }}
                                              <small>(excl. VAT)</small>
                                            </dd>
                                          </div>
                                          <div class="py-3 grid grid-cols-3" v-if="value.weather_forecast">
                                            <dt class="text-sm font-medium text-gray-400">Current weather</dt>
                                            <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                              {{ value.weather_forecast.celcius }}°c<br />
                                              <span class="font-semibold">{{ value.weather_forecast.weather_type }}</span>

                                            </dd>
                                          </div>
                                          <div class="py-3 grid grid-cols-3">
                                            <dt class="text-sm font-medium text-gray-400">Local time</dt>
                                            <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                              {{ value.time_zone | localTime }}
                                            </dd>
                                          </div>
                                          <div class="py-3 grid grid-cols-3">
                                            <dt class="text-sm font-medium text-gray-400">Local date</dt>
                                            <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                              {{ value.time_zone | localDate }}
                                            </dd>
                                          </div>

                                        </dl>
                                    </div>
                                  <div class="col-span-3 md:col-span-1">
                                    <div class="h-48 rounded overflow-hidden shadow-lg border border-gray-200 md:h-full w-full z-10 relative">

                                            <preview-map-object
                                                    :lat="value.lat"
                                                    :lon="value.lng"
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4">
                              <h4 class="mb-4 my-3 text-sm font-semibold text-gray-900">Advertising Restrictions</h4>
                              <div v-if="value.restrictions && value.restrictions.length > 0">

                                    <div class="mb-6">
                                        <el-alert type="warning" effect="dark" :closable="false">
                                            Please note that you will not be able to display playlist media items
                                            within this location that contain the following any of the below restrictions
                                        </el-alert>
                                    </div>

                                    <div class="relative flex items-start mb-3"
                                         :key="'restrictions-' + k"
                                         v-for="(x, k) of  value.restrictions">
                                        <div class="ml-3 text-sm">
                                            <label :for="'restriction-' + k" class="font-medium text-gray-700"><i class="el-icon-close text-red-500"></i> {{ x.type }}</label>
                                            <p class="text-gray-500">
                                                {{ x.description }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <el-alert type="success" effect="dark" :closable="false">
                                        This location has no advertising restrictions
                                    </el-alert>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="absolute bottom-0 w-full left-0 z-10 p-4 bg-gray-200 shadow border-t border-gray-200" v-if="totals.total_slots && totals.total_slots > 0">
                    <div class="flex w-full  items-center">
                        <div class="w-auto flex-grow">
                            <h4 class="font-bold">{{ totals.total_slots ? totals.total_slots : 0 }} slot{{ totals.total_slots && totals.total_slots > 1 ? 's' : '' }} selected</h4>
                        </div>
                        <div>
                            <el-button
                                    :disabled="loading_stripe_url || !totals.total_slots"
                                    @click="() => booking_confirm.show = true"
                                    type="success" icon="el-icon-bank-card">Book Dates
                            </el-button>
                        </div>
                    </div>
                </div>
                <div v-else>

                    <div class="mt-12">
                        <empty-state  description="Sorry, but you can't book any slots, as no date's have been selected" />

                        <p class="text-center mt-8">
                            <el-button type="info"  @click="activeStep = 1" icon="el-icon-date">Select Dates</el-button>
                        </p>
                    </div>
                </div>

            </div>
        </div>

      <event-popup
          @close="() => event.show = false"
          v-if="event.show"
          :date="event.object.date"
          :title="event.object.title"
          :type="event.object.location.type"
          :locationName="event.object.location.name"
          :price="event.object.price"
          :address="event.object.location.address"
          :lat="event.object.location.lat"
          :lng="event.object.location.lng"
          :description="event.object.description"
      />

        <booking-checkout
                @close="() => { booking_confirm.show = false; $emit('close') }"
                v-if="booking_confirm.show"
        :pod-id="value.id"
                :currency="currency"
        :slots="date_objects"
        />

    </div>
</template>

<script>

import EventPopup from '@/components/EventPopup';
import CalendarSelect from '../Calendar/CalendarSelect';
import EmptyState from '../EmptyState';
import CustomTabs from '../CustomTabs';
import PreviewMapObject from '../PreviewMapObject';
import OperatingHours from '../OperatingHours';
import BookingCheckout from '../BookingCheckout';

export default {
  name: 'BookPod',
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    activeStep() {
      if (this.activeStep === 2) {
        this.calculatePricing();
      }
    },
  },
  data() {
    return {
      currency: 'GBP',
      event: {
        show: false,
        object: {},
      },
      active_booking_tab: 'dates',
      booking_confirm: {
        show: false,
        loading: false,
      },
      calculating: false,
      loading_stripe_url: false,
      selected_days: [],
      date_objects: [],
      totals: {},
      days: 0,
      activeStep: 1,
    };
  },
  methods: {
    onEventSelect(object) {
      this.event.object = object;
      this.event.show = true;
    },
    removeDate(date) {
      if (this.$refs.calendarSelect) {
        this.$refs.calendarSelect.removeDateByDateString(date);
        this.$nextTick(() => {
          this.calculatePricing();
        });
        //
      }
    },
    onDaysSelected(days) {
      this.selected_days = days;

      for (let i = 0; i < this.selected_days.length; i++) {
        const d = this.selected_days[i];
        const key = d.date.format('YYYY-MM-DD');

        const find = this.date_objects.find((x) => x.date === key);
        if (!find) {
          this.date_objects.push({
            date: key,
            slots: 1,
          });
        }
      }

      for (let i = 0; i < this.date_objects.length; i++) {
        const s = this.date_objects[i];

        const index = this.selected_days.findIndex((x) => s.date === x.date.format('YYYY-MM-DD'));

        if (index === -1) {
          try {
            this.date_objects.splice(i, 1);
          } catch (e) {
            console.warn(e);
          }
        }
      }
    },
    calculatePricing() {
      const vm = this;
      vm.calculating = true;
      vm.$http.post(`/api/v1/pod/${vm.value.id}/checkout-calculator`, {
        selected: vm.getCalculatedSlots,
      })
        .then((response) => {
          vm.currency = response.data.data.location.currency;
          vm.date_objects = response.data.data.rows;
          vm.totals = response.data.data.totals;
          vm.calculating = false;
        }).catch(() => {
          vm.date_objects = [];
          vm.totals = {};
          vm.calculating = false;
        });
    },
    changeStep(step) {
      this.activeStep = step;
    },
  },
  computed: {
    buildTabs() {
      return [
        { value: 'dates', name: 'Dates & Slots' },
        { value: 'details', name: `Details & Restrictions${this.value.restrictions && this.value.restrictions.length > 0 ? ` (${this.value.restrictions.length})` : ''}` },
        { value: 'hours', name: 'Operating Hours' },
      ];
    },
    getTotalEstimatedPlays() {
      return this.date_objects.map((x) => (x.estimated_plays ? x.estimated_plays : 0)).reduce((partialSum, a) => partialSum + a, 0);
    },
    getCalculatedSlots() {
      const keys = Object.keys(this.date_objects);
      return keys.map((x) => {
        const obj = this.date_objects[x];
        return {
          date: obj.date,
          slots: obj.slots,
          total: obj.total ? obj.total : null,
          available_slots: obj.available_slots ? obj.available_slots : null,
          price: obj.price ? obj.price : null,
          is_event_day: obj.is_event_day ? obj.is_event_day : false,
          event_description: obj.event_description ? obj.event_description : null,
        };
      });
    },
  },
  components: {
    EventPopup,
    BookingCheckout,
    OperatingHours,
    PreviewMapObject,
    CustomTabs,
    EmptyState,
    CalendarSelect,
  },
};
</script>

<style scoped>

</style>
