<template>
    <div v-loading="true" class="h-48 w-20 mx-auto" v-if="loading">
    </div>
    <div v-else>

            <div class="h-48 -mx-6 -mt-6 relative z-20">
            <street-view-layer v-if="details.use_street_view" :location="{lat: details.lat, lng: details.lng}" />

              <div class="h-48 border-b border-gray-100 relative z-20 w-full" v-else>
                <preview-map-object
                  :lat="details.lat"
                  :lon="details.lng"
                />
              </div>

                <div class="absolute z-30 bottom-0 right-0 mb-3 mr-3">
                    <el-button
                            @click="() => $router.push('/locations/' + details.id + '/book')"
                            :type="details.use_street_view ? 'info' : 'success'"
                            icon="el-icon-date">
                        Book Advertising Slot
                    </el-button>
                </div>

            </div>

        <div class="my-2">
            <custom-tabs
                    split="w-1/5"
                    v-model="activeTab"
                    :tabs="getDetailTabs" />

            <div class="my-2 mt-5">
                <div v-if="activeTab === 'details'">
                    <div class="grid grid-cols-3 gap-10 mt-4">
                        <div class="col-span-3 md:col-span-2">
                          <dl>
                            <div class="py-3 grid grid-cols-3">
                              <dt class="text-sm font-medium text-gray-400">Address</dt>
                              <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                {{ details.address }}
                              </dd>
                            </div>
                                <div class="py-3 grid grid-cols-3">
                                    <dt class="text-sm font-medium text-gray-400">Type</dt>
                                    <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                        {{ details.type }}
                                    </dd>
                                </div>
                              <div class="py-3 grid grid-cols-3">
                                <dt class="text-sm font-medium text-gray-400">Starting from</dt>
                                <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                  From {{ details.default_price | currency($store.getters['auth/language'], details.currency) }}
                                  <small>(excl. VAT)</small>
                                </dd>
                              </div>
                              <div class="py-3 grid grid-cols-3" v-if="details.weather_forecast">
                                <dt class="text-sm font-medium text-gray-400">Current weather</dt>
                                <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                  {{ details.weather_forecast.celcius }}°c<br />
                                  <span class="font-semibold">{{ details.weather_forecast.weather_type }}</span>

                                </dd>
                              </div>
                              <div class="py-3 grid grid-cols-3">
                                <dt class="text-sm font-medium text-gray-400">Local time</dt>
                                <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                  {{ details.time_zone | localTime }}
                                </dd>
                              </div>
                              <div class="py-3 grid grid-cols-3">
                                <dt class="text-sm font-medium text-gray-400">Local date</dt>
                                <dd class="text-xs md:text-sm text-gray-900-0 col-span-2 text-right">
                                  {{ details.time_zone | localDate }}
                                </dd>
                              </div>

                            </dl>
                        </div>
                        <div class="col-span-3 md:col-span-1">
                            <div class="h-48 rounded overflow-hidden shadow-lg border border-gray-200 md:h-full w-full z-10 relative">

                                <preview-map-object
                                        :lat="details.lat"
                                        :lon="details.lng"
                                />

                            </div>
                        </div>
                    </div>
                    <h4 class="mb-4 my-3 text-sm font-semibold text-gray-900">Advertising Restrictions</h4>
                    <div v-if="details.restrictions.length > 0">

                        <div class="mb-6">
                            <el-alert type="warning" effect="dark" :closable="false">
                                Please note that you will not be able to display playlist media items
                                within this location that contain the following any of the below restrictions
                            </el-alert>
                        </div>

                        <div class="relative flex items-start mb-3"
                             :key="'restrictions-' + k"
                             v-for="(x, k) of details.restrictions">
                            <div class="ml-3 text-sm">
                                <label :for="'restriction-' + k" class="font-medium text-gray-700"><i class="el-icon-close text-red-500"></i> {{ x.type }}</label>
                                <p class="text-gray-500">
                                    {{ x.description }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <el-alert type="success" effect="dark" :closable="false">
                            This location has no advertising restrictions
                        </el-alert>
                    </div>
                </div>

                <div v-if="activeTab === 'operating-hours'">
                    <operating-hours :operating-hours="details.operating_hours" />
                </div>

                <div v-if="activeTab === 'local-area'">
                    <points-of-interest
                      :lat="details.lat"
                      :lon="details.lng"
                      :pod-id="details.id" />
                </div>

                <div v-if="activeTab === 'demographics'">
                    <dl>
                        <div class="py-3 grid grid-cols-3" v-if="details.average_income">
                            <dt class="text-sm font-medium text-gray-400">Average Income</dt>
                            <dd class="mt-1 text-xs md:text-sm text-gray-900-0 text-right col-span-2">
                                <div class="mb-2">
                                  {{ details.average_income | currency($store.getters['auth/language'], details.currency) }}
                                </div>
                                <div class="w-1/3 ml-auto">
                                    <el-progress
                                            :show-text="false"
                                            :percentage="82"/>
                                </div>
                            </dd>
                        </div>
                        <div class="py-3 grid grid-cols-3"  v-if="details.average_income">
                            <dt class="text-sm font-medium text-gray-400">Index of multiple deprivation</dt>
                            <dd class="mt-1 text-xs md:text-sm text-gray-900-0 text-right col-span-2">
                                <div class="mb-2">
                                    {{ details.imd.toLocaleString() }} / 34,000
                                </div>
                                <div class="w-1/3 ml-auto">
                                    <el-progress
                                            color="#24a800"
                                            :show-text="false"
                                            :percentage="96"/>
                                </div>
                            </dd>
                        </div>
                        <div class="py-3 grid grid-cols-3" v-if="details.rural_urban">
                            <dt class="text-sm font-medium text-gray-400">Rural/urban</dt>
                            <dd class="mt-1 text-xs md:text-sm text-gray-900-0 text-right col-span-2">
                                {{ details.rural_urban }}
                            </dd>
                        </div>
                        <div class="py-3 grid grid-cols-3" v-if="details.built_up_area">
                            <dt class="text-sm font-medium text-gray-400">Built up area</dt>
                            <dd class="mt-1 text-xs md:text-sm text-gray-900-0 text-right col-span-2">
                                {{ details.built_up_area }}
                            </dd>
                        </div>
                        <div class="py-3 grid grid-cols-3" v-if="details.llsoa">
                            <dt class="text-sm font-medium text-gray-400">Lower layer super output area</dt>
                            <dd class="mt-1 text-xs md:text-sm text-gray-900-0 text-right col-span-2">
                                {{ details.llsoa }}
                            </dd>
                        </div>
                        <div class="py-3 grid grid-cols-3" v-if="details.mlsoa">
                            <dt class="text-sm font-medium text-gray-400">Middle layer super output area
                            </dt>
                            <dd class="mt-1 text-xs md:text-sm text-gray-900-0 text-right col-span-2">
                                {{ details.mlsoa }}
                            </dd>
                        </div>
                        <div class="py-3 grid grid-cols-3">
                            <dt class="text-sm font-medium text-gray-400">Travel to work area</dt>
                            <dd class="mt-1 text-xs md:text-sm text-gray-900-0 text-right col-span-2">
                                {{ details.travel_to_work_area }}
                            </dd>
                        </div>
                    </dl>
                </div>

                <div v-if="activeTab === 'events'">
                    <div v-if="loading_events" class="h-64 flex items-center justify-center">
                        <spin-loader />
                    </div>
                    <div v-else>

                        <div v-if="events.length > 0">

                            <el-alert type="info"  effect="dark" :closable="false">
                                {{ events.length }} upcoming events have found.
                            </el-alert>

                            <ul class="el--playlist mt-6">
                                <li
                                        class="group relative block select-none hover:bg-gray-50 bg-white"
                                        :key="x" v-for="(o, x) of events">
                                    <div class="flex items-center">
                                        <div
                                                :class="{
                                                        'border-t rounded-t': x === 0,
                                                        'rounded-b': x === events.length - 1
                                                        }"
                                                class="min-w-0 flex-1 flex items-center border-b border-r border-l  border-gray-200  hover:cursor-pointer p-3 text-xs">
                                            <div class="min-w-0 flex-1 px-4 grid grid-cols-3 gap-1">
                                                <div>
                                                    <p class="font-medium text-red-700 text-sm truncate">
                                                        {{ o.title }}
                                                    </p>

                                                    <p class="text-xs mt-1 truncate">
                                                        {{ o.description }}
                                                    </p>
                                                </div>
                                                <div class="mx-3 flex items-center text-xs justify-center">
                                                    {{ o.booking_price | currency }} excl. VAT
                                                </div>
                                                <div class="mx-3 text-center flex justify-center items-center text-gray-700">
                                                    <p class="text-xs truncate">
                                                        {{ o.date | moment("MMMM Do YYYY") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="mx-2 w-48 text-right">
                                                <div class="hidden group-hover:block">
                                                    <el-button
                                                            size="small"
                                                            @click="() => { $router.push('/locations/' + details.id + '/book?date=' + o.date); }"
                                                            type="success" icon="el-icon-date">
                                                        Book
                                                    </el-button>
                                                    <el-button
                                                            size="small"
                                                            @click="$emit('open-event', o)"
                                                            type="info" icon="el-icon-view">
                                                        View
                                                    </el-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                        <div class v-else>
                            <div class="py-6">
                                <empty-state description="This location has no upcoming events" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

</template>
<script>
import StreetViewLayer from './StreetViewLayer';
import PreviewMapObject from '../PreviewMapObject';
import SpinLoader from '../SpinLoader';
import OperatingHours from '../OperatingHours';
import EmptyState from '../EmptyState';
import CustomTabs from '../CustomTabs';
import PointsOfInterest from '../PointsOfInterest';

export default {
  name: 'PodDetails',
  components: {
    PointsOfInterest,
    CustomTabs,
    EmptyState,
    OperatingHours,
    SpinLoader,
    PreviewMapObject,
    StreetViewLayer,
  },
  props: {
    details: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  watch: {
    $route(to) {
      if (to && to.name === 'PodBook') {
        this.$emit('open-booking');
      }
    },
  },
  data() {
    return {
      activeTab: 'details',
      loading_events: true,
      events: [],
      poi: {
        properties: [
          {},
          {},
          {},
          {},
          {},
        ],
      },
    };
  },
  computed: {
    getDetailTabs() {
      return [
        { value: 'details', name: 'Details/Restrictions' },
        { value: 'local-area', name: 'Points of Interest' },
        { value: 'demographics', name: 'Demographics' },
        { value: 'operating-hours', name: 'Operating Hours' },
        { value: 'events', name: `Events (${this.events.length})` },
      ];
    },
  },
  mounted() {
    this.fetchEvents();
    if (this.$route.name === 'PodBook') {
      this.$emit('open-booking');
    }
  },
  methods: {
    onBookClick() {
      this.$emit('open-booking');
    },
    viewEvent(e) {
      console.log(e);
    },
    fetchEvents() {
      const vm = this;
      vm.loading_events = true;
      return vm.$http.get(`/api/v1/pods/${vm.details.id}/events`)
        .then((response) => {
          vm.loading_events = false;
          vm.events = response.data.data;
        }).catch(() => {
          vm.loading_events = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
