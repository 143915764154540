<template>
    <page-wrapper
            :show-feed="false"
            :title="!loading && invoice && invoice.invoice && invoice.invoice.invoice_id ? 'Invoice #' + (invoice.invoice.invoice_id) : '' ">

        <template slot="nav-cta">
            <el-button v-if="!loading"
                       @click="openPrint"
                    icon="el-icon-printer"
                    type="success">Print
            </el-button>
        </template>
        <template slot="nav-search">
            <div v-if="!loading" class="flex-grow lg:block">
            </div>
        </template>

            <template slot="content">

                <div class="bg-white p-8 border border-gray-200 relative" v-if="!loading">
                    <invoice-view v-model="invoice" />
                </div>

        </template>
    </page-wrapper>
</template>

<script>

import InvoiceView from '@/components/Invoice/InvoiceView';

export default {
  name: 'invoice-page',
  components: { InvoiceView },
  data() {
    return {
      loading: true,
      invoice: {
        invoice: {},
        registered_address: {},
        billing_address: {},
        rows: [],
      },
    };
  },
  methods: {
    openPrint() {
      window.open(`/invoices/${this.$route.params.invoiceId}/print`);
    },
  },
  beforeRouteEnter(to, from, next) {
    return next((vm) => vm.$http.get(`/api/v1/invoices/${to.params.invoiceId}`)
      .then((response) => {
        vm.invoice = response.data.data;
        vm.loading = false;
      }).catch(() => {
        vm.$router.push('/invoices');
      }));
  },
};
</script>
