<template>
  <popper-js
    ref="sortOptions"
    :options="{
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8],
      },
    },
  ],
    }">

    <div
      class="z-40 w-48 py-2"
      :class="offsetClass"
      role="menu" aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1">
      <div class="rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
        <div class="py-1" role="none">
          <div
            @click="onChange(x)"
            :key="'sort-order-by-' + k"
            v-for="(x, k) of value"
            :class="getSortedByObject.order === x.order && getSortedByObject.name === x.name ? 'bg-gray-100 text-gray-900' : 'text-gray-700 hover:bg-gray-100'"
            class="group cursor-pointer flex items-center px-4 py-2 text-sm"
            role="menuitem" tabindex="-1">

            <svg v-if="x.order === 'desc'"
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-700">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-700">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
            </svg>



            <span>{{ x.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <button
      slot="reference"
      class="p-2 text-sm hover:bg-gray-200 flex justify-center items-center rounded">

      <svg v-if="getSortedByObject.order === 'desc'"
           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
      </svg>

      <span class="ml-2">{{ getSortedByObject ? getSortedByObject.name : null }}</span>
    </button>

  </popper-js>
</template>

<script>
export default {
  name: "SortOrderBy",
  props: {
    value: {
      type: Array,
      default(){
        return []
      }
    },
    sort: {
      type: String,
      default(){
        return 'created_at'
      }
    },
    offsetClass: {
      type: String,
      default(){
        return 'mr-20'
      }
    },
    order: {
      type: String,
      default(){
        return 'desc'
      }
    }
  },
  watch: {
    sort(value){
      this.sort_values.sort = value;
    },
    order(value){
      this.sort_values.order = value;
    }
  },
  data(){
    return {
      sort_values: {
        order: this.order,
        sort: this.sort
      }
    }
  },
  computed: {
    getSortedByObject(){
      let vm = this,
        find = vm.value.find((x) => x.order === vm.sort_values.order && x.sort === vm.sort_values.sort);
      return find ? find  : null;
    },
  },
  methods: {
    onChange({sort, order}){

      const vm = this;
      if(vm.$refs && vm.$refs.sortOptions) {
        vm.$refs.sortOptions.doClose();
        vm.sort_values = {sort, order};
        vm.$emit('change', this.sort_values)
      }
    }
  }
}
</script>

<style scoped>

</style>
