<template>
    <page-wrapper title="Media Library">

        <template slot="nav-cta">


            <el-button
                    @click="() => design.open = true"
                    type="info">
              <div class="flex gap-x-2">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg"
                       class="w-4 h-4 text-white"
                       fill="currentColor"
                       viewBox="0 0 24 24"><path d="M8.997 13.985c.01 1.104-.88 2.008-1.986 2.015-1.105.009-2.005-.88-2.011-1.984-.01-1.105.879-2.005 1.982-2.016 1.106-.007 2.009.883 2.015 1.985zm-.978-3.986c-1.104.008-2.008-.88-2.015-1.987-.009-1.103.877-2.004 1.984-2.011 1.102-.01 2.008.877 2.012 1.982.012 1.107-.88 2.006-1.981 2.016zm7.981-4.014c.004 1.102-.881 2.008-1.985 2.015-1.106.01-2.008-.879-2.015-1.983-.011-1.106.878-2.006 1.985-2.015 1.101-.006 2.005.881 2.015 1.983zm-12 15.847c4.587.38 2.944-4.492 7.188-4.537l1.838 1.534c.458 5.537-6.315 6.772-9.026 3.003zm14.065-7.115c1.427-2.239 5.846-9.748 5.846-9.748.353-.623-.429-1.273-.975-.813 0 0-6.572 5.714-8.511 7.525-1.532 1.432-1.539 2.086-2.035 4.447l1.68 1.4c2.227-.915 2.868-1.04 3.995-2.811zm-12.622 4.806c-2.084-1.82-3.42-4.479-3.443-7.447-.044-5.51 4.406-10.03 9.92-10.075 3.838-.021 6.479 1.905 6.496 3.447l1.663-1.456c-1.01-2.223-4.182-4.045-8.176-3.992-6.623.055-11.955 5.466-11.903 12.092.023 2.912 1.083 5.57 2.823 7.635.958.492 2.123.329 2.62-.204zm12.797-1.906c1.059 1.97-1.351 3.37-3.545 3.992-.304.912-.803 1.721-1.374 2.311 5.255-.591 9.061-4.304 6.266-7.889-.459.685-.897 1.197-1.347 1.586z"/></svg>
                </span>
                <span>Need Design Help?</span>
              </div>
            </el-button>
        </template>
        <template slot="nav-search">
            <div
              class="flex-grow lg:block" :class="{
            '': show_search,
             'hidden': !show_search,
            }">
                <div class="relative">
                    <i class="el-icon-search top-0 left-0 absolute text-lg text-gray-500 ml-4 mt-3"></i>
                    <input
                            placeholder="Search Media Library"
                            class="w-full p-2 px-4 pl-10 text-gray-500 rounded border border-gray-300 h-10 text-sm"
                            @input="($event) => debounceInput(this, $event)"
                            :value="search.term" />
                </div>
            </div>
            <div
                    @click="show_search = !show_search"
                    :class="{
                    'flex-0': show_search,
                    'flex-grow': !show_search,
                    }"
                    class="lg:hidden text-right text-2xl text-gray-600 hover:text-gray-900 cursor-pointer">
                <i
                        v-if="!show_search"
                        class="el-icon-search"></i>
                <i
                        v-if="show_search"
                        class="el-icon-close"></i>
            </div>
        </template>

        <template slot="overlays">

          <titled-overlay
            :title="details.model.name"
            @close="() => details.visible = false"
            :show="details.visible">
            <template slot="content">

              <div class="md:flex h-full items-stretch overflow-scroll md:overflow-hidden">
              <div class="hidden md:block w-full flex bg-black items-center border-r relative border-gray-200 h-full w-96">

                <div class="flex justify-center items-center h-full w-full">
                  <video
                    autoplay
                    loop
                    muted
                    class="z-10 h-auto"
                  >
                    <source
                      :src="details.model.remote_src"
                    />
                  </video>
                </div>

              </div>

              <div class="w-full relative flex-grow h-full overflow-scroll-y scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 ">

                <div class="p-8 text-sm">
                  <div class="grid gap-y-6">

                    <div class="flex flex-wrap w-full">
                      <div class="w-full lg:w-1/3 lg:mb-0 mb-3 text-gray-800 font-semibold">
                        File Name
                      </div>
                      <div class="w-full lg:w-2/3">
                        {{ details.model.name }}
                      </div>
                    </div>

                    <div class="flex flex-wrap w-full">
                      <div class="w-full lg:w-1/3 lg:mb-0 mb-3 text-gray-800 font-semibold">
                        Status
                      </div>
                      <div class="w-full lg:w-2/3">
                        <span
                          :class="{
                          'bg-green-300 text-green-700': details.model.status === 'ACTIVE',
                          'bg-orange-300 text-orange-700': details.model.status === 'PENDING',
                          }"
                          class="rounded font-semibold px-3 py-2 uppercase">
                          <span v-if="details.model.status === 'ACTIVE'">Active</span>
                          <span v-if="details.model.status === 'PENDING'">
                            Waiting for Approval
                          </span>
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="details.model.rejection_reason"
                      class="flex flex-wrap w-full">
                      <div class="w-full lg:w-1/3 lg:mb-0 mb-3 text-gray-800 font-semibold">
                        Rejected Reason
                      </div>
                      <div class="w-full lg:w-2/3">
                        {{ details.model.rejection_reason }}
                      </div>
                    </div>

                    <div class="flex flex-wrap w-full">
                      <div class="w-full lg:w-1/3 lg:mb-0 mb-3 text-gray-800 font-semibold">
                        Date Uploaded
                      </div>
                      <div class="w-full lg:w-2/3">
                        {{ details.model.uploaded_at | date }}
                      </div>
                    </div>

                    <div
                      v-if="details.model.status === 'ACTIVE' || details.model.status === 'PENDING'"
                      class="flex flex-wrap w-full">
                      <div class="w-full lg:w-1/3 lg:mb-0 mb-3 text-gray-800 font-semibold">
                        Extracted Colours
                      </div>
                      <div class="w-full lg:w-2/3">
                        <div class="flex flex-wrap w-full gap-3">
                          <div
                            :key="'color-' + c"
                            :style="'background:' + x"
                            class="rounded shadow w-6 h-6"
                            v-for="(x, c) of details.model.predefined_colors">
                          </div>
                        </div>
                      </div>
                    </div>


                    <div
                      v-if="details.model.status === 'ACTIVE' || details.model.status === 'PENDING'"
                         class="flex flex-wrap w-full">
                      <div class="w-full lg:w-1/3 lg:mb-0 mb-3 text-gray-800 font-semibold">
                        Tags
                      </div>
                      <div class="w-full lg:w-2/3">
                        <div class="flex flex-wrap w-full gap-2">
                          <div
                            :key="'color-' + c"
                            class="rounded-full shadow p-1 text-xs font-bold uppercase px-2 text-xs bg-blue-400 text-blue-900"
                            v-for="(x, c) of details.model.tags">
                            {{ x }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="details.model.status === 'ACTIVE'"
                      class="flex flex-wrap w-full">
                      <div class="w-full lg:w-1/3 lg:mb-0 mb-3 text-gray-800 font-semibold">
                        Categories
                      </div>
                      <div class="w-full lg:w-2/3">
                        <div class="relative flex items-start mb-3"
                             :key="'restrictions-' + k"
                             v-for="(x, k) of  details.model.restrictions">
                          <div class="ml-3 text-sm">
                            <label :for="'restriction-' + k" class="font-medium text-gray-700">
                              {{ x.type }}
                            </label>
                            <p class="text-gray-500">
                              {{ x.description }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              </div>

            </template>
          </titled-overlay>

          <tiny-modal
            @close="() => move.open = false"
            :show="move.open">
            <template slot="title">
              Move Media/Folder
            </template>
            <template slot="content">
              <el-alert :closable="false">
                Please select a folder to move your media/folder to.
              </el-alert>
              <div class="mt-2">
                <file-move
                  v-model="move.object.current_folder"
                />
              </div>
            </template>

            <template slot="actions">
              <button
                @click="moveFolder"
                :disabled="move.running"
                type="button" class="w-full bg-green-600 flex items-center inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                <i class="el-icon-loading mr-1" v-if="move.running"></i> Move Media
              </button>
              <button
                :disabled="move.running"
                @click="() => move.open = false"
                type="button" class="text-gray-700 hover:text-gray-500 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm">
                Cancel
              </button>
            </template>

          </tiny-modal>

          <tiny-modal
            @close="() => folder.open = false"
            :show="folder.open">
            <template slot="title">
              Create New Folder
            </template>
            <template slot="content">
              <div class="w-full">
                <label class="text-gray-700 block mb-2 font-semibold text-sm">Folder name<span class="text-red-700">*</span></label>

                <el-input v-model="folder.object.name" :disabled="folder.running"
                          class="w-full"
                          placeholder="e.g, Latest Videos"/>
              </div>
            </template>
            <template slot="actions">
              <button
                @click="createFolder"
                :disabled="folder.running"
                type="button" class="w-full bg-green-600 flex items-center inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                <i class="el-icon-loading mr-1" v-if="folder.running"></i> Create
              </button>
              <button
                :disabled="folder.running"
                @click="() => folder.open = false"
                type="button" class="text-gray-700 hover:text-gray-500 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm">
                Cancel
              </button>
            </template>
          </tiny-modal>


            <alert-confirm
                    :show="confirm.delete.open"
                    @cancel="confirm.delete.open = false"
                    @confirm="() => deleteMedia(confirm.delete.items)"
                    :loading="confirm.delete.loading"
                    :confirm-text="'Yes, Delete Media'"
                    @close="()=> { confirm.delete.open = false }"
            >
                <template slot="title">
                    Delete Media?
                </template>
                <template slot="message">
                    Are you sure you want to delete this media item? This cannot be undone.
                </template>
            </alert-confirm>


          <tiny-modal
            @close="() => design.open = false"
            :show="design.open">

            <template slot="title">
              Design your Media
            </template>
            <template slot="content">


              <div class="p-4 rounded bg-gray-200 text-gray-800 shadow">

                <div class="w-full gap-4 flex">
                  <div class="w-1/3 rounded flex items-center justify-center bg-white">
                    <img class="p-3" src="/imgs/canva-logo.png" />
                  </div>
                  <div class="w-2/3">
                    <div class="text-sm leading-tight">
                      <h4 class="mb-2 text-lg font-bold text-gray-800">Canva</h4>
                      <p class="mb-3">If your looking for a free platform to design your
                        content we recommend <strong>Canva</strong>.</p>

                      <p class="mb-3">
                        Canva is graphic design platform that is used to create
                        social media graphics and presentations. The app includes ready made templates for users to use.
                      </p>

                      <p>
                        <el-button
                          @click="launchCanva"
                          type="info">Visit Canva</el-button>
                      </p>


                    </div>
                  </div>
                </div>

              </div>


              <div class="my-6">
              </div>


              <div class="p-4 rounded bg-gray-200 text-gray-800 shadow">

                <div class="w-full gap-4 flex">
                  <div class="w-1/3 flex items-center justify-center rounded bg-red-700">
                    <svg class="h-12 w-auto text-white"  fill="currentColor" viewBox="0 0 1260 982" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g  fill="currentColor" fill-rule="nonzero">
                          <polygon points="479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"></polygon>
                          <polygon points="1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"></polygon>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="w-2/3">
                    <div class="text-sm leading-tight">
                      <h4 class="mb-2 text-lg font-bold text-gray-800">Chargabull Design Team</h4>
                      <p class="mb-3">
                        We offer a full design package starting form as little as £99 for those looking for
                        a more commercial offering.
                      </p>

                      <p>
                        <el-button
                          @click="launchChat"
                          type="info">Get in Touch</el-button>
                      </p>



                    </div>
                  </div>
                </div>

              </div>

            </template>
          </tiny-modal>

          <tiny-modal
            @close="() => upload.open = false"
            :show="upload.open">

            <template slot="title">
              Upload Media
            </template>
            <template slot="content">
              <file-pond-upload
                :folder-id="isSearchMode ? null : fetchFolderId"
                @uploaded="onUploaded"
              />
            </template>

          </tiny-modal>

            <menu-sidebar-layer
                    :show-actions="true"
                    :show-close="!slots.updating"
                    @close="()=> { slots.open = false }"
                    position="left" :show="slots.open">

                <template slot="title">
                    Manage Booking Slots
                </template>
                <template slot="content">

                    <media-slots
                            ref="mediaSlots"
                            @updating="(bool) => slots.updating = bool"
                            @hide-actions="(bool) => slots.hide_actions = bool"
                            @updated="() =>  fetch(1) "
                            v-model="slots.media_id"
                    />

                </template>
                <template slot="actions">
                    <el-button
                            :disabled="slots.updating"
                            @click="slots.open = false">
                        Close
                    </el-button>
                    <el-button
                            @click="$refs.mediaSlots.update()"
                            :loading="slots.updating"
                            :disabled="slots.updating"
                            type="success" v-if="!slots.hide_actions">
                        Update
                    </el-button>
                </template>
            </menu-sidebar-layer>

            <menu-sidebar-layer
                    :show-actions="true"
                    :show-close="!edit.updating"
                    @close="()=> { edit.open = false }"
                    position="left" :show="edit.open">

                <template slot="title">
                    Update Video Settings
                </template>
                <template slot="content">

                    <media-edit
                            ref="editMedia"
                            @updating="(bool) => edit.updating = bool"
                            @updated="() =>  fetch(1) "
                            @hide-actions="(bool) => edit.hide_actions = bool"
                            v-model="edit.media_id"
                    />

                </template>
                <template slot="actions">
                    <el-button
                            :disabled="edit.updating"
                            @click="edit.open = false">
                        Close
                    </el-button>
                    <el-button
                            @click="$refs.editMedia.update()"
                            :loading="edit.updating"
                            :disabled="edit.updating"
                            type="success" v-if="!edit.hide_actions">
                        Update
                    </el-button>
                </template>
            </menu-sidebar-layer>

            <media-preview
                    @close="() => preview.open = false"
                    :bg-color="preview.bg_color"
                    :video-source="preview.video_url"
                    :show="preview.open"
            />

        </template>

        <template slot="content">

            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto overflow-hidden truncate">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol role="list" class="flex items-center">
                            <li
                                    :key="'breadcrumb-' + i"
                                    v-for="(x, i) of getBreadcrumbPath">
                                <div class="flex items-center select-none">
                                    <svg v-if="i > 0" class="flex-shrink-0 h-5 w-5 text-gray-300"
                                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"
                                         aria-hidden="true">
                                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"/>
                                    </svg>
                                    <router-link
                                            draggable="false"
                                            v-if="i < (path.length - 1)" :to="x.id ? '/media/' + x.id : '/media'"
                                            class="ml-2 text-sm p-2 rounded font-medium text-gray-800 hover:text-gray-700">
                                        {{ x.name }}
                                    </router-link>
                                    <span class="text-gray-500 ml-2 cursor-default" v-else>
                                       {{ x.name }}
                                    </span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <div class="flex flex-wrap w-auto -mx-2">

                        <div class="p-1" v-if="active.checkbox || folder.selected">
                            <el-tooltip content="Delete Selected Items">
                                <button
                                        @click="confirmMultipleDelete"
                                        class="p-2 text-sm text-red-700 hover:bg-gray-200 flex justify-center items-center rounded">
                                    <svg
                                            class="h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                    </svg>
                                </button>
                            </el-tooltip>
                        </div>
                        <div class="p-1" v-if="active.checkbox">
                            <span class="block mx-2 h-full border-r border-gray-300"></span>
                        </div>
                        <div class="p-1" v-if="!isSearchMode">

                          <sort-order-by
                          v-model="sort_options"
                          :sort="sort"
                          :order="order"
                          @change="changeSort"
                          />


                        </div>

                      <div class="p-1" v-if="!isSearchMode">
                        <button
                          @click="folder.open = true"
                          class="p-2 text-sm hover:bg-gray-200 flex justify-center items-center rounded">
                          <svg xmlns="http://www.w3.org/2000/svg"
                               class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"/>
                          </svg>
                          <span class="ml-2">New Folder</span>
                        </button>
                      </div>

                      <div class="p-1" v-if="!isSearchMode">
                        <button
                          data-intro='Hello step two!'
                          @click="upload.open = true"
                                class="p-2 text-sm hover:bg-gray-200 flex justify-center items-center rounded">

                          <svg xmlns="http://www.w3.org/2000/svg"
                               class="h-6 w-6"
                               viewBox="0 0 24 24"><path d="M16 16h-3v5h-2v-5h-3l4-4 4 4zm3.479-5.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z"/></svg>

                          <span class="ml-2">Upload</span>
                        </button>
                      </div>
                        <div class="p-1">
                            <span class="block mx-2 h-full border-r border-gray-300"></span>
                        </div>
                        <div class="p-1">
                            <el-tooltip :content="active.checkbox ? 'Deselect all' : 'Select all'">
                                <button @click="active.checkbox = !active.checkbox"
                                        class="p-2 hover:bg-gray-200 hover:text-gray-900 rounded"
                                        :class="{
                             'text-gray-900': active.checkbox,
                              'text-gray-300': !active.checkbox
                            }">
                                    <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path v-if="!active.checkbox" fill-rule="evenodd" clip-rule="evenodd"
                                              d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6ZM6 5C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44772 18.5523 5 18 5H6Z"
                                        />

                                        <path v-if="active.checkbox"
                                              d="M16.7068 9.04063C17.0973 9.43116 17.0973 10.0643 16.7068 10.4548L12.7881 14.3736C11.6165 15.5452 9.717 15.5452 8.54543 14.3736L7.29317 13.1213C6.90264 12.7308 6.90264 12.0976 7.29317 11.7071C7.68369 11.3166 8.31686 11.3166 8.70738 11.7071L9.95964 12.9594C10.3502 13.3499 10.9833 13.3499 11.3739 12.9594L15.2926 9.04064C15.6831 8.65011 16.3163 8.65011 16.7068 9.04063Z"/>
                                        <path v-if="active.checkbox" fill-rule="evenodd" clip-rule="evenodd"
                                              d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM5 6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6Z"/>

                                    </svg>

                                </button>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4" v-show="active.view === 'grid'">
                <folder-view
                        ref="folderView"
                        @has-selected="(bool) => folder.selected = bool"
                        @open-upload="() => upload.open = true"
                        :multiple="active.checkbox"
                        :mode="isSearchMode ? 'search' : 'folder'"
                        :loading="folder.running || loading"
                        @move-file="onFileMove"
                        @delete="confirmDelete"
                        @details="mediaDetails"
                        @select-folder="onSelectFolder"
                        @preview="previewMedia"
                        @folder-clicked="onFolderClicked"
                        @edit-name="onEditName"
                        v-model="results"/>
            </div>

        </template>
    </page-wrapper>
</template>

<script>

import { debounce } from 'debounce';
import FilePondUpload from '@/components/FilePondUpload';
import MediaSlots from '@/components/Media/MediaSlots';
import AlertConfirm from '@/components/AlertConfirm';
import MediaEdit from '@/components/Media/MediaEdit';
import MediaPreview from '@/components/MediaPreview';
import FolderView from '@/components/Media/FolderView';
import FileMove from '@/components/Media/FileMove';

import introJs from "intro.js";
import TinyModal from "@/components/TinyModal";
import TitledOverlay from "@/components/TitledOverlay";
import SortOrderBy from "@/components/SortOrderBy";

import { media } from "@/utils/sort-options";

export default {
  name: 'media-library',
  components: {
    SortOrderBy,
    TitledOverlay,
    TinyModal,
    FileMove,
    FolderView,
    // EmptyState,
    MediaPreview,
    MediaEdit,
    AlertConfirm,
    MediaSlots,
    FilePondUpload,
  },
  data() {
    return {
      search: {
        term: '',
        running: false,
      },
      details: {
        visible: false,
        model : {}
      },
      sort: 'created_at',
      order: 'desc',
      sort_options: media(),
      multiple_select: false,
      show_search: false,
      list: [],
      move: {
        open: false,
        running: false,
        object: {
          current_folder: null,
          items: [],
        },
      },
      folder: {
        open: false,
        running: false,
        selected: false,
        object: {
          name: null,
        },
      },
      active: {
        view: 'grid',
        checkbox: false,
      },
      stats: {},
      upload: {
        open: false,
      },
      design: {
        open: false,
      },
      preview: {
        open: false,
        bg_color: null,
        video_url: null,
      },
      confirm: {
        delete: {
          items: [],
          open: false,
          loading: false,
        },
      },
      edit: {
        open: false,
        hide_actions: true,
        updating: false,
        media_id: null,
      },
      slots: {
        updating: false,
        hide_actions: true,
        open: false,
        media_id: null,
      },
      myFiles: [],
      settings: {
        open: false,
        loading: true,
        data: {},
      },
      loading: true,
      results: [],
      path: [],
    };
  },
  created() {
    const vm = this;
    vm.fetchFolder();
  },
  computed: {
    getBreadcrumbPath() {
      if (this.isSearchMode) {
        return [
          { name: 'Search Results' },
        ];
      }
      return this.path;
    },
    isSearchMode() {
      if (this.$route.params && this.$route.params.pathMatch) {
        if (this.$route.query && this.$route.query.q) {
          return this.$route.params.pathMatch === '/search';
        }
      }
      return false;
    },
    fetchFolderId() {
      let folder = null;
      if (this.$route.params && this.$route.params.pathMatch && this.$route.params.pathMatch.length > 0) {
        const full = this.$route.params.pathMatch;
        const split = full.split('/');
        folder = split[split.length - 1];
      }
      return folder;
    },
  },
  watch: {
    $route() {
      if (!this.isSearchMode) {
        this.fetchFolder();
      }
    },
  },
  mounted() {
    if (this.isSearchMode) {
      this.search.term = this.$route.query.q;
      this.performSearch();
    }
    this.$nextTick(() => {

        introJs().setOptions({
          steps: [
            {
            title: 'Welcome',
            intro: 'Hello World! 👋'
          },
            {
              title: 'Welcome 2',
              intro: 'Hello Wor'
            }
          ]});
    })
  },
  methods: {
    debounceInput: debounce((vm, e) => {
      vm.search.term = e.target.value;
      if (vm.search.term.length > 0) {
        vm.$router.push(`/media/search?q=${vm.search.term}`);
        vm.performSearch();
      } else {
        vm.$router.push('/media');
        vm.fetchFolder();
      }
    }, 500),
    launchChat(){
      document.querySelector(".woot--bubble-holder").click()
    },
    launchCanva(){
      window.open("https://www.canva.com")
    },
    mediaDetails(id){
      const vm = this;
      vm.loading = true;
      return vm.$http.get(`/api/v1/media/${id}`)
        .then((response) => {
          vm.details.model = response.data.data;
          vm.details.visible = true;
          vm.loading = false;
        }).catch(() => {
          vm.details.visible = false;
          vm.loading = false;
        });
    },
    changeSort({sort, order}){
      const vm = this;
      vm.sort = sort;
      vm.order = order;
      vm.fetchFolder();
    },
    onEditName({ id, name, type }) {

      let itemType = type === 'folder' ? 'folder' : 'media';

      const vm = this;
      return vm.$http.put(`/api/v1/${itemType}/${id}`, {
        name,
      })
        .then(() => {

        }).catch(() => {

        });
    },
    onFolderClicked(item) {
      this.search.term = '';
      this.$router.push(`/media/${item}`);
    },
    performSearch() {

      const vm = this;
      vm.loading = true;

      const path = `/api/v1/media?q=${this.search.term}`;

      return vm.$http.get(path).then((response) => {
        vm.results = response.data.data;
        vm.loading = false;
      }).catch(() => {
        vm.loading = false;
        vm.results = [];
      });
    },
    onSelectFolder(items) {
      const vm = this;
      vm.move.object.items = items;
      vm.move.object.current_folder = this.fetchFolderId ? this.fetchFolderId : null;
      vm.move.open = true;
    },
    moveFolder() {
      this.onFileMove({
        items: this.move.object.items,
        destination: this.move.object.current_folder ? this.move.object.current_folder : 'root',
      });
    },
    onFileMove({ items, destination }) {
      const vm = this;

      vm.folder.running = true;
      return vm.$http.put(`/api/v1/folder/${destination}/move`, {
        items,
      })
        .then(() => {
          if (vm.isSearchMode) {
            vm.performSearch();
          } else {
            this.fetchFolder();
          }
          vm.folder.running = false;
          vm.move.open = false;
        }).catch((e) => {
          vm.folder.running = false;
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
            offset: 0,
          });
        });
    },
    createFolder() {
      const vm = this;
      vm.folder.running = true;
      const { object } = vm.folder;

      const folder = this.fetchFolderId;
      if (folder) {
        object.folder_id = folder;
      }

      return vm.$http.post('/api/v1/folder', object)
        .then(() => {
          vm.folder.open = false;
          vm.folder.running = false;
          vm.folder.object.name = '';
          this.$notify({
            type: 'success',
            title: 'Folder Created Successfully',
            message: 'Your folder was created successfully.',
            position: 'bottom-left',
          });
          vm.fetchFolder();
        }).catch((e) => {
          vm.folder.running = false;
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
            offset: 0,
          });
        });
    },
    confirmMultipleDelete() {
      const vm = this;
      vm.confirm.delete.items = vm.$refs.folderView.getSelectedItemObjects;
      vm.confirm.delete.open = true;
    },
    deleteMedia(items) {
      const vm = this;
      vm.confirm.delete.loading = true;
      vm.confirm.delete.open = true;

      return vm.$http.delete('/api/v1/media', {
        data: {
          items,
        },
      })
        .then(() => {
          vm.confirm.delete.loading = false;
          vm.confirm.delete.open = false;

          if (vm.isSearchMode) {
            vm.performSearch();
          } else {
            this.fetchFolder();
          }

          this.$notify({
            type: 'success',
            offset: 65,
            title: 'Media item deleted successfully',
            message: 'Your media item has been deleted successfully.',
            position: 'top-right',
          });
        }).catch(() => {
          vm.confirm.delete.open = false;
          vm.confirm.delete.loading = false;
        });
    },
    confirmDelete({ id, type }) {
      const vm = this;
      vm.confirm.delete.items = [{ id, type }];
      vm.confirm.delete.open = true;
    },
    handleCommand(command, object) {
      this[command](object);
    },
    onUploaded() {
      if (!this.isSearchMode) {
        this.fetchFolder();
      }
      this.$notify({
        type: 'success',
        title: 'Media Uploaded Successfully',
        message: 'Your media has been uploaded and is waiting approval.',
        position: 'bottom-left',
      });
    },
    previewMedia({ remote_src, bg_color }) {
      const vm = this;
      vm.preview.video_url = remote_src;
      vm.preview.open = true;
      vm.preview.bg_color = bg_color;
    },
    view({ id }) {
      const vm = this;
      vm.settings.loading = true;
      vm.settings.open = true;
      return vm.$http.get(`/api/v1/campaigns/${id}`)
        .then((response) => {
          vm.settings.loading = false;
          vm.settings.data = response.data.data;
        }).catch(() => {
          vm.settings.open = false;
          vm.settings.data = {};
        });
    },
    fetchFolder() {
      const vm = this;
      vm.loading = true;
      const folder = this.fetchFolderId,
        params = {
        order: vm.order,
          sort: vm.sort
        };


      let path = '/api/v1/folder';

      if (folder) {
        params.folderId = folder;
      }

      return vm.$http.get(path, {
        params
      }).then((response) => {
        vm.results = response.data.data.contents;
        vm.path = response.data.data.path;
        vm.loading = false;
      }).catch(() => {
        vm.loading = false;
        vm.path = [];
        vm.results = [];
      });
    },
  },
};
</script>
