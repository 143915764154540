import axios from 'axios';
import { getApiEndpoint } from '@/utils/endpoints';
import store from '../store/index';

axios.defaults.baseURL = getApiEndpoint();

export function authenticate(email, password, recaptcha) {
  return new Promise((resolve, reject) => {
    axios.post('/api/v1/auth/login', {
      email,
      password,
      recaptcha,
    }).then((response) => {
      store.commit('auth/setAccessToken', response.data.data.token);
      store.commit('auth/setRefreshToken', response.data.data.refresh_token);
      return resolve(response);
    })
      .catch((e) => {
        return reject(e);
      });
  });
}

export function register(name, email, password) {
  return new Promise((resolve, reject) => {
    axios.post('/api/v1/auth/register', {
      name,
      email,
      password,
    }).then((response) => {
      store.commit('auth/setAccessToken', response.data.data.token);
      store.commit('auth/setRefreshToken', response.data.data.refresh_token);
      return resolve(response);
    })
      .catch((e) => {
        return reject(e);
      });
  });
}

export function reset(vm) {
  vm.$store.commit('auth/reset');
}
