<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div    class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <!--
          Background backdrop, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->

      <transition
        enter-active-class="transform duration-400 ease-out"
        enter-from-class="transform opacity-0"
        enter-to-class="transform opacity-100"
        leave-active-class="transform duration-400 delay-200  ease-in"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div v-if="show" class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"></div>
      </transition>



      <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="transform opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div v-if="show" class="fixed z-10 inset-0 overflow-y-auto">

            <button
                    v-if="!loading"
                    @click="$emit('close')"
                    type="button" class="text-white fixed top-0 right-0  p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: outline/x -->
                <svg class="h-16 w-16" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>

            <div class="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
                <!--
                  Modal panel, show/hide based on modal state.

                  Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                  Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <div
                        :style="{
                        'background-color': bgColor
                        }"
                        class="relative rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-w-xs">
                       <div class="video-height flex items-center">

                           <video
                                   v-if="visible"
                                   autoplay
                                   loop
                                   muted
                                   class="z-10 w-auto min-w-full min-h-full"
                           >
                               <source
                                       :src="videoSource"
                               />
                           </video>
                       </div>
                </div>
            </div>
        </div>
      </transition>
    </div>
</template>

<script>
export default {
  name: 'MediaPreview',
  props: {
    videoSource: {
      type: String,
      default() {
        return null;
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    bgColor: {
      type: String,
      default() {
        return '#000000';
      },
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    videoSource() {
      const vm = this;
      vm.visible = false;
      vm.$nextTick(() => {
        if (vm.videoSource) {
          vm.visible = true;
        }
      });
    },
  },
};
</script>

<style>
    .video-height {
        min-height: 32rem;
    }
</style>
