<template>

  <div class="h-screen bg-red-600 w-full flex justify-center">

    <div class="sm:mx-auto sm:w-full mt-8 sm:max-w-sm px-6 md:px-0">

      <div class="sm:mx-auto sm:w-full mb-6 flex justify-center items-center text-center sm:max-w-md text-white">
        <svg class="h-10 w-auto"  fill="currentColor" viewBox="0 0 1260 982" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g  fill="currentColor" fill-rule="nonzero">
              <polygon points="479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"></polygon>
              <polygon points="1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"></polygon>
            </g>
          </g>
        </svg>
        <div class="ml-3">
          <h3 class="pr-3 relative text-2xl font-montserrat uppercase font-bold">
            Chargabull <span class="absolute right-0 top-0 text-xs">®</span>
          </h3>
          <h5 class="text text-xs uppercase text-center">Electrify your audience</h5>
        </div>
      </div>

    <div class="bg-white p-4 shadow rounded-lg">

      <div v-if="loading">
        <spin-loader />
      </div>
      <div v-else>
        <div v-if="location.name" class="grid gap-3">
          <div>
            <h4 class="text-lg mt-1 font-semibold text-red-700">{{ location.name }}</h4>
            <h5 class="text-xs text-gray-500 flex-1">
              <i class="el-icon-location"></i>  {{ location.address }}
            </h5>
          </div>
          <div class="rounded border border-gray-200 h-40 w-full">
            <preview-map-object
                v-if="map_visible"
                :lon="location.lng"
                :lat="location.lat" />
          </div>
          <div class="text-center text-sm font-semibold">
            Please confirm your location below to begin charging via Monta.
          </div>
          <div>
            <el-button
                @click="onClick"
                class="w-full" type="success">
              <div class="flex gap-3 items-center justify-center p-1">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg"
                     class="w-6 h-6 text-white"
                     fill="currentColor"
                     viewBox="0 0 24 24"><path d="M0 19h21v-14h-21v14zm9.298-7.21v-3.79l8.535 6.248-5.878-1.728v3.48l-8.789-5.984 6.132 1.774zm14.702-2.54v4.5c0 .69-.56 1.25-1.25 1.25h-.75v-7h.75c.69 0 1.25.56 1.25 1.25z"/></svg>
              </span>
                <span class="font-bold uppercase">Start Charging</span>
              </div>
            </el-button>
          </div>
        </div>
        <div v-else>
          <empty-state
              description="Sorry, but we can't find that chargepoint" />

          <el-button class="w-full" type="success">
            View Map
          </el-button>

        </div>
      </div>
    </div>
    </div>

  </div>
</template>

<script>

import PreviewMapObject from '@/components/PreviewMapObject';
import SpinLoader from '@/components/SpinLoader';
import EmptyState from '@/components/EmptyState';
import { getApiEndpoint } from '@/utils/endpoints';

import axios from 'axios';

axios.defaults.baseURL = getApiEndpoint();

export default {
  name: 'PodQrLandingPage',
  components: {
    EmptyState,
    SpinLoader,
    PreviewMapObject,
  },
  data() {
    return {
      email: null,
      password: null,
      running: false,
      map_visible: false,
      found: false,
      loading: true,
      location: {
        name: null,
        address: null,
        lat: null,
        lng: null,
        forward_endpoint: null,
      },
    };
  },
  methods: {
    onClick() {
      window.location = this.location.forward_endpoint;
    },
  },
  mounted() {
    const vm = this;

    setTimeout(() => {
      vm.map_visible = true;
    }, 500);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('auth/setBreadcrumbs', [
        { title: 'Confirm Location' },
      ]);

      axios.get(`/api/v1/charge-point/${vm.$route.params.locationId}`)
        .then((response) => {
          vm.location = response.data.data;
          vm.loading = false;
        }).catch(() => {
          vm.loading = false;
        });
    });
  },
};
</script>
