import Vue from 'vue';
import Page from '@/components/Page';
import Pagination from '@/components/Pagination';
import MenuSidebarLayer from '@/components/MenuSidebarLayer';
import DateHumanView from '@/components/DateHumanView';
import TableCards from '@/components/TableCards';
import LabelOption from '@/components/LabelOption';
import ResultsPerPage from '@/components/ResultsPerPage';

import LProtobuf from '@/components/LProtobuf/LProtobuf';

import VueApexCharts from 'vue-apexcharts';

import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

import {
  LIcon, LMap, LTileLayer, LMarker, LWMSTileLayer, LControlZoom,
} from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import LeafletGoogleMutant from 'vue2-leaflet-googlemutant';

import VueClickOutsideElement from 'vue-click-outside-element';
import VueDragula from 'vue-dragula';
import { vue3Debounce } from 'vue-debounce';

Vue.use(VueClickOutsideElement);
Vue.use(VueDragula);
Vue.use('date-human-view', DateHumanView);
Vue.use(require('vue-moment'));

Vue.use(VueApexCharts);

Vue.directive('debounce', vue3Debounce({ lock: true }));
Vue.component('popper-js', Popper);
Vue.component('results-per-page', ResultsPerPage);
Vue.component('pagination-details', Pagination);
Vue.component('page-wrapper', Page);
Vue.component('label-option', LabelOption);
Vue.component('menu-sidebar-layer', MenuSidebarLayer);
Vue.component('apex-chart', VueApexCharts);
Vue.component('l-map', LMap);
Vue.component('table-cards', TableCards);
Vue.component('l-wms-tile', LWMSTileLayer);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-icon', LIcon);
Vue.component('l-marker', LMarker);
Vue.component('l-protobuf', LProtobuf);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker-cluster', Vue2LeafletMarkerCluster);
Vue.component('l-google-tile-layer', LeafletGoogleMutant);
