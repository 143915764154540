<template>
    <div v-if="show" class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <!--
          Background backdrop, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end sm:items-center justify-center min-h-full h-full">
                <!--
                  Modal panel, show/hide based on modal state.

                  Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                  Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                    <div class="relative bg-white md:rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:max-w-2xl h-full modal--content-playlist">
                        <div class="bg-white border-b border-gray-200 absolute flex p-4  w-full h-14">
                            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-grow">
                                {{ title }}
                            </h3>
                            <button type="button"
                                    @click="$emit('close')"
                                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                <span class="sr-only">Close</span>
                                <!-- Heroicon name: outline/x -->
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="2" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <div class="content h-full pt-14">
                           <div class="flex w-full h-full">
                               <div class="w-auto flex-grow bg-gray-700 overflow-scroll">
                                   <ul>
                                       <li
                                               @click="onVideoItemClick(i)"
                                               :key="x.id"
                                              v-for="(x, i) of videos"
                                               :class="{
                                               'text-white bg-gray-900 hover:bg-gray-800 border-gray-800': i === current_video_index,
                                               'bg-white hover:bg-gray-50 text-gray-900 border-gray-200': i !== current_video_index,
                                               }"
                                               class="p-2 py-4 flex items-center border-b select-none  cursor-pointer">

                                           <div>
                                               <img v-if="x.screenshot_src"
                                                       :style="{
                                                        'border-color': x.bg_color ? x.bg_color : '#000000',
                                                        }"
                                                       class="h-10 w-10 rounded border-2"
                                                       :src="x.screenshot_src"/>
                                               <div v-else
                                                    :style="{
                                                        'border-color': x.bg_color ? x.bg_color : '#000000',
                                                          'text-gray-900': i === current_video_index,
                                               'text-white': i !== current_video_index,
                                                        }"
                                                    class="text-xl text-center w-10 h-10 flex justify-center items-center rounded border-2">
                                                   <i
                                                           v-if="x.media_type === 'video' && !x.screenshot_src"
                                                           class="el-icon-video-camera"></i>
                                                   <i
                                                           v-if="x.media_type === 'image'  && !x.screenshot_src"
                                                           class="el-icon-picture"></i>
                                               </div>
                                           </div>

                                           <div class="ml-1 flex-grow">
                                               <div class="min-w-0 flex-1 px-2 grid grid-cols-1">
                                                   <p class="text-xs font-medium truncate">
                                                      {{ x.name }}
                                                   </p>
                                               </div>
                                           </div>

                                           <div class="ml-1 text-xs text-right">
                                                  <span v-if="current_video_duration && i === current_video_index">
                                               {{ current_video_time | duration_mm }}/{{  (x.custom_duration ? x.custom_duration : current_video_duration)  | duration_mm }}
                                               </span>
                                               <span v-if="i !== current_video_index">
                                                   {{ (x.custom_duration ? x.custom_duration : x.duration) | duration_mm }}
                                               </span>
                                               </div>

                                       </li>
                                   </ul>
                               </div>
                               <div
                                       :style="{
                                       'background-color': getActiveBackgroundColor
                                       }"
                                       class="w-1/2 relative">

                                 <playlist-item-q-r-code
                                     :enabled="hasActiveQRCode"
                                     :text="getActiveVideoObject.qr_code_text ? getActiveVideoObject.qr_code_text : null"
                                     :position="getActiveVideoObject.qr_code_location ? getActiveVideoObject.qr_code_location : null"
                                     :positions="positions"
                                     :url="getActiveVideoObject.qr_code_url ? getActiveVideoObject.qr_code_url : null"
                                 />

                                   <video
                                           ref="videoObject"
                                           @timeupdate="onVideoTimeUpdated"
                                           @ended="onVideoEnded"
                                           muted
                                           class="z-10 w-full h-full object-fit"
                                   >
                                       <source
                                               :src="getActiveVideoSrc"
                                       />
                                   </video>

                               </div>
                           </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlaylistItemQRCode from '@/components/PlaylistItemQRCode';
import { getQRCodePositions } from '@/utils/qr-codes';

export default {
  name: 'PlaylistPreview',
  components: { PlaylistItemQRCode },
  props: {
    title: {
      type: String,
      default() {
        return null;
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    videos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.onLoaded();
  },
  methods: {
    onVideoItemClick(index) {
      this.current_video_index = index;
      this.$nextTick(() => {
        this.playVideo();
      });
    },
    onVideoEnded() {
      if (this.videos[this.current_video_index + 1]) {
        this.current_video_index++;
      } else {
        this.current_video_index = 0;
      }

      this.$nextTick(() => {
        this.playVideo();
      });
    },
    onLoaded() {
      if (this.videos.length > 0) {
        this.$nextTick(() => {
          this.playVideo();
        });
      }
    },
    onVideoTimeUpdated(e) {
      this.current_video_time = e.target.currentTime;
      this.current_video_duration = e.target.duration;

      try {
        const object = this.videos[this.current_video_index];
        if (object && object.custom_duration) {
          const custom = object.custom_duration;
          if (this.current_video_time && this.current_video_time >= custom) {
            this.$refs.videoObject.pause();
            this.onVideoEnded();
          }
        }
      } catch (e) {
        console.warn(e);
      }
    },
    playVideo() {
      if (this.$refs && this.$refs.videoObject) {
        const object = this.$refs.videoObject;
        object.load();
        object.play();
      }
    },
  },
  computed: {
    hasActiveQRCode() {
      const obj = this.getActiveVideoObject;
      if (obj && obj.qr_code_enabled) {
        return obj.qr_code_enabled;
      }
      return false;
    },
    getActiveVideoObject() {
      if (this.videos[this.current_video_index]) {
        return this.videos[this.current_video_index];
      }
      return null;
    },
    getActiveVideoSrc() {
      const obj = this.getActiveVideoObject;
      if (obj && obj.src) {
        return obj.src;
      }
      return null;
    },
    getActiveBackgroundColor() {
      const obj = this.getActiveVideoObject;
      return obj && obj.bg_color ? obj.bg_color : '#000000';
    },
  },
  data() {
    return {
      current_video_time: 0,
      current_video_duration: 0,
      current_video_index: 0,
      positions: getQRCodePositions(),
    };
  },
};
</script>
