<template>
    <page-wrapper title="Upcoming Events">

        <template slot="nav-search">
            <div class="flex-grow lg:block" :class="{
            '': search.show,
             'hidden': !search.show,
            }">
                <div class="relative">
                    <i class="el-icon-search top-0 left-0 absolute text-lg text-gray-500 ml-4 mt-3"></i>
                    <input
                            placeholder="Search Events"
                            class="w-full p-2 px-4 pl-10 text-gray-500 rounded border border-gray-300 h-10 text-sm"
                            @input="($event) => debounceInput(this, $event)"
                            :value="search.term"/>
                </div>
            </div>
            <div
                    @click="search.show = !search.show"
                    :class="{
                    'flex-0': search.show,
                    'flex-grow': !search.show,
                    }"
                    class="lg:hidden text-right text-2xl text-gray-600 hover:text-gray-900 cursor-pointer">
                <i
                        v-if="!search.show"
                        class="el-icon-search"></i>
                <i
                        v-if="search.show"
                        class="el-icon-close"></i>
            </div>
        </template>

        <template slot="overlays">
            <small-modal :title="event.object.title"
                         @close="() => event.show = false"
                         :show="event.show">
                <template slot="content">
                    <div class="p-4 h-full relative overflow-scroll pb-24">

                        <div class="flex w-full px-2">
                            <div class="w-1/2 p-2">

                                <p>{{  event.object.description }}</p>

                            </div>
                            <div class="w-1/2 p-2">

                                <div class="shadow border border-gray-100 h-full rounded">
                                    <preview-map-object
                                            :lat="event.object.lat"
                                            :lon="event.object.lng"
                                    />
                                </div>

                                <div class="flex flex-wrap w-full">
                                    <div class="w-full">
                                        <dl>
                                            <div class="py-3 grid grid-cols-3">
                                                <dt class="text-sm font-medium text-gray-400">Location</dt>
                                                <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                                                  {{ event.object.name }}
                                                </dd>
                                            </div>
                                        </dl>
                                        <dl>
                                            <div class="py-3 grid grid-cols-3">
                                                <dt class="text-sm font-medium text-gray-400">Address</dt>
                                                <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                                                    {{ event.object.address }}
                                                </dd>
                                            </div>
                                        </dl>
                                        <dl>
                                            <div class="py-3 grid grid-cols-3">
                                                <dt class="text-sm font-medium text-gray-400">Type</dt>
                                                <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                                                    {{ event.object.type }}
                                                </dd>
                                            </div>
                                        </dl>
                                        <dl>
                                            <div class="py-3 grid grid-cols-3">
                                                <dt class="text-sm font-medium text-gray-400">Price</dt>
                                                <dd class="text-sm text-gray-900-0 col-span-2 text-right">
                                                    {{ event.object.booking_price | currency }} <small>excl. VAT</small>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="px-4 w-full border-t border-gray-200 bg-gray-50 flex items-center justify-end fixed bottom-0 h-20 left-0">
                            <el-button
                                    @click="() => event.show = false"
                            >Close</el-button>
                            <el-button
                                    @click="() =>  $router.push('/locations/' + event.object.location_id + '/book?date=' + event.object.date)"
                                    icon="el-icon-date" type="success">Book Event</el-button>
                        </div>
                    </div>
                </template>
            </small-modal>

          <event-popup
              @close="() => event.show = false"
              v-if="event.show"
              :date="event.object.date"
              :title="event.object.title"
              :type="event.object.type"
              :locationName="event.object.name"
              :price="event.object.booking_price"
          :address="event.object.address"
              :lat="event.object.lat"
              :lng="event.object.lng"
              :location-id="event.object.location_id"
          :description="event.object.description"
          />

        </template>
        <template slot="content">

            <div v-if="fetching">
                <spin-loader bg="transparent" height="h-64"/>
            </div>
            <div v-else>
                <div v-if="items.length > 0">
                    <div class="sm:flex sm:items-center mb-3">
                        <div class="sm:flex-auto text-gray-500 overflow-hidden truncate">
                            {{ items.length }} upcoming event{{ items.length > 1 ? 's' : '' }}
                        </div>
                    </div>

                    <ul class="el--playlist">
                        <li
                                class="group relative block select-none hover:bg-gray-50 bg-white"
                                :key="x" v-for="(o, x) of items">
                            <div class="flex items-center">
                                <div
                                        :class="{
                                                        'border-t rounded-t': x === 0,
                                                        'rounded-b': x === items.length - 1
                                                        }"
                                        class="min-w-0 flex-1 flex items-center border-b border-r border-l  border-gray-200  hover:cursor-pointer px-4 py-6">
                                    <div class="min-w-0 flex-1 px-4 grid grid-cols-2 md:grid-cols-4 gap-2">
                                        <div class="flex items-center" @click="viewEvent(o)">
                                            <p class="font-medium text-red-700 text truncate">
                                                {{ o.title }}
                                            </p>
                                        </div>
                                        <div @click="viewEvent(o)"
                                             class="text-left mx-8 flex justify-center items-center hidden md:block">
                                            <p class="font-semibold text-sm">{{ o.name }}</p>
                                            <p class="text-xs">{{ o.address }}</p>
                                        </div>
                                        <div @click="viewEvent(o)" class="mx-3 flex items-center text-sm justify-center">
                                            {{ o.booking_price | currency }} excl. VAT
                                        </div>
                                        <div @click="viewEvent(o)"
                                             class="mx-3 text-center flex justify-center items-center text-gray-700">
                                            <p class="text-sm truncate">
                                                {{ o.date | moment("MMMM Do YYYY") }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mx-2 w-24">
                                        <div class="hidden group-hover:block">
                                            <el-button
                                                    @click="viewEvent(o)"
                                                    type="info" icon="el-icon-view">
                                                View
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>
                <div class v-else>
                    <div class="py-20">
                        <empty-state
                                :description="search.term && search.term.length > 0 ?  'No search results found' : 'Sorry, no upcoming events have been found'"/>
                    </div>
                </div>
            </div>

        </template>
    </page-wrapper>
</template>

<script>

import { debounce } from 'debounce';
import EventPopup from '@/components/EventPopup';
import EmptyState from '@/components/EmptyState';

import SpinLoader from '@/components/SpinLoader';
import SmallModal from '@/components/SmallModal';
import PreviewMapObject from '@/components/PreviewMapObject';

export default {
  name: 'events-page',
  components: {
    EventPopup, PreviewMapObject, SmallModal, SpinLoader, EmptyState,
  },
  data() {
    return {
      fetching: true,
      search: {
        term: '',
        running: false,
        show: true,
      },
      event: {
        show: false,
        object: {
          id: null,
          name: null,
        },
      },
    };
  },
  created() {
    const vm = this;
    vm.fetch();
  },
  methods: {
    debounceInput: debounce((vm, e) => {
      vm.search.term = e.target.value;
      vm.fetch();
    }, 500),
    viewEvent(event) {
      const vm = this;
      event.date = this.$moment(event.date);
      vm.event.object = event;
      vm.event.show = true;
    },
    fetch() {
      const vm = this;
      vm.fetching = true;
      let path = '/api/v1/events';
      if (vm.search.term.length && vm.search.term.length > 0) {
        path += `?q=${vm.search.term}`;
      }
      vm.$http.get(path)
        .then((response) => {
          vm.fetching = false;
          vm.items = response.data.data.map((x) => {
            x.menu_open = false;
            return x;
          });
        }).catch(() => {
          vm.items = [];
          vm.fetching = false;
        });
    },
  },
};
</script>
