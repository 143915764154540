import { getApiEndpoint } from '@/utils/endpoints';

const initialState = {
  accessToken: null,
  refresh_token: null,
  api: getApiEndpoint(),
  showMenu: false,
  google_maps: {
    key: 'AIzaSyAENZy2SL14YFJdl4T9OiaPIgWgKejcCPg',
    opts: {
      type: 'roadmap',
    },
  },
  user: {
    id: null,
    contact_name: null,
    company_name: null,
    email: null,
    is_admin: false,
    language: 'en-GB',
  },
  breadcrumbs: [],
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    reset(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    setBreadcrumbs(state, prop) {
      state.breadcrumbs = prop;
      if (prop.length > 0) {
        document.title = `Chargabull - ${prop.map((item) => item.title).join(' / ')}`;
      } else {
        document.title = 'Chargabull';
      }
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setRefreshToken(state, refresh_token) {
      state.refresh_token = refresh_token;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setUserObject(state, payload) {
      state.user[payload.key] = payload.value;
    },
  },
  actions: {
    handleServerError: (object, params) => {
      const { error } = params;
      const notificationObject = {
        position: 'top-right',
        title: 'Sorry, something is wrong;',
        message: 'We had an internal error occurred. Please contact support',
        type: 'error',
        offset: typeof params.offset === 'number' ? params.offset : 65,
      };

      if (error.response) {
        if (error.response.status === 500) {
          params.vm.$notify(notificationObject);
        }

        if (error.response.status === 404 && error.response.data && error.response.data.message) {
          const { message } = error.response.data;
          if (message.length > 0) {
            params.vm.$notify(Object.assign(notificationObject, {
              message,
              type: 'warning',
            }));
          }
        }

        if (error.response.status === 400 && error.response.data && error.response.data.message) {
          const message = error.response.data.message.replace('body.', '');
          if (message.length > 0) {
            params.vm.$notify(Object.assign(notificationObject, {
              message,
              type: 'warning',
            }));
          }
        }

        if (error.response.status === 422 && error.response.data && error.response.data.message) {
          const { message } = error.response.data;
          if (message.length > 0) {
            params.vm.$notify(Object.assign(notificationObject, {
              message,
              type: 'warning',
            }));
          }
        }
      }
    },
  },
  getters: {
    accessToken: (state) => state.accessToken,
    language: (state) => (state.user.language ? state.user.language : 'en-GB'),
    refreshToken: (state) => state.refresh_token,
    isAuthenticated: (state) => state.accessToken !== null,
    hasBillingAccount: (state) => (state.user.has_account ? state.user.has_account : false),
    emailVerified: (state) => (state.user.email_confirmed ? state.user.email_confirmed : false),
    isAdmin: (state) => (state.user.is_admin ? state.user.is_admin : false),
    getUsername: (state) => {
      if (state.user && state.user.account) {
        return [state.user.account.first_name, state.user.account.last_name].filter((x) => x && x.length > 0).join(' ');
      }
      return null;
    },
    getEmail: (state) => {
      if (state.user && state.user.account) {
        return state.user.account.email;
      }
      return null;
    },
    getBreadcrumbs: (state) => [
      {
        title: 'Chargabull',
        path: '/',
      },
    ].concat(state.breadcrumbs),
  },
};
