<template>
    <div class="text-center p-8 py-0">

      <svg class="w-64 mx-auto"  viewBox="0 0 212 214" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g fill-rule="nonzero">
            <path d="M68.791,208.485 C83.648,217.44 94.247,213.723 98.304,206.542 C106.151,192.655 132.242,164.258 173.587,154.66 C228.348,141.948 216.912,108.196 183.248,90.484 C149.585,72.773 124.414,48.2652 128.224,21.6178 C132.034,-5.0297 94.288,-14.2159 77.502,37.0448 C66.6545,70.171 47.2151,79.046 34.819,81.179 C23.1358,83.19 10.3559,85.713 4.1238,95.797 C-3.4384,108.033 -0.7995,120.411 30.8619,128.9 C75.729,140.93 35.4911,188.413 68.791,208.485 Z" id="Path" fill="#DEE9FF"></path>
            <path d="M30.1905,150.424 C30.8193,150.064 37.9156,147.06 73.219,163.518 C73.116,163.658 73.072,163.724 73.072,163.724 C73.072,163.724 36.3711,176.084 29.1803,160.858 C27.033,156.325 27.6483,153.203 30.1292,150.459 L30.1905,150.424 Z" id="Path" fill="#670200"></path>
            <path d="M148.847,156.652 C141.177,163.1 129.149,167.78 117.543,161.557 C119.377,160.385 121.271,159.178 123.203,157.929 C134.858,150.44 145.125,155.451 148.847,156.652 Z" id="Path" fill="#670200"></path>
            <path d="M162.585,77.349 C170.421,142.601 151.799,154.443 149.726,156.242 C149.125,156.763 148.882,156.725 147.975,156.491 L147.959,156.5 C144.118,155.482 134.859,150.44 123.203,157.928 C121.271,159.177 119.376,160.384 117.542,161.556 C105.652,169.097 96.05,174.411 89.133,171.327 C83.265,168.333 77.975,165.743 73.219,163.518 C37.9157,147.06 30.8194,150.064 30.1907,150.424 C30.9202,150.027 37.6126,146.317 46.6239,139.793 C46.6545,139.776 46.6852,139.758 46.6918,139.734 C58.7365,131.005 74.867,117.249 86.461,99.554 C94.354,87.521 102.076,57.0171 116.975,51.0318 C147.594,39.6092 161.314,64.0763 162.585,77.349 Z" id="Path" fill="#B91E1B"></path>
            <path d="M128.728,76.204 C129.679,73.694 129.586,71.333 128.521,70.93 C127.457,70.526 125.823,72.234 124.872,74.744 C123.922,77.254 124.015,79.615 125.079,80.018 C126.144,80.422 127.778,78.714 128.728,76.204 Z" id="Path" fill="#FFFFFF"></path>
            <path d="M148.224,79.406 C148.898,76.808 148.551,74.471 147.448,74.185 C146.346,73.899 144.907,75.774 144.233,78.371 C143.56,80.969 143.907,83.307 145.009,83.593 C146.111,83.878 147.551,82.004 148.224,79.406 Z" id="Path" fill="#FFFFFF"></path>
            <path d="M136.357,88.945 C136.197,88.944 136.042,88.89 135.918,88.789 C135.794,88.689 135.708,88.549 135.674,88.393 C135.549,87.828 134.994,86.156 134.113,85.908 C133.233,85.659 131.506,86.624 130.807,87.13 C130.657,87.239 130.47,87.284 130.286,87.254 C130.103,87.225 129.939,87.124 129.83,86.974 C129.721,86.823 129.676,86.636 129.706,86.453 C129.735,86.269 129.836,86.105 129.986,85.996 C130.262,85.797 132.728,84.062 134.493,84.56 C136.335,85.08 136.975,87.788 137.041,88.095 C137.064,88.198 137.063,88.304 137.039,88.406 C137.015,88.507 136.968,88.603 136.902,88.684 C136.837,88.765 136.754,88.831 136.659,88.876 C136.565,88.921 136.461,88.945 136.357,88.945 L136.357,88.945 Z" id="Path" fill="#FFFFFF"></path>
          </g>
        </g>
      </svg>

        <h4 class="text-lg font-semibold text-gray-600 my-6">{{ description }}</h4>
    </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    description: {
      type: String,
      default() {
        return 'No data';
      },
    },
  },
};
</script>

<style scoped>

</style>
