<template>
    <page-wrapper v-if="isLoggedIn" :has-content="false" title="Page not found" :show-feed="false">
        <template slot="content-fs">
          <div class="h-full w-full flex items-center justify-center">
            <empty-state description="Sorry, but that page cant be found or seems to be missing."  />
          </div>
        </template>
    </page-wrapper>
  <div v-else class="h-full w-full flex items-center justify-center">
   <div>
     <empty-state description="Sorry, but that page cant be found or seems to be missing."  />
     <div class="text-center">
       <el-button @click="redirect" type="success">Back to Login</el-button>
     </div>
   </div>
  </div>
</template>

<script>
import EmptyState from '@/components/EmptyState';

export default {
  name: 'PageNotFound',
  components: { EmptyState },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
  methods: {
    redirect() {
      window.location = '/';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('auth/setBreadcrumbs', [
        { title: 'Page Not Found' },
      ]);
    });
  },
};
</script>
