<template>
  <div>

      <div v-show="!no_map">
          <div id="street-item-container" class="h-48">
          </div>
      </div>
      <div v-if="no_map">
          <empty-state description="Street View not available" />
      </div>
  </div>
</template>

<script>

import EmptyState from '../EmptyState';

const { google } = window;

export default {
  name: 'StreetViewLayer',
  components: { EmptyState },
  props: {
    location: {
      type: Object,
      default() {
        return {
          lat: 0,
          lng: 0,
        };
      },
    },
  },
  data() {
    return {
      map: null,
      no_map: false,
      estimated_distance: null,
    };
  },
  watch: {
    location: {
      handler() {
        this.fetchNearest();
      },
      deep: true,
    },
  },

  methods: {
    fetchNearest() {
      const vm = this;
      const sv = new google.maps.StreetViewService();
      const location = new google.maps.LatLng(this.location.lat, this.location.lng);
      vm.estimated_distance = null;
      vm.estimated_distance = null;
      sv.getPanoramaByLocation(location, 1000, (data, status) => {
        vm.estimated_street = data && data.location && data.location.description ? data.location.description : null;
        if (data && status === 'OK') {
          vm.map.setPano(data.location.pano);
          vm.map.setPov({
            heading: 270,
            pitch: 0,
          });
        } else {
          vm.no_map = true;
        }
      });
    },
  },
  mounted() {
    const point = new google.maps.LatLng(this.location.lat, this.location.lng);

    this.map = new google.maps.StreetViewPanorama(document.getElementById('street-item-container'), {
      position: point,
      streetViewControl: false,
      fullscreenControl: false,
      addressControl: false,
      enableCloseButton: false,
      scrollwheel: false,
      clickToGo: false,
      pov: {
        heading: 270,
        pitch: 0,
      },
    });

    this.fetchNearest();
  },
};
</script>
<style>
    #street-item-container {
        height: 12rem
    }
</style>
