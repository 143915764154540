var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-wrapper',{attrs:{"title":"Service Status","show-feed":false}},[_c('template',{slot:"content"},[_c('div',{staticClass:"w-full flex grid grid-cols-4 gap-6"},[_c('div',{staticClass:"lg:col-span-3 col-span-4"},[_c('dashboard-widget',{attrs:{"title":"Chargabull Active Connections"}},[(_vm.pods.fetching)?_c('spin-loader',{attrs:{"bg":"transparent","height":"h-32"}}):_c('div',[_c('custom-tabs',{attrs:{"split":"w-1/3","tabs":_vm.getPodTabs},model:{value:(_vm.active_pod_tab),callback:function ($$v) {_vm.active_pod_tab=$$v},expression:"active_pod_tab"}}),(_vm.active_pod_tab === 'all')?_c('div',[_c('ul',{staticClass:"divide-y divide-gray-200",attrs:{"role":"list"}},_vm._l((_vm.allPods),function(p,k){return _c('li',{key:'pod-' + k},[_c('pod-row',{attrs:{"details":p}})],1)}),0)]):_vm._e(),(_vm.active_pod_tab === 'online')?_c('div',[_c('ul',{staticClass:"divide-y divide-gray-200",attrs:{"role":"list"}},_vm._l((_vm.onlinePods),function(p,k){return _c('li',{key:'pod-' + k},[_c('pod-row',{attrs:{"details":p}})],1)}),0)]):_vm._e(),(_vm.active_pod_tab === 'offline')?_c('div',[_c('ul',{staticClass:"divide-y divide-gray-200",attrs:{"role":"list"}},_vm._l((_vm.offlinePods),function(p,k){return _c('li',{key:'pod-' + k},[_c('pod-row',{attrs:{"details":p}})],1)}),0)]):_vm._e()],1)],1)],1),_c('div',{staticClass:"lg:col-span-1 col-span-4"},[_c('dashboard-widget',{attrs:{"title":"Services"}},[(_vm.services.fetching)?_c('spin-loader',{attrs:{"bg":"transparent","height":"h-32"}}):_c('div',{staticClass:"p-5 flex flex-wrap gap-3"},_vm._l((_vm.services.data),function(x,i){return _c('div',{key:'service-' + i,staticClass:"w-full text-sm relative text-gray-800 flex items-center"},[_c('span',{staticClass:"flex relative h-3 w-3"},[_c('span',{staticClass:"animate-ping absolute inline-flex h-full w-full rounded-full opacity-75",class:{
                'bg-green-500': x.ok,
                  'bg-red-500': !x.ok,
                  }}),_c('span',{staticClass:"relative inline-flex rounded-full h-3 w-3",class:{
                'bg-green-500': x.ok,
                  'bg-red-500': !x.ok,
                  }})]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(x.name))])])}),0)],1)],1)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }