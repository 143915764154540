<template>
    <div>
        <div v-if="loading" class="h-screen flex items-center justify-center">
            <spin-loader />
        </div>
        <div v-else>

            <div v-if="edit.status === 'REJECTED' && !isAdmin">
                <el-alert type="error" theme="dark" :closable="false">
                    This media object had been rejected by our validation team and therefor can not be updated.
                </el-alert>

                <div class="pt-4" v-if="edit.rejection_reason">
                    <form-input label="Rejection reason">
                        <textarea class="border resize-none p-3 text-sm text-gray-800 border-gray-200 w-full rounded"
                                   :disabled="updating"
                                   v-model="edit.rejection_reason"
                                   readonly rows="5">
                </textarea>
                    </form-input>
                </div>
            </div>

            <div v-else>

                <div class="grid gap-5">
                    <form-input label="Media Name" required>
                        <el-input
                                :disabled="updating"
                                v-model="edit.name" />
                    </form-input>

                    <form-input v-if="isAdmin" label="Status" required>
                        <el-select
                                :disabled="updating"
                                class="w-full"
                                v-model="edit.status"
                                placeholder="Filter status..."
                        >
                            <el-option
                                    v-for="(item, index) in statuses"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.value"
                            >
                                {{ item.name }}
                            </el-option>
                        </el-select>
                    </form-input>

                    <form-input v-if="isAdmin && edit.status === 'REJECTED'" label="Rejection reason" required>
                        <el-input
                                :disabled="updating"
                                type="textarea" rows="5" v-model="edit.rejection_reason"></el-input>
                    </form-input>

                    <form-input label="Video Flags" v-if="isAdmin && edit.status !== 'REJECTED'" required>
                        <div class="relative flex items-start mb-3"
                             :key="'restrictions-' + x.id"
                             v-for="x of edit.restrictions">
                            <div class="flex items-center h-5">
                                <input
                                        :disabled="updating"
                                        v-model="x.selected"
                                        :id="'restriction-' + x.id" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                            </div>
                            <div class="ml-3 text-sm">
                                <label :for="'restriction-' + x.id" class="font-medium text-gray-700">{{ x.type }}</label>
                                <p class="text-gray-500">
                                    {{ x.description }}
                                </p>
                            </div>
                        </div>
                    </form-input>

                    <form-input label="Background color" required>
                        <el-color-picker
                                :predefine="edit.predefined_colors"
                                :disabled="updating"
                                v-model="edit.bg_color"></el-color-picker>
                    </form-input>

                    <form-input label="Preview" required>
                        <div
                                :style="{
                        'background-color': edit.bg_color
                        }"
                                class="mx-auto relative border border-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-w-xs">
                            <div class="video-height flex items-center">
                                <video
                                        autoplay
                                        loop
                                        muted
                                        class="z-10 w-auto min-w-full min-h-full"
                                >
                                    <source
                                            :src="edit.remote_src"
                                    />
                                </video>
                            </div>
                        </div>
                    </form-input>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import SpinLoader from '../SpinLoader';
import FormInput from '../FormInput';

export default {
  name: 'MediaEdit',
  components: { FormInput, SpinLoader },
  props: {
    isAdmin: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      restrictions: {
        data: [],
        fetching: true,
      },
      statuses: [
        { name: 'Pending', value: 'PENDING' },
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Rejected', value: 'REJECTED' },
      ],
      status_options: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'PAUSED', label: 'Paused' },
      ],
      edit: {
        name: null,
        bg_color: null,
      },
      hide: false,
      loading: true,
      updating: false,
    };
  },
  mounted() {
    this.$emit('hide-actions', true);
    this.fetch();
  },
  computed: {
    getTotalSlots() {
      return this.slots.filter((x) => this.selected_slots.indexOf(x.id) > -1).length;
    },
    buildSelectedSlots() {
      const items = this.slots.filter((x) => this.selected_slots.indexOf(x.id) > -1);

      const objects = {};

      for (const i of items) {
        const key = `${i.location_id}-${i.date}`;
        if (!objects[key]) {
          objects[key] = {
            key,
            type: i.type,
            location_address: i.location_address,
            location_name: i.location_name,
            date: i.date,
            event_description: i.event_description,
            slots: [],
          };
        }
        objects[key].slots.push({
          id: i.id,
          slot: `Slot ${objects[key].slots.length + 1}`,
        });
      }

      return Object.values(objects);
    },
  },
  methods: {
    deleteSlot(slotId) {
      const index = this.selected_slots.findIndex((x) => x === slotId);
      if (index > -1) {
        this.selected_slots.splice(index, 1);
      }
      this.hide = true;
      this.$nextTick(() => {
        this.hide = false;
      });
    },
    update() {
      const vm = this;
      vm.updating = true;
      this.$emit('updating', true);
      return vm.$http.post(`/api/v1/media/${vm.value}`, vm.edit)
        .then(() => {
          vm.updating = false;
          vm.$emit('updating', false);
          this.$notify({
            type: 'success',
            title: 'Media Item Updated',
            message: 'Your media item have been updated successfully.',
            position: 'bottom-left',
          });
          vm.$emit('updated', true);
        }).catch((e) => {
          vm.updating = false;
          vm.$emit('updating', false);
          vm.$store.dispatch('auth/handleServerError', {
            vm,
            error: e,
            offset: 0,
          });
        });
    },
    fetch() {
      const vm = this;
      if (vm.value) {
        vm.loading = true;
        return vm.$http.get(`/api/v1/media/${vm.value}`)
          .then((response) => {
            vm.loading = false;
            vm.edit = response.data.data;
            this.$emit('hide-actions', vm.edit.status === 'REJECTED' && !vm.isAdmin);
          }).catch((e) => {
            console.warn(e);
            vm.loading = false;
            this.$emit('hide-actions', true);
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
