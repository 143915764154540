var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-wrapper',{attrs:{"title":"Invoices","show-feed":false}},[_c('template',{slot:"nav-search"},[_c('div',{staticClass:"flex-grow lg:block",class:{
            '': _vm.search.show,
             'hidden': !_vm.search.show,
            }},[_c('div',{staticClass:"relative"},[_c('i',{staticClass:"el-icon-search top-0 left-0 absolute text-lg text-gray-500 ml-4 mt-3"}),_c('input',{staticClass:"w-full p-2 px-4 pl-10 text-gray-500 rounded border border-gray-300 h-10 text-sm",attrs:{"placeholder":"Search Invoices"},domProps:{"value":_vm.search.term},on:{"input":($event) => _vm.debounceInput(this, $event)}})])]),_c('div',{staticClass:"lg:hidden text-right text-2xl text-gray-600 hover:text-gray-900 cursor-pointer",class:{
                    'flex-0': _vm.search.show,
                    'flex-grow': !_vm.search.show,
                    },on:{"click":function($event){_vm.search.show = !_vm.search.show}}},[(!_vm.search.show)?_c('i',{staticClass:"el-icon-search"}):_vm._e(),(_vm.search.show)?_c('i',{staticClass:"el-icon-close"}):_vm._e()])]),_c('template',{slot:"content"},[(_vm.fetching)?_c('div',[_c('spin-loader',{attrs:{"bg":"transparent","height":"h-64"}})],1):_c('div',[(_vm.items.length > 0)?_c('div',[_c('div',{staticClass:"sm:flex sm:items-center mb-3"},[_c('div',{staticClass:"sm:flex-auto text-gray-500 overflow-hidden truncate"},[_vm._v(" "+_vm._s(_vm.items.length)+" invoices ")])]),_c('ul',{staticClass:"el--playlist"},_vm._l((_vm.items),function(o,x){return _c('li',{key:x,staticClass:"group relative block select-none hover:bg-gray-50 bg-white"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"min-w-0 flex-1 flex items-center border-b border-r border-l border-gray-200 hover:cursor-pointer px-4 py-6",class:{
                                                        'border-t rounded-t': x === 0,
                                                        'rounded-b': x === _vm.items.length - 1
                                                        }},[_c('div',{staticClass:"min-w-0 flex-1 px-4 grid grid-cols-2 md:grid-cols-3 gap-2"},[_c('div',{on:{"click":function($event){return _vm.$router.push('/invoices/' + o.id)}}},[_c('p',{staticClass:"font-medium text-red-700 text truncate"},[_vm._v(" #"+_vm._s(o.invoice_id)+" ")]),_c('p',{staticClass:"text-xs mt-1"},[_vm._v(" "+_vm._s(_vm._f("moment")(o.date,"MMMM Do YYYY"))+" ")])]),_c('div',{staticClass:"mx-3 text-center flex justify-center items-center text-gray-700",on:{"click":function($event){return _vm.$router.push('/invoices/' + o.id)}}},[_c('p',{staticClass:"text-sm truncate"},[_vm._v(" "+_vm._s(_vm._f("currency")(o.total,_vm.$store.getters['auth/language'], o.currency))+" incl. VAT ")])]),_c('div',{staticClass:"text-left flex justify-center items-center hidden md:block",on:{"click":function($event){return _vm.$router.push('/invoices/' + o.id)}}},[(o.status === 'OVERDUE')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("Overdue")]):_vm._e(),(o.status === 'PAID')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("Paid")]):_vm._e()],1)]),_c('div',{staticClass:"mx-2 w-24"},[_c('div',{staticClass:"hidden group-hover:block"},[_c('el-button',{attrs:{"type":"info","icon":"el-icon-view"},on:{"click":function($event){return _vm.$router.push('/invoices/' + o.id)}}},[_vm._v(" View ")])],1)])])])])}),0)]):_c('div',{},[_c('div',{staticClass:"py-20"},[_c('empty-state',{attrs:{"description":_vm.search.term && _vm.search.term.length > 0 ?  'No search results found' : 'You currently have no invoices'}})],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }