<template>
    <router-link :to="link">
        <div class="flex-1 space-y-2">
            <div class="flex items-center text-red-700">
                <svg v-if="icon === 'warning'" class="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>

                <svg v-if="icon === 'video'" class="mr-2 w-5 h-5" viewBox="0 0 515 506" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
                        <g  transform="translate(14.000000, 14.000000)" stroke="currentColor" stroke-width="28">
                            <path d="M312.5,91.5 L15.5,91.5"></path>
                            <path d="M13.5,92.5 L13.5,380.5"></path>
                            <path d="M486.5,0.5 L0.5,477.5"></path>
                            <path d="M358.5,131 L358.5,380.5"></path>
                            <path d="M358.5,380.5 L109.5,380.5"></path>
                            <path d="M358.5,190.5 L486.5,124.5"></path>
                            <path d="M486.5,124.5 L486.5,348.5"></path>
                            <path d="M486.5,348.5 L358.5,288.5"></path>
                        </g>
                    </g>
                </svg>

                <p class="text-sm font-semibold">
                    {{ title }}
                </p>
            </div>
            <p class="text-xs text-gray-500">
                <slot name="content"></slot>
            </p>
            <p class="mt-4 text-sm">
                <router-link :to="link" class="block font-semibold text-gray-800 hover:text-red-700">
                    Manage
                    <span aria-hidden="true"> &rarr;</span>
                </router-link>
            </p>
        </div>
    </router-link>
</template>

<script>
export default {
  name: 'AccountNotification',
  props: {
    link: {
      type: String,
      default() {
        return null;
      },
    },
    title: {
      type: String,
      default() {
        return null;
      },
    },
    icon: {
      type: String,
      default() {
        return 'warning';
      },
    },
  },
};
</script>

<style scoped>

</style>
