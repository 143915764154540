<template>
    <el-tooltip placement="top">
        <div slot="content">
            {{ compVal | moment(format) }}
        </div>
        <div class="inline-block" :class="size">
            <span
                class="inline-block  cursor-pointer border-gray-500 border-b border-dotted">
         {{ prefix }}   {{ diff  }} {{ suffix }}
        </span>
        </div>

    </el-tooltip>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DateHumanView',
  props: {
    format: {
      type: String,
      default() {
        return 'MMM Do YYYY HH:mma';
      },
    },
    diff: {
      type: String,
      default() {
        return null;
      },
    },
    size: {
      type: String,
      default() {
        return 'text-sm';
      },
    },
    value: {
      type: String,
      default() {
        return null;
      },
    },
    prefix: {
      type: String,
      default() {
        return '';
      },
    },
    suffix: {
      type: String,
      default() {
        return '';
      },
    },
  },
  computed: {
    compVal() {
      return moment(this.value).utc(false);
    },
    fromNow() {
      return moment.utc(this.value).utc(false);
    },
  },
};
</script>

<style scoped>

</style>
