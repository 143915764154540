<template>
    <div>
        <div v-if="loading">
            <spin-loader />
        </div>
        <div v-else class="grid gap-y-5">
          <div>
            <div class="h-56 border-b border-gray-100 relative z-20 w-full">
             <preview-map-object
               :zoom="14"
               :bounce="false"
               :show-overlays="true"
               :lat="lat"
               :lon="lon" />
            </div>
            <div class="my-2 flex items-center justify-center gap-x-3">
              <div
                class="flex items-center justify-center gap-x-2 text-sm"
                :key="'poi-' + k"
                v-for="(i, k) of POI">
                <span class="w-3 h-3 rounded-full shadow" :style="'background-color: ' + i.color"></span>
                <span class="font-semibold text-gray-500">{{ i.label }}</span>
              </div>
            </div>
          </div>
            <div>
                <h4 class="font-semibold ml-1 mb-4">Nearby Properties</h4>
                <div class="rounded overflow-hidden border border-gray-100">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-100">
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Type</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24">50m</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24">100m</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24">250m</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24">500m</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24">1km</th>
                        </tr>
                        </thead>
                        <tbody class="bg-white">
                        <!-- Odd row -->
                        <tr
                                :key="k"
                                :class="{
                        'bg-gray-50': k % 2
                        }"
                                v-for="(x, k) of data.poi">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ x.classification }}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                {{ x['50m'] | localeString }}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                {{ x['100m'] | localeString }}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                {{ x['250m'] | localeString }}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                {{ x['500m'] | localeString }}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                {{ x['1km'] | localeString }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h4 class="font-semibold ml-1 mb-4">Local Population</h4>
                <div class="rounded overflow-hidden border border-gray-100">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-100">
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Distance</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24">Population</th>
                        </tr>
                        </thead>
                        <tbody class="bg-white">
                        <!-- Odd row -->
                        <tr
                                :key="k"
                                :class="{
                        'bg-gray-50': k % 2
                        }"
                                v-for="(x, k) of data.population">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ x.label }}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                {{ x.count | localeString }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h4 class="font-semibold ml-1 mb-4">Nearest Transport Links</h4>
                <div class="rounded overflow-hidden border border-gray-100">
                    <table class="min-w-full divide-y divide-gray-100">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Type</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-left">Name</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24">Distance</th>
                        </tr>
                        </thead>
                        <tbody class="bg-white">
                        <!-- Odd row -->
                        <tr
                                :key="k"
                                :class="{
                        'bg-gray-50': k % 2
                        }"
                                v-for="(x, k) of data.transport">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {{ x.type }}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                                {{ x.name }}
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                                {{ x.distance_km }}<small>km</small>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SpinLoader from './SpinLoader';
import PreviewMapObject from "@/components/PreviewMapObject";
import POI from "@/utils/points-of-interest"

export default {
  name: 'PointsOfInterest',
  components: {PreviewMapObject, SpinLoader },
  props: {
    lat: {
      type: Number,
      default(){
        return 0
      }
    },
    lon: {
      type: Number,
      default(){
        return 0
      }
    },
    podId: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      loading: true,
      POI: POI,
      data: {
        poi: [],
        transport: [],
        population: [],
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      const vm = this;
      vm.loading = true;
      return vm.$http.get(`/api/v1/pods/${vm.podId}/poi`)
        .then((response) => {
          vm.loading = false;
          vm.data = response.data.data;
        }).catch(() => {
          vm.loading = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
