<template>
  <div v-if="days && days.length > 0">
    <div class="mb-4">
      <el-alert type="info" :closable="false">
        The times displayed below are reflective of the
        location's local time.
      </el-alert>
    </div>
    <ul>
      <li
        class="group relative block select-none bg-white"
        :class="x % 2 ? 'bg-white' : 'bg-gray-50'"
        :key="x" v-for="(o, x) of days">

        <div class="flex items-center">
          <div
            :class="{
                                                        'border-t rounded-t': x === 0,
                                                        'rounded-b': x === days.length - 1
                                                        }"
            class="min-w-0 flex-1 flex items-center border-b border-r border-l  border-gray-200  px-3 py-4">
            <div class="min-w-0 text-sm flex-1 px-4 grid grid-cols-2 gap-2">
              <div>
                <p class="font-medium text-red-700 text truncate">
                  {{ o.day }}
                </p>
              </div>
              <div
                class="mx-3 text-center text-sm flex justify-end items-center text-gray-700">
                <div v-if="o.enabled">
                  <div class="grid gap-2"  v-if="o.hours && o.hours.length > 0">
                    <div
                      :key="'hours-' + h"
                      v-for="(k, h) of o.hours">
                      {{ k.from }} - {{ k.to }}
                    </div>
                  </div>
                </div>
                <span v-else>
                                                        <el-tag type="warning">Closed</el-tag>
                                                    </span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'OperatingHours',
  props: {
    operatingHours: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      days: [],
    };
  },
  mounted() {
    this.days = this.operatingHours;
  },
  watch: {
    operatingHours() {
      this.days = this.operatingHours && Array.isArray(this.operatingHours) ? this.operatingHours : [];
    },
  },
};
</script>

<style scoped>

</style>
