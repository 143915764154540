<template>
    <label-option label="per page">
        <el-select
                class="w-40"
                size="medium"
                v-model="rpp"
                @change="() => $emit('input', rpp)"
                placeholder="Results per page">
            <el-option
                    v-for="(item, index) in [5, 10, 15, 20]"
                    :key="index"
                    :label="item + ' per page'"
                    :value="item"></el-option>
        </el-select>
    </label-option>
</template>

<script>
export default {
  name: 'ResultsPerPage',
  props: {
    value: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      rpp: this.value,
    };
  },
};
</script>

<style scoped>

</style>
