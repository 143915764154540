import Vue from 'vue';

import {
  Alert,
  Tag,
  Empty,
  Tree,
  Spinner,
  Checkbox,
  Collapse,
  CollapseItem,
  CheckboxGroup,
  Button,
  ButtonGroup,
  DatePicker,
  OptionGroup,
  Input, Notification, Table, TableColumn, Select,
  Option,
  Loading, Pagination, MessageBox, Drawer, Tooltip,
  Tabs,
  Dialog,
  Form,
  FormItem,
  TabPane,
  Popover,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  ColorPicker,
  InputNumber,
  Cascader,
  CascaderPanel,
  Switch,
  TimePicker,
  Progress, Radio, TimeSelect, RadioGroup, RadioButton,
} from 'element-ui';

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

locale.use(lang);

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Radio);
Vue.use(Spinner);
Vue.use(ButtonGroup);
Vue.use(Tree);
Vue.use(TimePicker);
Vue.use(TimeSelect);
Vue.use(InputNumber);
Vue.use(Progress);
Vue.use(ColorPicker);
Vue.use(Cascader);
Vue.use(CascaderPanel);
Vue.use(Drawer);
Vue.use(Form);
Vue.use(Popover);
Vue.use(FormItem);
Vue.use(Empty);
Vue.use(Select);
Vue.use(Switch);
Vue.use(Option);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(DatePicker);
Vue.use(OptionGroup);
Vue.use(Alert);
Vue.use(Table);
Vue.use(Tag);
Vue.use(TableColumn);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Tooltip);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dropdown);
Vue.use(Button);
Vue.use(RadioGroup);
Vue.use(RadioButton);

Vue.prototype.$notify = Notification;
Vue.prototype.$message = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
