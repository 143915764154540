<template>
    <div></div>
</template>
<script>
import L from 'leaflet';
import './Leaflet.VectorGrid.bundled';

function findRealParent(firstVueParent) {
  let found = false;
  while (firstVueParent && !found) {
    if (firstVueParent.mapObject === undefined) {
      firstVueParent = firstVueParent.$parent;
    } else {
      found = true;
    }
  }
  return firstVueParent;
}

export default {
  name: 'LProtobuf',
  props: {
    url: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    url() {
      this.updateLayer();
    },
    options() {
      this.updateLayer();
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.removeLayer();
  },
  methods: {
    init() {
      this.mapObject = L.vectorGrid.protobuf(this.url, this.options);

      if (this.options && this.options.interactive) {
        L.DomEvent.on(this.mapObject, this.$listeners);
      }
      const parent = findRealParent(this.$parent);
      this.deferredMountedTo(parent.mapObject);
    },
    deferredMountedTo(parent) {
      this.mapObject.addTo(parent);
      this.attributionControl = parent.attributionControl;
      for (let i = 0; i < this.$children.length; i++) {
        if (typeof this.$children[i].deferredMountedTo === 'function') {
          this.$children[i].deferredMountedTo(this.mapObject);
        }
      }
    },
    setAttribution(val, old) {
      this.attributionControl.removeAttribution(old);
      this.attributionControl.addAttribution(val);
    },
    removeLayer() {
      const parent = findRealParent(this.$parent);
      parent.removeLayer(this);
    },
    updateLayer() {
      this.removeLayer();
      this.init();
    },
  },
};
</script>
