import Vue from 'vue';
import App from './App.vue';

import './plugins/element.js';
import './plugins/components.js';
import './plugins/filters.js';
import './assets/index.css';
import './assets/elememt-ui/index.css';

import axios from './utils/api';
import store from './store';
import router from './router';

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
