<template>
<page-wrapper title="Chargabull">
    <template slot="x">

        <div class="flex flex-wrap -mx-4">
            <div class="w-full md:w-1/4 p-3">
                <simple-stat-box :loading="loading.stats">
                    <template slot="subtitle">
                        Last 30 days
                    </template>
                    <template slot="title">
                        Video Plays
                    </template>
                    <template slot="value">
                        {{ results.stats.impressions_last_30_days ? results.stats.impressions_last_30_days.toLocaleString() : 0 }}
                    </template>
                </simple-stat-box>
            </div>
            <div class="w-full md:w-1/4 p-3">
                <simple-stat-box :loading="loading.stats">
                    <template slot="title">
                        Upcoming Bookings
                    </template>
                    <template slot="value">
                        {{ results.stats.upcoming_bookings ? results.stats.upcoming_bookings.toLocaleString() : 0 }}
                    </template>
                </simple-stat-box>
            </div>
            <div class="w-full md:w-1/4 p-3">
                <simple-stat-box :loading="loading.stats">
                    <template slot="title">
                        Upcoming Locations
                    </template>
                    <template slot="value">
                        {{ results.stats.upcoming_locations ? results.stats.upcoming_locations.toLocaleString() : 0 }}
                    </template>
                </simple-stat-box>
            </div>
            <div class="w-full md:w-1/4 p-3">
                <simple-stat-box :loading="loading.stats">
                    <template slot="title">
                        Approved Media
                    </template>
                    <template slot="value">
                        {{ results.stats.approved_media ? results.stats.approved_media.toLocaleString() : 0 }}
                    </template>
                </simple-stat-box>
            </div>
            <div class="w-full xl:w-1/2 p-3">
                <dashboard-widget title="Video Plays">

                    <template slot="cta">
                        <el-button @click="() => $router.push('/play-logs')" type="success">View Video Plays</el-button>
                    </template>

                    <spin-loader v-if="loading.impressions" />
                    <div class="p-4" v-else>
                        <apex-chart type="line" height="400" :options="getChartOptions"
                                   :series="results.impressions.series" />
                    </div>
                </dashboard-widget>
            </div>
            <div class="w-full xl:w-1/2 p-3">
                <dashboard-widget title="Upcoming Bookings">
                    <template slot="cta">
                        <el-button
                                @click="() => $router.push('/bookings')"
                                type="success">Manage Bookings</el-button>
                    </template>
                    <spin-loader v-if="loading.bookings" />
                    <div v-else>
                        <div v-if="results.bookings.length > 0">
                            <div class="bg-white shadow overflow-hidden sm:rounded-md">
                                <ul role="list" class="divide-y divide-gray-200">
                                    <li :key="'bookings-' + k" v-for="(r, k) of results.bookings">
                                        <div class="block hover:bg-gray-50 select-none">
                                            <div class="flex items-center p-4">
                                                <div class="min-w-0 flex-1 flex items-center">
                                                    <div class="w-full flex">
                                                        <div class="flex items-center justify-center text-xs px-3">
                                                            {{ r.date | moment("MMMM Do YYYY") }}
                                                        </div>
                                                        <div class="flex-grow px-3">
                                                            <p class="text-sm font-medium text-red-600 truncate">
                                                                {{ r.name }}
                                                            </p>
                                                            <p class="mt-1 flex items-center text-xs text-gray-500">
                                                                <i class="el-icon-location mr-2"></i>
                                                                <span class="truncate">{{ r.address }}</span>
                                                            </p>
                                                        </div>
                                                        <div class="px-2 flex items-center justify-center block">
                                                            <el-tag class="uppercase" :type="r.assigned_media ? 'success' : 'warning'">
                                                                {{ r.assigned_media ? 'Assigned Media' : 'No media assigned' }}
                                                            </el-tag>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="py-10" v-else>
                            <empty-state description="You currently have no upcoming bookings" />
                        </div>
                    </div>
                </dashboard-widget>
            </div>
            <div class="w-full xl:w-1/2 p-3">
                <dashboard-widget title="Upcoming Events">
                    <template slot="cta">
                        <el-button
                                @click="() => $router.push('/events')"
                                type="success">View Events</el-button>
                    </template>
                    <spin-loader v-if="loading.events" />
                    <div v-else>
                        <div v-if="results.events.length > 0">
                            <div class="bg-white shadow overflow-hidden sm:rounded-md">
                                <ul role="list" class="divide-y divide-gray-200">
                                    <li :key="'bookings-' + k" v-for="(r, k) of results.events">
                                        <div class="block hover:bg-gray-50 select-none">
                                            <div class="flex items-center p-4">
                                                <div class="min-w-0 flex-1 flex items-center">
                                                    <div class="w-full flex">
                                                        <div class="flex items-center justify-center text-xs px-3">
                                                            {{ r.date | moment("MMMM Do YYYY") }}
                                                        </div>
                                                        <div class="flex-grow flex items-center px-3">
                                                            <p class="text-sm font-medium truncate">
                                                                {{ r.title }}
                                                            </p>
                                                        </div>
                                                        <div class="flex-grow px-3">
                                                            <p class="text-sm font-medium text-red-600 truncate">
                                                                {{ r.name }}
                                                            </p>
                                                            <p class="mt-2 text-xs flex items-center text-sm text-gray-500 lead">
                                                                <i class="el-icon-location mr-2"></i>
                                                                <span class="truncate">{{ r.address }}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="py-10" v-else>
                            <empty-state description="There are currently no upcoming events" />
                        </div>
                    </div>
                </dashboard-widget>
            </div>
            <div class="w-full xl:w-1/2 p-3">
                <dashboard-widget title="My Videos">
                    <template slot="cta">
                        <el-button
                                @click="() => $router.push('/media')"
                                type="success">Manage Videos</el-button>
                    </template>
                    <spin-loader v-if="loading.media" />
                    <div v-else>
                        <div v-if="results.media.length > 0">
                            <div class="bg-white shadow overflow-hidden sm:rounded-md">
                                <ul role="list" class="divide-y divide-gray-200">
                                    <li :key="'media-' + k" v-for="(m, k) of results.media">
                                        <div class="block hover:bg-gray-50 select-none">
                                            <div class="flex items-center p-4">
                                                <div class="min-w-0 flex-1 flex items-center">
                                                    <div class="w-full flex items-center">
                                                        <div class="text-xs flex-grow">
                                                            <div class="flex items-center ">
                                                                <span  :style="{ background: m.bg_color   }" class="w-3 h-3 mr-2 rounded flex-none border border-gray-200"></span>
                                                                <span>{{ m.media_name }}</span>
                                                            </div>
                                                           <div class="mt-1 text-xs"><i class="el-icon-time"></i>  {{ m.duration }} seconds</div>
                                                        </div>
                                                        <div class="flex items-center  flex-grow-0  text-right justify-center text-xs text-xs px-3">
                                                            {{ m.impressions }} plays
                                                        </div>
                                                        <div class="flex items-center justify-center text-xs px-3 w-32">
                                                            <media-status v-model="m.status" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="py-10" v-else>
                            <empty-state description="You currently have no upcoming bookings" />
                        </div>
                    </div>
                </dashboard-widget>
            </div>
        </div>

    </template>
</page-wrapper>
</template>

<script>

import moment from 'moment';
import SimpleStatBox from '@/components/Report/SimpleStatBox';
import DashboardWidget from '@/components/DashboardWidget';
import SpinLoader from '@/components/SpinLoader';

import EmptyState from '@/components/EmptyState';
import MediaStatus from '@/components/MediaStatus';
import { impressionChartOptions } from '../utils/chart';

export default {
  name: 'dashboard-page',
  components: {
    MediaStatus, EmptyState, SpinLoader, DashboardWidget, SimpleStatBox,
  },
  data() {
    return {
      loading: {
        stats: true,
        media: true,
        bookings: true,
        events: true,
        impressions: true,
      },
      results: {
        stats: {},
        media: [],
        events: [],
        bookings: [],
        impressions: {
          series: [],
          categories: [],
        },
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$router.push('/media');
    });
  },
  computed: {
    getChartOptions() {
      const opts = impressionChartOptions({
        plotBy: 'plays',
        categories: this.results.impressions.categories,
      });
      return opts;
    },
  },
  methods: {
    fetchImpressionChart() {
      const vm = this;
      vm.loading.impressions = true;
      return vm.$http.get('/api/v1/impressions/chart', {
        params: {
          from: moment().subtract(14, 'days').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
          plot_by: 'plays',
          group_by: 'video',
        },
      }).then((response) => {
        vm.loading.impressions = false;
        vm.results.impressions.series = response.data.data.series;
        vm.results.impressions.categories = response.data.data.categories;
      }).catch(() => {
        vm.loading.impressions = false;
        vm.results.impressions.series = [];
        vm.results.impressions.categories = [];
      });
    },
    fetchMedia() {
      const vm = this;
      vm.loading.media = true;
      return vm.$http.get('/api/v1/media', {
        params: {
          page: 1,
          rpp: 5,
          sort: 'uploaded_at',
        },
      }).then((response) => {
        vm.results.media = response.data.data.results;
        vm.loading.media = false;
      }).catch(() => {
        vm.loading.media = false;
        vm.results.media = [];
      });
    },
    fetchEvents() {
      const vm = this;
      vm.loading.events = true;
      return vm.$http.get('/api/v1/events', {
        params: {
          page: 1,
          rpp: 10,
          sort: 'date',
          order: vm.results.default_sort && vm.results.default_sort.order ? vm.results.default_sort.order === 'descending' ? 'desc' : 'asc' : 'desc',
        },
      }).then((response) => {
        vm.results.events = response.data.data.results;
        vm.loading.events = false;
      }).catch(() => {
        vm.loading.events = false;
        vm.results.events = [];
      });
    },
    fetchBookings() {
      const vm = this;
      vm.loading.bookings = true;
      return vm.$http.get('/api/v1/bookings', {
        params: {
          view: 'overview',
        },
      }).then((response) => {
        vm.results.bookings = response.data.data;
        vm.loading.bookings = false;
      }).catch(() => {
        vm.loading.bookings = false;
        vm.results.bookings = [];
      });
    },
    fetchStats() {
      const vm = this;
      vm.loading.stats = true;
      return vm.$http.get('/api/v1/stats')
        .then((response) => {
          vm.results.stats = response.data.data;
          vm.loading.stats = false;
        }).catch(() => {
          vm.results.stats = {};
          vm.loading.stats = false;
        });
    },
  },
  created() {
    this.fetchMedia();
    this.fetchEvents();
    this.fetchImpressionChart();
    this.fetchBookings();
    this.fetchStats();
  },
};
</script>
