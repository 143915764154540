<template>
    <section class="text-center">
        <div class="flex items-center text-gray-900">
            <button
                    @click="prevMonth"
                    :class="{
                    'text-gray-400 hover:text-gray-500': !isDisabledPrevious,
                    'text-gray-200 cursor-not-allowed': isDisabledPrevious
                    }"
                    type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5">
                <span class="sr-only">Previous month</span>
                <svg class="h-5 w-5" x-description="Heroicon name: solid/chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                </svg>
            </button>
            <h2 class="flex-auto font-semibold">{{ month }} {{ year }}</h2>
            <button
                    @click="nextMonth"
                    :class="{
                    'text-gray-400 hover:text-gray-500': !isDisabledNext,
                    'text-gray-200 cursor-not-allowed': isDisabledNext
                    }"
                    type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5">
                <span class="sr-only">Next month</span>
                <svg class="h-5 w-5" x-description="Heroicon name: solid/chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                </svg>
            </button>
        </div>
        <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>MON</div>
            <div>TUES</div>
            <div>WED</div>
            <div>THUR</div>
            <div>FRI</div>
            <div>SAT</div>
            <div>SUN</div>
        </div>
        <div class="mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm text-gray-900 shadow ring-1 ring-gray-200 overflow-hidden">
            <calendar-day
                    :key="k"
                    v-for="(d, k) of buildDateRange"
                    :date="d.date"
                    @remove="removeDay"
                    @select="selectDay"
                    @event-select="onEventSelect"
                    :current-month="d.is_current_month"
                    :is-disabled="d.is_disabled"
                    :event-location="d.event_location"
                    :selected="d.is_selected"
                    :price="d.price"
                    :currency="currency"
                    :is-special="d.is_special"
                    :tooltip-description="d.description"
                    :tooltip-title="d.title"
            />
        </div>
    </section>
</template>

<script>

import { parse } from 'fecha';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import CalendarDay from './CalendarDay';

const moment = extendMoment(Moment);

export default {
  name: 'calendar-month',
  props: {
    isDisabledPrevious: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isDisabledNext: {
      type: Boolean,
      default() {
        return false;
      },
    },
    defaultPrice: {
      type: Number,
      default() {
        return null;
      },
    },
    currency: {
      type: String,
      default() {
        return 'GBP';
      },
    },
    month: {
      type: String,
      default() {
        return null;
      },
    },
    year: {
      type: String,
      default() {
        return null;
      },
    },
    specificPricing: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedDays: {
      type: Array,
      default() {
        return [];
      },
    },
    unavailableDates: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    CalendarDay,
  },
  methods: {
    onEventSelect(object) {
      this.$emit('event-select', object);
    },
    prevMonth() {
      if (!this.isDisabledPrevious) {
        this.$emit('prev-month');
      }
    },
    nextMonth() {
      if (!this.isDisabledNext) {
        this.$emit('next-month');
      }
    },
    removeDay(moment) {
      this.$emit('remove', moment);
    },
    selectDay(moment) {
      this.$emit('select', moment);
    },
  },
  computed: {
    buildDateRange() {
      const start = parse(`${this.month} 1st, ${this.year}`, 'MMMM Do, YYYY');
      const startMoment = moment(start);
      const endMoment = moment(start).endOf('month');

      const range = moment().range(startMoment, endMoment);
      const days = Array.from(range.by('day', { step: 1 })).map((x) => x);

      if (days.length > 0) {
        while (days[0].format('e') > 1) {
          const first = days[0];
          days.unshift(first.clone().subtract(1, 'day'));
        }

        while (days.length < 35) {
          const last = days[days.length - 1];
          days.push(last.clone().add(1, 'day'));
        }
        while (days.length % 7 !== 0) {
          const last = days[days.length - 1];
          days.push(last.clone().add(1, 'day'));
        }
      }

      return days.map((x) => {
        let price = this.defaultPrice; let description = null; let title = null; let
          event_location = null;

        const find = this.specificPricing.find((d) => d.date === x.format('YYYY-MM-DD'));

        if (find) {
          title = find.title;
          description = find.description;
          price = find.price;
          event_location = find.location;
        }

        return {
          date: x,
          is_current_month: x.isBetween(startMoment, endMoment)
                            || startMoment.format('YYYY-MM-DD') === x.format('YYYY-MM-DD')
                            || endMoment.format('YYYY-MM-DD') === x.format('YYYY-MM-DD'),
          is_selected: this.selectedDays.filter((d) => d.format('YYYY-MM-DD') === x.format('YYYY-MM-DD')).length > 0,
          is_disabled: this.unavailableDates.filter((d) => d.format('YYYY-MM-DD') === x.format('YYYY-MM-DD')).length > 0 || x.isBefore(moment().subtract(1, 'day')),
          price,
          is_special: price !== this.defaultPrice,
          title,
          description,
          event_location,
        };
      });
    },
  },
};
</script>

<style scoped>

</style>
