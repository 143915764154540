var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('spin-loader')],1):_c('div',{staticClass:"grid gap-y-5"},[_c('div',[_c('div',{staticClass:"h-56 border-b border-gray-100 relative z-20 w-full"},[_c('preview-map-object',{attrs:{"zoom":14,"bounce":false,"show-overlays":true,"lat":_vm.lat,"lon":_vm.lon}})],1),_c('div',{staticClass:"my-2 flex items-center justify-center gap-x-3"},_vm._l((_vm.POI),function(i,k){return _c('div',{key:'poi-' + k,staticClass:"flex items-center justify-center gap-x-2 text-sm"},[_c('span',{staticClass:"w-3 h-3 rounded-full shadow",style:('background-color: ' + i.color)}),_c('span',{staticClass:"font-semibold text-gray-500"},[_vm._v(_vm._s(i.label))])])}),0)]),_c('div',[_c('h4',{staticClass:"font-semibold ml-1 mb-4"},[_vm._v("Nearby Properties")]),_c('div',{staticClass:"rounded overflow-hidden border border-gray-100"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_vm._m(0),_c('tbody',{staticClass:"bg-white"},_vm._l((_vm.data.poi),function(x,k){return _c('tr',{key:k,class:{
                    'bg-gray-50': k % 2
                    }},[_c('td',{staticClass:"whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"},[_vm._v(" "+_vm._s(x.classification)+" ")]),_c('td',{staticClass:"whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"},[_vm._v(" "+_vm._s(_vm._f("localeString")(x['50m']))+" ")]),_c('td',{staticClass:"whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"},[_vm._v(" "+_vm._s(_vm._f("localeString")(x['100m']))+" ")]),_c('td',{staticClass:"whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"},[_vm._v(" "+_vm._s(_vm._f("localeString")(x['250m']))+" ")]),_c('td',{staticClass:"whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"},[_vm._v(" "+_vm._s(_vm._f("localeString")(x['500m']))+" ")]),_c('td',{staticClass:"whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"},[_vm._v(" "+_vm._s(_vm._f("localeString")(x['1km']))+" ")])])}),0)])])]),_c('div',[_c('h4',{staticClass:"font-semibold ml-1 mb-4"},[_vm._v("Local Population")]),_c('div',{staticClass:"rounded overflow-hidden border border-gray-100"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_vm._m(1),_c('tbody',{staticClass:"bg-white"},_vm._l((_vm.data.population),function(x,k){return _c('tr',{key:k,class:{
                    'bg-gray-50': k % 2
                    }},[_c('td',{staticClass:"whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"},[_vm._v(" "+_vm._s(x.label)+" ")]),_c('td',{staticClass:"whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"},[_vm._v(" "+_vm._s(_vm._f("localeString")(x.count))+" ")])])}),0)])])]),_c('div',[_c('h4',{staticClass:"font-semibold ml-1 mb-4"},[_vm._v("Nearest Transport Links")]),_c('div',{staticClass:"rounded overflow-hidden border border-gray-100"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-100"},[_vm._m(2),_c('tbody',{staticClass:"bg-white"},_vm._l((_vm.data.transport),function(x,k){return _c('tr',{key:k,class:{
                    'bg-gray-50': k % 2
                    }},[_c('td',{staticClass:"whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"},[_vm._v(" "+_vm._s(x.type)+" ")]),_c('td',{staticClass:"whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left"},[_vm._v(" "+_vm._s(x.name)+" ")]),_c('td',{staticClass:"whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"},[_vm._v(" "+_vm._s(x.distance_km)),_c('small',[_vm._v("km")])])])}),0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gray-100"},[_c('tr',[_c('th',{staticClass:"py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",attrs:{"scope":"col"}},[_vm._v("Type")]),_c('th',{staticClass:"px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24",attrs:{"scope":"col"}},[_vm._v("50m")]),_c('th',{staticClass:"px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24",attrs:{"scope":"col"}},[_vm._v("100m")]),_c('th',{staticClass:"px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24",attrs:{"scope":"col"}},[_vm._v("250m")]),_c('th',{staticClass:"px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24",attrs:{"scope":"col"}},[_vm._v("500m")]),_c('th',{staticClass:"px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24",attrs:{"scope":"col"}},[_vm._v("1km")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gray-100"},[_c('tr',[_c('th',{staticClass:"py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",attrs:{"scope":"col"}},[_vm._v("Distance")]),_c('th',{staticClass:"px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24",attrs:{"scope":"col"}},[_vm._v("Population")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gray-50"},[_c('tr',[_c('th',{staticClass:"py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",attrs:{"scope":"col"}},[_vm._v("Type")]),_c('th',{staticClass:"px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-left",attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{staticClass:"px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-right w-24",attrs:{"scope":"col"}},[_vm._v("Distance")])])])
}]

export { render, staticRenderFns }