var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-2 -mx-2"},[_c('div',{staticClass:"flex-grow m-2"},[_c('el-time-select',{staticClass:"el-select--time-picker-custom",attrs:{"size":"small","placeholder":"Start time","picker-options":{
   start: '00:15',
  step: '00:15',
  end: '23:45',
}},model:{value:(_vm.object.from),callback:function ($$v) {_vm.$set(_vm.object, "from", $$v)},expression:"object.from"}})],1),_c('div',{staticClass:"w-10 m-2 flex items-center justify-center"},[_vm._v(" to ")]),_c('div',{staticClass:"flex-grow m-2"},[_c('el-time-select',{staticClass:"el-select--time-picker-custom",attrs:{"size":"small","placeholder":"End time","picker-options":{
   start: '00:15',
  step: '00:15',
  end: '23:45',
        minTime: _vm.object.from
}},model:{value:(_vm.object.to),callback:function ($$v) {_vm.$set(_vm.object, "to", $$v)},expression:"object.to"}})],1),_c('div',{staticClass:"w-10 m-2 flex items-center justify-center"},[_c('el-tooltip',{attrs:{"content":"Delete"}},[_c('i',{staticClass:"el-icon-delete cursor-pointer text-red-700",on:{"click":function($event){return _vm.$emit('delete')}}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }