<template>
    <div class="sm:pt-2" :class="{
    'sm:grid sm:grid-cols-5 sm:gap-4 sm:items-start ': !clearLabel,
    'w-full': clearLabel
    }">
        <label class="block text-sm font-medium text-gray-700 sm:mt-px col-span-2 sm:h-full sm:flex" :class="{
        'mb-3': clearLabel,
        'sm:items-center': labelPosition === 'center',
                'sm:items-start sm:mt-2': labelPosition === 'start',
        }">
            {{ label }}

            <el-tooltip
                    :disabled="tooltip === null"
                    :content="tooltip" placement="top">
                <div slot="content">
                    <span class="text-center w-40 block" v-html="tooltip"></span>
                </div>

                <svg v-if="tooltip !== null" xmlns="http://www.w3.org/2000/svg"
                     class="w-3 h-3 m-2 my-3 text-blue-600 inline-block flex-grow-0 cursor-pointer"
                     fill="currentColor"
                     viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"/></svg>

            </el-tooltip>
            <span class="text-red-500 ml-1" v-if="required">*</span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3">
        <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    label: {
      type: String,
      default() {
        return '';
      },
    },
    labelPosition: {
      type: String,
      default() {
        return 'center';
      },
    },
    tooltip: {
      type: String,
      default() {
        return null;
      },
    },
    clearLabel: {
      type: Boolean,
      default() {
        return false;
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

<style scoped>

</style>
