<template>
    <div class="flex mb-2 -mx-2">
        <div class="flex-grow m-2">
            <el-time-select
                    v-model="object.from"
                    size="small"
                    class="el-select--time-picker-custom"
                    placeholder="Start time"
                    :picker-options="{
       start: '00:15',
      step: '00:15',
      end: '23:45',
    }" />
        </div>
        <div class="w-10 m-2 flex items-center justify-center">
            to
        </div>
        <div class="flex-grow m-2">
            <el-time-select
                    v-model="object.to"
                    size="small"
                    class="el-select--time-picker-custom"
                    placeholder="End time"
                    :picker-options="{
       start: '00:15',
      step: '00:15',
      end: '23:45',
            minTime: object.from
    }" />
        </div>
        <div class="w-10 m-2 flex items-center justify-center">
            <el-tooltip content="Delete">
                <i
                        @click="$emit('delete')"
                        class="el-icon-delete cursor-pointer text-red-700"></i>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CustomTimeSelect',
  props: {
    value: {
      type: Object,
      default() {
        return {
          from: '07:00',
          to: '22:00',
        };
      },
    },
  },
  mounted() {
    this.object = this.value;
  },
  data() {
    return {
      object: {
        from: '07:00',
        to: '22:00',
      },
    };
  },
  watch: {
    object: {
      deep: true,
      handler() {
        this.$emit('input', this.object);
      },
    },
    value: {
      deep: true,
      handler() {
        if (this.value && this.value.from) {
          this.object.from = this.value.from;
        }
        if (this.value && this.value.to) {
          this.object.to = this.value.to;
        }
      },
    },
  },
};
</script>

<style scoped>

</style>
