<template>
    <div v-if="value && value.invoice">
        <div class="flex flex-wrap w-full -mx-2">
            <div class="w-full md:w-2/3 p-2">

                <div class="flex items-center flex-shrink-0 px-4 mb-12 mt-1 text-red-600">
                    <svg class="h-10 w-auto"  fill="currentColor" viewBox="0 0 1260 982" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g  fill="currentColor" fill-rule="nonzero">
                                <polygon id="Path" points="479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"></polygon>
                                <polygon id="Path" points="1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"></polygon>
                            </g>
                        </g>
                    </svg>

                    <div class="ml-3">
                        <h3 class="pr-3 relative text-2xl font-montserrat uppercase font-bold">
                            Chargabull <span class="absolute right-0 top-0 text-xs">®</span>
                        </h3>
                        <h5 class="text-xs text-center">Electrify your audience</h5>
                    </div>

                </div>

                <h3 class="mb-8 text-3xl font-bold mt-12">Sales Invoice</h3>

                <p>
                             <span
                                     :key="k"
                                     v-for="(x, k) of getBillingAddress">
                                    {{ x }} <br />
                                </span>
                </p>

            </div>
            <div class="w-full md:w-1/3 p-2">

                <div class="flex mb-2 text-sm w-full">
                    <div class="w-1/2 font-semibold">Invoice ID</div>
                    <div class="w-1/2 text-right">{{ value.invoice.invoice_id }}</div>
                </div>
                <div class="flex mb-2 text-sm w-full">
                    <div class="w-1/2 font-semibold">Date of issue</div>
                    <div class="w-1/2 text-right">{{value.invoice.date | moment("MMMM Do YYYY") }}</div>
                </div>
                <div class="flex mb-2 text-sm">
                    <div class="w-1/2 font-semibold">Status</div>
                    <div class="w-1/2 text-right">
                        <el-tag type="success" v-if="value.invoice.status === 'PAID'">Paid</el-tag>
                        <el-tag v-else type="warning">Overdue</el-tag>
                    </div>
                </div>
                <div class="flex mb-2 text-sm" v-if="value.invoice.date_paid">
                    <div class="w-1/2 font-semibold">Date Paid</div>
                    <div class="w-1/2 text-right">{{ value.invoice.date_paid }}</div>
                </div>
                <div class="flex mb-2 text-sm">
                    <div class="w-1/2 font-semibold">VAT No</div>
                    <div class="w-1/2 text-right">{{ value.invoice.vat_number }}</div>
                </div>

                <p class="leading-tight text-sm mt-8">
                    <strong>IntelliEV Ltd t/a Chargabull</strong><br/>
                    <span
                            :key="k"
                            v-for="(x, k) of getRegisteredAddress">
                                    {{ x }} <br />
                                </span>
                </p>

            </div>
        </div>

        <div class="mt-8 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead v-if="value.rows.length > 0">
                        <tr>
                            <th scope="col"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-red-600 uppercase sm:pl-6 md:pl-0">
                                Location
                            </th>
                            <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-red-600 w-40 uppercase text-left  hidden md:table-cell">
                                Date
                            </th>
                            <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-red-600 w-28 uppercase text-right  hidden md:table-cell">
                                Price
                            </th>
                            <th scope="col"
                                class="py-3.5 px-3 text-left text-sm font-semibold text-red-600 w-28 uppercase text-right hidden md:table-cell">
                                Slots
                            </th>
                            <th scope="col"
                                class="py-3.5 px-3 text-left text-sm font-semibold text-red-600 w-28 uppercase text-right">
                                Amount
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="border-gray-200 border-b"
                            :key="'row-' + k"
                            v-for="(r, k) of value.rows">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 md:pl-0">
                                <strong>{{ r.name }}</strong><br/>
                                {{ r.address }} <span class="md:hidden mt-2 block">
                                            {{ r.date | moment("MMMM Do YYYY") }} -  {{  r.slots ? r.slots.toLocaleString() : 0 }} slot(s) @ {{ r.price | currency($store.getters['auth/language'], value.invoice.currency) }}
                                        </span>
                            </td>
                            <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-left hidden md:table-cell">
                                {{ r.date | moment("MMMM Do YYYY") }}
                            </td>
                            <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-right hidden md:table-cell">
                                {{ r.price | currency($store.getters['auth/language'], value.invoice.currency) }}
                            </td>
                            <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-right hidden md:table-cell">
                                {{  r.slots ? r.slots.toLocaleString() : 0 }}
                            </td>
                            <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-right">{{
                                r.amount | currency($store.getters['auth/language'], value.invoice.currency) }}
                            </td>
                        </tr>

                        <tr>
                            <td class="pt-12"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <table>
                <tbody>
                <tr>
                    <td colspan="4"
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-red-600 sm:pl-6 md:pl-0 text-right uppercase">
                        Sub Total
                    </td>
                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-right">{{
                        value.invoice.sub_total | currency($store.getters['auth/language'], value.invoice.currency) }}
                    </td>
                </tr>
                <tr v-if="value.invoice.discount">
                    <td colspan="4"
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-red-600 sm:pl-6 md:pl-0 text-right uppercase">
                        Discount ({{ value.invoice.discount_rate }}%)
                    </td>
                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-right">-{{
                        value.invoice.discount | currency($store.getters['auth/language'], value.invoice.currency) }}
                    </td>
                </tr>
                <tr>
                    <td colspan="4"
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-red-600 sm:pl-6 md:pl-0 text-right uppercase">
                        VAT ({{ value.invoice.vat_rate }}%)
                    </td>
                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-right">{{
                        value.invoice.vat | currency($store.getters['auth/language'], value.invoice.currency) }}
                    </td>
                </tr>
                <tr>
                    <td colspan="4"
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-bold text-red-600 sm:pl-6 md:pl-0 text-right uppercase">
                        Total Due
                    </td>
                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-right">{{
                        value.invoice.total | currency($store.getters['auth/language'], value.invoice.currency) }}
                    </td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>
</template>

<script>
export default {
  name: 'InvoiceView',
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    getRegisteredAddress() {
      return Object.values(this.value.registered_address);
    },
    getBillingAddress() {
      return Object.values(this.value.billing_address).filter((x) => x && x.length > 0);
    },
  },
};
</script>

<style scoped>

</style>
