<template>
  <div class="grid grid-cols-8 gap-6 md:gap-3 p-5 border-b">
    <div class="col-span-6 lg:col-span-3">
      <div  @click="onListItemClick" class="flex gap-4 cursor-pointer">
        <div class="flex-shrink-0">
          <img
              class="h-12 w-12 rounded border-2"
              :style="{
                                                        'border-color': bgColor ? bgColor : '#000000',
                                                        }"
              :src="screenshot"
           alt=""/>
        </div>
        <div class="min-w-0 flex-1 flex flex-wrap items-center">
          <p class="text-sm font-medium text-red-700 truncate w-full">
            {{ name }}<span v-if="fileExtension">.{{ fileExtension }}</span>
          </p>

          <div v-if="qrCodeEnabled" class="flex w-full">
            <div v-if="qrCodeEnabled" class="text-xs flex items-center w-full">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"/>
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"/>
              </svg>
              <span class="ml-1">QR code</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-span-2 lg:col-span-5 flex items-center w-full text-sm">
      <div class="grid gap-3 w-full grid-cols-4">
        <div class="col-span-3 hidden lg:block">
          <div class="grid gap-1 grid-cols-6">
            <div class="flex col-span-2 items-center text-xs justify-center">
              {{ mediaType === 'video' ? 'Video' : 'Image' }}
            </div>
            <div class="flex col-span-2 items-center justify-center">

              <el-tooltip placement="top"
                          v-if="!disabledMedia"
                          :disabled="!(customDurationEnabled && customDuration)"
                          content="Custom duration set">
                                                                <span class="flex items-center justify-center gap-1 text-xs">

                                                                  <svg
                                                                      v-if="customDurationEnabled && customDuration"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      class="w-4 h-4 text-gray-600"
                                                                      fill="currentColor"
                                                                      viewBox="0 0 24 24"><path d="M6 14h6v-6c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6zm16 0c0 5.523-4.478 10-10 10s-10-4.477-10-10 4.478-10 10-10 10 4.477 10 10zm-2 0c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8 8-3.589 8-8zm-6-11.819v-2.181h-4v2.181c1.408-.238 2.562-.243 4 0zm6.679 3.554l1.321-1.321-1.414-1.414-1.407 1.407c.536.402 1.038.844 1.5 1.328z"/></svg>

                                                                {{
                                                                    customDurationEnabled && customDuration ?
                                                                        customDuration : duration
                                                                  }} secs
                                                                </span>
              </el-tooltip>

            </div>
            <div class="flex col-span-2 gap-3 items-center justify-center">

              <el-tooltip
                  v-if="customAvailability"
                  placement="top" content="Custom day/time set">
              <svg xmlns="http://www.w3.org/2000/svg"
                   fill="currentColor"
                   class="w-6 h-6 text-gray-500"
                   viewBox="0 0 24 24"><path d="M17 3v-2c0-.552.447-1 1-1s1 .448 1 1v2c0 .552-.447 1-1 1s-1-.448-1-1zm-12 1c.553 0 1-.448 1-1v-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5zm-14.237 3.5h-7.763v-13h19v1.763c.727.33 1.399.757 2 1.268v-9.031h-3v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-9v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-3v21h11.031c-.511-.601-.938-1.273-1.268-2z"/></svg>
              </el-tooltip>

              <el-tooltip
                  v-if="customWeatherAvailability"
                  placement="top" content="Custom weather set">
                <svg xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor"
                     class="w-6 h-6 text-yellow-500"
                     viewBox="0 0 24 24"><path d="M4.069 13h-4.069v-2h4.069c-.041.328-.069.661-.069 1s.028.672.069 1zm1.618 3.897l-2.879 2.88 1.414 1.414 2.879-2.88c-.527-.411-1.001-.885-1.414-1.414zm1.416-11.209l-2.88-2.881-1.415 1.414 2.881 2.881c.411-.529.885-1.003 1.414-1.414zm11.208 1.414l2.881-2.881-1.414-1.414-2.881 2.881c.529.411 1.003.886 1.414 1.414zm-5.311-3.033v-4.069h-2v4.069c.328-.041.66-.069 1-.069.34 0 .672.028 1 .069zm3.898 14.243l2.88 2.88 1.415-1.414-2.881-2.88c-.411.528-.885 1.002-1.414 1.414zm3.033-7.312c.041.328.069.661.069 1s-.028.672-.069 1h4.069v-2h-4.069zm-8.931 8.931v4.069h2v-4.069c-.328.041-.66.069-1 .069-.34 0-.672-.028-1-.069zm7-7.931c0 3.314-2.686 6-6 6-3.315 0-6-2.686-6-6s2.685-6 6-6c3.314 0 6 2.686 6 6zm-2 0c0-2.19-1.818-3.963-4-3.994 2.533 1.816 2.61 6.119 0 7.988 2.182-.031 4-1.804 4-3.994z"/></svg></el-tooltip>

              <el-tooltip v-if="disabledMedia" placement="top"
                          content="Media is disabled">
              <svg xmlns="http://www.w3.org/2000/svg"
                   class="w-6 h-6 text-orange-600"
                   fill="currentColor"
                   viewBox="0 0 24 24"><path
                  d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>
              </el-tooltip>

            </div>
          </div>
        </div>
        <div class="flex col-span-4 lg:col-span-1 items-center gap-3 justify-end">
          <el-tooltip content="Edit">
              <svg
                  @click="onListItemClick"
                  stroke-linejoin="round"
                   stroke-miterlimit="2"
                   class="w-7 h-7 text-blue-800 cursor-pointer"
                   fill="currentColor"
                   viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="m11.239 15.533c-1.045 3.004-1.238 3.451-1.238 3.84 0 .441.385.627.627.627.272 0 1.108-.301 3.829-1.249zm.888-.888 3.22 3.22 6.408-6.401c.163-.163.245-.376.245-.591 0-.213-.082-.427-.245-.591-.58-.579-1.458-1.457-2.039-2.036-.163-.163-.377-.245-.591-.245-.213 0-.428.082-.592.245zm-3.127-.895c0-.402-.356-.75-.75-.75-2.561 0-2.939 0-5.5 0-.394 0-.75.348-.75.75s.356.75.75.75h5.5c.394 0 .75-.348.75-.75zm5-3c0-.402-.356-.75-.75-.75-2.561 0-7.939 0-10.5 0-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75zm0-3c0-.402-.356-.75-.75-.75-2.561 0-7.939 0-10.5 0-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75zm0-3c0-.402-.356-.75-.75-.75-2.561 0-7.939 0-10.5 0-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75z" fill-rule="nonzero"/></svg>
         </el-tooltip>
          <el-tooltip content="Remove">
            <i @click="onRemoveItem"
               class="el-icon-delete cursor-pointer text-red-600 text-xl"></i>
          </el-tooltip>
          <svg xmlns="http://www.w3.org/2000/svg"
               class="h-5 w-5 text-gray-400 cursor-pointer"
               fill="none" viewBox="0 0 24 24" stroke="currentColor"
               stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaylistListItem',
  props: {
    customDurationEnabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    customDuration: {
      type: Number,
      default() {
        return null;
      },
    },
    duration: {
      type: Number,
      default() {
        return null;
      },
    },
    customAvailability: {
      type: Boolean,
      default() {
        return false;
      },
    },
    customWeatherAvailability: {
      type: Boolean,
      default() {
        return false;
      },
    },
    qrCodeEnabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabledMedia: {
      type: Boolean,
      default() {
        return false;
      },
    },
    bgColor: {
      type: String,
      default() {
        return null;
      },
    },
    mediaType: {
      type: String,
      default() {
        return null;
      },
    },
    screenshot: {
      type: String,
      default() {
        return null;
      },
    },
    name: {
      type: String,
      default() {
        return null;
      },
    },
    fileExtension: {
      type: String,
      default() {
        return null;
      },
    },
  },
  methods: {
    onListItemClick() {
      this.$emit('edit');
    },
    onRemoveItem() {
      this.$emit('remove');
    },
  },
};
</script>

<style scoped>

</style>
