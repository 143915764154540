var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sm:pt-2",class:{
'sm:grid sm:grid-cols-5 sm:gap-4 sm:items-start ': !_vm.clearLabel,
'w-full': _vm.clearLabel
}},[_c('label',{staticClass:"block text-sm font-medium text-gray-700 sm:mt-px col-span-2 sm:h-full sm:flex",class:{
    'mb-3': _vm.clearLabel,
    'sm:items-center': _vm.labelPosition === 'center',
            'sm:items-start sm:mt-2': _vm.labelPosition === 'start',
    }},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('el-tooltip',{attrs:{"disabled":_vm.tooltip === null,"content":_vm.tooltip,"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"text-center w-40 block",domProps:{"innerHTML":_vm._s(_vm.tooltip)}})]),(_vm.tooltip !== null)?_c('svg',{staticClass:"w-3 h-3 m-2 my-3 text-blue-600 inline-block flex-grow-0 cursor-pointer",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"currentColor","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"}})]):_vm._e()]),(_vm.required)?_c('span',{staticClass:"text-red-500 ml-1"},[_vm._v("*")]):_vm._e()],1),_c('div',{staticClass:"mt-1 sm:mt-0 sm:col-span-3"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }