var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"relative z-40",attrs:{"aria-labelledby":"modal-title","role":"dialog","aria-modal":"true"}},[_c('div',{staticClass:"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"}),_c('div',{staticClass:"fixed z-10 inset-0 overflow-y-auto"},[_c('div',{staticClass:"flex items-end sm:items-center justify-center min-h-full h-full"},[_c('div',{staticClass:"relative bg-white md:rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:max-w-2xl h-full modal--content-playlist"},[_c('div',{staticClass:"bg-white border-b border-gray-200 absolute flex p-4 w-full h-14"},[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900 flex-grow"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('button',{staticClass:"bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close")]),_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18L18 6M6 6l12 12"}})])])]),_c('div',{staticClass:"content h-full pt-14"},[_c('div',{staticClass:"flex w-full h-full"},[_c('div',{staticClass:"w-auto flex-grow bg-gray-700 overflow-scroll"},[_c('ul',_vm._l((_vm.videos),function(x,i){return _c('li',{key:x.id,staticClass:"p-2 py-4 flex items-center border-b select-none cursor-pointer",class:{
                                           'text-white bg-gray-900 hover:bg-gray-800 border-gray-800': i === _vm.current_video_index,
                                           'bg-white hover:bg-gray-50 text-gray-900 border-gray-200': i !== _vm.current_video_index,
                                           },on:{"click":function($event){return _vm.onVideoItemClick(i)}}},[_c('div',[(x.screenshot_src)?_c('img',{staticClass:"h-10 w-10 rounded border-2",style:({
                                                    'border-color': x.bg_color ? x.bg_color : '#000000',
                                                    }),attrs:{"src":x.screenshot_src}}):_c('div',{staticClass:"text-xl text-center w-10 h-10 flex justify-center items-center rounded border-2",style:({
                                                    'border-color': x.bg_color ? x.bg_color : '#000000',
                                                      'text-gray-900': i === _vm.current_video_index,
                                           'text-white': i !== _vm.current_video_index,
                                                    })},[(x.media_type === 'video' && !x.screenshot_src)?_c('i',{staticClass:"el-icon-video-camera"}):_vm._e(),(x.media_type === 'image'  && !x.screenshot_src)?_c('i',{staticClass:"el-icon-picture"}):_vm._e()])]),_c('div',{staticClass:"ml-1 flex-grow"},[_c('div',{staticClass:"min-w-0 flex-1 px-2 grid grid-cols-1"},[_c('p',{staticClass:"text-xs font-medium truncate"},[_vm._v(" "+_vm._s(x.name)+" ")])])]),_c('div',{staticClass:"ml-1 text-xs text-right"},[(_vm.current_video_duration && i === _vm.current_video_index)?_c('span',[_vm._v(" "+_vm._s(_vm._f("duration_mm")(_vm.current_video_time))+"/"+_vm._s(_vm._f("duration_mm")((x.custom_duration ? x.custom_duration : _vm.current_video_duration)))+" ")]):_vm._e(),(i !== _vm.current_video_index)?_c('span',[_vm._v(" "+_vm._s(_vm._f("duration_mm")((x.custom_duration ? x.custom_duration : x.duration)))+" ")]):_vm._e()])])}),0)]),_c('div',{staticClass:"w-1/2 relative",style:({
                                   'background-color': _vm.getActiveBackgroundColor
                                   })},[_c('playlist-item-q-r-code',{attrs:{"enabled":_vm.hasActiveQRCode,"text":_vm.getActiveVideoObject.qr_code_text ? _vm.getActiveVideoObject.qr_code_text : null,"position":_vm.getActiveVideoObject.qr_code_location ? _vm.getActiveVideoObject.qr_code_location : null,"positions":_vm.positions,"url":_vm.getActiveVideoObject.qr_code_url ? _vm.getActiveVideoObject.qr_code_url : null}}),_c('video',{ref:"videoObject",staticClass:"z-10 w-full h-full object-fit",attrs:{"muted":""},domProps:{"muted":true},on:{"timeupdate":_vm.onVideoTimeUpdated,"ended":_vm.onVideoEnded}},[_c('source',{attrs:{"src":_vm.getActiveVideoSrc}})])],1)])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }