<template>
    <div class="text-xs flex items-center uppercase" :class="[padding]">
        <span class="mr-3 text-gray-600">{{ label }}</span>
        <div class="w-auto flex-grow">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LabelOption',
  props: {
    label: {
      type: String,
    },
    padding: {
      type: String,
      default() {
        return 'p-2';
      },
    },
  },
};
</script>

<style scoped>

</style>
