import Vue from 'vue';
import moment from 'moment-timezone';
import prettyMilliseconds from 'pretty-ms';


Vue.filter('currency', (value, locale, currency) => {
  locale = locale || 'en-GB';
  currency = currency || 'GBP';
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
});
Vue.filter('duration', (value) => moment.utc(value * 1000).format('HH:mm:ss'));
Vue.filter('duration_mm', (value) => moment.utc(value * 1000).format('mm:ss'));
Vue.filter('localeString', (value) => (value ? value.toLocaleString() : 0));

Vue.filter('localTime', (locationTz) => moment().tz(locationTz).format('HH:mma'));

Vue.filter('localDate', (locationTz, format) => {
  format = format || 'MMMM Do YYYY';
  return moment().tz(locationTz).format(format);
});

Vue.filter('date', (value) => (moment(value).isValid() ? moment(value).format('MMMM Do YYYY') : value));

Vue.filter('secs_to_human', (value) => {
  return prettyMilliseconds(value * 1000);
  /*
  return hd(value * 1000, {
    largest: 2,
    units: ['d', 'm', 'h', 's']
  })

   */
});
