<template>
    <el-tag class="uppercase" :type="getStatusClass">
        {{ value }}
    </el-tag>
</template>

<script>
export default {
  name: 'MediaStatus',
  props: {
    value: {
      type: String,
      default() {
        return null;
      },
    },
  },
  computed: {
    getStatusClass() {
      switch (this.value) {
        case 'PAUSED':
          return 'warning';
        case 'ACTIVE':
          return 'success';
        case 'REJECTED':
          return 'danger';
      }
      return 'info';
    },
  },
};
</script>

<style scoped>

</style>
