<template>
    <div class="px-4 py-3 bg-white shadow rounded sm:p-6">
        <dt class="text-sm font-medium text-gray-700 truncate flex">
            <span class="uppercase">
            <slot name="title"></slot>
            </span>
            <span class="text-xs text-gray-400 ml-auto">
            <slot  name="subtitle"></slot>
            </span>
        </dt>
        <dd class="mt-1 text-2xl font-semibold text-gray-900">
            <div class="w-12 h-12 mt-3 mx-auto" v-if="loading" v-loading="true">
            </div>
            <slot v-if="!loading" name="value"></slot>
            <slot v-if="!loading" name="sparkline"></slot>
        </dd>
    </div>
</template>

<script>
export default {
  name: 'SimpleStatBox',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>

</style>
