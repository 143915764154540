<template>
  <div>
    <el-select
        :disabled="loading"
        :loading="loading"
        :multiple="multiple"
        :clearable="clearable"
        :filterable="filterable"
        :collapse-tags="collapseTags"
        @change="onChange"
        v-model="selected"
        class="w-full"
        placeholder="Search Pod Locations..."
    >
      <el-option
          class="el-option--item"
          v-for="(item, index) in pods"
          :key="index + '-pod-location'"
          :label="item.name + ' - ' + item.address"
          :value="item.id"
      >
        <div class="grid gap-4 grid-flow-col auto-cols-max">
          <div
              :class="{
            'w-16 h-16': showPrice,
            'w-12 h-12': !showPrice,
              }">
            <img
                class="rounded"
                :src="'https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=120x120&markers=color:red%7Clabel:C%7C' + item.lat + ',' + item.lng + '&key=AIzaSyAENZy2SL14YFJdl4T9OiaPIgWgKejcCPg'" />
          </div>
          <div>
            <div>
              <span class="font-semibold text-red-700">{{ item.name }} </span>  <span class="ml-2 text-xs"> {{ item.type }}</span>
            </div>
            <div class="flex text-xs items-center my-1">
                                       <span class="truncate">
                                       <i class="el-icon-location"></i> {{ item.address }}
                                       </span>
            </div>
            <div v-if="showPrice">
              From {{ item.default_price | currency($store.getters['auth/language'], item.currency)  }}
            </div>
          </div>
        </div>

      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'PodLocationSelect',
  props: {
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showPrice: {
      type: Boolean,
      default() {
        return true;
      },
    },
    selectDefault: {
      type: Boolean,
      default() {
        return false;
      },
    },
    filterable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    collapseTags: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      type: [String, Array],
      default() {
        return null;
      },
    },
  },
  watch: {
    value() {
      this.selected = this.value;
    },
  },
  data() {
    return {
      selected: null,
      loading: true,
      pods: [],
    };
  },
  created() {
    this.fetchPods();
  },
  methods: {
    onChange() {
      this.$emit('input', this.selected);
      this.$emit('change');
    },
    fetchPods() {
      const vm = this;
      vm.loading = true;
      vm.$http.get('/api/v1/pods')
        .then((response) => {
          vm.pods = response.data.data.pods;
          if (this.selectDefault) {
            vm.selected = vm.pods[0].id;
            vm.$emit('input', vm.selected);
          }
          vm.loading = false;
        }).catch(() => {
          vm.loading = false;
        });
    },
  },
};
</script>

<style>
    .el-option--item {
        height: auto !important;
        line-height: 1.2rem !important;
        padding: 0.5rem
    }
</style>
