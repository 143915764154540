<template>
    <page-wrapper title="Service Status" :show-feed="false">
      <template slot="content">

        <div class="w-full flex grid grid-cols-4 gap-6">
          <div class="lg:col-span-3 col-span-4">
            <dashboard-widget title="Chargabull Active Connections">

              <spin-loader v-if="pods.fetching" bg="transparent" height="h-32"/>
              <div v-else>
                <custom-tabs
                    split="w-1/3"
                    v-model="active_pod_tab"
                    :tabs="getPodTabs"
                />

                <div v-if="active_pod_tab === 'all'">

                  <ul role="list" class="divide-y divide-gray-200">
                    <li
                        :key="'pod-' + k"
                        v-for="(p, k) of allPods">
                      <pod-row :details="p" />
                    </li>
                  </ul>

                </div>
                <div v-if="active_pod_tab === 'online'">
                  <ul role="list" class="divide-y divide-gray-200">
                    <li
                        :key="'pod-' + k"
                        v-for="(p, k) of onlinePods">
                      <pod-row :details="p" />
                    </li>
                  </ul>
                </div>
                <div v-if="active_pod_tab === 'offline'">
                  <ul role="list" class="divide-y divide-gray-200">
                    <li
                        :key="'pod-' + k"
                        v-for="(p, k) of offlinePods">
                      <pod-row :details="p" />
                    </li>
                  </ul>
                </div>

              </div>

            </dashboard-widget>
          </div>
          <div class="lg:col-span-1 col-span-4">
            <dashboard-widget title="Services">

              <spin-loader v-if="services.fetching" bg="transparent" height="h-32"/>
              <div class="p-5 flex flex-wrap gap-3" v-else>
                <div :key="'service-' + i" v-for="(x, i) of services.data" class="w-full text-sm relative text-gray-800 flex items-center">
                <span class="flex relative h-3 w-3">
                  <span
                      :class="{
                    'bg-green-500': x.ok,
                      'bg-red-500': !x.ok,
                      }"
                      class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"></span>
                  <span
                      :class="{
                    'bg-green-500': x.ok,
                      'bg-red-500': !x.ok,
                      }"
                      class="relative inline-flex rounded-full h-3 w-3"></span>
                </span>
                  <span class="ml-2">{{ x.name }}</span>
                </div>
              </div>
            </dashboard-widget>
          </div>
        </div>

      </template>
    </page-wrapper>
</template>

<script>

import DashboardWidget from '@/components/DashboardWidget';
import SpinLoader from '@/components/SpinLoader';
import CustomTabs from '@/components/CustomTabs';
import PodRow from '@/components/Admin/PodRow';

export default {
  name: 'AdminStatusPage',
  components: {
    PodRow, CustomTabs, SpinLoader, DashboardWidget,
  },
  data() {
    return {
      active_pod_tab: 'all',
      services: {
        fetching: true,
        data: [],
      },
      pods: {
        fetching: true,
        data: [],
      },
    };
  },
  mounted() {
    this.fetchServices();
    this.fetchPods();
  },
  computed: {
    onlinePods() {
      return this.allPods.filter((x) => x.online);
    },
    offlinePods() {
      return this.allPods.filter((x) => !x.online);
    },
    allPods() {
      return this.pods.data && Array.isArray(this.pods.data) ? this.pods.data : [];
    },
    getPodTabs() {
      return [
        { value: 'all', name: `All (${this.allPods.length})` },
        { value: 'online', name: `Online (${this.onlinePods.length})` },
        { value: 'offline', name: `Offline (${this.offlinePods.length})` },
      ];
    },
  },
  methods: {
    fetchPods() {
      const vm = this;
      vm.pods.fetching = true;
      vm.pods.data = [];
      vm.$http.get('/api/v1/admin/pods')
        .then((response) => {
          vm.pods.data = response.data.data;
          vm.pods.fetching = false;
        }).catch(() => {
          vm.pods.fetching = false;
        });
    },
    fetchServices() {
      const vm = this;
      vm.services.fetching = true;
      vm.services.data = [];
      vm.$http.get('/api/v1/admin/services')
        .then((response) => {
          vm.services.data = response.data.data;
          vm.services.fetching = false;
        }).catch(() => {
          vm.services.fetching = false;
        });
    },
  },
};
</script>
