<template>
    <div class="bg-white w-56 leading-tight rounded text-xs overflow-hidden rounded shadow-lg">
        <h2 class="p-1 px-2 bg-gray-900 w-full text-white">
            <span class="block font-medium">{{ address }}</span>
        </h2>
        <div class="p-1 px-2 bg-white flex w-full gap-3">
            <p>{{ getType }}</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PoiTooltip',
  props: {
    address: {
      type: String,
      default() {
        return '';
      },
    },
    classification: {
      type: String,
      default() {
        return null;
      },
    },
    classification_items: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default() {
        return null;
      },
    },
  },
  computed: {
    getType() {
      const vm = this;
      const items = this.classification_items;
      const find = items.find((x) => x.id === vm.classification);
      console.log(find);
      return find ? find.label : null;
    },
  },
};
</script>

<style scoped>

</style>
