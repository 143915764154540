var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.showOverlay && _vm.show ? 'top-0  z-40 w-full top-0 h-full fixed' : ''},[_c('transition',{attrs:{"enter-active-class":"transition ease-in-out duration-300 transform","enter-class":"opacity-0","enter-to-class":"opacity-50","leave-active-class":"transition ease-in-out duration-300 transform","leave-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.show && _vm.showOverlay)?_c('div',{staticClass:"absolute w-full h-full bg-black bg-opacity-50",style:({
  'z-index': 5001
  })}):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"transition ease-in-out duration-300 transform","enter-class":_vm.position !== 'left' ? 'translate-x-full' : '-translate-x-full',"enter-to-class":"translate-x-0","leave-active-class":"transition ease-in-out duration-300 transform","leave-class":"translate-x-0","leave-to-class":_vm.position !== 'left' ? 'translate-x-full' : '-translate-x-full'}},[(_vm.show)?_c('div',{staticClass:"fixed h-full top-0 shadow-lg bg-white shadow el-block--sidebar-option w-full md:w-3/4 lg:w-1/2",class:{
      'left-0': _vm.position === 'left',
      'right-0': _vm.position !== 'left',
      customClass: _vm.customClass
      },style:({
  'z-index': 6001
  })},[_c('div',{staticClass:"h-full flex flex-col bg-white shadow-xl overflow-y-scroll bg-gray-50 pb-0"},[_c('div',{staticClass:"py-6 px-4 bg-red-700 sm:px-6"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('h2',{staticClass:"text-lg font-semibold text-white"},[_vm._t("title")],2),(_vm.showClose)?_c('div',{staticClass:"ml-3 h-7 flex items-center"},[_c('button',{staticClass:"rounded-md text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-white",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close panel")]),_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])])]):_vm._e()]),_c('div',{staticClass:"mt-0"},[_c('p',{staticClass:"text-xs text-white"},[_vm._t("subtitle")],2)])]),_c('div',{staticClass:"px-4 sm:px-6 bg-white h-full overflow-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200",class:{
                'relative': _vm.showActions
                }},[_c('div',{class:{
                  'absolute inset-0 px-4 sm:px-6': _vm.showActions
                  }},[_c('div',{staticClass:"py-6",class:{
            'pb-24': _vm.showActions
            }},[_vm._t("content")],2)])]),(_vm.showActions)?_c('div',{staticClass:"px-4 py-4 w-full flex justify-end bg-gray-200 shadow border-t border-gray-200 absolute bottom-0 right-0 z-30"},[_vm._t("actions")],2):_vm._e()])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }