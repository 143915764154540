<template>
    <l-map
      :zoom="opts.zoom"
      :options="{
            zoomControl: false,
            scrollWheelZoom:false,
            dragging: false
            }"
      :center="{lat: opts.lat, lng: opts.lon}">

      <l-marker
        :lat-lng="{lat: opts.lat, lng: opts.lon}">
        <l-icon>
          <div
            :class="{
                'animate-bounce': bounce
                }"
            class="w-8 h-8 bg-red-700 border-white border-2 shadow-lg text-white text-lg rounded-full flex items-center justify-center"><svg fill="currentColor" viewBox="0 0 1260 982" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="h-4 w-auto text-white"><g data-v-09512f3f="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g data-v-09512f3f="" fill="currentColor" fill-rule="nonzero"><polygon data-v-09512f3f="" id="Path" points="479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"></polygon><polygon data-v-09512f3f="" id="Path" points="1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"></polygon></g></g></svg></div>
        </l-icon>
      </l-marker>

      <l-google-tile-layer
        :apikey="$store.state.auth.google_maps.key"
        :options="$store.state.auth.google_maps.opts"/>

      <l-protobuf
        v-if="showOverlays"
        :options="poiOptions"
        url="https://media.chargabull.com/tiles/poi/2022-08-23/{z}/{x}/{y}.pbf"
      />

    </l-map>
</template>

<script>

import POI from "@/utils/points-of-interest";

export default {
  name: 'PreviewMapObject',
  props: {
    zoom: {
      type: Number,
      default() {
        return 17;
      },
    },
    bounce: {
      type: Boolean,
      default(){
        return true
      }
    },
    showOverlays: {
      type: Boolean,
      default(){
        return false
      }
    },
    lat: {
      type: Number,
      default() {
        return 0;
      },
    },
    lon: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  methods: {
    stylePOI(item) {
      const poiItems = POI;
      //const items = this.getPOIItemsList;
      const find = poiItems.find((x) => x.id === item.classification);
      return {
        weight: 0.5,
        radius: 7,
        fillColor: find ? find.color : '#fff',
        fillOpacity: 0.9,
        color: '#fff',
        fill: true,
      };
    }
  },
  data() {
    return {
      opts: {
        zoom: this.zoom,
        lat: this.lat,
        lon: this.lon,
      },
      poiOptions: {
          interactive: true,
          getFeatureId: (f) => f.properties.uprn,
          vectorTileLayerStyles: {
            poi: (item) => this.stylePOI(item),
          },
      }
    };
  },
};
</script>

<style scoped>

</style>
