var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-map',{attrs:{"zoom":_vm.opts.zoom,"options":{
        zoomControl: false,
        scrollWheelZoom:false,
        dragging: false
        },"center":{lat: _vm.opts.lat, lng: _vm.opts.lon}}},[_c('l-marker',{attrs:{"lat-lng":{lat: _vm.opts.lat, lng: _vm.opts.lon}}},[_c('l-icon',[_c('div',{staticClass:"w-8 h-8 bg-red-700 border-white border-2 shadow-lg text-white text-lg rounded-full flex items-center justify-center",class:{
            'animate-bounce': _vm.bounce
            }},[_c('svg',{staticClass:"h-4 w-auto text-white",attrs:{"fill":"currentColor","viewBox":"0 0 1260 982","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('g',{attrs:{"data-v-09512f3f":"","stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd"}},[_c('g',{attrs:{"data-v-09512f3f":"","fill":"currentColor","fill-rule":"nonzero"}},[_c('polygon',{attrs:{"data-v-09512f3f":"","id":"Path","points":"479.28 520.07 529.6 0 428.54 93.82 0.66 171.88 95.37 410.88 49.99 600.67 228.25 437.51 210.84 321.02 363.88 395.6 449.64 796.8 401.68 857.59 478.68 981.79 478.69 981.91 568.45 981.91 632.36 527.88"}}),_c('polygon',{attrs:{"data-v-09512f3f":"","id":"Path","points":"1260 171.88 832.12 93.82 741.58 9.77 668.86 376.71 820.06 384.52 618.3 907.47 618.3 907.47 589.58 981.91 596.7 981.91 781.97 981.91 781.98 981.79 858.98 857.59 811.02 796.8 896.78 395.6 1049.83 321.02 1032.41 437.51 1210.67 600.67 1165.29 410.88"}})])])])])])],1),_c('l-google-tile-layer',{attrs:{"apikey":_vm.$store.state.auth.google_maps.key,"options":_vm.$store.state.auth.google_maps.opts}}),(_vm.showOverlays)?_c('l-protobuf',{attrs:{"options":_vm.poiOptions,"url":"https://media.chargabull.com/tiles/poi/2022-08-23/{z}/{x}/{y}.pbf"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }