<template>
    <div class="flex flex-wrap items-center" v-if="total > 0">
            <div class="text-sm p-1 md:block w-full lg:w-1/3">
               {{ total ? total.toLocaleString() : 0 }} {{ type }} found. Displaying page {{ currentPage }} of {{ totalPages === 0  ? 1 : totalPages }}
            </div>
            <div class="w-full md:p-1 md:w-2/3 flex flex-wrap gap-3 justify-center md:justify-end ml-auto">
              <div class="w-full flex justify-end lg:w-auto">
                <slot name="actions"></slot>
              </div>
                <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="(page)=>{ $emit('change', page) }"
                        :page-size="rpp"
                        :current-page="currentPage"
                        :total="total">
                </el-pagination>
            </div>
    </div>
</template>

<script>
export default {
  name: 'pagination-details',
  props: {
    type: {
      type: String,
      default() {
        return 'items';
      },
    },
    total: {
      type: Number,
      default() {
        return 0;
      },
    },
    currentPage: {
      type: Number,
      default() {
        return 0;
      },
    },
    totalPages: {
      type: Number,
      default() {
        return 0;
      },
    },
    rpp: {
      type: Number,
      default() {
        return 10;
      },
    },
    current: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
};
</script>
