<template>
    <div
            class="border-b border-gray-200">
        <nav class="-mb-px flex overflow-y-auto whitespace-nowrap overflow-y-scroll" aria-label="Tabs">
            <!-- Current: "border-red-500 text-red-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->

            <button
                    :key="k"
                    v-for="(x, k) of tabs"
                    @click="$emit('input', x.value)"
                    :class="x.value === value ? getActiveClass : getInactiveClass">
            {{ x.name }}</button>

        </nav>
    </div>

</template>

<script>
export default {
  name: 'CustomTabs',
  props: {
    value: {
      type: String,
      default() {
        return null;
      },
    },
    split: {
      type: String,
      default() {
        return 'w-1/4';
      },
    },
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    getActiveClass() {
      return `border-red-500 text-red-600 p-3 text-center border-b-2 font-medium text-sm w-auto md:${this.split}`;
    },
    getInactiveClass() {
      return `border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 p-3 text-center border-b-2 font-medium text-sm w-auto md:${this.split}`;
    },
  },
};
</script>

<style scoped>

</style>
